import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IPosition } from '@/interfaces/position';
import { IAmmDetail } from '@/interfaces/amm';
import { IFuturesDetail } from '@/interfaces/futures';

export const resetState = createAction<void>('position/resetState');

export const savePositions = createAction<{ positions: IPosition[] }>('position/savePositions');
export const isFetchingData = createAction<{ isFetching: boolean }>('position/isFetchingData');
export const addPosition = createAction<{ position: IPosition }>('position/addPosition');
export const deletePosition = createAction<{ position: IPosition }>('position/deletePosition');
export const updatePosition = createAction<{ position: IPosition }>('position/updatePosition');
export const updatePositionDetail = createAction<{ positionID: string; positionDetail: IPosition }>(
  'position/updatePositionDetail',
);
export const updatePositionAmmDetail = createAction<{ positionID: string; ammDetail: IAmmDetail }>(
  'position/updatePositionAmmDetail',
);
export const changeCurrentPosition = createAction<{ position: IPosition }>('position/changeCurrentPosition');
export const resetCurrentPosition = createAction<{}>('position/resetCurrentPosition');
export const updateCurrentPositionFuturesDetail = createAction<{ position: IPosition; futuresDetail: IFuturesDetail }>(
  'position/updateCurrentPositionFuturesDetail',
);

export const isLoadingPositionDetail = createAction<{ positionID: string; isLoading: boolean }>(
  'position/isLoadingPositionDetail',
);

export const isLoadingPositionAmmDetail = createAction<{ positionID: string; isLoading: boolean }>(
  'position/isLoadingPositionAmmDetail',
);
