import React, { ReactNode } from 'react';
import Web3 from 'web3';
import { EventLog, TransactionReceipt, TransactionConfig } from 'web3-core';
import { AbiInput, AbiItem, Unit } from 'web3-utils';
import { ITransactionError, IRevertReason } from '@/interfaces/error';
import { SYNFUTURES_DOCS_PARAMETERS_LINK, PARSE_RECEIPT_ERROR } from '@/constants';
import Mapping from '@/locales/en/mapping.json';
import { GaCategory, gaEvent, gaException } from '@/utils/gaUtil';
import { chainConfig } from '@/constants/chain';

export const MappingObject: { [index: string]: any } = Mapping;

/**
 * 获取revert失败的原因
 * @param web3 web3
 * @param sendingOptions 发送交易的参数
 */
export const getRevertReason = async (web3: Web3, sendingOptions: TransactionConfig): Promise<IRevertReason> => {
  let reason: IRevertReason = {
    reason: '',
    type: 'string',
  };
  try {
    const res = await web3.eth.call(sendingOptions);
    // 解析异常
    // 对于bsc上异常是直接返回的
    if (res && res.startsWith('0x')) {
      reason = revertReasonParse(web3, res);
    }
  } catch (error) {
    gaException(error.message);
    let errMessage: string = error.message.toString().trim();
    if (errMessage.startsWith('VM execution error.\nReverted ')) {
      errMessage = errMessage.substr(29);
      reason = revertReasonParse(web3, errMessage);
      console.log('Bad tx', 'Revert reason:', reason);
    } else {
      // bsc返回的是以Internal JSON-RPC error...为开头的字符串！！
      // 只能用正则查找对应hex，并解析
      const matches = errMessage.match(/0x[A-Fa-f0-9]+/);
      if (matches && matches[0]) {
        reason = revertReasonParse(web3, matches[0]);
      }
    }
  } finally {
    return reason;
  }
};

/**
 * 转化reason hex成字符串
 * @param reasonData reason
 * @param web3 web3
 * @returns
 */
function parseHexToReasonStr(reasonData: string, web3: Web3): string {
  let res = '';
  try {
    res = web3.utils.hexToUtf8(reasonData);
  } catch (error) {
    gaException(error.message);
    res = web3.utils.toAscii(reasonData);
  }
  return res;
}

export const getWrappedReason = (reason: string): IRevertReason => {
  // 特殊字符串，添加learn more链接
  const mappingReason = MappingObject[`${reason}`] || reason;
  if (
    reason.includes('user max OI ratio') ||
    reason.includes('block slippage breached') ||
    reason.includes(`amm's position too low`)
  ) {
    return {
      type: 'html',
      reason: mappingReason,
      reasonHtml: (
        <span>
          {mappingReason}.{' '}
          <a href={SYNFUTURES_DOCS_PARAMETERS_LINK} rel="noreferrer noopener" className="syn-link" target="_blank">
            Learn more
          </a>
        </span>
      ),
    };
  } else {
    return { type: 'string', reason: mappingReason };
  }
};

/**
 * 解析错误原因，并处理
 * @param reason 错误原因
 */
function revertReasonParse(web3: Web3, reasonData: string): IRevertReason {
  let reason: IRevertReason = {
    reason: '',
    type: 'string',
  };
  if (reasonData && reasonData.startsWith('0x')) {
    reasonData = `0x${reasonData.substr(138)}`; // 去除特殊字符
    const reasonStr = parseHexToReasonStr(reasonData, web3);
    reason = getWrappedReason(reasonStr);
  }
  return reason;
}

const errorNotifyWrap = (
  error: ITransactionError,
  receipt: TransactionReceipt,
  defaultMessage?: ReactNode,
): ReactNode => {
  let res = defaultMessage;
  // 有revertReason，显示
  if (error.revertReason) {
    res = <span>{error.revertReason.reason}</span>;
    // TODO: 临时禁用链接，因为点击后还是跳转到tx了
    // if (error.revertReason.type === 'html') {
    //   res = error.revertReason.reasonHtml;
    // }
  }
  // 有receipt，提取tx
  if (receipt) {
    res = MappingObject[`${res}`] || res;
  }
  return res;
};

/**
 * 给合约发送交易
 * @param fn 合约method
 * @param params 参数
 * @param sendingOptions 发送tx参数
 * @param onAfterSendTx 发送tx后回调
 */
export const sendTransactionWrap = (
  web3: Web3,
  fn: (...params: any[]) => any,
  params: any[],
  sendingOptions: TransactionConfig,
  methodName?: string,
  onAfterSendTx?: (result: string) => void,
): Promise<TransactionReceipt> => {
  return new Promise<TransactionReceipt>((resolve, reject) => {
    // ReactGaEvent

    // console.log(` \n\n\n\n\n\n  fn-name1`, fn.name);
    // console.log(` \n\n\n\n\n\n  fn-name2`, fn.toString());
    let txConfirmationNumber = 0;
    let txHash = '';
    fn(...params)
      .send(sendingOptions)
      .on('transactionHash', function(hash: string) {
        txHash = hash;
        // ### ReactGAEvent ###
        const sendTransactionParams = {
          method: methodName,
          hash,
          ...params,
          ...sendingOptions,
        };
        console.log(`GoogleAnalytics:${JSON.stringify(sendTransactionParams)}`);
        gaEvent(GaCategory.DEBUG, 'sendTransaction', JSON.stringify(sendTransactionParams));
        // ### ReactGAEvent - END ###

        if (onAfterSendTx) {
          onAfterSendTx(hash);
        }
      })
      .on('receipt', function(receipt: TransactionReceipt) {
        resolve(receipt);
      })
      .on('confirmation', function(confirmationNumber: number, receipt: TransactionReceipt) {
        txConfirmationNumber = confirmationNumber;
        if (confirmationNumber === 0) {
          resolve(receipt);
        }
      })
      .on('error', async function(error: ITransactionError, receipt: TransactionReceipt) {
        console.log('🚀 ~ file: contract.tsx ~ line 165 ~ .on ~ error', error, receipt);
        // 50个块后未确认，infura就直接reject了
        // Error: Transaction was not mined within 50 blocks, please make sure your transaction was properly sent. Be aware that it might still be mined!
        if (error.message.includes('not mined within 50 blocks')) {
          // 定制获取receipt来判断异常还是成功
          const handle = setInterval(() => {
            txHash &&
              web3.eth.getTransactionReceipt(txHash).then(async (resp) => {
                console.log('🚀 ~ file: contract.tsx ~ line 195 ~ web3.eth.getTransactionReceipt ~ resp', resp);
                if (resp != null && resp.blockNumber > 0) {
                  clearInterval(handle);
                  if (resp.status) {
                    resolve(resp);
                  } else {
                    // 调用则为error.receipt
                    error.revertReason = await getRevertReason(web3, sendingOptions);
                    error.notifyWrap = errorNotifyWrap.bind(null, error, resp);
                    reject(error);
                  }
                }
              });
          }, chainConfig.BLOCK_TIME_INTERVAL);
        } else {
          // MetaMask Tx Signature: User denied transaction signature.
          if (!(error.code && error.code === 4001)) {
            // 调用则为error.receipt
            error.revertReason = await getRevertReason(web3, sendingOptions);
            error.notifyWrap = errorNotifyWrap.bind(null, error, receipt);
          }
          reject(error);
        }
      });
  });
};

export const parseReceipt = (web3: Web3, receipt: TransactionReceipt, abis: any) => {
  const result: any = {};
  const eventAbis: any = {};

  try {
    for (const abiItem of abis) {
      if (abiItem.type === 'event') {
        const eventSignature = web3.eth.abi.encodeEventSignature(abiItem as AbiItem);
        eventAbis[eventSignature] = abiItem;
      }
    }
    for (const key of Object.keys(receipt.events!)) {
      const base = receipt.events![key];
      if (Array.isArray(base)) {
        for (let i = 0; i < base.length; i++) {
          const item = base[i].raw;
          const abiItem = eventAbis[item.topics[0]];
          if (abiItem && abiItem.inputs) {
            const decoded = web3.eth.abi.decodeLog(abiItem.inputs, item.data, item.topics.slice(1));
            result[abiItem.name] = JSON.parse(JSON.stringify(decoded));
          }
        }
      } else {
        const item = receipt.events![key]?.raw!;
        const abiItem = eventAbis[item.topics[0]];

        if (abiItem && abiItem.inputs) {
          console.log(`abiItem`, abiItem, `abiItem.inputs`, abiItem.inputs);
          const decoded = web3.eth.abi.decodeLog(abiItem.inputs, item.data, item.topics.slice(1));
          // console.log(`\n [parseReceipt]::`, decoded, `\n`, JSON.parse(JSON.stringify(decoded)));
          // 去掉返回对象中的 [`Result `] 前缀
          result[abiItem.name] = JSON.parse(JSON.stringify(decoded));
          console.log(`\n [parseReceipt]:: --->abiItem:::decoded: ${abiItem.name}`, decoded);
        }
      }
    }

    return MappingObject[`${result}`] || result;
  } catch (error) {
    gaException(error.message);
    console.log(error);
    return PARSE_RECEIPT_ERROR;
  }
};

export const simulate = async (
  web3: Web3,
  account: string,
  to: string,
  data: string,
): Promise<{ reason: ReactNode }> => {
  // ### ReactGAEvent ###
  const simulateParams = {
    method: 'simulate',
    account,
    to,
    data,
  };
  console.log(`GoogleAnalytics:${JSON.stringify(simulateParams)}`);
  gaEvent(GaCategory.DEBUG, 'simulate', JSON.stringify(simulateParams));
  // ### ReactGAEvent - END ###
  const reason = await getRevertReason(web3, { from: account, to: to, data: data });
  console.log('🚀 ~ file: contract.tsx ~ line 248 ~ reason', reason);
  return {
    reason: reason.type === 'html' ? reason.reasonHtml : reason.reason,
  };
};
