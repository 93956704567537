import React from 'react';
import { TRADE_DIRECTION } from '@/constants';
import IconBuy from '@/assets/svg/trade/icon_buy.svg';
import IconBuyWhite from '@/assets/svg/trade/icon_buy_w.svg';
import IconSell from '@/assets/svg/trade/icon_sell.svg';
import IconSellWhite from '@/assets/svg/trade/icon_sell_w.svg';
import { useTradeHook } from '@/state/trade';
import './TradeTabs.scss';

export default function TradeTabs(): JSX.Element {
  const { onChangeOrderSide, tradeSide } = useTradeHook();
  return (
    <div className={`trade-tabs ${tradeSide === TRADE_DIRECTION.LONG ? 'trade-tabs_long' : 'trade-tabs_short'}`}>
      <div
        className={`trade-tab ${tradeSide === TRADE_DIRECTION.LONG && 'trade-tab_current'}`}
        onClick={(): void => {
          onChangeOrderSide(TRADE_DIRECTION.LONG);
        }}>
        <img src={tradeSide === TRADE_DIRECTION.LONG ? IconBuyWhite : IconBuy} alt="buy tab" /> Long / Buy
      </div>
      <div
        className={`trade-tab ${tradeSide === TRADE_DIRECTION.SHORT && 'trade-tab_current'}`}
        onClick={(): void => {
          onChangeOrderSide(TRADE_DIRECTION.SHORT);
        }}>
        <img src={tradeSide === TRADE_DIRECTION.SHORT ? IconSellWhite : IconSell} alt="buy tab" /> Short / Sell
      </div>
    </div>
  );
}
