import { createAction } from '@reduxjs/toolkit';

// export const selectCurrency = createAction<{ field: Field; currencyId: string }>('swap/selectCurrency')
// export const selectToken = createAction<{ field: Field; token: string }>('swap/selectToken')
// export const aggragateToken = createAction<{ token: TokenDTO }>('swap/aggragateToken')

export enum RedirectFromEnum {
  INIT_CREATE_POOL = 'INIT_CREATE_POOL',
  ROUTE_CREATE_POOL = 'ROUTE_CREATE_POOL',

  FROM_CREATE_POOL = 'FROM_CREATE_POOL',
  POOL_LIST_RECORD = 'POOL_LIST_RECORD',
  POOL_PAGE_BUTTON = 'POOL_PAGE_BUTTON',
}

// create action
export const createPoolModalVisible = createAction<{ visible: boolean }>(`options/createPoolModalVisible`);
export const addLiquidityModalVisible = createAction<{ visible: boolean }>('options/addLiquidityModalVisible');

export const removeLiquidityModalVisible = createAction<{ visible: boolean }>('options/removeLiquidityModalVisible');

export const updateRedirectFrom = createAction<{ from: RedirectFromEnum }>('options/updateRedirectFrom');
// selected variable
export const updateRecordCache = createAction<{ record: object }>('options/updateRecordCache');
export const updateGlobalConfig = createAction<{ configs: object }>('options/updateGlobalConfig');

// initial variable
export const updateInitialPrice = createAction<{ value: string }>('options/updateInitialPrice');
export const updateInitialAmount = createAction<{ value: string }>('options/updateInitialAmount');

export const updateRouterParams = createAction<{ params: string }>('options/updateRouterParams');

export const test = createAction('TEST');
