/**
 * 产品类型
 */
export enum PRODUCT_TYPE {
  BASIC = 'BASIC', // 基础产品
  DIFFICULTY = 'DIFFICULTY', // 难度产品
}

/**
 *  可用的产品类型，用于控制显示的产品及交互（如只显示basic产品）
 */
let AVAILABLE_PRODUCTS: PRODUCT_TYPE[] = [];
// 读取环境变量配置
if (process.env.REACT_APP_PRODUCTS) {
  AVAILABLE_PRODUCTS = process.env.REACT_APP_PRODUCTS.split(',').map((type) => type as PRODUCT_TYPE);
}

/**
 * 产品发布环境
 * 如：prod环境会禁用mint/faucet等功能
 */
export enum PRODUCT_RELEASE_ENV {
  DEV = 'dev', // 开发
  PRE = 'pre', // 预发布
  PROD = 'prod', // 线上
}

/**
 * 当前产品发布环境
 */
export const currentProductReleaseEnv = (process.env.REACT_APP_PRODUCT_RELEASE_ENV || 'dev') as PRODUCT_RELEASE_ENV;

export { AVAILABLE_PRODUCTS };
