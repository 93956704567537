import { createReducer } from '@reduxjs/toolkit';
import { IContractGlobalConfig, IProductContractGlobalConfigs, IMarginsParam } from '@/interfaces/global';
import {
  saveGlobalConfig,
  saveGlobalMarginParam,
  loadLocalGlobalConfig,
  loadLocalGlobalMargins,
  changeProduct,
  updateCurrentUTCTime,
  changeCurrentChainId,
} from './actions';
import { PRODUCT_TYPE } from '@/constants/product';

const DEFAULT_CONFIG: IContractGlobalConfig = {
  emaTimeConstant: 0,
  poolFeeRatio: 0,
  poolReserveFeeRatio: 0,
  maxPriceSlippageRatio: 0,
  maxInitialDailyBasis: 0,
  maxUserTradeOpenInterestRatio: 0,
  minAmmOpenInterestRatio: 0,
  maxSpotIndexChangePerSecondRatio: 0,
  initialMarginRatio: 0,
  maintenanceMarginRatio: 0,
  bankruptcyLiquidatorRewardRatio: 0,
  insurancePremiumRatio: 0,
};

const DEFAYLT_PARAM: IMarginsParam = {
  USDC: {
    allowed: false,
    alignToFriday: false,
    updateReward: 0,
  },
  WBTC: {
    allowed: false,
    alignToFriday: false,
    updateReward: 0,
  },
};

export type GlobalState = {
  readonly globalConfigs: IProductContractGlobalConfigs;
  readonly marginsParam: IMarginsParam;
  readonly currentProductType: PRODUCT_TYPE; //当前产品类型
  readonly currentUTCTime: number; // 当前UTC时间
  readonly currentChainId: number;
};

const initialState: GlobalState = {
  globalConfigs: {
    [PRODUCT_TYPE.BASIC]: DEFAULT_CONFIG,
    [PRODUCT_TYPE.DIFFICULTY]: DEFAULT_CONFIG,
  },
  marginsParam: DEFAYLT_PARAM,
  currentProductType: PRODUCT_TYPE.BASIC,
  currentUTCTime: 0,
  currentChainId: 0,
};

export default createReducer<GlobalState>(initialState, (builder) =>
  builder
    .addCase(saveGlobalConfig, (state, { payload: { config, productType } }) => {
      state.globalConfigs[productType] = config;
      localStorage.setItem('syn_global_configs', JSON.stringify(state.globalConfigs));
    })
    .addCase(saveGlobalMarginParam, (state, { payload: { params } }) => {
      state.marginsParam = params;
      localStorage.setItem('syn_global_margins_param', JSON.stringify(state.marginsParam));
    })
    .addCase(loadLocalGlobalConfig, (state, { payload: {} }) => {
      const localConfigData = localStorage.getItem('syn_global_configs');
      if (localConfigData) {
        const configs = JSON.parse(localConfigData);
        state.globalConfigs = {
          ...state.globalConfigs,
          ...configs,
        };
      }
    })
    .addCase(loadLocalGlobalMargins, (state, { payload: {} }) => {
      const localMarginsParamData = localStorage.getItem('syn_global_margins_param');
      if (localMarginsParamData) {
        const marginsParam: IMarginsParam = JSON.parse(localMarginsParamData);
        state.marginsParam = {
          ...state.marginsParam,
          ...marginsParam,
        };
      }
    })
    .addCase(changeProduct, (state, { payload: { params } }) => {
      state.currentProductType = params;
    })
    .addCase(updateCurrentUTCTime, (state, { payload: { time } }) => {
      state.currentUTCTime = time;
    })
    .addCase(changeCurrentChainId, (state, { payload: { chainId } }) => {
      state.currentChainId = chainId;
    }),
);
