import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { ICoinBalance, ICoinBalanceList, ICoinBalanceDisplay } from '@/interfaces/balance';

export const resetState = createAction<void>('balance/resetState');

export const updateBalanceListPrice = createAction<{ priceBalanceList: ICoinBalanceList }>(
  'balance/updateBalanceListPrice',
);
export const isFetchingData = createAction<{ isFetching: boolean }>('balance/isFetchingData');
export const updateCoinBalance = createAction<{ coinBalance: ICoinBalanceDisplay }>('balance/updateCoinBalance');
export const updateCoinWalletBalance = createAction<{ coinBalance: ICoinBalance; walletBalance: string }>(
  'balance/updateCoinWalletBalance',
);

export const isLoadingCoinWalletBalance = createAction<{ coinBalance: ICoinBalance; isLoading: boolean }>(
  'balance/isLoadingCoinWalletBalance',
);
