import { createReducer } from '@reduxjs/toolkit';
import {
  resetState,
  updateBalanceListPrice,
  isFetchingData,
  updateCoinBalance,
  updateCoinWalletBalance,
  isLoadingCoinWalletBalance,
} from './actions';
import { ICoinBalance, ICoinBalanceDisplay, ICoinBalanceList } from '@/interfaces/balance';
import { chainConfig } from '@/constants/chain';
import { mapValues } from 'lodash';

export type BalanceState = {
  readonly balanceList: ICoinBalanceList;
  /**
   * 是否在加载链上数据
   *
   * @type {boolean}
   */
  readonly isFetching: boolean;
};

const initialState: BalanceState = {
  balanceList: mapValues(chainConfig.quotesCoinConfig, (coin) => {
    return {
      ...coin,
      walletBalance: '0',
      balance: 0,
      estimatedValue: 0,
      isLoadingMarginBalance: false,
      isLoadingWalletBalance: false,
    } as ICoinBalanceDisplay;
  }),
  isFetching: false,
};

export default createReducer<BalanceState>(initialState, (builder) =>
  builder
    .addCase(resetState, () => initialState)
    .addCase(isFetchingData, (state, { payload: { isFetching } }) => {
      state.isFetching = isFetching;
    })
    .addCase(updateBalanceListPrice, (state, { payload: { priceBalanceList } }) => {
      Object.values(priceBalanceList).forEach((price) => {
        state.balanceList[price.symbol].coinPrice = price.coinPrice;
        state.balanceList[price.symbol].coingeckoSymbol = price.coingeckoSymbol;
      });
    })
    .addCase(updateCoinBalance, (state, { payload: { coinBalance } }) => {
      state.balanceList[coinBalance.symbol] = coinBalance;
    })
    .addCase(updateCoinWalletBalance, (state, { payload: { coinBalance, walletBalance } }) => {
      state.balanceList[coinBalance.symbol].walletBalance = walletBalance;
    })
    .addCase(isLoadingCoinWalletBalance, (state, { payload: { coinBalance, isLoading } }) => {
      state.balanceList[coinBalance.symbol].isLoadingWalletBalance = isLoading;
    }),
);
