/**
 *
 *
 * @export 获取块高列表
 * @param {number[]} [excludeBlocks] 排除的高度
 * @param {number} [startBlock=0] 当前块高度
 * @param {number} [listCount=24] 列表总数，24相当于1年
 * @param {number} [difficultyRetargeting=2016] 难度调整系数，2016是BTC调整系数
 * @returns {number[]}
 */
export function getBlockHeightList(
  excludeBlocks: number[],
  startBlock = 0,
  listCount = 24,
  difficultyRetargeting = 2016,
): number[] {
  const res = [...Array(excludeBlocks.length + listCount).keys()]
    .map((i) => {
      return (Math.floor(startBlock / difficultyRetargeting) + i + 1) * difficultyRetargeting;
    })
    .filter((blockHeight) => !excludeBlocks.includes(blockHeight));
  return res;
}
