import { Contract } from '@ethersproject/contracts';
import { getAddress } from '@ethersproject/address';
import { AddressZero } from '@ethersproject/constants';
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import { BigNumber } from '@ethersproject/bignumber';
import { AMM_STATUS, DIFF_BASE_NAME, ORACLE_TYPE } from '@/constants/config';
import { JSBI, Percent, Token, CurrencyAmount, Currency, ETHER } from '@uniswap/sdk';
import { TokenAddressMap } from '@/state/lists/hooks';
import { chainConfig, ChainId } from '@/constants/chain';
import { gaException } from '@/utils/gaUtil';

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch (error) {
    gaException(error.message);
    return false;
  }
}

export function getEtherscanLink(data: string, type: 'transaction' | 'token' | 'address' | 'block'): string {
  const prefix = chainConfig.ETHERSCAN_PREFIX;

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`;
    }
    case 'token': {
      return `${prefix}/token/${data}`;
    }
    case 'block': {
      return `${prefix}/block/${data}`;
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`;
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

/**
 * 显示短地址，不会验证地址格式
 * 地址前6后4
 * @param {number} address 地址
 */
export const toShortAddr = (address: string, length = 4): string => {
  if (address) {
    if (address.length > length * 2) {
      return `${address.substring(0, length + 2)}...${address.substr(-length)}`;
    } else {
      return address;
    }
  }
  return '';
};

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000));
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000));
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`);
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
  ];
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any);
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === ETHER) return true;
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address]);
}

/**
 * 根据symbol判断是否是难度产品
 *
 * @export
 * @param {string} symbol 类似'BTCMD-WBTC-681408-SFT'
 * @returns {boolean}
 */
export function isDiffProductSymbol(symbol: string): boolean {
  const splits = symbol.split('-');
  return splits.length === 4 && isBtcMiningDifficulty(splits[0]);
}

/**
 *  根据baseName判断是否是难度产品
 * @param baseName 选择的baseName
 */
export function isBtcMiningDifficulty(baseName: string): boolean {
  return baseName === chainConfig.coinListConfig[DIFF_BASE_NAME].symbol;
}

/**
 * 根据简称获取oracleType
 * @param symbol 类型 UNI\LINK\SFT
 */
export function getOracleTypeByCoinSymbol(symbol: string): ORACLE_TYPE {
  let res = chainConfig.defaultOracleType;
  const oracle = Object.values(chainConfig.oracleListConfig).find((oracle) => oracle.symbol === symbol);
  if (oracle) {
    res = oracle.type;
  }
  return res;
}

/**
 * 获取oracle symbol
 * @param oracleType oracleType
 */
export function getSymbolByOracleType(oracleType: ORACLE_TYPE): string {
  return chainConfig.oracleListConfig[oracleType].symbol;
}

/**
 * 轮询
 * @param {Function} func 轮询方法
 * @param {Number} interval 轮询时间
 */
export const pollingFunc = (func: () => void, interval = 3000, immediately = true): NodeJS.Timeout => {
  if (immediately) {
    func();
  }
  return setInterval(() => {
    func();
  }, interval);
};

/**
 * 根据合约返回的状态数值返回AMM状态枚举
 * @param statusNum 合约状态码
 * @returns AMM_STATUS
 */
export const getAmmStatusByNum = (statusNum: number): AMM_STATUS => {
  const AMM_STATUS_ARR = Object.values(AMM_STATUS);
  return AMM_STATUS_ARR[statusNum];
};

/**
 * 根据symbol获取base\quote
 * 从之前的ammDetailDTO挪过来的
 * TODO：用别的方法代替
 * @param symbol
 * @returns
 */
export function getBaseQuoteBySymbol(symbol: string): [string, string] {
  if (symbol) {
    const arr = symbol.split('-');
    return [arr[0], arr[1]];
  }
  return ['', ''];
}

/**
 * 获取chain Name
 * @param chainId
 * @returns
 */
export function getChainName(chainId: ChainId | number): string {
  let chainName = '';
  switch (chainId) {
    case ChainId.MAINNET:
      chainName = 'Ethereum';
      break;
    case ChainId.ROPSTEN:
      chainName = 'Ropsten';
      break;
    case ChainId.RINKEBY:
      chainName = 'Rinkeby';
      break;
    case ChainId.GÖRLI:
      chainName = 'Goerli';
      break;
    case ChainId.KOVAN:
      chainName = 'Kovan';
      break;
    case ChainId.BSC:
      chainName = 'BSC';
      break;

    default:
      break;
  }

  return chainName;
}
