import { PRODUCT_TYPE } from '@/constants/product';
import { AddressConfig, ProductAddressConfig } from '@/interfaces/config';

/**
 * V1基础合约配置
 */
const BASE_CONTRACT_ADDRESS_CONFIG: AddressConfig = {
  ConfigAddr: '0x20c8884Ec2C30C47Ba001E59FA621C7D0C1f0324',
  FactoryAddr: '0xD21a8dd2C5d6358aD6c03cc66361D52642957ce6',
  ReaderAddr: '0x39542282366594Da643a0B2871D5AF07b6E6Fc18',
};

/**
 * 难度产品合约配置
 */
const DIFF_CONTRACT_ADDRESS_CONFIG: AddressConfig = {
  ConfigAddr: '0x377Cf0C1c2C01D671bCb2362514C1284364ccB45',
  OracleAddr: '0x55e5de4Ac80883c84fbeAa4873CB99b6221F1aA3',
  FactoryAddr: '0x68dB178C5B065dE43d370dC3f49255F262a52BcE',
  ReaderAddr: '0xb9ACD144be346db534B91FdEbbBb6c943E18B6A3',
};

/**
 * contract Address 配置
 */
export const CONTRACT_ADDRESS: ProductAddressConfig = {
  [PRODUCT_TYPE.BASIC]: BASE_CONTRACT_ADDRESS_CONFIG,
  [PRODUCT_TYPE.DIFFICULTY]: DIFF_CONTRACT_ADDRESS_CONFIG,
};
