import React from 'react';
import { notification, Button, Modal, Steps } from 'antd';
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { ArgsProps, IconType } from 'antd/lib/notification';
import { getEtherscanLink } from '@/utils';
import './index.scss';

const typeToIcon = {
  success: CheckCircleOutlined,
  info: InfoCircleOutlined,
  error: CloseCircleOutlined,
  warning: ExclamationCircleOutlined,
};

interface INotificationArgs extends ArgsProps {
  type?: IconType;
  tx?: string;
}

/**
 * Notification封装，添加点击效果等
 * @param args Notification参数
 */
function openNotification(args: INotificationArgs): void {
  let iconNode: React.ReactNode = null;
  if (args.type) {
    const prefix = 'ant-notification-notice';
    iconNode = React.createElement(typeToIcon[args.type] || null, {
      className: `${prefix}-icon ${prefix}-icon-${args.type}`,
    });
  }

  notification.open({
    message: args.message,
    description: args.description,
    icon: iconNode,
    className: `${args.tx && 'tx-notification'}`,
    onClick: () => {
      if (args.tx) {
        window.open(getEtherscanLink(args.tx, 'transaction'));
      }
    },
  });
}

/**
 *  交易Notification封装，添加点击能力
 */
const txNotification = {
  open: openNotification,
  success: openNotification,
  info: openNotification,
  warning: openNotification,
  error: openNotification,
};

const types: IconType[] = ['success', 'info', 'warning', 'error'];
types.forEach((type: IconType) => {
  txNotification[type] = (args: INotificationArgs): void =>
    txNotification.open({
      ...args,
      type,
    });
});

export { txNotification };
