import React, { useState, useEffect } from 'react';
import { Tooltip, Button } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { capitalize } from 'lodash';

import { synWeb3 } from '@/synWeb3';
import { useActiveWeb3React } from '@/hooks';
import { useDarkModeManager } from '@/state/user/hooks';

import Row, { RowFixed } from '../Row';
import Web3Status from '../Web3Status';
import GlobalConfigsUI from '@/pages/Modal/GlobalConfig';
import { appChainId, ChainId } from '@/constants/chain';
import { getChainName } from '@/utils';
import Logo from '@/assets/svg/logos/logo_24.svg';
import LogoDark from '@/assets/images/logo.svg';
import settingIcon from '@/assets/svg/icons/icon_nav_setting.svg';
import { currentProductReleaseEnv, PRODUCT_RELEASE_ENV } from '@/constants/product';

import './index.scss';
import { GaCategory, gaEvent } from '@/utils/gaUtil';
import { useGlobalGetter } from '@/state/global/hooks';

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  z-index: 2;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.03);
  // ${({ theme }) => theme.mediaWidth.upToMedium`
  //   grid-template-columns: 1fr;
  //   padding: 0 1rem;
  //   width: calc(100%);
  //   position: relative;
  // `};

  // ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  //       padding: 0.5rem 1rem;
  // `}
`;

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  margin-right: 24px;

  // ${({ theme }) => theme.mediaWidth.upToMedium`
  //   flex-direction: row;
  //   justify-content: space-between;
  //   justify-self: center;
  //   width: 100%;
  //   max-width: 960px;
  //   padding: 1rem;
  //   position: fixed;
  //   bottom: 0px;
  //   left: 0px;
  //   width: 100%;
  //   z-index: 99;
  //   height: 72px;
  //   border-radius: 12px 12px 0 0;
  //   background-color: ${theme.bg1};
  // `};
`;

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  // ${({ theme }) => theme.mediaWidth.upToMedium`
  //  flex-direction: row-reverse;
  //   align-items: center;
  // `};
`;

const HeaderRow = styled(RowFixed)`
  // ${({ theme }) => theme.mediaWidth.upToMedium`
  //  width: 100%;
  // `};
`;

const HeaderLinks = styled(Row)`
  justify-content: center;
  height: 64px;
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     padding: 1rem 0 1rem 1rem;
//     justify-content: flex-end;
// `};
`;

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
  /* :hover {
    background-color: ${({ theme, active }) => (!active ? theme.bg2 : theme.bg4)};
  } */
`;

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 63px;
  margin-left: 24px;
  // ${({ theme }) => theme.mediaWidth.upToSmall`
  //   justify-self: center;
  // `};
  :hover {
    cursor: pointer;
  }
`;

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`;

const NetWorkName = styled.div`
  padding: 4px 10px;
  border-radius: 4px;
  border: 1px solid #f1f1f1;
  background-color: #fbfbfb;
  color: orange;
  font-weight: bolder;
`;

const activeClassName = 'ACTIVE';

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 14px;
  width: fit-content;
  margin: 0 25px;
  font-weight: 500;
  height: 100%;
  align-items: center;

  &.${activeClassName} {
    border-radius: 0;
    font-weight: 600;
    color: #00bfbfff;
    border-bottom: 2px solid #00bfbf;
    margin-bottom: -2px;
  }

  :hover,
  :focus {
    color: #00bfbfff;
  }
`;

export default function Header() {
  const { account } = useActiveWeb3React();
  const [isDark] = useDarkModeManager();
  const { currentChainId } = useGlobalGetter();

  const [showConfigsModal, setShowConfigsModal] = useState(false);

  const [chainName, setChainName] = useState('Loading');
  useEffect(() => {
    setChainName(getChainName(currentChainId));
  }, [currentChainId]);

  // const ChainName = ChainId[appChainId].toLowerCase().replace(/^\S/, (s) => s.toUpperCase());

  return (
    <HeaderFrame>
      <HeaderRow>
        <Title href=".">
          <UniIcon>
            <img width={'150px'} height={32} src={isDark ? LogoDark : Logo} alt="logo" />
          </UniIcon>
        </Title>
        <HeaderLinks>
          <StyledNavLink id={`swap-nav-link`} to={'/trade'}>
            {'Trade'}
          </StyledNavLink>
          <StyledNavLink id={`pool-nav-link`} to={'/pool'}>
            {'Pool'}
          </StyledNavLink>

          <StyledNavLink className="nav-link" id={`pool-nav-link`} to={'/account'}>
            <span>
              {currentProductReleaseEnv === PRODUCT_RELEASE_ENV.PROD ? 'Account' : 'Account / Mint Testing Tokens'}
            </span>
            {currentProductReleaseEnv !== PRODUCT_RELEASE_ENV.PROD && (
              <Tooltip title="You can mint testing margin tokens in My Balances table">
                <QuestionCircleFilled className="tooltip-info-icon" />
              </Tooltip>
            )}
          </StyledNavLink>
        </HeaderLinks>
      </HeaderRow>
      <HeaderControls>
        <HeaderElement>
          <NetWorkName>{chainName}</NetWorkName>
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            <Web3Status />
          </AccountElement>
          <Button
            style={{ border: 'none' }}
            onClick={() => {
              gaEvent(GaCategory.HEADER, 'Show Configs Modal');
              setShowConfigsModal(!showConfigsModal);
            }}>
            <img src={settingIcon} width={24} />{' '}
          </Button>
        </HeaderElement>
      </HeaderControls>

      {showConfigsModal && (
        <GlobalConfigsUI isOpen={showConfigsModal} handleGlobalConfigsModalVisible={setShowConfigsModal} />
      )}
    </HeaderFrame>
  );
}
