import { createReducer, AnyAction, PayloadAction } from '@reduxjs/toolkit';

import * as actions from './actions';
import { IContractGlobalConfig } from '@/interfaces/global';
export interface IVisible {
  visible: string | boolean;
}

export enum RedirectFromEnum {
  CREATE_POOL = 'create_pool',
  POOL_LIST_RECORD = 'pool_list_record',
  POOL_PAGE_BUTTON = 'pool_page_button',
}
//actions.RedirectFromEnum

export interface IOptionsState {
  createPoolVisible: boolean;
  addLiquidityVisible: boolean;
  removeLiquidityVisible: boolean;
  routerParams: string;
  redirectFrom: string;
  recordCache: object;
  globalConfig: IContractGlobalConfig;
  initialPrice: string;
  initialAmount: string;
}

export const initialState = {
  // create action
  createPoolVisible: false,
  addLiquidityVisible: false,
  removeLiquidityVisible: false,
  routerParams: '',
  redirectFrom: actions.RedirectFromEnum.FROM_CREATE_POOL, //'',
  recordCache: {},
  globalConfig: {},
  initialPrice: '0',
  initialAmount: '0',
} as IOptionsState;

function isActionWithNumberPayload(action: AnyAction): action is PayloadAction<number> {
  return typeof action.payload === 'number';
}

// # create reducer
const reducers = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.createPoolModalVisible, (state, action) => {
      state.createPoolVisible = action.payload.visible;
    })
    .addCase(actions.addLiquidityModalVisible, (state, action) => {
      state.addLiquidityVisible = action.payload.visible;
    })
    .addCase(actions.removeLiquidityModalVisible, (state, action) => {
      const visible = action.payload.visible;
      console.log(`action:`, action, `visible:`, visible);
      state.removeLiquidityVisible = visible;
    })
    .addCase(actions.updateRedirectFrom, (state, action) => {
      const from = action.payload.from;
      console.log(`action:`, action, `from:`, from);
      state.redirectFrom = from;
    })
    .addCase(actions.updateGlobalConfig, (state, action) => {
      const configs = action.payload.configs;
      console.log(`action:`, action, `configs:`, configs);
      state.globalConfig = configs as IContractGlobalConfig;
    })
    .addCase(actions.updateRouterParams, (state, action) => {
      state.routerParams = action.payload.params;
    })
    .addCase(actions.updateRecordCache, (state, action) => {
      state.recordCache = action.payload.record;
    })
    .addCase(`TEST`, (state) => {
      console.log(`state:`, state);
    })
    // You can apply a "matcher function" to incoming actions
    .addMatcher(isActionWithNumberPayload, (state, action) => {
      //TODO: 如果不需要了，就删除把
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    })
    // and provide a default case if no other handlers matched

    .addDefaultCase((state, action) => {
      //TODO: 如果不需要了，就删除把
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    });
});

export default reducers;
