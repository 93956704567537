export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
// token

export const localStorageKey = 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3';
export const decryptAlgorithm = 'aes-256-ctr';

/**
 * Oracle 类型
 *
 * @export
 * @enum {number}
 */
export enum ORACLE_TYPE {
  CHAINLINK = 'CHAINLINK',
  UNISWAPV2 = 'UNISWAPV2',
  SYNFUTURES = 'SYNFUTURES',
  PANCAKEV2 = 'PANCAKEV2',
}

/**
 * AMM状态
 *
 * @export
 * @enum {number}
 */
export enum AMM_STATUS {
  TRADING = 'TRADING',
  SETTLING = 'SETTLING',
  SETTLED = 'SETTLED',
  EMERGENCY = 'EMERGENCY',
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
export const DEFAULT_COIN_ICON = require('../assets/svg/icons/icon_crypto_default.svg');
// export const DEFAULT_COIN_ICON = require('../assets/svg/logos/logo_25d_200.svg');

export const DIFF_HASHRATE_SCALE = 'T'; // 难度产品单位
export const DIFF_UNIT = 'PH/s'; // 难度产品单位

export const DIFF_BASE_NAME = 'BTCHASH'; // 难度产品的baseName

/**
 * 配置类型
 */
export enum CONFIG_TYPE {
  /**
   * 系统配置
   */
  SYSTEM = 'SYSTEM',
  /**
   * 用户自己添加的（如自选币种及对应交易对）
   */
  CUSTOM = 'CUSTOM',
}

/**
 * 默认显示小数位个数
 */
export const DEFAULT_DISPLAY_DECIMALS_PLACES = 4;

export const ETH_DECIMALS_PLACES = 18;

/**
 * USD类输入小数位个数
 */
export const USD_KEEP_DECIMALS_PLACES = 4;
/**
 * BTC类输入小数位个数
 */
export const BTC_KEEP_DECIMALS_PLACES = 8;
/**
 * ETH类输入小数位个数
 */
export const ETH_KEEP_DECIMALS_PLACES = 6;
