import { PRODUCT_TYPE } from '@/constants/product';
import { AddressConfig, ProductAddressConfig } from '@/interfaces/config';

/**
 * V1基础合约配置
 */
const BASE_CONTRACT_ADDRESS_CONFIG: AddressConfig = {
  ConfigAddr: '0x928e85Ec6A7F6dC4Aa8211428e9E49e89e1b8f04',
  FactoryAddr: '0x6E893DDfA75D67FEbb853e00f81c913c151BF9A9',
  ReaderAddr: '0x4f6EEEd538D289490A073ed4C7711ceD20DAD46e',
};

/**
 * 难度产品合约配置
 */
const DIFF_CONTRACT_ADDRESS_CONFIG: AddressConfig = {
  ConfigAddr: '0x377Cf0C1c2C01D671bCb2362514C1284364ccB45',
  OracleAddr: '0x55e5de4Ac80883c84fbeAa4873CB99b6221F1aA3',
  FactoryAddr: '0x68dB178C5B065dE43d370dC3f49255F262a52BcE',
  ReaderAddr: '0xb9ACD144be346db534B91FdEbbBb6c943E18B6A3',
};

/**
 * contract Address 配置
 */
export const CONTRACT_ADDRESS: ProductAddressConfig = {
  [PRODUCT_TYPE.BASIC]: BASE_CONTRACT_ADDRESS_CONFIG,
  [PRODUCT_TYPE.DIFFICULTY]: DIFF_CONTRACT_ADDRESS_CONFIG,
};
