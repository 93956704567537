import { AppDispatch, AppState } from '@/state';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentBlockHeight } from '@/state/diff/actions';
import { useCallback } from 'react';
import { getBlockHeightList } from '@/utils/block';
import { IPair } from '@/interfaces/pair';
import { IBlockHeight } from '@/interfaces/diff';
import { getBlockHeightTime } from '@/utils/timeUtil';
import { usePairGetter } from '../pairs/hooks';
import { synDiffWeb3 } from '@/synWeb3';
import { AVAILABLE_PRODUCTS, PRODUCT_TYPE } from '@/constants/product';

export function useDiffState(): AppState['diff'] {
  return useSelector<AppState, AppState['diff']>((state) => state.diff);
}

// 过滤后的块高列表
// 过滤规则：1. 当前块高以后的 2. 2016倍数的块高 3. 去掉已选择的块
function getFilteredBlockHeightList(
  currentBlockHeight: number,
  currentBlockTime: number,
  allPairs: IPair[],
): IBlockHeight[] {
  // 已经创建的pairs
  const expireBlockHeightArr: number[] = allPairs.map((item) => Number(item.oraclePair.expiry)) || [];
  const blockHeightList = getBlockHeightList(expireBlockHeightArr, currentBlockHeight);
  return blockHeightList.map((height) => {
    return { height, utcTime: getBlockHeightTime(height, currentBlockHeight, currentBlockTime) };
  });
}

export const useDiffHook = () => {
  const { currentBlockHeight, currentBlockTime } = useDiffState();
  const dispatch = useDispatch<AppDispatch>();
  const { diffAllPairs } = usePairGetter();

  const getLatestBlockHeight = useCallback(async () => {
    if (AVAILABLE_PRODUCTS.includes(PRODUCT_TYPE.DIFFICULTY)) {
      const { height, timestamp } = await synDiffWeb3.oracle.getLatestBlockHeight();
      dispatch(updateCurrentBlockHeight({ height, timestamp }));
    }
  }, [dispatch]);
  // 过滤后的块高列表
  // 过滤规则：1. 当前块高以后的 2. 2016倍数的块高 3. 去掉已选择的块
  const filteredBlockHeight = getFilteredBlockHeightList(currentBlockHeight, currentBlockTime, diffAllPairs);
  return { currentBlockHeight, currentBlockTime, filteredBlockHeight, getLatestBlockHeight };
};
