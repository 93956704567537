import BigNumber from 'bignumber.js';
import {
  MIN_NUMBER_00000,
  MIN_NUMBER_00001,
  MAX_NUMBER_999999,
  DEFAULT_DECIMAL_PLACES,
  LESS_NUMBER_00001,
  MORE_NUMBER_999999,
} from '@/constants';

export function checkPositiveNumeric(value: string): boolean {
  const reg = /^\d*(\.\d*)?$/;
  return reg.test(value) || value === '';
}

// remove zero after point
export function removeTrailingZeros(value: string | number): string {
  value = value.toString();
  if (value.match(/\./)) {
    value = value.replace(/\.?0+$/, '');
  }
  return value;
}

/**
 * 数字千分位
 */
const numberWithCommas = (x: number | string): string => {
  const parts = x.toString().split('.');
  if (parts.length > 0) {
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
  return '';
};

/**
 * 格式化数值，默认保留4位小数
 * (可选的) 千分位逗号分隔符
 * @export
 * @param {(number | string)} num 数值
 * @param {number} [decimals=DEFAULT_DECIMAL_PLACES] 默认4位
 * @param {boolean} [isShowSeparator=true] 是否显示分隔符
 * @param {number} [roundingMode=BigNumber.ROUND_DOWN] 默认向下取整
 * @param {boolean} [isRemoveTrailingZeros=false] 是否移除小数位后0
 * @returns
 */
export function formatNumber(
  num: number | string,
  decimals: number = DEFAULT_DECIMAL_PLACES,
  isShowSeparator = true,
  roundingMode: BigNumber.RoundingMode = BigNumber.ROUND_DOWN,
  isRemoveTrailingZeros = false,
): string {
  // 容错处理
  if (
    num === 'NaN' ||
    num.toString().trim() === '' ||
    Math.abs(Number(num)) === Infinity ||
    Number.isNaN(Number(num))
  ) {
    num = 0;
  }

  // 向下取整
  let res = new BigNumber(num).toFixed(decimals, roundingMode);

  if (isShowSeparator) {
    res = numberWithCommas(res);
  }
  if (isRemoveTrailingZeros) {
    res = removeTrailingZeros(res);
  }

  return res;
}

/**
 * 转换为 < 0.00001 或者 > 999999.9999
 * @param num
 * @param decimals
 * @param isShowSeparator
 * @param roundingMode
 * @param isRemoveTrailingZeros
 * @returns
 */
export function formatNumberTransition(
  num: number | string,
  decimals: number = DEFAULT_DECIMAL_PLACES,
  isShowSeparator = true,
  roundingMode: BigNumber.RoundingMode = BigNumber.ROUND_DOWN,
  isRemoveTrailingZeros = false,
): string {
  // 容错处理
  if (
    num === 'NaN' ||
    num.toString().trim() === '' ||
    Math.abs(Number(num)) === Infinity ||
    Number.isNaN(Number(num))
  ) {
    num = 0;
  }

  if (lessThanByNum(num)) {
    return LESS_NUMBER_00001;
  } else if (moreThanByNum(num)) {
    return MORE_NUMBER_999999;
  } else {
    return formatNumber(num, decimals, isShowSeparator, roundingMode, isRemoveTrailingZeros);
  }
}

/**
 * 确保输入的数值在范围内，否则强制转换
 * make sure input legal num
 * number should between 1e6 and 1e-6 [1e-6, 1e6)
 * @export
 * @param {(number | string)} num the number user input
 * @param {number} [decimals=6]
 * @param {number} [biggestNumber=1e6 - 1e-4]
 * @returns return number with String type
 */
export function inputNumChecker(num: number | string, decimals = 4, integerMaxLength = 6): string {
  num = num.toString();
  // replace illegal digital
  num = num.replace(/[^0-9.]/g, '');
  const parts = num.split('.');
  // user paste the number contains more then two dot[.]
  if (parts.length >= 3) {
    return '';
  }
  let integerPart = parts[0];
  // remove leading zero except 1 zero
  if (integerPart.length > 0) {
    while (integerPart.length > 1 && integerPart[0] === '0') {
      integerPart = integerPart.substr(1);
    }
  } else if (num.indexOf('.') === 0) {
    integerPart = '0';
  }
  // max integer length <=7
  if (integerPart.length > integerMaxLength) {
    return (integerPart = integerPart.substr(0, integerMaxLength));
  }
  let decimalPart = parts[1] || '';
  // only 8 decimal places
  if (decimalPart.length > decimals) {
    decimalPart = decimalPart.substr(0, decimals);
  }
  if (decimalPart.length !== 0) {
    num = `${integerPart}.${decimalPart}`;
  } else {
    // get parse num except single [.]
    num = integerPart + (parts.length === 2 ? '.' : '');
  }
  return num;
}

/**
 * 格式化输入数值，默认保留4位小数、删除小数位后0
 * (可选的) 千分位逗号分隔符
 * @export
 * @param {(number | string)} num 数值
 * @param {number} [decimals=DEFAULT_DECIMAL_PLACES] 默认4位
 * @param {number} [roundingMode=BigNumber.ROUND_DOWN] 默认向下取整
 * @param {boolean} [isRemoveTrailingZeros=true] 是否移除小数位后0
 * @returns
 */
export function formatInputNumber(
  num: number | string,
  decimals: number = DEFAULT_DECIMAL_PLACES,
  roundingMode: BigNumber.RoundingMode = BigNumber.ROUND_DOWN,
): string {
  return formatNumber(num, decimals, false, roundingMode, true);
}

/**
 *  判断小于下限0.0001的并不等于0的情况
 * @param num
 */
export function lessThanByNum(num: number | string): boolean {
  return Number(num) < Number(MIN_NUMBER_00001) && Number(num) !== 0 && Number(num) > 0;
}

/**
 *  判断大于上限 999999 的并不等于0的情况
 * @param num
 */
export function moreThanByNum(num: number | string): boolean {
  return Number(num) > Number(MAX_NUMBER_999999) && Number(num) !== 0;
}

/**
 * 判断数字是小于0, 负数颜色设置
 * lessThanZero-> 更名 negativeNumberColor
 */
export function negativeNumberColor(num: number | string): string {
  return Number(num) >= 0 ? '#14cc52' : '#ff6666';
}

/**
 * 转为BigNumber
 * @param str 输入值 string|number
 * @returns
 */
export function formatBigNumber(str: string | number): string {
  return new BigNumber(str).toString(10);
}

/**
 * 小数点后尾数超过 18位 Web3.utils.toBN 报错，安全处理
 * @param num
 * @returns
 */
export function safeNumberFormat(num: string | number): string {
  let safeNumber = '';
  const originNumber = num.toString();
  // 小数点后尾数超过 18位 Web3.utils.toBN 报错，安全处理
  const hasDecimal = originNumber.includes('.');

  if (hasDecimal) {
    const temp = originNumber.split('.');
    safeNumber = temp[1].length > 18 ? `${temp[0]}.${temp[1].substr(0, 18)}` : originNumber;
  } else {
    safeNumber = originNumber;
  }
  return safeNumber;
}
