import React, { useCallback, useState, useMemo, ReactElement } from 'react';
import { Input, List, Modal } from 'antd';
import { useTokenActionHandlers } from '@/state/swap/hooks';
import { Field } from '@/state/swap/actions';
import { useTranslation } from 'react-i18next';
import { IToken } from '@/interfaces/token';
import { usePairsActionHandlers } from '@/state/pairs/hooks';
import { ICoin } from '@/interfaces/coin';
import { chainConfig } from '@/constants/chain';
import { useCoinGetter } from '@/state/pairs/hooks';
import { DEFAULT_COIN_ICON, ORACLE_TYPE, CONFIG_TYPE } from '@/constants/config';
import { synWeb3 } from '@/synWeb3';
import { getEtherscanLink, toShortAddr } from '@/utils';
import './index.scss';
import { gaException } from '@/utils/gaUtil';

interface IContractSelectorModalProps {
  isOpen: boolean;
  field: Field;
  onTokenSelect: (token: IToken, oracleType: ORACLE_TYPE) => void;
  handleContractSelectorModalVisible: (isOpen: boolean) => void;
}

export default function ContractSelectorModal({
  isOpen,
  field,
  onTokenSelect,
  handleContractSelectorModalVisible,
}: IContractSelectorModalProps): ReactElement {
  const { t } = useTranslation();
  const defaultTokenList: IToken[] = [];
  const { coinListConfig } = useCoinGetter();
  const [tokenList, handleToken] = useState(defaultTokenList);
  const [loading, setLoading] = useState(false);

  const { onTokenSelection } = useTokenActionHandlers();
  const { addBaseAction } = usePairsActionHandlers();

  async function addressInputChange(rawAddress: string): Promise<void> {
    rawAddress = rawAddress.trim();
    if (rawAddress.length !== 42 || !rawAddress.startsWith('0x')) {
      handleToken([]);
      return;
    }

    try {
      setLoading(true);
      const [selectToken, err] = await synWeb3.token.getTokenFromAddress(rawAddress);
      // 0x0414d92ec668f2d29fca424870e4867ba6d064f2 XDEFI
      // 0xdc598a8c57feae748ca8832fab3b43bcaf5c1469 DAI
      // 0xa990a65bab3208a39def7d6d77a3a28e65481e78 WBTC
      // 0x28c32821bbccc555906578f623699ee109c32502
      console.log(`addressInputChange: `, rawAddress);
      console.log(`selectToken: `, selectToken);

      if (err !== '') {
        handleToken([]);
        return;
      }

      setLoading(false);
      if (selectToken) {
        handleToken([selectToken]);
      }
    } catch (error) {
      gaException(error.message);
      setLoading(false);
      handleToken([]);
    }
  }

  const selectedCoins = useMemo(() => {
    let res: ICoin[] = [];
    const defaultIcon = DEFAULT_COIN_ICON;
    if (tokenList && tokenList.length > 0) {
      res = tokenList.map((token) => {
        const coin = {
          symbol: token.symbol || '',
          fullName: token.symbol || '',
          icon: defaultIcon,
          address: token.address,
        } as ICoin;
        // 如果是存在的币种
        if (coinListConfig[coin.symbol]) {
          coin.icon = coinListConfig[coin.symbol].icon;
        }
        return coin;
      });
    }
    return res;
  }, [coinListConfig, tokenList]);

  const onSelectToken = useCallback(
    (token: IToken) => {
      const defaultIcon = DEFAULT_COIN_ICON;
      // 默认是UNISWAP，USDC/USDT交易对
      const defaultOracleType: ORACLE_TYPE = chainConfig.defaultOracleType;
      const defaultQuoteCoins: ICoin[] = chainConfig.oracleListConfig[defaultOracleType].defaultQuoteCoins;
      const coin = {
        symbol: token.symbol || '',
        fullName: token.symbol || '',
        icon: defaultIcon,
        address: token.address,
        configType: CONFIG_TYPE.CUSTOM,
        decimals: token.decimals,
      } as ICoin;
      if (coinListConfig[coin.symbol]) {
        coin.icon = coinListConfig[coin.symbol].icon;
      }
      addBaseAction(coin, defaultOracleType, defaultQuoteCoins, CONFIG_TYPE.CUSTOM);

      handleContractSelectorModalVisible(false);
      onTokenSelect(token, defaultOracleType);
      onTokenSelection(field, token.symbol ? token.symbol : '');
    },
    [addBaseAction, coinListConfig, field, handleContractSelectorModalVisible, onTokenSelect, onTokenSelection],
  );

  return (
    <Modal
      centered
      className="search-token-modal"
      visible={isOpen}
      onOk={(): void => handleContractSelectorModalVisible(false)}
      onCancel={(): void => handleContractSelectorModalVisible(false)}
      footer={null}>
      <div className="select-token-title">Search Token</div>

      <div className="select-token-address">
        <label>Address</label>
      </div>
      <Input
        className="select-token-input"
        placeholder={t('message.placeholder.searchTokenAddress')}
        defaultValue=""
        type="text"
        spellCheck="false"
        onChange={(e: React.ChangeEvent<HTMLInputElement>): Promise<void> => addressInputChange(e.target.value)}
      />

      <div>
        <div className="select-token-name"></div>

        <List
          dataSource={tokenList}
          loading={loading}
          className="tokenSelectorList token-select-list"
          renderItem={(item: IToken, index): JSX.Element => (
            <List.Item onClick={(): void => onSelectToken(item)} style={{ cursor: 'pointer' }}>
              <div className="token-select-item">
                <div className="token-item-logo">
                  {selectedCoins[index] && (
                    <img className="icon-coin" src={selectedCoins[index].icon} alt={selectedCoins[index].symbol} />
                  )}
                </div>

                <div className="token-item-info">
                  <div className="token-item-symbol">
                    <label>{item.symbol}</label>
                    <span>{item.name}</span>
                  </div>
                </div>

                <div className="token-item-balance">
                  <a
                    target="_blank"
                    className="syn-link"
                    rel="noreferrer"
                    href={getEtherscanLink(item.address, 'address')}>
                    {toShortAddr(item.address)}{' '}
                  </a>
                </div>
              </div>
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
}
