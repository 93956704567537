// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import { Tooltip } from 'antd';
import { useActiveWeb3React } from '@/hooks';
import useCopyClipboard from '../../hooks/useCopyClipboard';
import { AppDispatch } from '@/state';
import { clearAllTransactions } from '@/state/transactions/actions';
import { getEtherscanLink, shortenAddress } from '@/utils';
import Transaction from './Transaction';

import { SUPPORTED_WALLETS } from '@/constants';
import { ReactComponent as Close } from '../../assets/images/x.svg';
import { fortmatic, injected, portis, walletconnect, walletlink } from '../../connectors';
import CoinbaseWalletIcon from '../../assets/images/coinbaseWalletIcon.svg';
import WalletConnectIcon from '../../assets/images/walletConnectIcon.svg';
import FortmaticIcon from '../../assets/images/fortmaticIcon.png';
import PortisIcon from '../../assets/images/portisIcon.png';
import IconWalletMetamask from '../../assets/svg/icons/icon_wallet_metamask.svg';
import IconWalletChange from '../../assets/svg/icons/icon_wallet_change.svg';
import IconAddrCopy from '../../assets/svg/icons/icon_addr_copy.svg';
import IconAddrLink from '../../assets/svg/icons/icon_addr_link.svg';
import IconClose from '../../assets/svg/icons/icon_close.svg';
import Identicon from '../Identicon';
import { ButtonSecondary } from '../Button';
import { ExternalLink } from '@/theme';
import './index.scss';

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`;

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`;

const InfoCard = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 20px;
  position: relative;
  display: grid;
  grid-row-gap: 12px;
  margin-bottom: 20px;
`;

const AccountGroupingRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};

  div {
    ${({ theme }) => theme.flexRowNoWrap}
    align-items: center;
  }
`;

const AccountSection = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 0rem 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 0rem 1rem 1.5rem 1rem;`};
`;

const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }
`;

const LowerSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  padding: 1.5rem;
  flex-grow: 1;
  overflow: auto;
  background-color: ${({ theme }) => theme.bg2};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  h5 {
    margin: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.text3};
  }
`;

const AccountControl = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 0;
  width: 100%;

  font-weight: 500;
  font-size: 1.25rem;

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const AddressLink = styled(ExternalLink)<{ hasENS: boolean; isENS: boolean }>`
  font-size: 0.825rem;
  color: ${({ theme }) => theme.text3};
  margin-left: 1rem;
  font-size: 0.825rem;
  display: flex;
  :hover {
    color: ${({ theme }) => theme.text2};
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`;

const WalletName = styled.div`
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text3};
`;

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`;

const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
`;

const WalletAction = styled(ButtonSecondary)`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  padding: 4px 6px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const MainWalletAction = styled(WalletAction)`
  color: ${({ theme }) => theme.primary1};
`;

function renderTransactions(transactions: string[]) {
  return (
    <TransactionListWrapper>
      {transactions.map((hash, i) => {
        return <Transaction key={i} hash={hash} />;
      })}
    </TransactionListWrapper>
  );
}

interface IAccountDetailsProps {
  toggleWalletModal: () => void;
  pendingTransactions: string[];
  confirmedTransactions: string[];
  ENSName?: string;
  openOptions: () => void;
}

export default function AccountDetails({
  toggleWalletModal,
  pendingTransactions,
  confirmedTransactions,
  ENSName,
  openOptions,
}: IAccountDetailsProps) {
  const { chainId, account, connector } = useActiveWeb3React();
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch<AppDispatch>();

  const [isCopied, setCopied] = useCopyClipboard();
  console.log('ENSName', ENSName, chainId);

  function formatConnectorName() {
    const { ethereum } = window;
    const isMetaMask = !!(ethereum && ethereum.isMetaMask);
    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(
        (k) =>
          SUPPORTED_WALLETS[k].connector === connector && (connector !== injected || isMetaMask === (k === 'METAMASK')),
      )
      .map((k) => SUPPORTED_WALLETS[k].name)[0];
    return name;
  }

  function getStatusIcon() {
    if (connector === injected) {
      return (
        <IconWrapper size={16}>
          <Identicon />
        </IconWrapper>
      );
    } else if (connector === walletconnect) {
      return (
        <IconWrapper size={16}>
          <img src={WalletConnectIcon} alt={'wallet connect logo'} />
        </IconWrapper>
      );
    } else if (connector === walletlink) {
      return (
        <IconWrapper size={16}>
          <img src={CoinbaseWalletIcon} alt={'coinbase wallet logo'} />
        </IconWrapper>
      );
    } else if (connector === fortmatic) {
      return (
        <IconWrapper size={16}>
          <img src={FortmaticIcon} alt={'fortmatic logo'} />
        </IconWrapper>
      );
    } else if (connector === portis) {
      return (
        <>
          <IconWrapper size={16}>
            <img src={PortisIcon} alt={'portis logo'} />
            <MainWalletAction
              onClick={() => {
                portis.portis.showPortis();
              }}>
              Show Portis
            </MainWalletAction>
          </IconWrapper>
        </>
      );
    }
    return null;
  }

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }));
  }, [dispatch, chainId]);

  const walletName = formatConnectorName();

  return (
    <>
      <div className="wallet-account-container">
        <div className="wallet-account-header">
          <h3 className="wallet-account-header__title">Account</h3>
          <a className="wallet-account-close syn-link" onClick={toggleWalletModal}>
            <img src={IconClose} alt="close" />
          </a>
        </div>
        <div className="wallet-account-body">
          <div className="wallet-account-connect">
            <div className="wallet-account-connect__left">
              <label>Connected with</label>
              <div className="wallet-name">
                {walletName.toUpperCase() === 'METAMASK' && <img src={IconWalletMetamask} alt="metamask" />}
                <span>{walletName}</span>
              </div>
            </div>
            <div className="wallet-account-connect__right">
              <a
                className="wallet-account-change"
                onClick={() => {
                  openOptions();
                }}>
                <img src={IconWalletChange} alt="change" />
                Change
              </a>
            </div>
          </div>
          <div className="wallet-account-address">
            <div className="wallet-account-address__left">
              {getStatusIcon()}
              <span> {account && shortenAddress(account)}</span>
            </div>
            <div className="wallet-account-address__right">
              <Tooltip title="Copy Address">
                <a className="wallet-account-address-btn syn-link" onClick={() => setCopied(account || '')}>
                  <img src={IconAddrCopy} alt="IconAddrCopy" />
                </a>
              </Tooltip>
              {chainId && account && (
                <Tooltip title="View on Etherscan">
                  <a
                    className="wallet-account-address-btn"
                    target="_blank"
                    rel="noreferrer noopener"
                    href={chainId && getEtherscanLink(account || '', 'address')}>
                    <img src={IconAddrLink} alt="IconAddrLink" />
                  </a>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <div className="wallet-account-footer" />
      </div>
    </>
  );
}
