import React from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { DEFAULT_DECIMAL_PLACES } from '@/constants';
import formatNumberPrefixTooltip from '@/components/Common/formatNumberPrefixTooltip';
import WalletCoinIcon from '@/assets/svg/icons/icon_wallet_16.svg';
import PositionCoinIcon from '@/assets/svg/icons/icon_balance_16.svg';
import './index.scss';
export enum BalanceTitleType {
  POSITION = 'Position',
  BALANCE = 'Balance',
}
interface ICurrentBalanceTitleProps {
  balance: number | string;
  unit: string;
  title: string;
  balanceDecimals?: number;
  tooltip?: string;
  className?: string;
  type: BalanceTitleType;
  balanceStyle?: React.CSSProperties;
  onClickBalance: (balance: number | string, unit: string) => void;
}

export default function CurrentBalanceTitle({
  balance,
  unit,
  title,
  balanceDecimals = DEFAULT_DECIMAL_PLACES,
  tooltip,
  className,
  type = BalanceTitleType.BALANCE,
  balanceStyle,
  onClickBalance,
}: ICurrentBalanceTitleProps): JSX.Element {
  return (
    <div className={`current-balance-title ${className && className}`}>
      <img src={type === BalanceTitleType.BALANCE ? WalletCoinIcon : PositionCoinIcon} alt="balance icon" />
      <span>
        {title}
        {tooltip ? (
          <Tooltip title={tooltip}>
            <QuestionCircleFilled className="tooltip-info-icon" />
          </Tooltip>
        ) : null}
      </span>
      <b
        style={balanceStyle}
        onClick={(): void => {
          onClickBalance(balance, unit);
        }}>
        {' '}
        {formatNumberPrefixTooltip(balance, balanceDecimals)} {`${unit}`}
      </b>
    </div>
  );
}
