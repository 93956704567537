import React, { ReactElement, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useWeb3React } from '@web3-react/core';
import { usePositionHook } from '@/state/position';
import { useGlobalGetter } from '@/state/global/hooks';
import Selector from '../Selector';

import TradeEntry from '@/pages/TradePage/TradeEntry';
import BalanceDeposit from '@/pages/TradePage/BalanceDeposit';
import Details from './Details';
import './style.scss';

export default function Trade(): ReactElement {
  const fetchPositionPollingRef = useRef<NodeJS.Timeout | null>(null);
  const { onFetchCurrentPosition, currentPosition, currentAmmDetail } = usePositionHook();
  const { isCorrectChain, currentWeb3 } = useGlobalGetter();
  const { account } = useWeb3React();
  const traderAddress = useMemo(() => {
    return account || '';
  }, [account]);
  // 切换账户重新获取数据
  useEffect(() => {
    if (traderAddress) {
      if (fetchPositionPollingRef.current) {
        clearInterval(fetchPositionPollingRef.current);
      }
      // 已经选择pair并在正确链上
      if (isCorrectChain && currentPosition.id) {
        // 定时更新当前仓位
        fetchPositionPollingRef.current = setInterval(() => {
          onFetchCurrentPosition(traderAddress, currentWeb3);
        }, 5000); //5s
      }
    }
  }, [traderAddress, isCorrectChain, onFetchCurrentPosition, currentWeb3, currentPosition.id]);
  return (
    <>
      <div className="trade-page">
        <div className="trade-page-body">
          <div className="trade-page-container tarde-selector">
            <Selector showCreatePool={true} />
          </div>
          <div className="trade-page-container top-line">
            <TradeEntry />
          </div>
          <div className="trade-page-container top-line">
            <BalanceDeposit />
          </div>
        </div>
        <Details></Details>
      </div>
    </>
  );
}
