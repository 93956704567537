import { JSBI, Percent } from '@uniswap/sdk';

import { provider, TransactionReceipt } from 'web3-core';
import { AbstractConnector } from '@web3-react/abstract-connector';

import { injected } from '@/connectors';
import { DEFAULT_DISPLAY_DECIMALS_PLACES } from './config';

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export interface IWalletInfo {
  connector?: AbstractConnector;
  name: string;
  iconName: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}

export const SUPPORTED_WALLETS: { [key: string]: IWalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
};

export const NetworkContextName = 'NETWORK';

export const UNKNOWN_NETWORK = 'Unknown Network';

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50;
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20;

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)); // .01 ETH

// 交易方向
export enum TRADE_DIRECTION {
  LONG = 'Long',
  SHORT = 'Short',
}

/**
 * 默认显示小数位个数
 */
export { DEFAULT_DISPLAY_DECIMALS_PLACES as DEFAULT_DECIMAL_PLACES };

/**
 * 在一些地方限制最大数值,超过替换
 */
export const MAX_NUMBER_999999 = '999999.9999';
export const MAX_SAFE_NUMBER = 100000000;
export const MIN_NUMBER_00000 = '0.0000';
export const MIN_NUMBER_00001 = '0.0001';
export const LESS_NUMBER_00001 = '< 0.0001';
export const MORE_NUMBER_999999 = '> 999,999.9999';

// parse receipt error 特殊返回值
export const PARSE_RECEIPT_ERROR = 'PARSE_RECEIPT_ERROR';

/**
 * 默认token decimals小数位
 */
export const DEFAULT_TOKEN_DECIMALS = 18;
export const SYNFUTURES_DOCS_LINK = `https://synfutures.github.io/docs/docs`;

export const SYNFUTURES_DOCS_PARAMETERS_LINK = `${SYNFUTURES_DOCS_LINK}/parameters/`;
export const SETTING_LEARN_MORE_LINK = `${SYNFUTURES_DOCS_LINK}#5-what-is-the-life-cycle-of-a-trade`;
export const DIFF_CONTRACT_SPECS_LINK = `${SYNFUTURES_DOCS_LINK}/hash_rate_futures/`;
export const DIFF_CURRENT_BLOCK_UPDATE_LINK = `${SYNFUTURES_DOCS_LINK}/hash_rate_futures_general/#38-how-often-does-spot-difficulty-index-get-updated`;

export const TransactionReceiptDefault: TransactionReceipt = {
  status: false,
  transactionHash: '',
  transactionIndex: 0,
  blockHash: '',
  blockNumber: 0,
  from: '',
  to: '',
  cumulativeGasUsed: 0,
  gasUsed: 0,
  logs: [],
  logsBloom: '',
};

// USD标的的稳定版
// TODO 挪到配置中
export const USD_COINS = ['USDT', 'USDC', 'DAI', 'BUSD'];
