import React, { useState, useMemo, useCallback, useEffect, ReactNode } from 'react';
import { Card, Form, Input, Button, Checkbox, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import moment from 'moment';
import BalanceTable from '@/pages/BalanceTable';
import PositionTable from '@/pages/PositionTable';
import { ICoinBalance } from '@/interfaces/balance';
import { formatNumber, negativeNumberColor } from '@/utils/numberUtil';
import { usePositionHook } from '@/state/position';
import { useBalanceHook } from '@/state/balance';
import { OPERATION_TYPE } from '@/pages/PositionTable/operationButtons';
import { IPosition } from '@/interfaces/position';
import './style.scss';
import { SYNWeb3, synWeb3, synDiffWeb3 } from '@/synWeb3';
import { chainConfig } from '@/constants/chain';
import { ORACLE_TYPE } from '@/constants/config';
import {
  getBaseQuoteBySymbol,
  getEtherscanLink,
  getOracleTypeByCoinSymbol,
  isDiffProductSymbol,
  shortenAddress,
} from '@/utils';
import { IAmmDetail } from '@/interfaces/amm';
import { IFuturesDetail } from '@/interfaces/futures';
import BigNumber from 'bignumber.js';
import { useTradeToken, useUIGlobalConfigs } from '@/state/swap/hooks';
import { FutureSymbol } from '@/components/FutureSymbol';
import { ITransactionError } from '@/interfaces/error';
import { txNotification } from '@/components/TxNotification';
import formatNumberPrefixTooltip from '@/components/Common/formatNumberPrefixTooltip';

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export default function Liquidation() {
  const { t } = useTranslation();
  const { active, account } = useWeb3React();
  const { onFetchAmmDetail, onFetchAccountDetail, onFetchFuturesDetail } = usePositionHook();
  const managerAddress = useMemo(() => {
    return account || '';
  }, [account]);
  const [traderAddress, setTraderAddress] = useState('');
  const [symbol, setSymbol] = useState('');
  const [ammProxy, setAmmProxy] = useState('');
  const [futuresProxy, setFuturesProxy] = useState('');
  const [ammDetail, setAmmDetail] = useState<IAmmDetail | null>(null);
  const [futuresDetail, setFuturesDetail] = useState<IFuturesDetail | null>(null);
  const [positionDetail, setPositionDetail] = useState<IPosition | null>(null);
  const [isInTakeOver, setIsInTakeOver] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isInAuto, setIsInAuto] = useState(false);
  const { uiGlobalConfig } = useUIGlobalConfigs();

  const currentWeb3 = useMemo(() => {
    return isDiffProductSymbol(symbol) ? synDiffWeb3 : synWeb3;
  }, [symbol]);

  useEffect(() => {
    // ##### AccountInfoList
    if (!active) {
      notification.warning({
        message: t('notification.wallet.title'),
        description: t('notification.wallet.noConnection'),
      });
    } else {
      // console.log(`traderAddresstraderAddress`, traderAddress);
    }
  }, [active, managerAddress]);

  const onFinish = (values: any) => {
    console.log('Success:', values);
    const symbol = values.symbol as string;
    const traderAddress = values.traderAddress as string;
    searchContract(symbol.trim(), traderAddress.trim());
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  // 获取合约地址
  const getContractAddresses = async (
    oracleType: string,
    baseAddress: string,
    quoteAddress: string,
    maturity: string,
    currentWeb3: SYNWeb3 = synWeb3,
  ): Promise<[string, string, string]> => {
    let ammProxy: null | string = ``;
    let futuresProxy: null | string = ``;
    let err = '';
    if (oracleType === chainConfig.defaultOracleType) {
      [ammProxy, futuresProxy, err] = await currentWeb3.reader.getUniswapContractAddresses(
        baseAddress,
        quoteAddress,
        maturity,
      );
    } else if (oracleType === ORACLE_TYPE.CHAINLINK) {
      [ammProxy, futuresProxy, err] = await currentWeb3.reader.getChainlinkContractAddresses(
        baseAddress,
        quoteAddress,
        maturity,
      );
    } else if (oracleType === ORACLE_TYPE.SYNFUTURES) {
      [ammProxy, futuresProxy, err] = await currentWeb3.reader.getBtcHashRateContractAddresses(quoteAddress, maturity);
    }
    return [ammProxy || '', futuresProxy || '', err];
  };

  const searchContract = useCallback(
    async (symbol: string, traderAddress: string) => {
      try {
        const [baseToken, quoteToken, maturityStr, oracleStr] = symbol.split('-');
        if (!baseToken || !quoteToken || !maturityStr || !oracleStr) {
          return;
        }
        setTraderAddress(traderAddress);
        setSymbol(symbol);
        let maturity = '';
        if (isDiffProductSymbol(symbol)) {
          maturity = maturityStr;
        } else {
          maturity = moment(maturityStr).format('YYYY-MM-DD');
        }
        const oracleType = getOracleTypeByCoinSymbol(oracleStr);
        let baseAddress = baseToken;
        const quoteAddress = chainConfig.coinListConfig[quoteToken].address;

        if (oracleType === chainConfig.defaultOracleType) {
          baseAddress = chainConfig.coinListConfig[baseToken].address;
        }

        const [ammProxy, futuresProxy, err] = await getContractAddresses(
          oracleType,
          baseAddress,
          quoteAddress,
          maturity,
          currentWeb3,
        );
        console.log('🚀 ~ file: index.tsx ~ line 96 ~ async ~ ammProxy, futuresProxy', ammProxy, futuresProxy);
        if (!ammProxy || !futuresProxy) {
          return;
        }
        setAmmProxy(ammProxy);
        setFuturesProxy(futuresProxy);
        // notification.info({
        //   message: `ammProxy: ${ammProxy}, futuresProxy:${futuresProxy}`,
        // });

        const ammDetail = await onFetchAmmDetail(traderAddress, futuresProxy, ammProxy, currentWeb3);
        console.log('🚀 ~ file: index.tsx ~ line 131 ~ searchContract ~ ammDetail', ammDetail);
        if (!ammDetail) {
          return;
        }
        setAmmDetail(ammDetail);
        const futuresDetail = await onFetchFuturesDetail(futuresProxy, currentWeb3);
        setFuturesDetail(futuresDetail);
        console.log('🚀 ~ file: index.tsx ~ line 136 ~ searchContract ~ futuresDetail', futuresDetail);
        const positionDetail = await onFetchAccountDetail(traderAddress, futuresProxy, currentWeb3);
        if (positionDetail) {
          positionDetail.marginBalance = Number(positionDetail.accountBalance) + Number(positionDetail.unrealPnl);
        }
        setPositionDetail(positionDetail);
        console.log('🚀 ~ file: index.tsx ~ line 138 ~ searchContract ~ positionDetail', positionDetail);
      } catch (error) {
        console.log('🚀 ~ file: index.tsx ~ line 110 ~ searchContract ~ error', error);
        notification.error({
          message: error.message,
        });
      }
    },
    [onFetchAccountDetail, onFetchAmmDetail, onFetchFuturesDetail],
  );

  const onRefreshClick = useCallback(async () => {
    try {
      setIsRefresh(true);
      await searchContract(symbol, traderAddress);
      notification.info({
        message: 'refreshed',
      });
    } catch (error) {
      notification.error({
        message: error.message,
      });
    } finally {
      setIsRefresh(false);
    }
  }, [searchContract, symbol, traderAddress]);

  const onTakeOverClick = useCallback(async () => {
    try {
      setIsInTakeOver(true);
      const result = await currentWeb3.amm.liquidate(managerAddress, ammProxy, traderAddress, uiGlobalConfig.deadline);
      console.log('🚀 ~ file: index.tsx ~ line 180 ~ onTakeOverClick ~ res', result);
      if (result.status) {
        try {
          const tradeResultInfo = currentWeb3.futures.parseReceipt(result);
          console.log(`\n tradeResultInfo \n: `, tradeResultInfo);
        } catch (error) {
          console.log('🚀 ~ file: index.tsx ~ line 188 ~ onTakeOverClick ~ error', error);
        }
        notification.success({
          message: 'Operation Succeed',
        });
      }
    } catch (error) {
      if (error && error.code === 4001) {
        return;
      }
      const err = error as ITransactionError;
      let errDescription: ReactNode = error.message;
      if (err.notifyWrap) {
        errDescription = err.notifyWrap(errDescription);
      }

      txNotification.error({
        duration: null,
        message: `Take Over ${symbol}`,
        description: errDescription,
        tx: err.receipt && err.receipt.transactionHash,
      });
    } finally {
      setIsInTakeOver(false);
    }
  }, [ammProxy, currentWeb3.amm, currentWeb3.futures, managerAddress, symbol, traderAddress, uiGlobalConfig.deadline]);

  const onAutoClick = useCallback(async () => {
    try {
      setIsInAuto(true);
      const result = await currentWeb3.amm.liquidateByAmm(
        managerAddress,
        ammProxy,
        traderAddress,
        uiGlobalConfig.deadline,
      );
      console.log('🚀 ~ file: index.tsx ~ line 180 ~ onAutoClick ~ res', result);
      if (result.status) {
        try {
          const tradeResultInfo = currentWeb3.futures.parseReceipt(result);
          console.log(`\n tradeResultInfo \n: `, tradeResultInfo);
        } catch (error) {
          console.log('🚀 ~ file: index.tsx ~ line 188 ~ onAutoClick ~ error', error);
        }
        notification.success({
          message: 'Operation Succeed',
        });
      }
    } catch (error) {
      if (error && error.code === 4001) {
        return;
      }
      const err = error as ITransactionError;
      let errDescription: ReactNode = error.message;
      if (err.notifyWrap) {
        errDescription = err.notifyWrap(errDescription);
      }

      txNotification.error({
        duration: null,
        message: `Auto liquidate ${symbol}`,
        description: errDescription,
        tx: err.receipt && err.receipt.transactionHash,
      });
    } finally {
      setIsInAuto(false);
    }
  }, [ammProxy, currentWeb3.amm, currentWeb3.futures, managerAddress, symbol, traderAddress, uiGlobalConfig.deadline]);

  return (
    <div className="liquidation-dashboard">
      <Card title="Liquidation Info">
        <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item label="Symbol" name="symbol" rules={[{ required: true, message: 'Please input symbol' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Address" name="traderAddress" rules={[{ required: true, message: 'Please input address' }]}>
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Find
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Card title="Contract">
        <table>
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Mark Price</th>
              <th>Fair Price</th>
              <th>Open Interests</th>
              <th>AMM Position</th>
              <th>Latest Update</th>
            </tr>
          </thead>
          <tbody>
            {ammDetail && futuresDetail && positionDetail && (
              <tr>
                <td>
                  <FutureSymbol status={ammDetail.status} symbol={ammDetail.symbol} isShowTradeLink={false} />
                </td>
                <td>{formatNumberPrefixTooltip(positionDetail.markPrice)}</td>
                <td>{formatNumberPrefixTooltip(ammDetail.midPrice)}</td>
                <td>{formatNumberPrefixTooltip(futuresDetail.openInterests)}</td>
                <td>{formatNumberPrefixTooltip(ammDetail.ammPosition)}</td>
                <td>-</td>
              </tr>
            )}
          </tbody>
        </table>
      </Card>
      <Card title="Position">
        <table>
          <thead>
            <tr>
              <th>Address</th>
              <th>Position</th>
              <th>Liq. Price </th>
              <th>Position Value</th>
              <th>Margin Bal.</th>
              <th>Margin Ratio</th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {ammDetail && futuresDetail && positionDetail && (
              <tr>
                <td>
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    className="syn-link"
                    href={getEtherscanLink(traderAddress, 'address')}>
                    {shortenAddress(traderAddress)}
                  </a>
                </td>
                <td>
                  <div style={{ color: negativeNumberColor(positionDetail.position) }} className="desc-item-current">
                    {formatNumberPrefixTooltip(positionDetail.position)}
                  </div>
                </td>
                <td>{formatNumberPrefixTooltip(positionDetail.liqPrice)}</td>
                <td>
                  {formatNumberPrefixTooltip(
                    new BigNumber(positionDetail.position)
                      .abs()
                      .times(positionDetail.markPrice)
                      .toString(10),
                  )}
                </td>
                <td>{formatNumberPrefixTooltip(positionDetail.marginBalance || 0)}</td>
                <td>
                  {formatNumberPrefixTooltip(
                    new BigNumber(positionDetail.marginBalance || 0)
                      .div(new BigNumber(positionDetail.position).abs().times(positionDetail.markPrice))
                      .toString(10),
                  )}
                </td>
                <td>
                  <Button loading={isRefresh} type="primary" onClick={onRefreshClick}>
                    Refresh
                  </Button>
                  <Button loading={isInTakeOver} type="primary" onClick={onTakeOverClick}>
                    Take Over
                  </Button>
                  <Button loading={isInAuto} type="primary" onClick={onAutoClick}>
                    Auto
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Card>
    </div>
  );
}
