import { Web3Provider } from '@ethersproject/providers';
import { synWeb3, synDiffWeb3 } from '@/synWeb3';

export default function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider, 'any');
  library.pollingInterval = 15000;
  // 切换网络重新触发init
  synWeb3.global.initWeb3(provider);
  synDiffWeb3.global.initWeb3(provider);
  return library;
}
