import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import enNotification from '@/locales/en/notification.json';
import enMapping from '@/locales/en/mapping.json';
import enMessage from '@/locales/en/message.json';

// en: { translation: en },
// const resources = {
//   en: {
//     notification: { translation: enNotification },
//     mapping: { translation: enMapping },
//   },
// };

const resources = {
  en: {
    translation: {
      notification: enNotification,
      mapping: enMapping,
      message: enMessage,
    },
  },
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    // backend: {
    //   loadPath: `@/locales/{{lng}}/*.json`,
    // },
    react: {
      useSuspense: true,
    },
    fallbackLng: 'en',
    preload: ['en'],
    interpolation: { escapeValue: false },
  });

export default i18n;
