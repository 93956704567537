import React, { FC, ReactElement, useEffect, useState, useMemo } from 'react';
import { Modal } from 'antd';
import './style.scss';
import { synWeb3, synDiffWeb3 } from '@/synWeb3';
// import { ChainId } from '@uniswap/sdk';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/state';
import { ApplicationModal, setOpenModal } from '@/state/application/actions';
import { getChainName } from '@/utils';
import { appChainId, ChainId } from '@/constants/chain';
import { UNKNOWN_NETWORK } from '@/constants';
import { useGlobalGetter } from '@/state/global/hooks';

const SwitchNetworksModel: FC = (): ReactElement => {
  const { currentChainId, isCorrectChain } = useGlobalGetter();
  const [visible, setVisible] = useState<boolean>(false);
  const [walletChainId, setWalletChainId] = useState<number>(0);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (currentChainId) {
      setVisible(!isCorrectChain);
    }
    setWalletChainId(currentChainId);
  }, [currentChainId, isCorrectChain]);

  const switchWallet = (): void => {
    setVisible(false);
    setTimeout(() => {
      dispatch(setOpenModal(ApplicationModal.WALLET));
    }, 300);
  };

  return (
    <Modal
      wrapClassName="switch-network-modal"
      title="You Must Change Networks"
      centered
      destroyOnClose={true}
      visible={visible}
      onOk={(): void => switchWallet()}
      onCancel={(): void => setVisible(false)}
      okText="Switch Wallet"
      cancelText="Dismiss"
      okType="default"
      okButtonProps={{ size: 'large' }}
      cancelButtonProps={{ size: 'large' }}
      width={600}>
      <div>
        <p className="content">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          We've detected that you need to switch your wallet's network from{' '}
          <strong>{getChainName(walletChainId) || UNKNOWN_NETWORK}</strong> to{' '}
          <strong>{getChainName(Number(appChainId))}</strong> network for this Dapp.
        </p>
        <p className="description">
          Some wallets may not support changing networks. If you can not change networks in your wallet you may consider
          switching to a different wallet.
        </p>
      </div>
    </Modal>
  );
};

export default SwitchNetworksModel;
