import React, { ReactNode, useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TRADE_DIRECTION, SETTING_LEARN_MORE_LINK } from '@/constants';
import { AMM_STATUS } from '@/constants/config';
import Mask from '@/components/Mask';
import { usePositionHook } from '@/state/position';
import { useTradeHook } from '@/state/trade';
import TradeForm from './TradeForm';
import TradeTabs from './TradeTabs';

export default function TradeEntry(): JSX.Element {
  const { t } = useTranslation();
  const { currentPosition, currentAmmDetail } = usePositionHook();
  const { tradeSide } = useTradeHook();

  const settlingTipsNode: ReactNode = useMemo(() => {
    return (
      <div className="trade-settling-tips">
        <div>{t('message.settlingTips')} </div>
        <a target="_blank" className="syn-link" rel="noreferrer noopener" href={SETTING_LEARN_MORE_LINK}>
          Learn more
        </a>
      </div>
    );
  }, [t]);

  // 是否禁用单边交易
  const isSettlingDisable = useMemo(() => {
    let res = false;
    if (currentAmmDetail && currentAmmDetail.status === AMM_STATUS.SETTLING) {
      if (
        (tradeSide === TRADE_DIRECTION.LONG && Number(currentPosition.position) >= 0) ||
        (tradeSide === TRADE_DIRECTION.SHORT && Number(currentPosition.position) <= 0)
      )
        res = true;
    }

    return res;
  }, [currentAmmDetail, currentPosition.position, tradeSide]);

  return (
    <div className="trade-entry">
      <TradeTabs></TradeTabs>
      <div className="trade-entry-body">
        <Mask visible={isSettlingDisable} title={settlingTipsNode} className={'trade-settling-mask'}>
          <TradeForm tradeSide={tradeSide}></TradeForm>
        </Mask>
      </div>
    </div>
  );
}
