import { Input, message, Modal, Radio, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { QuestionCircleFilled } from '@ant-design/icons';

import { IUIGlobalConfigs } from '@/interfaces/global/UIGlobalConfigs';
import { useUIGlobalConfigActionHandlers, useUIGlobalConfigs } from '@/state/swap/hooks';
import { useURLWarningVisible } from '@/state/user/hooks';
import IconSetting from '@/assets/svg/icons/icon_setting_16.svg';
import iconDialogClose from '@/assets/svg/icons/icon_dialog_close.svg';
import { inputNumChecker } from '@/utils/numberUtil';
import './globalConfig.scss';

interface IGlobalConfigsModalProps {
  isOpen: boolean;
  handleGlobalConfigsModalVisible: (visible: boolean) => void;
}

export default function GlobalConfigsUI({ isOpen, handleGlobalConfigsModalVisible }: IGlobalConfigsModalProps) {
  const { onSaveGlobalConfigUI, getLocalGlobalConfigUI } = useUIGlobalConfigActionHandlers();
  const { uiGlobalConfig } = useUIGlobalConfigs();

  const showURLWarning = useURLWarningVisible();

  const [slippage, handleSlippage] = useState((uiGlobalConfig.slippage * 100).toString());
  const [deadline, handleDeadline] = useState((uiGlobalConfig.deadline / 60).toString());

  const changeGlobalConfigsVisible = useCallback(() => {
    handleGlobalConfigsModalVisible(false);
  }, [handleGlobalConfigsModalVisible]);

  const changeGlobalConfigsSlippage = useCallback(
    (e) => {
      let slippageStr = e.toString();
      slippageStr = inputNumChecker(slippageStr);
      handleSlippage(slippageStr);
    },
    [handleSlippage],
  );

  const changeGlobalConfigsDeadline = useCallback(
    (e) => {
      let deadlineStr = e;
      deadlineStr = inputNumChecker(deadlineStr);
      handleDeadline(deadlineStr);
    },
    [handleDeadline],
  );

  useEffect(() => {
    if (!slippage || !deadline) return;

    if (Number(slippage) === uiGlobalConfig.slippage * 100 && Number(deadline) * 60 === uiGlobalConfig.deadline) {
      return;
    }
    const modifiedUIGlobalConfig: IUIGlobalConfigs = {
      ...uiGlobalConfig,
      ...{ slippage: Number(slippage) / 100, deadline: Number(deadline) * 60 },
    };

    onSaveGlobalConfigUI(modifiedUIGlobalConfig);
  }, [slippage, deadline]);

  return (
    <>
      {/* Global Configs Modal */}

      <Modal
        mask={false}
        className="global-config-modal"
        style={{ top: showURLWarning ? '90px' : '61px' }}
        visible={isOpen}
        closeIcon={<img src={iconDialogClose}></img>}
        onOk={changeGlobalConfigsVisible}
        onCancel={changeGlobalConfigsVisible}
        footer={null}>
        <div className="global-config-arrow"></div>
        <div className="global-config-container">
          <div className="global-config-header">
            <img src={IconSetting} alt="setting"></img>
            <span>Configuration</span>
          </div>
          <div className="global-config-body">
            <div className="global-config-item">
              <label>
                Slippage
                <Tooltip title="Maximum trade price slippage allowed from the estimated trade price showed in trade entry. Due to the uncertainty of the timing when your transaction is confirmed on blockchain, the actual traded price could deviate from the estimated trade price.">
                  <QuestionCircleFilled className="tooltip-info-icon" />
                </Tooltip>
              </label>
              <div className="global-config-inputs">
                <Radio.Group
                  optionType="button"
                  buttonStyle="solid"
                  value={slippage}
                  onChange={(e) => changeGlobalConfigsSlippage(e.target.value)}>
                  <Radio.Button value={'0.1'}>0.1%</Radio.Button>
                  <Radio.Button value={'0.5'}>0.5%</Radio.Button>
                  <Radio.Button value={'1'}>1%</Radio.Button>
                </Radio.Group>

                <Input
                  placeholder="0"
                  defaultValue="0"
                  min={0}
                  value={slippage}
                  onChange={(e) => changeGlobalConfigsSlippage(e.target.value)}
                  suffix={'%'}
                />
              </div>
            </div>
            <div className="global-config-item">
              <label>
                Deadline
                <Tooltip title="If the transaction takes longer time than the deadline to be mined, it will be rejected.">
                  <QuestionCircleFilled className="tooltip-info-icon" />
                </Tooltip>
              </label>
              <div className="global-config-inputs">
                <Radio.Group
                  defaultValue=""
                  buttonStyle="solid"
                  value={deadline}
                  onChange={(e) => changeGlobalConfigsDeadline(e.target.value)}>
                  <Radio.Button value={'5'}>5min</Radio.Button>
                  <Radio.Button value={'10'}>10min</Radio.Button>
                  <Radio.Button value={'15'}>15min</Radio.Button>
                </Radio.Group>

                <Input
                  placeholder="0"
                  defaultValue="0"
                  min={0}
                  value={deadline}
                  onChange={(e) => changeGlobalConfigsDeadline(e.target.value)}
                  suffix={'min'}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
