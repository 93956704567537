import React, { useEffect, useMemo, useState } from 'react';
import { Card, Button, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { usePositionHook } from '@/state/position/hooks';
import customizeRenderEmpty from '../EmptyTable';

import PoolList from '@/components/PoolList';
import CreatePoolModal from '@/components/CreatePoolModal';
import AddLiquidityModal from '@/components/LiquidityModal/AddLiquidity';
import { RedirectFromEnum } from '@/state/options/actions';
import { useWeb3React } from '@web3-react/core';
import { txNotification } from '@/components/TxNotification';
import { IPosition } from '@/interfaces/position';

import AddPoolIcon from '@/assets/svg/icons/icon_add.svg';
import AddWhiteIcon from '@/assets/svg/icons/icon_add_w.svg';

import './style.scss';
import { GaCategory, gaEvent } from '@/utils/gaUtil';
import { useGlobalGetter } from '@/state/global/hooks';

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

export default function Pool(): JSX.Element {
  const { t } = useTranslation();
  const { active, account } = useWeb3React();
  const { isCorrectChain } = useGlobalGetter();
  const history = useHistory();
  const query = useQuery();
  const queryBase = query.get('base') || '';
  const queryQuote = query.get('quote') || '';
  const queryOracle = query.get('oracle') || '';

  const {
    isFetching: isFetchingPositionList,
    poolPositions,
    onFetchAllPositionList,
    onResetState: onResetPositionState,
  } = usePositionHook();

  const [dataInfo, setDataInfo] = useState<IPosition>();
  const [queryParams, setQueryParams] = useState('');

  const [createPoolModalVisible, setCreatePoolModalVisible] = useState(false);
  const [addLiquidityModalVisible, setAddLiquidityModalVisible] = useState(false);

  const [freeze, setFreeze] = useState(false);
  const [createPoolTargetFrom, setCreatePoolTargetFrom] = useState('');
  const [addLiquidityTargetFrom, setAddLiquidityTargetFrom] = useState('');

  useMemo(async () => {
    if (!active) {
      txNotification.warning({
        message: t('notification.wallet.title'), // 'Warning',
        description: t('notification.wallet.noConnection'), // 'Please connect your wallet',
      });
    } else {
      onResetPositionState();
      if (!isCorrectChain) {
        return;
      }

      onFetchAllPositionList(account as string);
    }
  }, [account, active, isCorrectChain, onFetchAllPositionList, onResetPositionState, t]);

  useEffect(() => {
    if (queryBase && queryQuote && queryOracle) {
      initializeAndCloseModal();
      const params = JSON.stringify({ base: queryBase, quote: queryQuote, oracle: queryOracle });
      setQueryParams(params);
      setCreatePoolModalVisible(true);
      setCreatePoolTargetFrom(RedirectFromEnum.ROUTE_CREATE_POOL); //通过路由获取创建
      history.replace('pool');
    }
  }, [queryBase, queryQuote, queryOracle, history]);

  // 重置弹窗为关闭状态
  const initializeAndCloseModal = (): void => {
    setCreatePoolModalVisible(false);
    setAddLiquidityModalVisible(false);
  };

  function onClickCreatePoolHandler(): void {
    gaEvent(GaCategory.POOL, 'Open Create Pool Modal');
    setFreeze(false);
    setCreatePoolModalVisible(true);
    setCreatePoolTargetFrom(RedirectFromEnum.INIT_CREATE_POOL); // INIT 创建Pool
  }

  function onClickAddLiquidityHandler(): void {
    gaEvent(GaCategory.POOL, 'Open Add Liquidity Modal');
    setFreeze(false);
    setDataInfo(undefined);
    setAddLiquidityModalVisible(true);
    setAddLiquidityTargetFrom(RedirectFromEnum.POOL_PAGE_BUTTON); // POOL_PAGE_BUTTON AddLiquidity
  }

  return (
    <React.Fragment>
      <Card
        title="My Pools"
        bordered={false}
        className="my-pool-card"
        headStyle={{ padding: '0', borderBottom: 'none' }}
        bodyStyle={{ padding: '0' }}
        extra={
          <div className="operation-buttons">
            <Button className="button-wrap" onClick={onClickCreatePoolHandler}>
              <div className="button-inner white">
                <img src={AddWhiteIcon} style={{ height: 20 }} />
                Create Pool
              </div>
            </Button>

            <Button className="button-wrap button-white" onClick={onClickAddLiquidityHandler}>
              <div
                className="button-inner"
                style={{
                  color: '#184858',
                }}>
                <img src={AddPoolIcon} style={{ height: 20 }} />
                Add Liquidity
              </div>
            </Button>
            <CreatePoolModal
              targetFrom={createPoolTargetFrom}
              visible={createPoolModalVisible}
              freeze={freeze}
              setFreeze={setFreeze}
              setVisible={setCreatePoolModalVisible}
              setAddLiquidityTargetFrom={setAddLiquidityTargetFrom}
              setAddLiquidityModalVisible={setAddLiquidityModalVisible}
              setDataInfo={setDataInfo}
              query={queryParams}
            />
          </div>
        }>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <PoolList
            loading={isFetchingPositionList}
            setDataInfo={(info): void => {
              setDataInfo(info);
            }}
            poolListData={poolPositions}
            setAddLiquidityTargetFrom={setAddLiquidityTargetFrom}
            setAddLiquidityModalVisible={setAddLiquidityModalVisible}
          />
        </ConfigProvider>

        {addLiquidityModalVisible && (
          <AddLiquidityModal
            targetFrom={addLiquidityTargetFrom}
            setTargetFrom={setAddLiquidityTargetFrom}
            dataInfo={dataInfo}
            setDataInfo={setDataInfo}
            visible={addLiquidityModalVisible}
            setVisible={setAddLiquidityModalVisible}
            poolListData={poolPositions}
          />
        )}
      </Card>
    </React.Fragment>
  );
}
