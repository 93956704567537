import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import './index.scss';

export enum LOADING_SIZE_MAP {
  MD = 24,
  SM = 16,
  LG = 32,
}

interface ILoadingWrapProps {
  loading: boolean;
  children: JSX.Element;
  align?: 'left' | 'center' | 'right';
  alignMargin?: number;
  size?: LOADING_SIZE_MAP;
}

const LoadingWrap = ({
  loading,
  children,
  align = 'center',
  alignMargin = 15,
  size = LOADING_SIZE_MAP.MD,
}: ILoadingWrapProps) => (
  <div>
    {loading ? (
      <div className="loading-wrap">
        <div
          className="spin-holder"
          style={{
            textAlign: align,
            right: align === 'right' ? alignMargin : 'unset',
            left: align === 'left' ? alignMargin : 'unset',
          }}>
          <LoadingOutlined style={{ fontSize: size }} spin />
        </div>
        <div className="loading-content-container">{children}</div>
      </div>
    ) : (
      children
    )}
  </div>
);

export default LoadingWrap;
