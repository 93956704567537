import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { AppDispatch, AppState } from '../index';
import { resetState, changeInputAmount, changePrice, changeSide, changeDepositMargin } from './actions';
import { usePositionHook } from '../position';
import { TRADE_DIRECTION } from '@/constants';
import { useUIGlobalConfigs } from '@/state/swap/hooks';

import { SYNWeb3 } from '@/synWeb3';
import { getMarginAfterTradeAmount } from '@/utils/trade';
import { useGlobalGetter } from '../global/hooks';
import { gaException } from '@/utils/gaUtil';

export function useTradeState(): AppState['trade'] {
  return useSelector<AppState, AppState['trade']>((state) => state.trade);
}

export const useTradeHook = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { tradeAmount, tradePrice, tradeSide, initialMargin, depositMargin } = useTradeState();
  const { currentAmmDetail, currentPosition } = usePositionHook();
  const { uiGlobalConfig } = useUIGlobalConfigs();
  const { currentGlobalConfig } = useGlobalGetter();

  const onResetState = useCallback(() => {
    dispatch(resetState());
  }, [dispatch]);

  const onChangeOrderSide = useCallback(
    (side: TRADE_DIRECTION) => {
      dispatch(changeSide({ side }));
    },
    [dispatch],
  );

  const onChangeTradeAmount = useCallback(
    (inputAmountStr: string) => {
      dispatch(changeInputAmount({ inputAmount: Number(inputAmountStr) || 0 }));
    },
    [dispatch],
  );

  const onChangePrice = useCallback(
    ({ price, initialMargin }: { price: number; initialMargin: number }) => {
      dispatch(changePrice({ price, initialMargin }));
    },
    [dispatch],
  );

  const getLimitPriceBN = useCallback(
    (tradePrice: number) => {
      const res = new BigNumber(
        tradeSide === TRADE_DIRECTION.LONG ? 1 + uiGlobalConfig.slippage : 1 - uiGlobalConfig.slippage,
      ).times(tradePrice);
      // TODO: 临时添加用，看看为啥计算有问题
      console.log(
        ` tradePrice, uiGlobalConfig.slippage,LimitPrice`,
        tradePrice,
        uiGlobalConfig.slippage,
        res.toString(10),
      );

      return res;
    },
    [tradeSide, uiGlobalConfig.slippage],
  );

  /**
   * 获取价格与initialMargin
   */
  const onFetchPrice = useCallback(
    async (currentWeb3: SYNWeb3, inputAmountStr: string): Promise<BigNumber> => {
      // 获取价格
      try {
        console.log(`currentAmmDetail`, currentAmmDetail, tradeSide, inputAmountStr);

        if (!currentAmmDetail) {
          return new BigNumber(0);
        }

        const [backPrice, initialMargin, getPriceError] = await currentWeb3.amm.getPrice(
          tradeSide,
          inputAmountStr,
          currentAmmDetail.ammProxy,
        );
        console.log(`backPrice, initialMargin, getPriceError`, backPrice, initialMargin, getPriceError);

        onChangePrice({ price: backPrice, initialMargin });

        if (getPriceError && getPriceError !== '') {
          return new BigNumber(0);
        }
        return getLimitPriceBN(backPrice);
      } catch (error) {
        gaException(error.message);
        onChangePrice({ price: 0, initialMargin: 0 });
        console.log(`\n ERROR------> await currentWeb3.amm.getBuyPrice(${inputAmountStr}, ${currentAmmDetail})`, error);
      }
      return new BigNumber(0);
    },
    [currentAmmDetail, getLimitPriceBN, onChangePrice, tradeSide],
  );

  const limitPriceBN = useMemo(() => {
    return getLimitPriceBN(tradePrice);
  }, [getLimitPriceBN, tradePrice]);

  const limitPriceStr = useMemo(() => {
    return limitPriceBN.toString(10);
  }, [limitPriceBN]);

  const newMidPrice = useMemo(() => {
    if (!currentAmmDetail) {
      return 0;
    }
    let newMidPrice = 0;
    if (tradeSide === TRADE_DIRECTION.LONG) {
      newMidPrice =
        (Number(currentAmmDetail.ammPosition) *
          Number(currentAmmDetail.ammPosition) *
          Number(currentAmmDetail.midPrice)) /
        (Number(currentAmmDetail.ammPosition) - tradeAmount) /
        (Number(currentAmmDetail.ammPosition) - tradeAmount);
    } else {
      newMidPrice =
        (Number(currentAmmDetail.ammPosition) *
          Number(currentAmmDetail.ammPosition) *
          Number(currentAmmDetail.midPrice)) /
        (Number(currentAmmDetail.ammPosition) + tradeAmount) /
        (Number(currentAmmDetail.ammPosition) + tradeAmount);
    }
    // 设置price impact
    return newMidPrice;
  }, [currentAmmDetail, tradeSide, tradeAmount]);

  /**
   * priceImpact
   */
  const priceImpact = useMemo(() => {
    if (!currentAmmDetail || limitPriceBN.isZero()) {
      return 0;
    }
    // 设置price impact
    return newMidPrice / Number(currentAmmDetail.midPrice) - 1;
  }, [currentAmmDetail, limitPriceBN, newMidPrice]);

  /**
   * 输入后相关margin(modifiedInitialMargin, availableMargin, additionalMargin)
   */
  const tradeMargins = useMemo(() => {
    const [modifiedInitialMargin, availableMargin, additionalMargin] = getMarginAfterTradeAmount(
      tradeSide,
      tradeAmount,
      initialMargin,
      currentPosition,
    );
    return { initialMargin: modifiedInitialMargin, availableMargin, additionalMargin };
  }, [tradeSide, tradeAmount, initialMargin, currentPosition]);

  // 交易费用：quantity * price * (poolFeeRatio + poolReserveFeeRatio)
  const tradeFee = useMemo(() => {
    let res = 0;
    if (tradeAmount > 0 && tradePrice > 0) {
      res =
        (tradeAmount * tradePrice * (currentGlobalConfig.poolFeeRatio + currentGlobalConfig.poolReserveFeeRatio)) /
        10000;
    }
    return res;
  }, [currentGlobalConfig.poolFeeRatio, currentGlobalConfig.poolReserveFeeRatio, tradeAmount, tradePrice]);

  const calcAdditionalMargin = useCallback(
    (leverageRatio: number) => {
      const position = Number(currentPosition.position);
      const marginBalance = Number(currentPosition.marginBalance);
      const markPrice = Number(currentPosition.markPrice);

      let calcResult = 0;
      if (tradeAmount === 0) {
        calcResult = (Math.abs(position) * markPrice) / leverageRatio - marginBalance;
      } else {
        if (tradeSide === TRADE_DIRECTION.LONG) {
          calcResult = (Math.abs(position + tradeAmount) * newMidPrice) / leverageRatio - marginBalance;
        } else if (tradeSide === TRADE_DIRECTION.SHORT) {
          calcResult = (Math.abs(position - tradeAmount) * markPrice) / leverageRatio - marginBalance;
        }
      }
      return new BigNumber(Math.max(calcResult, 0)).toString(10);
    },
    [
      currentPosition.marginBalance,
      currentPosition.markPrice,
      currentPosition.position,
      newMidPrice,
      tradeAmount,
      tradeSide,
    ],
  );

  const onChangeDepositMargin = useCallback(
    (margin: string) => {
      dispatch(changeDepositMargin({ margin }));
    },
    [dispatch],
  );

  return {
    tradeSide,
    tradeAmount,
    tradePrice,
    onResetState,
    onChangeTradeAmount,
    onChangePrice,
    onChangeOrderSide,
    priceImpact,
    limitPriceStr,
    limitPriceBN,
    tradeMargins,
    onFetchPrice,
    calcAdditionalMargin,
    tradeFee,
    depositMargin,
    onChangeDepositMargin,
  } as const;
};
