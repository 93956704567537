import { TRADE_DIRECTION } from '@/constants';
import { IPosition } from '@/interfaces/position';

/**
 * 获取availableMargin，additionalMargin
 * @param tradeAmount 交易数量
 * @param initialMargin 初始margin
 * @param accountDetail 账户信息
 */
export function getMarginAfterTradeAmount(
  tradeSide: TRADE_DIRECTION,
  tradeAmount: number,
  initialMargin: number,
  accountDetail: IPosition,
): [number, number, number, string] {
  const amount = tradeSide === TRADE_DIRECTION.LONG ? tradeAmount : -tradeAmount;
  if (amount === 0) {
    return [0, 0, 0, 'please type in valid amount']; //modifiedInitialMargin, availableMargin, additionalMargin
  }

  const position = Number(accountDetail.position);
  const marginBalance: number = Number(accountDetail.accountBalance) + Number(accountDetail.unrealPnl);

  if (position * amount < 0) {
    if (Math.abs(amount) <= Math.abs(position)) {
      // 1. reduce current position, not exceeded
      return [0, Number(accountDetail.availableMargin), 0, ''];
    } else {
      // 2. reduce and increase current position
      const availableMargin = Number(marginBalance);
      const scale = Math.abs((amount + position) / amount);
      const scaledInitialMargin = initialMargin * scale;
      return [scaledInitialMargin, availableMargin, Math.max(0, scaledInitialMargin - availableMargin), ''];
    }
  } else {
    // 3. increase current position
    return [
      initialMargin,
      Number(accountDetail.availableMargin),
      Math.max(0, initialMargin - Number(accountDetail.availableMargin)),
      '',
    ];
  }
}
