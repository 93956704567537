import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { Button, Input, Modal, Tooltip } from 'antd';
import BigNumber from 'bignumber.js';
import { ArrowRightOutlined } from '@ant-design/icons';
import { IPosition } from '@/interfaces/position';
import { formatNumberTransition, inputNumChecker } from '@/utils/numberUtil';
import { synDiffWeb3, synWeb3 } from '@/synWeb3';
import { useTranslation } from 'react-i18next';
import DepositIcon from '@/assets/svg/icons/icon_arrow_deposit.svg';
import WithdrawIcon from '@/assets/svg/icons/icon_arrow_withdraw.svg';
import { useCoinGetter } from '@/state/pairs/hooks';
import { ITransactionError } from '@/interfaces/error';
import { txNotification } from '@/components/TxNotification';
import { isDiffProductSymbol } from '@/utils';
import { AMM_STATUS } from '@/constants/config';
import { PARSE_RECEIPT_ERROR } from '@/constants';
import CurrentBalanceTitle, { BalanceTitleType } from '@/components/CurrentBalanceTitle';
import { useBalanceHook } from '@/state/balance';

import './operationButtons.scss';
import { GaCategory, gaEvent, gaException } from '@/utils/gaUtil';

export enum SettleStateEnum {
  NORMAL = 'NORMAL',
  LOADING = 'LOADING',
  DONE = 'DONE',
}

export enum OPERATION_TYPE {
  DEPOSIT = 'Deposit',
  WITHDRAW = 'Withdraw',
  UPDATE = 'Update',
  SETTLE = 'Settle',
}

export interface IOperationModal {
  positionInfo: IPosition;
  traderAddress: string;
  onHandleOk: (positionInfo: IPosition, operationType: OPERATION_TYPE) => void;
}

export default function OperationButtons({ positionInfo, traderAddress, onHandleOk }: IOperationModal): JSX.Element {
  const { t } = useTranslation();
  const [tokenAmountStr, setTokenAmount] = useState('');
  const [isShowOperationModal, handleIsShowOperationModal] = useState(false);
  const [operationType, handleOperationType] = useState(OPERATION_TYPE.DEPOSIT);
  const [isLoadingSettle, setLoadingSettle] = useState(false);
  const [isDepositing, setIsDepositing] = useState(false); // deposit 中
  const [isWithdrawing, setIsWithdrawing] = useState(false); // withdraw 中
  const { coinListConfig } = useCoinGetter();
  const [settleState, setSettleState] = useState<SettleStateEnum>(SettleStateEnum.NORMAL); // settle进行中
  const { balanceList } = useBalanceHook();

  const quoteSymbol = useMemo(() => {
    let res = '';
    if (positionInfo.symbol) {
      res = positionInfo.symbol.split('-')[1];
    }
    return res;
  }, [positionInfo.symbol]);

  const quoteWalletBalance = useMemo(() => {
    let res = '0';
    if (quoteSymbol && balanceList && balanceList.length > 0) {
      const coin = balanceList.find((coin) => coin.symbol === quoteSymbol);
      if (coin) {
        res = coin.walletBalance;
      }
    }
    return res;
  }, [quoteSymbol, balanceList]);

  const onDeposit = async () => {
    return new Promise<void>(async (resolve, reject) => {
      const contractSymbol = positionInfo.symbol;
      try {
        // Approve token
        setIsDepositing(true);
        const futureProxy = positionInfo.pair?.futuresProxy || '';
        const currentWeb3 = isDiffProductSymbol(positionInfo.symbol) ? synDiffWeb3 : synWeb3;

        // ### ReactGAEvent
        const depositParams = {
          method: 'currentWeb3.futures.deposit',
          contractSymbol,
          futureProxy,
          traderAddress,
          amount: tokenAmountStr,
        };

        console.log(`GoogleAnalytics:${JSON.stringify(depositParams)}`);
        // log selected wallet
        gaEvent(GaCategory.DEBUG, 'Deposit', JSON.stringify(depositParams));
        // ### ReactGAEvent-END

        // # 在用户的 Account 下的PAIR LIST 无需再次 Approve
        const depositResult = await currentWeb3.futures.deposit(
          coinListConfig[quoteSymbol].address,
          futureProxy,
          traderAddress,
          tokenAmountStr,
          () => {
            txNotification.info({
              message: t('notification.deposit.title', { contractSymbol: contractSymbol }),
              description: t('notification.deposit.info'),
            });
          },
        );
        setIsDepositing(false);

        if (depositResult.status) {
          try {
            const depositResultInfo = currentWeb3.futures.parseReceipt(depositResult);

            if (depositResultInfo === PARSE_RECEIPT_ERROR) {
              console.log(`PARSE_RECEIPT_ERROR`);
              throw new Error(PARSE_RECEIPT_ERROR);
            }

            console.log(`\n depositResultInfo---> res:`, depositResultInfo);
            const wadAmountNumber = currentWeb3.utils.fromWei(depositResultInfo.Deposit.wadAmount);
            const wadAmount = formatNumberTransition(wadAmountNumber);
            const quoteToken = positionInfo.symbol.split('-')[1];

            const notificationStr = t('notification.deposit.success', {
              quoteToken: quoteToken,
              wadAmount: wadAmount,
            });
            const descriptionNode: ReactNode = (
              <div>
                <span dangerouslySetInnerHTML={{ __html: notificationStr }}></span>
              </div>
            );

            txNotification.success({
              message: t('notification.deposit.title', { contractSymbol: contractSymbol }),
              description: descriptionNode,
              tx: depositResult.transactionHash,
            });
            resolve();
          } catch (error) {
            gaException(error.message);
            txNotification.success({
              message: t('notification.deposit.title', { contractSymbol: contractSymbol }),
              description: t('notification.deposit.default'),
              tx: depositResult.transactionHash,
            });

            resolve();
          }
        }
      } catch (error) {
        gaException(error.message);
        setIsDepositing(false);
        reject(error);
        if (error && error.code === 4001) {
          console.log('USER REJECT');
          return;
        }
        const err = error as ITransactionError;
        let errDescription: ReactNode = t('notification.deposit.error');
        if (err.notifyWrap) {
          errDescription = err.notifyWrap(errDescription);
        }

        txNotification.error({
          duration: null,
          message: t('notification.deposit.title', { contractSymbol: contractSymbol }),
          description: errDescription,
          tx: err.receipt && err.receipt.transactionHash,
        });
      }
    });
  };

  const onWithdraw = async () => {
    return new Promise<void>(async (resolve, reject) => {
      const contractSymbol = positionInfo.symbol;
      const futureProxy = positionInfo.pair?.futuresProxy || '';
      try {
        // ### ReactGAEvent
        const withdrawParams = {
          method: 'currentWeb3.futures.withdraw',
          contractSymbol,
          futureProxy,
          traderAddress,
          amount: tokenAmountStr,
        };

        console.log(`GoogleAnalytics:${JSON.stringify(withdrawParams)}`);
        // log selected wallet
        gaEvent(GaCategory.DEBUG, 'Withdraw', JSON.stringify(withdrawParams));
        // ### ReactGAEvent-END

        setIsWithdrawing(true);
        const currentWeb3 = isDiffProductSymbol(positionInfo.symbol) ? synDiffWeb3 : synWeb3;
        const withdrawResult = await currentWeb3.futures.withdraw(futureProxy, traderAddress, tokenAmountStr, () => {
          txNotification.info({
            message: t('notification.withdraw.title', { contractSymbol: contractSymbol }),
            description: t('notification.withdraw.info'),
          });
        });
        setIsWithdrawing(false);
        if (withdrawResult.status) {
          try {
            const withdrawResultInfo = currentWeb3.futures.parseReceipt(withdrawResult);

            if (withdrawResultInfo === PARSE_RECEIPT_ERROR) {
              console.log(`PARSE_RECEIPT_ERROR`);
              throw new Error(PARSE_RECEIPT_ERROR);
            }

            console.log(`\n withdrawResultInfo---> res:`, withdrawResultInfo);
            const wadAmountNumber = currentWeb3.utils.fromWei(withdrawResultInfo.Withdraw.wadAmount);
            const wadAmount = formatNumberTransition(wadAmountNumber);
            const quoteToken = positionInfo.symbol.split('-')[1];

            const notificationStr = t('notification.withdraw.success', {
              wadAmount: wadAmount,
              quoteToken: quoteToken,
            });
            const descriptionNode: ReactNode = (
              <div>
                <span dangerouslySetInnerHTML={{ __html: notificationStr }}></span>
              </div>
            );

            txNotification.success({
              message: t('notification.withdraw.title', { contractSymbol: contractSymbol }),
              description: descriptionNode,
              tx: withdrawResult.transactionHash,
            });
            resolve();
          } catch (error) {
            gaException(error.message);
            txNotification.success({
              message: t('notification.withdraw.title', { contractSymbol: contractSymbol }),
              description: t('notification.withdraw.default'),
              tx: withdrawResult.transactionHash,
            });

            resolve();
          }
        }
      } catch (error) {
        gaException(error.message);
        console.log('🚀 ~ file: operationModal.tsx ~ line 109 ~ returnnewPromise<string> ~ error', error);
        setIsWithdrawing(false);
        reject(error);
        if (error && error.code === 4001) {
          console.log('USER REJECT');
          return;
        }

        const err = error as ITransactionError;
        let errDescription: ReactNode = t('notification.withdraw.error');
        if (err.notifyWrap) {
          errDescription = err.notifyWrap(errDescription);
        }

        txNotification.error({
          duration: null,
          message: t('notification.withdraw.title', { contractSymbol: contractSymbol }),
          description: errDescription,
          tx: err.receipt && err.receipt.transactionHash,
        });
      }
    });
  };

  const handleOk = async (operationType: OPERATION_TYPE) => {
    try {
      if (!tokenAmountStr || Number(tokenAmountStr) <= 0) {
        return;
      }
      if (operationType === OPERATION_TYPE.WITHDRAW) {
        await onWithdraw();
      } else if (operationType === OPERATION_TYPE.DEPOSIT) {
        await onDeposit();
      }
      onHandleOk(positionInfo, operationType);
      setTokenAmount('');
      setIsWithdrawing(false);
      setIsDepositing(false);
      handleIsShowOperationModal(false);
    } catch (error) {
      gaException(error.message);
      console.log('🚀 ~ file: operationButtons.tsx ~ line 70 ~ handleOk ~ error', error);
      if (error && error.code === 4001) {
        console.log('USER REJECT');
        return;
      }

      setIsWithdrawing(false);
      setIsDepositing(false);
      handleIsShowOperationModal(false);
    }
  };

  const handleCancel = () => {
    setIsWithdrawing(false);
    setIsDepositing(false);
    setTokenAmount('');
    handleIsShowOperationModal(false);
  };

  const onClickSettleHandler = async (record: IPosition) => {
    handleOperationType(OPERATION_TYPE.SETTLE);
    setLoadingSettle(true);

    const contractSymbol = record.symbol;

    try {
      console.log(`onClickSettleHandler:`, record);
      if (!positionInfo.pair?.futuresProxy || !traderAddress) {
        return;
      }
      const currentWeb3 = isDiffProductSymbol(record.symbol) ? synDiffWeb3 : synWeb3;
      const settleResult = await currentWeb3.futures.settle(
        positionInfo.pair?.futuresProxy || '',
        traderAddress,
        function() {
          txNotification.info({
            message: t('notification.settle.title', { contractSymbol: contractSymbol }),
            description: t('notification.settle.info'),
          });
        },
      );
      console.log(`onClickSettleHandler---> res:`, settleResult);

      if (settleResult.status) {
        onHandleOk(positionInfo, OPERATION_TYPE.SETTLE);

        try {
          const settleResultInfo = currentWeb3.futures.parseReceipt(settleResult);
          console.log(`\n settleResultInfo---> res:`, settleResultInfo);

          if (settleResultInfo === PARSE_RECEIPT_ERROR) {
            console.log(`PARSE_RECEIPT_ERROR`);
            throw new Error(PARSE_RECEIPT_ERROR);
          }

          let settlePrice = '';
          let wadAmount = '';
          if (settleResultInfo && settleResultInfo.Settle && settleResultInfo.Settle.price) {
            settlePrice = formatNumberTransition(currentWeb3.utils.fromWei(settleResultInfo.Settle.price));
          }
          if (settleResultInfo && settleResultInfo.Withdraw && settleResultInfo.Withdraw.wadAmount) {
            wadAmount = formatNumberTransition(currentWeb3.utils.fromWei(settleResultInfo.Withdraw.wadAmount));
          }

          const hasPrice = Number(record.position) !== 0;
          const quoteToken = record.symbol.split('-')[1];
          const description = hasPrice
            ? t('notification.settle.success', {
                quoteToken: quoteToken,
                wadAmount: wadAmount,
                settlePrice: settlePrice,
              })
            : t('notification.settle.successNoPrice', {
                quoteToken: quoteToken,
                wadAmount: wadAmount,
              });

          const descriptionNode: ReactNode = (
            <div>
              <span dangerouslySetInnerHTML={{ __html: description }}></span>
            </div>
          );

          setSettleState(SettleStateEnum.DONE);

          txNotification.success({
            message: t('notification.settle.title', { contractSymbol: contractSymbol }),
            description: descriptionNode,
            tx: settleResult.transactionHash,
          });
        } catch (error) {
          gaException(error.message);
          txNotification.success({
            message: t('notification.settle.title', { contractSymbol: contractSymbol }),
            description: t('notification.settle.default'),
            tx: settleResult.transactionHash,
          });
          return;
        }
      }
      setLoadingSettle(false);
    } catch (error) {
      gaException(error.message);
      console.log('🚀 ~ file: index.tsx ~ line 122 ~ onClickUpdateFutures ~ error', error);
      setLoadingSettle(false);

      if (error && error.code === 4001) {
        console.log('USER REJECT');
        return;
      }

      const err = error as ITransactionError;
      txNotification.error({
        duration: null,
        message: t('notification.settle.title', { contractSymbol: contractSymbol }),
        description: t('notification.settle.error'), // `Settlement failed`,
        tx: err.receipt && err.receipt.transactionHash,
      });
    }
  };

  let resDom = null;

  // Settled 的合约不需要弹窗
  if (positionInfo.pair?.ammDetail?.status === AMM_STATUS.SETTLED) {
    resDom = (
      <Tooltip title="Settle">
        <Button
          type="primary"
          size="small"
          className="position-settle-btn"
          loading={isLoadingSettle}
          disabled={settleState === SettleStateEnum.DONE}
          onClick={() => onClickSettleHandler(positionInfo)}>
          <ArrowRightOutlined />
        </Button>
      </Tooltip>
    );
  } else {
    resDom = (
      <>
        <Tooltip title="Deposit">
          <Button
            type="primary"
            size="small"
            className="position-deposit-btn"
            loading={isDepositing}
            onClick={() => {
              handleIsShowOperationModal(true);
              handleOperationType(OPERATION_TYPE.DEPOSIT);
            }}>
            <img alt="Deposit" src={DepositIcon} style={{ height: 20 }} />
          </Button>
        </Tooltip>

        <Tooltip title="Withdraw">
          <Button
            type="primary"
            size="small"
            className="button-wrap position-withdraw-btn button-white"
            loading={isWithdrawing}
            onClick={() => {
              handleIsShowOperationModal(true);
              handleOperationType(OPERATION_TYPE.WITHDRAW);
            }}>
            <img src={WithdrawIcon} style={{ height: 20 }} />
          </Button>
        </Tooltip>
      </>
    );
  }

  const onClickCurrentBalance = useCallback(
    (balance: number | string, unit: string) => {
      setTokenAmount(new BigNumber(balance).abs().toFixed(coinListConfig[quoteSymbol].decimals));
    },
    [coinListConfig, quoteSymbol],
  );

  return (
    <div>
      {resDom}
      <Modal
        className="operation-modal"
        title={operationType.toString()}
        visible={isShowOperationModal}
        onOk={() => {
          handleOk(operationType);
        }}
        onCancel={handleCancel}
        closable={!isDepositing || isWithdrawing}
        maskClosable={!isDepositing || isWithdrawing}
        footer={
          null
          // [
          // <Button className="operation-btn" key="back" disabled={isDepositing || isWithdrawing} onClick={handleCancel}>
          //   Cancel
          // </Button>,
          // <Button
          //   className={`operation-btn ${
          //     operationType === OPERATION_TYPE.DEPOSIT ? 'operation-deposit' : 'operation-withdraw'
          //   }`}
          //   key="submit"
          //   type="primary"
          //   loading={isDepositing || isWithdrawing}
          //   onClick={() => {
          //     handleOk(operationType);
          //   }}>
          //   {operationType.toString()}
          // </Button>,
          // ]
        }>
        {operationType === OPERATION_TYPE.DEPOSIT && (
          <CurrentBalanceTitle
            className="operation-modal__current-balance"
            title="Current Balance"
            balance={quoteWalletBalance}
            unit={quoteSymbol}
            type={BalanceTitleType.BALANCE}
            onClickBalance={onClickCurrentBalance}></CurrentBalanceTitle>
        )}
        {operationType === OPERATION_TYPE.WITHDRAW && (
          <CurrentBalanceTitle
            className="operation-modal__current-balance"
            title="Available Margin"
            balance={positionInfo.availableMargin}
            unit={quoteSymbol}
            type={BalanceTitleType.BALANCE}
            onClickBalance={onClickCurrentBalance}></CurrentBalanceTitle>
        )}
        <Input
          className="operation-modal-input"
          placeholder={t('message.placeholder.liquidityQuantity')}
          defaultValue="0"
          value={tokenAmountStr}
          min={0}
          onChange={(e) => {
            const val = inputNumChecker(e.target.value, coinListConfig[quoteSymbol].decimals);
            setTokenAmount(val);
          }}
          suffix={
            <>
              {quoteSymbol && <img className="icon-coin" src={coinListConfig[quoteSymbol].icon} alt={quoteSymbol} />}
              <span>{quoteSymbol}</span>
            </>
          }
        />

        {[OPERATION_TYPE.DEPOSIT, OPERATION_TYPE.WITHDRAW].includes(operationType) && (
          <Button
            className={`operation-btn ${
              operationType === OPERATION_TYPE.DEPOSIT ? 'operation-deposit' : 'button-wrap button-white'
            }`}
            block
            loading={isDepositing || isWithdrawing}
            onClick={() => {
              handleOk(operationType);
            }}>
            {operationType}
          </Button>
        )}
      </Modal>
    </div>
  );
}
