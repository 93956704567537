import { parseUnits } from '@ethersproject/units';
import { Currency, CurrencyAmount, ETHER, JSBI, Token, TokenAmount, Trade } from '@uniswap/sdk';
import { ParsedQs } from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useActiveWeb3React } from '../../hooks';
import useParsedQueryString from '../../hooks/useParsedQueryString';
import { isAddress } from '../../utils';
import { AppDispatch, AppState } from '../index';
import { readLocalStorage, saveLocalStorage } from '../../utils/localStorageUtil';
import {
  Field,
  replaceSwapState,
  saveGlobalConfig,
  saveUIGlobalConfig,
  selectCurrency,
  selectMarity,
  selectOracle,
  selectToken,
  setRecipient,
  switchCurrencies,
  typeInput,
} from './actions';
import { ISwapState } from './reducer';
import { IContractGlobalConfig } from '@/interfaces/global';
import { IUIGlobalConfigs } from '@/interfaces/global/UIGlobalConfigs';
import { AMM_STATUS } from '@/constants/config';

export function useSwapState(): AppState['swap'] {
  return useSelector<AppState, AppState['swap']>((state) => state.swap);
}

export function useGlobalConfigActionHandlers(): {
  onSaveGlobalConfig: (globalConfig: IContractGlobalConfig) => void;
} {
  const dispatch = useDispatch<AppDispatch>();
  const onSaveGlobalConfig = useCallback(
    (globalConfig: IContractGlobalConfig) => {
      dispatch(
        saveGlobalConfig({
          globalConfig: globalConfig,
        }),
      );
    },
    [dispatch],
  );

  return {
    onSaveGlobalConfig,
  };
}

export function useUIGlobalConfigActionHandlers(): {
  onSaveGlobalConfigUI: (uiGlobalConfig: IUIGlobalConfigs) => void;
  getLocalGlobalConfigUI: () => void;
} {
  const dispatch = useDispatch<AppDispatch>();
  const onSaveGlobalConfigUI = useCallback(
    (uiGlobalConfig: IUIGlobalConfigs) => {
      saveLocalStorage('setting', JSON.stringify(uiGlobalConfig));
      dispatch(
        saveUIGlobalConfig({
          uiGlobalConfig: uiGlobalConfig,
        }),
      );
    },
    [dispatch],
  );

  const getLocalGlobalConfigUI = useCallback(() => {
    const localStorageSetting = readLocalStorage('setting');
    if (localStorageSetting) {
      const uiGlobalConfig = JSON.parse(localStorageSetting);
      console.log('uiGlobalConfig', uiGlobalConfig);

      dispatch(
        saveUIGlobalConfig({
          uiGlobalConfig: uiGlobalConfig,
        }),
      );
    }
  }, [dispatch]);

  return {
    onSaveGlobalConfigUI,
    getLocalGlobalConfigUI,
  };
}

export function useTokenActionHandlers(): {
  onTokenSelection: (field: Field, token: string) => void;
  onMarityChange: (field: Field, marity: string) => void;
  onOracleChange: (field: Field, oracle: string) => void;
} {
  const dispatch = useDispatch<AppDispatch>();
  const onTokenSelection = useCallback(
    (field: Field, token: string) => {
      dispatch(
        selectToken({
          field,
          token: token,
        }),
      );
    },
    [dispatch],
  );

  const onOracleChange = useCallback(
    (field: Field, oracle: string) => {
      dispatch(
        selectOracle({
          field,
          value: oracle,
        }),
      );
    },
    [dispatch],
  );

  const onMarityChange = useCallback(
    (field: Field, marity: string) => {
      dispatch(
        selectMarity({
          field,
          marity: marity,
        }),
      );
    },
    [dispatch],
  );

  return {
    onTokenSelection,
    onMarityChange,
    onOracleChange,
  };
}

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void;
  onSwitchTokens: () => void;
  onUserInput: (field: Field, typedValue: string) => void;
  onChangeRecipient: (recipient: string | null) => void;
} {
  const dispatch = useDispatch<AppDispatch>();
  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      dispatch(
        selectCurrency({
          field,
          currencyId: currency instanceof Token ? currency.address : currency === ETHER ? 'ETH' : '',
        }),
      );
    },
    [dispatch],
  );

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies());
  }, [dispatch]);

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }));
    },
    [dispatch],
  );

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }));
    },
    [dispatch],
  );

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  };
}

// from the current swap inputs, compute the best trade and return it.

export function useUIGlobalConfigs() {
  const {
    [Field.UIGLOBALCONFIG]: { uiGlobalConfig: uiGlobalConfig },
  } = useSwapState();
  return { uiGlobalConfig: uiGlobalConfig };
}

export function useGlobalConfigs() {
  const {
    [Field.GLOBALCONFIG]: { globalConfig: globalConfigs },
  } = useSwapState();
  return { globalConfig: globalConfigs };
}

export function useTradeToken() {
  const {
    [Field.BASE]: { token: inputToken },
    [Field.QUOTE]: { token: outputToken },
  } = useSwapState();
  const currencies: { [field in Field]?: string } = {
    [Field.BASE]: undefined,
    [Field.QUOTE]: undefined,
  };
  if (!inputToken && !outputToken) {
    return { currencies: currencies };
  }
  if (inputToken) {
    currencies[Field.BASE] = inputToken;
  }
  if (outputToken) {
    currencies[Field.QUOTE] = outputToken;
  }

  return { currencies: currencies };
}

export function useTradeMarity() {
  const {
    [Field.MARITY]: { marity: marityTime },
  } = useSwapState();

  const marity: { [field in Field]?: string } = {
    [Field.MARITY]: '',
  };
  if (!marityTime) {
    return { marity: marity };
  }
  marity[Field.MARITY] = marityTime;

  return { marity: marity };
}

export function useTradeOrcle() {
  const {
    [Field.ORACLE]: { value: selectedOracle },
  } = useSwapState();

  const oracle: { [field in Field]?: string } = {
    [Field.ORACLE]: '',
  };
  if (!selectedOracle) {
    return { oracle: oracle };
  }
  oracle[Field.ORACLE] = selectedOracle;

  return { oracle: oracle };
}

function parseCurrencyFromURLParameter(urlParam: any): string {
  if (typeof urlParam === 'string') {
    const valid = isAddress(urlParam);
    if (valid) return valid;
    if (urlParam.toUpperCase() === 'ETH') return 'ETH';
    if (valid === false) return 'ETH';
  }
  return 'ETH' ?? '';
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : '';
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output' ? Field.OUTPUT : Field.INPUT;
}

const ENS_NAME_REGEX = /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/;
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== 'string') return null;
  const address = isAddress(recipient);
  if (address) return address;
  if (ENS_NAME_REGEX.test(recipient)) return recipient;
  if (ADDRESS_REGEX.test(recipient)) return recipient;
  return null;
}

export function queryParametersToSwapState(parsedQs: ParsedQs): ISwapState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency);
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency);
  if (inputCurrency === outputCurrency) {
    if (typeof parsedQs.outputCurrency === 'string') {
      inputCurrency = '';
    } else {
      outputCurrency = '';
    }
  }

  const recipient = validatedRecipient(parsedQs.recipient);

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency,
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency,
    },
    [Field.ORACLE]: {
      value: undefined,
    },
    [Field.BASE]: {
      token: undefined,
    },
    [Field.QUOTE]: {
      token: undefined,
    },
    [Field.MARITY]: {
      marity: '',
    },
    [Field.GLOBALCONFIG]: {
      globalConfig: {
        emaTimeConstant: 0,
        poolFeeRatio: 0,
        poolReserveFeeRatio: 0,
        maxPriceSlippageRatio: 0,
        maxInitialDailyBasis: 0,
        maxUserTradeOpenInterestRatio: 0,
        minAmmOpenInterestRatio: 0,
        maxSpotIndexChangePerSecondRatio: 0,
        initialMarginRatio: 0,
        maintenanceMarginRatio: 0,
        bankruptcyLiquidatorRewardRatio: 0,
        insurancePremiumRatio: 0,
      },
    },
    [Field.UIGLOBALCONFIG]: {
      uiGlobalConfig: {
        slippage: 0,
        deadline: 0,
        gasPrice: 0,
      },
    },
    typedValue: parseTokenAmountURLParameter(parsedQs.exactAmount),
    independentField: parseIndependentFieldURLParameter(parsedQs.exactField),
    recipient,
    normalPairs: [],
    allPairs: [],
  };
}

// updates the swap state to use the defaults for a given network
export function useDefaultsFromURLSearch():
  | { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined }
  | undefined {
  const { chainId } = useActiveWeb3React();
  const dispatch = useDispatch<AppDispatch>();
  const parsedQs = useParsedQueryString();
  const [result, setResult] = useState<
    { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined } | undefined
  >();

  useEffect(() => {
    if (!chainId) return;
    const parsed = queryParametersToSwapState(parsedQs);

    dispatch(
      replaceSwapState({
        typedValue: parsed.typedValue,
        field: parsed.independentField,
        inputCurrencyId: parsed[Field.INPUT].currencyId,
        outputCurrencyId: parsed[Field.OUTPUT].currencyId,
        recipient: parsed.recipient,
      }),
    );

    setResult({ inputCurrencyId: parsed[Field.INPUT].currencyId, outputCurrencyId: parsed[Field.OUTPUT].currencyId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, chainId]);

  return result;
}
