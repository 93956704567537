import React, { useState, useEffect } from 'react';
import { Modal as AntDModal } from 'antd';
import { ModalProps } from 'antd/lib/modal';

interface IModalProps extends ModalProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  children?: React.ReactNode;
}

/**
 *  antd modal的封装
 *  visible为false时候不渲染
 *  afterClose解决动画问题
 * @param param0 antd modal props
 * @returns
 */
export default function Modal(props: IModalProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(props.visible);
  useEffect(() => setIsOpen(props.visible), [props.visible]);
  function onClickCancelHandler(): void {
    setIsOpen(false);
  }
  if (!props.visible) {
    return <></>;
  }
  return (
    <AntDModal
      className="pool-card-modal"
      maskClosable={false}
      footer={null}
      destroyOnClose
      {...props}
      visible={isOpen}
      onOk={(): void => {
        props.onOk();
        onClickCancelHandler();
      }}
      onCancel={onClickCancelHandler}
      afterClose={(): void => {
        props.onCancel();
      }}>
      {props.children}
    </AntDModal>
  );
}
