import React from 'react';
import { useCoinGetter } from '@/state/pairs/hooks';

type Props = {
  quoteValue: string;
};

export default function CoinIcon({ quoteValue }: Props) {
  const { coinListConfig } = useCoinGetter();
  return (
    <React.Fragment>
      <img className="icon-coin" src={coinListConfig[quoteValue].icon} alt={quoteValue} />
      <span>{quoteValue}</span>
    </React.Fragment>
  );
}
