import { createReducer } from '@reduxjs/toolkit';
import { updateCurrentBlockHeight } from './actions';
import { currentUTCTime } from '@/utils/timeUtil';

export interface IDiffState {
  readonly currentBlockHeight: number;
  readonly currentBlockTime: number;
}

const initialState: IDiffState = {
  currentBlockHeight: 0,
  currentBlockTime: 0,
};

export default createReducer<IDiffState>(initialState, (builder) =>
  // 更新块高度
  builder.addCase(updateCurrentBlockHeight, (state, { payload: { height, timestamp } }) => {
    const time = currentUTCTime();
    return {
      ...state,
      currentBlockHeight: height,
      currentBlockTime: timestamp * 1000 || time,
    };
  }),
);
