import React, { useMemo } from 'react';
import { Badge, Tooltip } from 'antd';
import { AMM_STATUS } from '@/constants/config';
import { IAmmDetail } from '@/interfaces/amm';

type IFutureSymbolProps = {
  status: AMM_STATUS;
  symbol: string;
  isShowTradeLink: boolean;
  link?: string;
  linkTarget?: string;
  record?: IAmmDetail;
  onClickTradeHandler?: (record: IAmmDetail) => void;
};

/**
 * 显示symbol及状态
 * @param props 合约symbol及状态等参数
 * @returns
 */
export function FutureSymbol(props: IFutureSymbolProps): JSX.Element {
  const linkStr = useMemo(() => {
    let res = '';
    if (props.link) {
      res = props.link;
    } else if (props.isShowTradeLink && [AMM_STATUS.TRADING, AMM_STATUS.SETTLING].includes(props.status)) {
      res = `#/trade?symbol=${props.symbol}`;
    }
    return res;
  }, [props.isShowTradeLink, props.link, props.status, props.symbol]);

  // badge Status
  const badgeStatus = useMemo(() => {
    let res: 'success' | 'processing' | 'error' | 'default' | 'warning' | undefined = 'success';
    switch (props.status) {
      case AMM_STATUS.SETTLING:
        res = 'warning';
        break;
      case AMM_STATUS.SETTLED:
        res = 'default';
        break;
      case AMM_STATUS.EMERGENCY:
        res = 'error';
        break;
      default:
        res = 'success';
        break;
    }
    return res;
  }, [props.status]);

  const symbolTooltip = useMemo(() => {
    const res = <div>{`State: ${props.status.toString()}`}</div>;
    return res;
  }, [props.status]);
  return (
    <span>
      {props.symbol !== '-' && (
        <Tooltip title={symbolTooltip}>
          <Badge status={badgeStatus} />
        </Tooltip>
      )}
      {linkStr ? (
        <a
          onClick={() => props.onClickTradeHandler && props.onClickTradeHandler(props.record!)}
          className="syn-link"
          target={props.linkTarget}
          href={linkStr}>
          {props.symbol}
        </a>
      ) : (
        <span>{props.symbol}</span>
      )}
    </span>
  );
}
