import React from 'react';
import { Tooltip } from 'antd';
import BigNumber from 'bignumber.js';

import { DEFAULT_DECIMAL_PLACES, LESS_NUMBER_00001, MORE_NUMBER_999999 } from '@/constants';
import { lessThanByNum, formatNumber, moreThanByNum, negativeNumberColor } from '@/utils/numberUtil';
// 格式化数值并在小于 0.0001的时候显示Tooltip

/**
 *
 * @param num 参数数值
 * @param showTooltip 是否显示tooltip ，默认显示
 * @param colorShader 是否对负数进行着色， 默认不着色
 * @param keepMaxOriginalNumber 是否保留最大原始值
 * @returns
 */
const formatNumberPrefixTooltip = (
  num: number | string,
  decimals = DEFAULT_DECIMAL_PLACES,
  colorShader = false,
  keepMaxOriginalNumber = false,
): React.ReactNode => {
  if (Number(num) === -Infinity || Number(num) === NaN) {
    num = 0;
  }

  if (lessThanByNum(num)) {
    return (
      <Tooltip title={new BigNumber(num).toString(10)}>
        {colorShader ? <span style={{ color: negativeNumberColor(num) }}>{LESS_NUMBER_00001}</span> : LESS_NUMBER_00001}
      </Tooltip>
    );
  } else if (moreThanByNum(num)) {
    if (keepMaxOriginalNumber) {
      return (
        <Tooltip title={new BigNumber(num).toString(10)}>
          {colorShader ? (
            <span style={{ color: negativeNumberColor(num) }}>{formatNumber(num, decimals)}</span>
          ) : (
            formatNumber(num, decimals)
          )}
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={new BigNumber(num).toString(10)}>
          {colorShader ? (
            <span style={{ color: negativeNumberColor(num) }}>{MORE_NUMBER_999999}</span>
          ) : (
            MORE_NUMBER_999999
          )}
        </Tooltip>
      );
    }
  } else {
    return (
      <Tooltip title={new BigNumber(num).toString(10)}>
        {colorShader ? (
          <span style={{ color: negativeNumberColor(num) }}>{formatNumber(num, decimals)}</span>
        ) : (
          formatNumber(num, decimals)
        )}
      </Tooltip>
    );
  }
};

export default formatNumberPrefixTooltip;
