import React, { useMemo, useCallback, useEffect } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import BalanceTable from '@/pages/BalanceTable';
import PositionTable from '@/pages/PositionTable';
import { ICoinBalance } from '@/interfaces/balance';
import { usePositionHook } from '@/state/position';
import { useBalanceHook } from '@/state/balance';

import formatNumberPrefixTooltip from '@/components/Common/formatNumberPrefixTooltip';
import { DEFAULT_DECIMAL_PLACES } from '@/constants';

import './style.scss';
import { useGlobalGetter } from '@/state/global/hooks';

export default function Account(): JSX.Element {
  const { t } = useTranslation();
  const { active, account } = useWeb3React();
  const { isCorrectChain } = useGlobalGetter();
  const traderAddress = useMemo(() => {
    return account || '';
  }, [account]);

  const { onFetchAllPositionList, onResetState: onResetPositionState } = usePositionHook();
  const {
    totalEstimatedValue,
    onFetchBalanceTokenPrice,
    onFetchAllWalletBalance,
    onFetchTokenBalance,
    onResetState: onResetBalanceState,
  } = useBalanceHook();

  const onRefreshBalance = useCallback(
    (balanceInfo: ICoinBalance) => {
      onFetchTokenBalance(traderAddress, balanceInfo);
    },
    [onFetchTokenBalance, traderAddress],
  );

  useEffect(() => {
    // ##### AccountInfoList
    if (!active) {
      notification.warning({
        message: t('notification.wallet.title'),
        description: t('notification.wallet.noConnection'),
      });
    } else {
      // console.log(`traderAddresstraderAddress`, traderAddress);
      onResetBalanceState();
      onResetPositionState();
      if (!isCorrectChain) {
        return;
      }

      onFetchAllPositionList(traderAddress);
      onFetchBalanceTokenPrice();
      onFetchAllWalletBalance(traderAddress);
    }
    //TODO: 修复dependencies导致的无限刷新问题
  }, [active, traderAddress, isCorrectChain]);

  return (
    <div>
      <div style={{ width: '100%' }}>
        <div className="account-number">
          <div className="dollar-sign">$</div>
          <div className="total-estimate">
            {formatNumberPrefixTooltip(totalEstimatedValue, DEFAULT_DECIMAL_PLACES, false, true)}
          </div>

          <div className="estimate-value">Estimated Value</div>
        </div>
        <BalanceTable onRefreshBalance={onRefreshBalance} />

        <PositionTable />
      </div>
    </div>
  );
}
