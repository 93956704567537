import { createAction } from '@reduxjs/toolkit';
import { IContractGlobalConfig, IMarginsParam } from '@/interfaces/global';
import { PRODUCT_TYPE } from '@/constants/product';

// fired once when the app reloads but before the app renders
// allows any updates to be applied to store data loaded from localStorage
export const updateVersion = createAction<void>('global/updateVersion');

export const saveGlobalConfig = createAction<{ config: IContractGlobalConfig; productType: PRODUCT_TYPE }>(
  'global/saveGlobalConfig',
);
export const saveGlobalMarginParam = createAction<{ params: IMarginsParam }>('global/saveGlobalMarginParam');

export const loadLocalGlobalConfig = createAction<{}>('global/loadLocalGlobalConfig');
export const loadLocalGlobalMargins = createAction<{}>('global/loadLocalGlobalMargins');

// 切换当前产品类型
export const changeProduct = createAction<{ params: PRODUCT_TYPE }>('global/changeProduct');

// 更新当前UTC时间
export const updateCurrentUTCTime = createAction<{ time: number }>('global/updateCurrentUTCTime');

// 当前网络
export const changeCurrentChainId = createAction<{ chainId: number }>('global/changeCurrentChainId');
