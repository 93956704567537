import React, { ReactNode, useCallback, useEffect, useState, useMemo } from 'react';
import { animated, useSpring } from 'react-spring';
import ContractDetail from './cards/ContractDetail';
import PositionDetail from './cards/PositionDetail';
import AdditionalMargin from './cards/AdditionalMargin';
import IconRollout from '@/assets/svg/icons/icon_rollout_2line.svg';
import IconRolloutWhite from '@/assets/svg/icons/icon_rollout_2line_w.svg';
import IconArrowDt from '@/assets/svg/icons/icon_arrow_dt.svg';
import IconArrowDtWhite from '@/assets/svg/icons/icon_arrow_dt_w.svg';

import './index.scss';

export default function TradeDetails(): JSX.Element {
  const [isOpen, setIsOpen] = useState(true);
  const slideInStyles = useSpring({
    from: { opacity: 0, width: isOpen ? 300 : 0 },
    to: {
      opacity: 1,
      width: isOpen ? 300 : 0,
    },
  });

  const onClickDetailsHeader = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div className={`trade-details ${isOpen && 'trade-details__open'}`}>
      <div className="trade-details__header" onClick={onClickDetailsHeader}>
        <img className="trade-details-header__rollout" src={IconRollout} alt="IconRollout" />
        <img className="trade-details-header__rollout_hover" src={IconRolloutWhite} alt="IconRollout hover" />
        <div className="trade-details-header__details">
          <img className="trade-details-header__arrow" src={IconArrowDt} alt="IconArrowDt" />
          <img className="trade-details-header__arrow_hover" src={IconArrowDtWhite} alt="IconArrowDt hover" />
          <span>Details</span>
          <img className="trade-details-header__arrow" src={IconArrowDt} alt="IconArrowDt" />
          <img className="trade-details-header__arrow_hover" src={IconArrowDtWhite} alt="IconArrowDt hover" />
        </div>
        <img className="trade-details-header__rollout" src={IconRollout} alt="IconRollout" />
        <img className="trade-details-header__rollout_hover" src={IconRolloutWhite} alt="IconRollout hover" />
      </div>
      <animated.div style={{ ...slideInStyles, overflow: 'hidden' }}>
        <div className="trade-details__body">
          <ContractDetail></ContractDetail>
          <PositionDetail></PositionDetail>

          <AdditionalMargin></AdditionalMargin>
        </div>
      </animated.div>
    </div>
  );
}
