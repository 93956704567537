import { IAmmDetail } from '@/interfaces/amm';
import { IContractGlobalConfig } from '@/interfaces/global';
import { MIN_NUMBER_00000, MAX_NUMBER_999999 } from '@/constants';
import { formatBigNumber, formatInputNumber } from '@/utils/numberUtil';
import { IPosition } from '@/interfaces/position';
export interface IPositionDetail {
  position: string;
  margin: string;
  initMargin: string;
  initMarginDelta?: string;
  mainMargin: string;
  liqPrice: string;
  poolShare: string;
  poolShareAmount: string;
  availableMargin?: string | undefined;
  shareDelta?: number;
  valueDelta?: number;
}

export const globalConfigDefault: IContractGlobalConfig = {
  emaTimeConstant: 0,
  poolFeeRatio: 0,
  poolReserveFeeRatio: 0,
  maxPriceSlippageRatio: 0,
  maxInitialDailyBasis: 0,
  maxUserTradeOpenInterestRatio: 0,
  minAmmOpenInterestRatio: 0,
  maxSpotIndexChangePerSecondRatio: 0,
  initialMarginRatio: 0,
  maintenanceMarginRatio: 0,
  bankruptcyLiquidatorRewardRatio: 0,
  insurancePremiumRatio: 0,
};

export const currentPositionDefault: IPositionDetail = {
  position: '0',
  margin: '0',
  initMargin: '0',
  initMarginDelta: '0',
  mainMargin: '0',
  availableMargin: '0',
  liqPrice: '0',
  poolShare: '0',
  poolShareAmount: '0',
  shareDelta: 0,
  valueDelta: 0,
};

export const anticipatePositionDefault: IPositionDetail = {
  position: '0',
  margin: '0',
  initMargin: '0',
  initMarginDelta: '0',
  mainMargin: '0',
  availableMargin: '0',
  liqPrice: '0',
  poolShare: '0',
  poolShareAmount: '0',
  shareDelta: 0,
  valueDelta: 0,
};

//https://synfutures.atlassian.net/browse/SFT-50
export function trialBalancePosition(
  globalConfig: IContractGlobalConfig,
  accountDetail: IPosition,
  ammDetail: IAmmDetail,
  quantity: string,
  price: number,
  leverage: number,
): [IPositionDetail, IPositionDetail, number] {
  quantity = quantity === '' ? '0' : quantity;
  leverage = leverage / 10; // NOTE: because of slider behavior, leverage is 10~100

  // TODO://
  // dY的计算最后除的 price, 是 ammDetail.minPrice , 如果还没初始化，是用户输入的 initPrice
  const dY = ((parseFloat(quantity) / (2 * leverage + 1)) * leverage) / price;
  const dX = dY * price;
  // 用户会收到的LP token数量为 dY / Y * Total Share, Y为AMM当前仓位
  // console.log(`----dy:${dY} dx:${dX} `, ammDetail);
  const isInit = Number(ammDetail.ammPosition) == 0;

  const afterLPToken = isInit ? dY : (dY / parseFloat(ammDetail.ammPosition)) * parseFloat(ammDetail.shareTotalSupply);
  const currentMargin: number = parseFloat(accountDetail.accountBalance) + parseFloat(accountDetail.unrealPnl);

  const initialMarginRatio = globalConfig.initialMarginRatio / 10000;
  const maintenanceMarginRatio = globalConfig.maintenanceMarginRatio / 10000;

  console.log(`\n IN_CALC_PROCESS----> isInit:`, isInit, `price:`, price);

  function calcCurrentPosition(): IPositionDetail {
    const share = isInit
      ? '0.00'
      : formatBigNumber((parseFloat(ammDetail.shareBalance) / parseFloat(ammDetail.shareTotalSupply)) * 100).toString();
    const shareAmount = formatBigNumber(ammDetail.shareBalance);

    const liqPrice = parseFloat(accountDetail.liqPrice);
    const liqPriceText =
      liqPrice > Number(MAX_NUMBER_999999) ? MIN_NUMBER_00000.toString() : formatBigNumber(liqPrice).toString();
    const calcCurrentPositionValue: IPositionDetail = {
      position: formatBigNumber(accountDetail.position).toString(),
      margin: formatBigNumber(currentMargin).toString(),
      initMargin: formatBigNumber(accountDetail.positionMargin).toString(),
      mainMargin: formatBigNumber(accountDetail.mainMargin).toString(),
      liqPrice: liqPriceText,
      poolShareAmount: shareAmount.toString(),
      poolShare: share,
    };

    return calcCurrentPositionValue;
  }
  // midPrice
  function calcAnticipatePosition(): IPositionDetail {
    console.log('\n calcAnticipatePosition isInit ', isInit);
    const markPrice = isInit ? price : parseFloat(accountDetail.markPrice);
    const newPosition = parseFloat(accountDetail.position) - dY;
    const newMargin = currentMargin + dX / leverage;
    const newInitMargin = Math.abs(newPosition * markPrice * initialMarginRatio);
    const newMaintenanceMargin = Math.abs(newPosition * markPrice * maintenanceMarginRatio);
    const liqPrice = markPrice - (newMargin - newMaintenanceMargin) / newPosition || 0;
    const liqPriceText =
      liqPrice > Number(MAX_NUMBER_999999) ? MIN_NUMBER_00000.toString() : formatBigNumber(liqPrice).toString();
    const share = isInit
      ? '100.00'
      : formatInputNumber(
          ((parseFloat(ammDetail.shareBalance) + afterLPToken) /
            (parseFloat(ammDetail.shareTotalSupply) + afterLPToken)) *
            100,
          2,
        );
    const shareAmount = formatBigNumber(parseFloat(ammDetail.shareBalance) + afterLPToken);
    const calcAnticipatePositionValue: IPositionDetail = {
      position: formatBigNumber(newPosition).toString(),
      margin: formatBigNumber(newMargin).toString(),
      initMargin: formatBigNumber(newInitMargin).toString(),
      initMarginDelta: formatBigNumber(newInitMargin - currentMargin).toString(),
      mainMargin: formatBigNumber(newMaintenanceMargin).toString(),
      liqPrice: liqPriceText,
      poolShare: share.toString(),
      poolShareAmount: shareAmount.toString(),
      shareDelta: dY,
      valueDelta: dX * 2,
    };
    return calcAnticipatePositionValue;
  }

  console.groupEnd();

  return [calcCurrentPosition(), calcAnticipatePosition(), afterLPToken];
}

// https://synfutures.atlassian.net/browse/SFT-52
export function trialBalanceReduceLiquidity(
  globalConfig: IContractGlobalConfig,
  accountDetail: IPosition,
  ammDetail: IAmmDetail,
  quantity: string,
  price: number,
): [IPositionDetail, IPositionDetail] {
  const share = Number(quantity);
  const dY = (share / Number(ammDetail.shareTotalSupply)) * Number(ammDetail.ammPosition);
  const dX = dY * price;
  const currentMargin: number = parseFloat(accountDetail.accountBalance) + parseFloat(accountDetail.unrealPnl);
  const initialMarginRatio = globalConfig.initialMarginRatio / 10000;
  const maintenanceMarginRatio = globalConfig.maintenanceMarginRatio / 10000;

  function calcCurrentPosition(): IPositionDetail {
    const calcCurrentPositionValue: IPositionDetail = {
      position: formatBigNumber(accountDetail.position).toString(),
      margin: formatBigNumber(currentMargin).toString(),
      initMargin: formatBigNumber(accountDetail.positionMargin).toString(),
      mainMargin: formatBigNumber(accountDetail.mainMargin).toString(),
      liqPrice: formatBigNumber(accountDetail.liqPrice).toString(),
      poolShare: formatInputNumber(
        (parseFloat(ammDetail.shareBalance) / parseFloat(ammDetail.shareTotalSupply)) * 100,

        2,
      ).toString(),
      poolShareAmount: formatBigNumber(ammDetail.shareBalance).toString(),
    };

    return calcCurrentPositionValue;
  }

  function calcAnticipatePosition(): IPositionDetail {
    const markPrice = parseFloat(accountDetail.markPrice);
    const newPosition = parseFloat(accountDetail.position) + dY;
    const newMargin = currentMargin + dX * 2;
    const newInitMargin = Math.abs(newPosition * markPrice * initialMarginRatio);
    const newMaintenanceMargin = Math.abs(newPosition * markPrice * maintenanceMarginRatio);
    const liqPrice = markPrice - (newMargin - newMaintenanceMargin) / newPosition;

    const isRemovingAll = parseFloat(ammDetail.shareTotalSupply) == share;
    const poolShare = isRemovingAll
      ? 0
      : ((parseFloat(ammDetail.shareBalance) - share) / (parseFloat(ammDetail.shareTotalSupply) - share)) * 100;
    const shareAmount = isRemovingAll ? '0' : formatBigNumber(parseFloat(ammDetail.shareBalance) - share).toString();

    const calcAnticipatePositionValue: IPositionDetail = {
      position: formatBigNumber(newPosition).toString(),
      margin: formatBigNumber(newMargin).toString(),
      initMargin: formatBigNumber(newInitMargin).toString(),
      initMarginDelta: formatBigNumber(newInitMargin - currentMargin).toString(),
      mainMargin: formatBigNumber(newMaintenanceMargin).toString(),
      liqPrice: formatBigNumber(liqPrice).toString(),
      poolShare: formatInputNumber(poolShare, 2).toString(),
      poolShareAmount: shareAmount,
      shareDelta: dY,
      valueDelta: dX * 2,
    };
    return calcAnticipatePositionValue;
  }

  console.groupEnd();

  return [calcCurrentPosition(), calcAnticipatePosition()];
}
