import React, { ReactElement, useCallback, useMemo } from 'react';
import { Card, ConfigProvider, Space, Table, Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import { IAmmDetail } from '@/interfaces/amm';
import customizeRenderEmpty from '../EmptyTable';
import { usePositionHook } from '@/state/position';
import { useWeb3React } from '@web3-react/core';

import OperationButtons, { OPERATION_TYPE } from './operationButtons';
import { useDiffHook } from '@/state/diff';

import { formatNumber, formatNumberTransition, lessThanByNum, negativeNumberColor } from '@/utils/numberUtil';
import { DEFAULT_DECIMAL_PLACES, LESS_NUMBER_00001, MIN_NUMBER_00000 } from '@/constants';
import { getDiffNumByPrice, getDiffSymbolTips } from '@/utils/common';
import { isDiffProductSymbol, pollingFunc, getBaseQuoteBySymbol } from '@/utils';
import { AMM_STATUS } from '@/constants/config';
import { FutureSymbol } from '@/components/FutureSymbol';
import { IPosition, IPositionDisplay } from '@/interfaces/position';
import { useBalanceHook } from '@/state/balance';
import LoadingWrap from '@/components/LoadingWrap';
import { tablePaginationSetting } from '@/constants/ui';
import formatNumberPrefixTooltip from '@/components/Common/formatNumberPrefixTooltip';

import './style.scss';

export default function PositionTable(): ReactElement {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const { currentBlockHeight, currentBlockTime } = useDiffHook();
  const { isFetching: isFetchingPositionList, positions, onFetchPositionOnChain } = usePositionHook();
  const { onFetchTokenBalance, balanceList } = useBalanceHook();
  const traderAddress = account || '';

  // 按钮操作完成后更新数据等操作
  const onHandleOperationSuccess = useCallback(
    (positionInfo: IPosition, type: OPERATION_TYPE) => {
      console.log('onHandleOperationSuccess', positionInfo, type);
      // TODO: 暂时用定时任务更新数据，后期考虑用event优化
      if (type === OPERATION_TYPE.UPDATE) {
        return;
      }
      let times = 3;
      const refreshInterval = pollingFunc(async () => {
        if (times <= 0) {
          clearInterval(refreshInterval);
        }
        onFetchPositionOnChain(traderAddress, positionInfo);
        // 更新balance
        if (positionInfo.symbol && balanceList && balanceList.length > 0) {
          const [, quoteTokenSymbol] = getBaseQuoteBySymbol(positionInfo.symbol);
          const coin = balanceList.find((c) => c.symbol === quoteTokenSymbol);
          if (coin) {
            onFetchTokenBalance(traderAddress, coin);
          }
        }

        times--;
      }, 2000);
      // onAfterOperationSucceed(positionInfo, type);
    },
    [balanceList, onFetchPositionOnChain, onFetchTokenBalance, traderAddress],
  );

  const diffSymbolTipsDisplay = useCallback(
    (record: IAmmDetail): JSX.Element | string => {
      const title = getDiffSymbolTips(record, currentBlockHeight, currentBlockTime, t);
      if (title) {
        return (
          <Tooltip title={title}>
            <QuestionCircleFilled className="tooltip-info-icon" />
          </Tooltip>
        );
      } else {
        return '';
      }
    },
    [currentBlockHeight, currentBlockTime, t],
  );

  const rowClassName = (record: IPosition): string => {
    return record.pair?.ammDetail?.status === AMM_STATUS.EMERGENCY ? 'row-gray-bg' : '';
  };

  const columnsInfo = useMemo(() => {
    return [
      {
        title: 'Symbol',
        dataIndex: 'symbol',
        key: 'symbol',
        render: (text: string, record: IPosition): JSX.Element | undefined => {
          const ammDetail = record.pair?.ammDetail;
          return (
            ammDetail && (
              <>
                <FutureSymbol
                  status={ammDetail.status}
                  symbol={ammDetail.symbol}
                  isShowTradeLink={true}
                  record={ammDetail}
                />
                {ammDetail.status !== AMM_STATUS.SETTLED && diffSymbolTipsDisplay(ammDetail)}
              </>
            )
          );
        },
      },

      {
        title: 'Position',
        dataIndex: 'position',
        key: 'position',
        render: (text: string, record: IPositionDisplay): ReactElement => {
          return (
            <LoadingWrap loading={record.isLoading || false} align="right">
              <span className="my-positions-row-cell">
                {formatNumberPrefixTooltip(text, DEFAULT_DECIMAL_PLACES, true)}
              </span>
            </LoadingWrap>
          );
        },
      },

      {
        title: 'Mark Price',
        dataIndex: 'markPrice',
        key: 'markPrice',
        render: (text: string, record: IPositionDisplay): ReactElement => {
          const isDiffProduct = isDiffProductSymbol(record.symbol);
          return (
            <span style={{ display: 'block', textAlign: 'right' }}>
              {isDiffProduct ? (
                <Tooltip
                  title={
                    <div>
                      <div>Mark Difficulty: {getDiffNumByPrice(text)}</div>
                    </div>
                  }>
                  {formatNumber(text)}
                </Tooltip>
              ) : (
                formatNumberPrefixTooltip(text)
              )}
            </span>
          );
        },
      },
      {
        title: 'Liq. Price',
        dataIndex: 'liqPrice',
        key: 'liqPrice',
        render: (text: string, record: IPositionDisplay): ReactElement => {
          const isDiffProduct = isDiffProductSymbol(record.symbol);
          const liqDisplay = record.position === '0' ? MIN_NUMBER_00000.toString() : record.liqPrice;
          return (
            <span style={{ display: 'block', textAlign: 'right' }}>
              {isDiffProduct ? (
                <Tooltip
                  title={
                    <div>
                      <div>Liq. Difficulty: {getDiffNumByPrice(liqDisplay)}</div>
                    </div>
                  }>
                  {formatNumberPrefixTooltip(liqDisplay)}
                </Tooltip>
              ) : (
                formatNumberPrefixTooltip(liqDisplay)
              )}
            </span>
          );
        },
      },

      {
        title: 'Margin Bal.',
        dataIndex: 'marginBalance',
        render: (text: string, record: IPositionDisplay): ReactElement => {
          const marginBalance = record.marginBalance || 0;

          return (
            <span className="my-positions-row-cell">
              {formatNumberPrefixTooltip(marginBalance, DEFAULT_DECIMAL_PLACES, false)}
            </span>
          );
        },
      },

      {
        title: 'Avail. Margin',
        dataIndex: 'availableMargin',
        key: 'availableMargin',
        render: (text: string, record: IPositionDisplay): ReactElement => {
          return (
            <span className="my-positions-row-cell">
              {formatNumberPrefixTooltip(text, DEFAULT_DECIMAL_PLACES, false)}
            </span>
          );
        },
      },

      {
        title: 'Operations',
        className: 'position-table-operations',
        key: 'action',
        align: 'center' as 'center',
        render: (_: any, record: IPosition): ReactElement => {
          return (
            <Space>
              {record.pair?.ammDetail?.status !== AMM_STATUS.EMERGENCY && (
                <OperationButtons
                  positionInfo={record}
                  traderAddress={traderAddress}
                  onHandleOk={onHandleOperationSuccess}
                />
              )}
            </Space>
          );
        },
      },
    ];
  }, [traderAddress, diffSymbolTipsDisplay, onHandleOperationSuccess]);

  return (
    <React.Fragment>
      <Card
        headStyle={{ padding: '0', borderBottom: 'none' }}
        bodyStyle={{ padding: '0' }}
        title={
          <div
            style={{
              fontSize: 16,
              fontFamily: 'Helvetica',
              fontWeight: 'bold',
              color: '#184858',
            }}>
            My Positions
          </div>
        }
        bordered={false}
        className="PositionTableCard"
        style={{ background: 'transparent', width: 960, marginTop: '2%', margin: 'auto' }}>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Table
            loading={isFetchingPositionList}
            className="position-table-card-table"
            columns={columnsInfo}
            dataSource={positions}
            rowKey={(record: IPosition): string => record.id}
            rowClassName={(record: IPosition): string => rowClassName(record)}
            size="middle"
            pagination={tablePaginationSetting}
            style={{
              border: '1px solid #F1F1F1',
              boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.03)',
            }}
          />
        </ConfigProvider>
      </Card>
    </React.Fragment>
  );
}
