import { useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { orderBy } from 'lodash';
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import { debounce } from 'lodash';
import { AppDispatch, AppState } from '../index';
import {
  resetState,
  updateBalanceListPrice,
  isFetchingData,
  updateCoinBalance,
  updateCoinWalletBalance,
  isLoadingCoinWalletBalance,
} from './actions';
import { ICoinBalance, ICoinBalanceDisplay, ICoinBalanceList } from '@/interfaces/balance';
import { AVAILABLE_PRODUCTS, PRODUCT_TYPE } from '@/constants/product';
import { synDiffWeb3, synWeb3 } from '@/synWeb3';
import { usePositionHook, usePositionState } from '../position';
import useDebounce from '@/hooks/useDebounce';
import { chainConfig } from '@/constants/chain';
import { gaException } from '@/utils/gaUtil';

export function useBalanceState(): AppState['balance'] {
  return useSelector<AppState, AppState['balance']>((state) => state.balance);
}
interface IPrice {
  usd: number;
}

interface IPriceData {
  [id: string]: IPrice;
}

/**
 * 币种对应coingecko的mapping
 * TODO：转成动态配置
 * @param coinSymbol 币种
 * @returns
 */
function coingeckoCoinNameMapping(coinSymbol: string): string {
  switch (coinSymbol) {
    case 'DAI':
      return 'dai';
    case 'ETH':
      return 'ethereum';
    case 'USDT':
      return 'tether';
    case 'USDC':
      return 'usd-coin';
    case 'WBTC':
      return 'wrapped-bitcoin';
    case 'BTCB':
      return 'bitcoin-bep2';
    case 'BNB':
      return 'binancecoin';
    case 'BUSD':
      return 'binance-usd';
  }
  return '';
}

export const useBalanceHook = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { balanceList: allBalanceList, isFetching } = useBalanceState();
  const { positions: allPositions, isFetching: isFetchingPositionList } = usePositionHook();
  const positions = useDebounce(allPositions, 500);

  const onResetState = useCallback(() => {
    dispatch(resetState());
  }, [dispatch]);

  const onFetchBalanceTokenPrice = useCallback(async () => {
    // Create `axios-cache-adapter` instance
    const cache = setupCache({
      maxAge: 15 * 60 * 1000,
    });

    // Create `axios` instance passing the newly created `cache.adapter`
    const api = axios.create({
      adapter: cache.adapter,
    });
    const priceBalanceList = JSON.parse(JSON.stringify(allBalanceList)) as ICoinBalanceList;
    let tokenParams = '';
    Object.values(priceBalanceList).forEach((balance) => {
      balance.coingeckoSymbol = coingeckoCoinNameMapping(balance.symbol);
      priceBalanceList[balance.symbol] = balance;
      tokenParams = tokenParams + `%2C${balance.coingeckoSymbol}`;
    });

    if (tokenParams) tokenParams = tokenParams.slice(3);

    const tokenPriceUrl = `https://api.coingecko.com/api/v3/simple/price?ids=${tokenParams}&vs_currencies=usd`;
    const priceResponse = await api.get<IPriceData>(tokenPriceUrl);
    if (priceResponse && priceResponse.data) {
      const balanceArr = Object.values(priceBalanceList);
      Object.entries(priceResponse.data).forEach(([coingeckoSymbol, price]) => {
        const coin = balanceArr.find((b) => b.coingeckoSymbol === coingeckoSymbol);
        if (coin) {
          priceBalanceList[coin.symbol].coinPrice = price.usd;
        }
      });
      dispatch(updateBalanceListPrice({ priceBalanceList: priceBalanceList }));
    }
  }, [allBalanceList, dispatch]);

  // 更新一行数据
  const onFetchTokenBalance = useCallback(
    async (traderAddress: string, balanceInfo: ICoinBalance) => {
      let currentWeb3 = synWeb3; // 默认v1 basic 产品
      // 只有难度产品
      if (AVAILABLE_PRODUCTS.length === 1 && AVAILABLE_PRODUCTS[0] === PRODUCT_TYPE.DIFFICULTY) {
        currentWeb3 = synDiffWeb3;
      }
      try {
        let walletBalance: string | null, err;
        dispatch(isLoadingCoinWalletBalance({ coinBalance: balanceInfo, isLoading: true }));
        if (balanceInfo.symbol === chainConfig.chainBasicCoin.symbol) {
          [walletBalance, err] = await currentWeb3.account.getBalance(traderAddress);
        } else {
          [walletBalance, err] = await currentWeb3.account.getMarginTokenBalance(balanceInfo.address, traderAddress);
        }
        if (err) {
          console.log('sumAccountBalanceByMarginType token balance err ', err);
        }
        if (walletBalance) {
          const walletBalanceStr = new BigNumber(walletBalance).div(Math.pow(10, balanceInfo.decimals)).toString(10);
          dispatch(updateCoinWalletBalance({ coinBalance: balanceInfo, walletBalance: walletBalanceStr }));
        }
      } catch (error) {
        gaException(error.message);
        console.log('🚀 ~ file: index.tsx ~ line 177 ~ sumAccountBalanceByMarginType ~ error', error);
      } finally {
        dispatch(isLoadingCoinWalletBalance({ coinBalance: balanceInfo, isLoading: false }));
      }
    },
    [dispatch],
  );

  const onUpdateTokenBalance = useCallback(
    async (balanceInfo: ICoinBalance, walletBalance: string) => {
      dispatch(updateCoinWalletBalance({ coinBalance: balanceInfo, walletBalance: walletBalance }));
    },
    [dispatch],
  );

  const onFetchAllWalletBalance = useCallback(
    async (traderAddress: string) => {
      let currentWeb3 = synWeb3; // 默认v1 basic 产品
      // 只有难度产品
      if (AVAILABLE_PRODUCTS.length === 1 && AVAILABLE_PRODUCTS[0] === PRODUCT_TYPE.DIFFICULTY) {
        currentWeb3 = synDiffWeb3;
      }
      dispatch(
        isFetchingData({
          isFetching: true,
        }),
      );
      for (let i = 0; i < Object.keys(allBalanceList).length; i++) {
        const coinBalance = allBalanceList[Object.keys(allBalanceList)[i]];
        onFetchTokenBalance(traderAddress, coinBalance);
      }
      dispatch(
        isFetchingData({
          isFetching: false,
        }),
      );
    },
    [allBalanceList, dispatch, onFetchTokenBalance],
  );

  const balances = useMemo(() => {
    const list = Object.values(allBalanceList);
    return list.map((balance) => {
      const { marginBalance, isLoadingMarginBalance } = positions
        .filter((position) => {
          if (position.symbol) {
            const quoteTokenSymbol = position.symbol.split('-')[1];
            return quoteTokenSymbol === balance.symbol;
          }
          return false;
        })
        .reduce(
          (prev, position) => {
            return {
              marginBalance: prev.marginBalance + Number(position.marginBalance || 0),
              isLoadingMarginBalance: prev.isLoadingMarginBalance || position.isLoading || false,
            };
          },
          {
            marginBalance: 0,
            isLoadingMarginBalance: false,
          },
        );

      const totalBalance = new BigNumber(marginBalance).plus(balance.walletBalance);
      const estimatedValue = totalBalance.times(balance.coinPrice).toNumber();
      return {
        ...balance,
        totalBalance: totalBalance.toString(10),
        balance: marginBalance,
        estimatedValue,
        isLoadingMarginBalance: isLoadingMarginBalance || isFetchingPositionList,
      } as ICoinBalanceDisplay;
    });
  }, [allBalanceList, isFetchingPositionList, positions]);

  const balanceList = useMemo(() => {
    const coinBalanceList = orderBy(
      balances,
      [
        (coin) => {
          if (coin.symbol === chainConfig.chainBasicCoin.symbol) {
            return -1;
          }
          return coin.symbol.toLowerCase().charCodeAt(0);
        },
      ],
      ['asc'],
    ).map((coin, i) => {
      return coin;
    });

    return coinBalanceList;
  }, [balances]);

  const totalEstimatedValue = useMemo(() => {
    return balances.map((balance) => balance.estimatedValue).reduce((sum, current) => sum + current, 0);
  }, [balances]);

  return {
    isFetching,
    onFetchBalanceTokenPrice,
    balanceList,
    onFetchAllWalletBalance,
    totalEstimatedValue,
    onFetchTokenBalance,
    onResetState,
    onUpdateTokenBalance,
  } as const;
};
