import { PRODUCT_TYPE } from '@/constants/product';
import { AddressConfig, ProductAddressConfig } from '@/interfaces/config';

/**
 * V1基础合约配置
 */
const BASE_CONTRACT_ADDRESS_CONFIG: AddressConfig = {
  ConfigAddr: '0xE861F9B924Df89a0424B1D19Bf2F01d4C18B6FF3',
  FactoryAddr: '0x26B8af4989Ec642fF3ddD0F8286ab6F60348E1Bb',
  ReaderAddr: '0xbA49B833b52D437eC20a1576e266D05F38d8310A',
};

/**
 * 难度产品合约配置
 */
const DIFF_CONTRACT_ADDRESS_CONFIG: AddressConfig = {
  ConfigAddr: '0x556fa8Cf636681FF91B8650D154096C2FA981113',
  FactoryAddr: '0xD21a8dd2C5d6358aD6c03cc66361D52642957ce6',
  ReaderAddr: '0x39542282366594Da643a0B2871D5AF07b6E6Fc18',
};

/**
 * contract Address 配置
 */
export const CONTRACT_ADDRESS: ProductAddressConfig = {
  [PRODUCT_TYPE.BASIC]: BASE_CONTRACT_ADDRESS_CONFIG,
  [PRODUCT_TYPE.DIFFICULTY]: DIFF_CONTRACT_ADDRESS_CONFIG,
};
