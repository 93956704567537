import { ICoinList } from '@/interfaces/coin';
import {
  ZERO_ADDRESS,
  DIFF_BASE_NAME,
  USD_KEEP_DECIMALS_PLACES,
  BTC_KEEP_DECIMALS_PLACES,
  ETH_KEEP_DECIMALS_PLACES,
} from '@/constants/config';

import { PRODUCT_TYPE, AVAILABLE_PRODUCTS } from '@/constants/product';

const allCoins: ICoinList = {
  BTC: {
    symbol: 'BTC',
    fullName: 'Bitcoin',
    address: '',
    decimals: 8,
    keepDecimals: BTC_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_btc.svg'),
  },
  XAU: {
    symbol: 'XAU',
    fullName: 'Gold',
    address: '',
    decimals: 8, // TODO：目前没找到对应decimals，暂用8
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_xau.svg'),
  },
  ETH: {
    symbol: 'ETH',
    fullName: 'Ethereum',
    address: ZERO_ADDRESS,
    decimals: 18,
    keepDecimals: ETH_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_eth.svg'),
  },
  LINK: {
    symbol: 'LINK',
    fullName: 'Chainlink',
    address: '0x514910771af9ca656af840dff83e8264ecf986ca',
    decimals: 18,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_chainlink.svg'),
  },
  UNI: {
    symbol: 'UNI',
    fullName: 'Uniswap',
    address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    decimals: 18,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_uniswap.svg'),
  },
  USDC: {
    symbol: 'USDC',
    fullName: 'USD Coin',
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    decimals: 6,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_usdc.svg'),
  },
  USDT: {
    symbol: 'USDT',
    fullName: 'Tether',
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    decimals: 6,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_usdt.svg'),
  },
  DAI: {
    symbol: 'DAI',
    fullName: 'DAI',
    address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    decimals: 18,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_dai.svg'),
  },
  [DIFF_BASE_NAME]: {
    symbol: DIFF_BASE_NAME,
    fullName: 'Bitcoin Hash Rate',
    address: '',
    decimals: 8,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_mining.svg'),
  },
  SFT: {
    symbol: 'SFT',
    fullName: 'SynFutures',
    address: '',
    decimals: 18,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_sf.svg'),
  },
  WBTC: {
    symbol: 'WBTC',
    fullName: 'Wrapped Bitcoin',
    address: '0xd19eB5103b2238f5738411D367F528A5340ED943',
    decimals: 8,
    keepDecimals: BTC_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_wbtc.svg'),
  },
  MATIC: {
    symbol: 'MATIC',
    fullName: 'Polygon',
    address: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
    decimals: 18,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_matic.svg'),
  },
};

const chainBasicCoin = allCoins['ETH'];

/**
 * 基础产品Base配置
 */
const basicBasesConfig: ICoinList = {
  BTC: allCoins['BTC'],
  XAU: allCoins['XAU'],
  ETH: allCoins['ETH'],
  UNI: allCoins['UNI'],
  LINK: allCoins['LINK'],
  MATIC: allCoins['MATIC'],
};

/**
 * 基础产品Quote配置
 */
const basicQuotesConfig: ICoinList = {
  ETH: allCoins['ETH'],
  USDC: allCoins['USDC'],
  USDT: allCoins['USDT'],
  DAI: allCoins['DAI'],
};

/**
 * 难度产品Base配置
 */
const synFuturesBasesConfig: ICoinList = {
  [DIFF_BASE_NAME]: allCoins[DIFF_BASE_NAME],
  SFT: allCoins['SFT'],
};

/**
 * 难度产品Quote配置
 */
const synFuturesQuotesConfig: ICoinList = {
  WBTC: allCoins['WBTC'],
};

/**
 * 基础产品币种配置
 */
const basicCoinListConfig: ICoinList = {
  ...basicBasesConfig,
  ...basicQuotesConfig,
};

/**
 * 难度产品币种配置
 */
const synFuturesCoinListConfig: ICoinList = {
  ...synFuturesBasesConfig,
  ...synFuturesQuotesConfig,
};

/**
 * 币种配置
 */
const coinListConfig: ICoinList = {
  ...basicCoinListConfig,
  ...synFuturesCoinListConfig,
};

/**
 * bases币种配置
 */
const basesCoinConfig: ICoinList = AVAILABLE_PRODUCTS.reduce((config, productType) => {
  if (productType === PRODUCT_TYPE.DIFFICULTY) {
    config = { ...config, ...synFuturesBasesConfig };
  } else if (productType === PRODUCT_TYPE.BASIC) {
    config = { ...config, ...basicBasesConfig };
  }
  return config;
}, {} as ICoinList);

/**
 * quote币种配置（如 my balance币种列表）
 */
const quotesCoinConfig: ICoinList = AVAILABLE_PRODUCTS.reduce((config, productType) => {
  if (productType === PRODUCT_TYPE.DIFFICULTY) {
    config = { ...config, ...synFuturesQuotesConfig };
  } else if (productType === PRODUCT_TYPE.BASIC) {
    config = { ...config, ...basicQuotesConfig };
  }
  return config;
}, {} as ICoinList);

export { coinListConfig, basesCoinConfig, quotesCoinConfig, chainBasicCoin };
