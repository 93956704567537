import React, { useMemo } from 'react';
import { Field } from '@/state/swap/actions';
import { useTradeToken } from '@/state/swap/hooks';
import { formatInputNumber, formatNumberTransition } from '@/utils/numberUtil';
import { usePositionHook } from '@/state/position';
import { useTradeHook } from '@/state/trade';
import { isDiffProductSymbol } from '@/utils';
import { useCoinGetter } from '@/state/pairs/hooks';
import DetailCard from './DetailCard';

import { AdditionalMarginTooltip } from '@/pages/TradePage/AdditionalMarginTooltip';
import { chainConfig } from '@/constants/chain';

import './AdditionalMargin.scss';
import formatNumberPrefixTooltip from '@/components/Common/formatNumberPrefixTooltip';

export default function AdditionalMargin(): JSX.Element {
  const { currentPosition } = usePositionHook();
  const { calcAdditionalMargin, onChangeDepositMargin } = useTradeHook();
  const { coinListConfig } = useCoinGetter();

  const { currencies } = useTradeToken();
  const quoteDefaultValue = currencies[Field.QUOTE] || '';

  const isDiffProduct = useMemo(() => {
    if (currentPosition.symbol) {
      return isDiffProductSymbol(currentPosition.symbol);
    }
    return false;
  }, [currentPosition.symbol]);

  const margin2x = useMemo(() => {
    return calcAdditionalMargin(2);
  }, [calcAdditionalMargin]);

  const margin3x = useMemo(() => {
    return calcAdditionalMargin(3);
  }, [calcAdditionalMargin]);

  const margin5x = useMemo(() => {
    return calcAdditionalMargin(5);
  }, [calcAdditionalMargin]);

  return (
    <DetailCard className={`trade-margin-card_additional-margin`} title={<span>Account Leverage Guide</span>}>
      <div className={`margin-detail__table ${isDiffProduct && 'margin-detail__table_diff'}`}>
        <dl>
          <dt>
            2x<small>(Low Risk)</small>
            <AdditionalMarginTooltip />
          </dt>
          <dd
            onClick={(): void => {
              onChangeDepositMargin(formatInputNumber(margin2x, coinListConfig[quoteDefaultValue].decimals).toString());
            }}>
            {formatNumberPrefixTooltip(margin2x)} {quoteDefaultValue}
          </dd>
        </dl>
        <dl>
          <dt>
            3x<small>(Medium Risk)</small>
            <AdditionalMarginTooltip />
          </dt>
          <dd
            onClick={(): void => {
              onChangeDepositMargin(formatInputNumber(margin3x, coinListConfig[quoteDefaultValue].decimals).toString());
            }}>
            {formatNumberPrefixTooltip(margin3x)} {quoteDefaultValue}
          </dd>
        </dl>
        <dl>
          <dt>
            5x<small>(High Risk)</small>
            <AdditionalMarginTooltip />
          </dt>
          <dd
            onClick={(): void => {
              onChangeDepositMargin(formatInputNumber(margin5x, coinListConfig[quoteDefaultValue].decimals).toString());
            }}>
            {formatNumberPrefixTooltip(margin5x)} {quoteDefaultValue}
          </dd>
        </dl>
      </div>
    </DetailCard>
  );
}
