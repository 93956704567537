import { orderBy } from 'lodash';
import { formatNumber } from '@/utils/numberUtil';
import { isDiffProductSymbol } from '@/utils';
import { formatUTCTime, getBlockHeightTimestamps } from '@/utils/timeUtil';
import { AMM_STATUS } from '@/constants/config';
import { IAmmDetail } from '@/interfaces/amm';
import { DEFAULT_DECIMAL_PLACES } from '@/constants';

export const ampleAllowance = (approveNumber: string, initAmount: string): boolean => {
  // console.log(`1-approveNumber:${approveNumber}`, `initAmount:${initAmount}`);
  if (approveNumber == null) {
    approveNumber = '';
  }
  if (initAmount == null) {
    initAmount = '';
  }
  const len = approveNumber.length > initAmount.length ? approveNumber.length : initAmount.length;
  const a = approveNumber.padStart(len, '0');
  const b = initAmount.padStart(len, '0');
  let hasAmpleAllowance = false;
  for (let i = 0; i < len; i++) {
    console.log(i, a[i], b[i]);
    console.log(i, a[i], parseInt(a[i], 10), b[i], parseInt(b[i], 10));
    if (parseInt(a[i], 10) > parseInt(b[i], 10)) {
      hasAmpleAllowance = true;
      break;
    } else if (parseInt(b[i], 10) >= parseInt(a[i], 10)) {
      hasAmpleAllowance = false;
      break;
    }
  }
  return hasAmpleAllowance;
};

/**
 * 根据价格获取难度值
 * @param price 难度价格
 * @param decimal 小数位
 * @param isShowSeparator 是否显示千分位
 * @returns
 */
export const getDiffNumByPrice = (
  price: string | number,
  decimal = DEFAULT_DECIMAL_PLACES,
  isShowSeparator = true,
): string => {
  if (isNaN(Number(price)) || Number(price) <= 0) return formatNumber('0', decimal, isShowSeparator);
  // 计算公司为 Difficulty = 显示的数字为 14 * 86400 * 6.25 / 2^32 / midPrice
  // 其中 14 * 86400 * 6.25  = 7560000 TH * 1000 = 7560000000 PH
  return formatNumber(7560000000 / Math.pow(2, 32) / Number(price), decimal, isShowSeparator);
};

/**
 * 排序 IAmmDetail, IAccountAndAmmDetail 数据
 * @param list
 * @param currentBlockHeight
 * @returns
 */
export const orderByDataList = <T extends { [key: string]: any }>(
  list: T[],
  currentBlockHeight: number,
  currentBlockTime: number,
) => {
  // 排序规则， 按日期 由近到远，symbol字母正序排列
  const orderList = orderBy(
    list,
    [
      function(item) {
        return Object.keys(AMM_STATUS).indexOf(item.status);
      },
      function(item) {
        const isDiffProduct = isDiffProductSymbol(item.symbol);
        if (isDiffProduct) {
          return Math.floor(
            getBlockHeightTimestamps(Number(item.maturity), currentBlockHeight, currentBlockTime) / 1000,
          );
        }
        return item.maturity;
      },
      function(item) {
        return item.symbol;
      },
    ],
    ['desc', 'asc', 'asc'],
  );

  return orderList;
};

/**
 * 获取 Symbol Tooltips Title
 * @param ammInfo
 * @param currentBlockHeight
 * @param t : i18next.TFunction
 * @returns
 */
export const getDiffSymbolTips = (
  ammInfo: IAmmDetail,
  currentBlockHeight: number,
  currentBlockTime: number,
  t: any,
): string => {
  if (isDiffProductSymbol(ammInfo.symbol)) {
    const estimatedMaturityDate = formatUTCTime(
      getBlockHeightTimestamps(Number(ammInfo.maturity), currentBlockHeight, currentBlockTime),
      'YYYY-MM-DD',
    );
    const title = t('message.estimatedMaturity', { maturityDate: estimatedMaturityDate });
    return title;
  }

  return '';
};
