import { createAction } from '@reduxjs/toolkit';

import { IContractGlobalConfig } from '@/interfaces/global';
import { IUIGlobalConfigs } from '@/interfaces/global/UIGlobalConfigs';

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
  ORACLE = 'ORACLE',
  BASE = 'BASE',
  QUOTE = 'QUOTE',
  MARITY = 'MARITY',
  AMMPROXY = 'AMMPROXY',
  FUTUREPROXY = 'FUTUREPROXY',
  GLOBALCONFIG = 'GLOBALCONFIG',
  UIGLOBALCONFIG = 'UIGLOBALCONFIG',
}

export const saveUIGlobalConfig = createAction<{ uiGlobalConfig: IUIGlobalConfigs }>('swap/saveUIGlobalConfig');

export const selectCurrency = createAction<{ field: Field; currencyId: string }>('swap/selectCurrency');
export const selectToken = createAction<{ field: Field; token: string }>('swap/selectToken');
export const selectOracle = createAction<{ field: Field; value: string }>('swap/selectOracle');

export const saveGlobalConfig = createAction<{ globalConfig: IContractGlobalConfig }>('swap/saveGlobalConfig');

export const selectMarity = createAction<{ field: Field; marity: string }>('swap/selectMarity');
export const switchCurrencies = createAction<void>('swap/switchCurrencies');
export const typeInput = createAction<{ field: Field; typedValue: string }>('swap/typeInput');
export const replaceSwapState = createAction<{
  field: Field;
  typedValue: string;
  inputCurrencyId?: string;
  outputCurrencyId?: string;
  recipient: string | null;
}>('swap/replaceSwapState');
export const setRecipient = createAction<{ recipient: string | null }>('swap/setRecipient');
