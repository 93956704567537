import { createAction } from '@reduxjs/toolkit';

export interface ISerializedToken {
  chainId: number;
  address: string;
  decimals: number;
  symbol?: string;
  name?: string;
}

export interface ISerializedPair {
  token0: ISerializedToken;
  token1: ISerializedToken;
}

export const updateMatchesDarkMode = createAction<{ matchesDarkMode: boolean }>('user/updateMatchesDarkMode');
export const updateUserDarkMode = createAction<{ userDarkMode: boolean }>('user/updateUserDarkMode');
export const updateUserExpertMode = createAction<{ userExpertMode: boolean }>('user/updateUserExpertMode');
export const updateUserSlippageTolerance = createAction<{ userSlippageTolerance: number }>(
  'user/updateUserSlippageTolerance',
);
export const updateUserDeadline = createAction<{ userDeadline: number }>('user/updateUserDeadline');
export const addSerializedToken = createAction<{ serializedToken: ISerializedToken }>('user/addSerializedToken');
export const removeSerializedToken = createAction<{ chainId: number; address: string }>('user/removeSerializedToken');
export const addSerializedPair = createAction<{ serializedPair: ISerializedPair }>('user/addSerializedPair');
export const removeSerializedPair = createAction<{ chainId: number; tokenAAddress: string; tokenBAddress: string }>(
  'user/removeSerializedPair',
);
export const toggleURLWarning = createAction<void>('app/toggleURLWarning');
