import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

// Redirects to swap but only replace the pathname
export function RedirectPathToTradeOnly({ location }: RouteComponentProps): JSX.Element {
  return <Redirect to={{ ...location, pathname: '/trade' }} />;
}

// Redirects from the /swap/:outputCurrency path to the /swap?outputCurrency=:outputCurrency format
export function RedirectToTrade(props: RouteComponentProps<{ outputCurrency: string }>): JSX.Element {
  const {
    location: { search },
    match: {
      params: { outputCurrency },
    },
  } = props;

  return (
    <Redirect
      to={{
        ...props.location,
        pathname: '/trade',
        search:
          search && search.length > 1
            ? `${search}&outputCurrency=${outputCurrency}`
            : `?outputCurrency=${outputCurrency}`,
      }}
    />
  );
}
