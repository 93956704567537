import React, { Suspense, useCallback, useEffect, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import GoogleAnalyticsReporter from '@/components/analytics/GoogleAnalyticsReporter';
import Header from '@/components/Header';
import URLWarning from '@/components/Header/URLWarning';
import Web3ReactManager from '@/components/Web3ReactManager';
import DarkModeQueryParamReader from '@/theme/DarkModeQueryParamReader';
import Account from '@/pages/Account';
import Pool from '@/pages/Pool';
import TradePage from '@/pages/TradePage/index';
import { RedirectPathToTradeOnly, RedirectToTrade } from '@/pages/TradePage/redirects';
import { useUIGlobalConfigActionHandlers } from '@/state/swap/hooks';
import { usePairsActionHandlers } from '@/state/pairs/hooks';
import { useGlobalActionHandlers, useGlobalGetter, useGlobalMarginsActionHandlers } from '@/state/global/hooks';
import { synWeb3, synDiffWeb3 } from '@/synWeb3';
import { useDiffHook } from '@/state/diff';
import { useBalanceHook } from '@/state/balance';
import Liquidation from '@/pages/Liquidation';
import { appChainId } from '@/constants/chain';
import { getChainName, pollingFunc } from '@/utils';

import './app.scss';

const AppWrapper = styled.div``;

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
`;

const BodyWrapper = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 16px;
    padding-top: 2rem;
  `};
`;

const Marginer = styled.div`
  margin-top: 5rem;
`;

export default function App(): JSX.Element {
  const { getLocalGlobalConfigUI } = useUIGlobalConfigActionHandlers();
  const { loadLocalPairsAndCoinsAction, onFetchAllOraclePairsAction } = usePairsActionHandlers();
  const { onFetchGlobalConfigAction, onRefreshCurrentUTCTime, onListenChainNetworkChange } = useGlobalActionHandlers();
  const { onFetchGlobalMarginsAction } = useGlobalMarginsActionHandlers();
  const { getLatestBlockHeight } = useDiffHook();
  const { onFetchBalanceTokenPrice } = useBalanceHook();
  const { isCorrectChain } = useGlobalGetter();
  const latestBlockHeightRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (typeof window.ethereum === 'undefined') {
      console.log('MetaMask is not installed!');
      return;
    }
    document.title = `SynFutures ${getChainName(appChainId)} App`;
    // 初始化web3，默认浏览器provider
    synWeb3.global.initWeb3();
    synDiffWeb3.global.initWeb3();
    getLocalGlobalConfigUI();
    loadLocalPairsAndCoinsAction();
    // 每秒刷新当前UTC时间
    onRefreshCurrentUTCTime();
    // 监听网络变化
    onListenChainNetworkChange();
  }, []);

  // 网络更新后获取链上信息
  const onAfterChainChange = useCallback(() => {
    getLocalGlobalConfigUI();
    onFetchGlobalConfigAction();
    // 查询QUOTE-LIST合约配置信息
    onFetchGlobalMarginsAction();
    // 获取币种价格
    onFetchBalanceTokenPrice();
    // 获取链上pair，并处理pair config
    onFetchAllOraclePairsAction();
    // 轮询获取当前难度
    latestBlockHeightRef.current = pollingFunc(() => {
      getLatestBlockHeight();
    }, 5 * 60 * 1000); // 5min 更新当前难度
  }, [
    getLatestBlockHeight,
    getLocalGlobalConfigUI,
    onFetchAllOraclePairsAction,
    onFetchBalanceTokenPrice,
    onFetchGlobalConfigAction,
    onFetchGlobalMarginsAction,
  ]);

  useEffect(() => {
    // 清除定时器
    if (latestBlockHeightRef.current) {
      clearInterval(latestBlockHeightRef.current);
    }
    if (isCorrectChain) {
      onAfterChainChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCorrectChain]);

  return (
    <Suspense fallback={null}>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <AppWrapper className="app">
        <URLWarning />
        <HeaderWrapper className="header">
          <Header />
        </HeaderWrapper>
        <BodyWrapper className="main">
          <Web3ReactManager>
            <Switch>
              <Route exact strict path="/trade" component={TradePage} />
              <Route exact strict path="/swap/:outputCurrency" component={RedirectToTrade} />
              <Route exact strict path="/pool" component={Pool} />
              <Route exact strict path="/account" component={Account} />
              <Route exact strict path="/liquidation-dashboard" component={Liquidation} />
              <Route component={RedirectPathToTradeOnly} />
            </Switch>
          </Web3ReactManager>
          <Marginer />
        </BodyWrapper>
      </AppWrapper>
    </Suspense>
  );
}
