import { formatNumberTransition } from '@/utils/numberUtil';
import { Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import React, { useMemo } from 'react';
import { TRADE_DIRECTION } from '@/constants';

import { useTradeHook } from '@/state/trade';
import { usePositionHook } from '@/state/position';
import { useTradeToken } from '@/state/swap/hooks';
import { Field } from '@/state/swap/actions';

export function AdditionalMarginTooltip(): JSX.Element {
  const { tradeAmount, tradeSide } = useTradeHook();
  const { currencies } = useTradeToken();
  const { currentPosition } = usePositionHook();
  const makeMarginTooltipText: string = useMemo(() => {
    const baseCurrency = currencies[Field.BASE] || '';
    const currentPositionNumber = Number(currentPosition.position);
    const currentSide = currentPositionNumber < 0 ? TRADE_DIRECTION.SHORT : TRADE_DIRECTION.LONG;
    const currentPositionNumberFormat = formatNumberTransition(Math.abs(currentPositionNumber));
    return tradeAmount == 0
      ? `Deposit suggested margin to achieve target leverage for current position of ${currentSide} ${currentPositionNumberFormat} ${baseCurrency} `
      : `Deposit suggested margin to achieve target leverage for proposed trade of ${tradeSide} ${formatNumberTransition(
          tradeAmount,
        )} ${baseCurrency} and current position of ${currentSide} ${currentPositionNumberFormat} ${baseCurrency}`;
  }, [tradeAmount, tradeSide, currencies, currentPosition.position]);

  return (
    <Tooltip title={makeMarginTooltipText}>
      <QuestionCircleFilled className="tooltip-info-icon" style={{ marginLeft: 4 }} />
    </Tooltip>
  );
}
