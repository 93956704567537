import * as basicABIs from '@/constants/chain/kovan/abis/basic';
import * as diffABIs from '@/constants/chain/kovan/abis/diff';
import { ABIConfig, ProductABIConfig } from '@/interfaces/chain';
import { PRODUCT_TYPE } from '@/constants/product';

/**
 * ABI配置,(用Kovan相同的abi)
 */
const ABI_CONFIG: ProductABIConfig = {
  /**
   * 基础ABI
   */
  [PRODUCT_TYPE.BASIC]: basicABIs as ABIConfig,
  /**
   * 难度产品ABI
   */
  [PRODUCT_TYPE.DIFFICULTY]: diffABIs as ABIConfig,
};

export { ABI_CONFIG };
