import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  resetState,
  savePositions,
  isFetchingData,
  addPosition,
  deletePosition,
  updatePosition,
  updatePositionDetail,
  updatePositionAmmDetail,
  changeCurrentPosition,
  resetCurrentPosition,
  updateCurrentPositionFuturesDetail,
  isLoadingPositionDetail,
  isLoadingPositionAmmDetail,
} from './actions';
import { IPosition, IPositionDisplay } from '@/interfaces/position';
import { IAmmDetail } from '@/interfaces/amm';

const initialPosition = {
  id: '',
  symbol: '',
  position: '0',
  entryPrice: '0',
  markPrice: '0',
  accountBalance: '0',
  unrealPnl: '0',
  liqPrice: '0',
  positionMargin: '0',
  mainMargin: '0',
  availableMargin: '0',
  pair: {},
  isLoading: false,
} as IPositionDisplay;

export type PositionState = {
  readonly positions: IPositionDisplay[];
  /**
   * 最后操作新增的position（用于pool列表、position展示）
   */
  readonly newPositionID: string;
  /**
   * 是否在加载链上数据
   *
   * @type {boolean}
   */
  readonly isFetching: boolean;

  readonly currentPosition: IPositionDisplay;
};

const initialState: PositionState = {
  positions: [],
  newPositionID: '',
  isFetching: false,
  currentPosition: initialPosition,
};

export default createReducer<PositionState>(initialState, (builder) =>
  builder
    .addCase(resetState, () => initialState)
    .addCase(isFetchingData, (state, { payload: { isFetching } }) => {
      state.isFetching = isFetching;
    })
    .addCase(savePositions, (state, { payload: { positions } }) => {
      state.positions = _.values(_.merge(_.keyBy(state.positions, 'id'), _.keyBy(positions, 'id')));
      state.newPositionID = '';
    })
    .addCase(updatePositionDetail, (state, { payload: { positionID, positionDetail } }) => {
      state.positions.forEach((position, i) => {
        if (position.id === positionID) {
          state.positions[i] = { ...position, ...positionDetail };
        }
      });
      if (state.currentPosition && state.currentPosition.id === positionID) {
        state.currentPosition = {
          ...state.currentPosition,
          ...positionDetail,
        };
      }
    })
    .addCase(updatePositionAmmDetail, (state, { payload: { positionID, ammDetail } }) => {
      state.positions.forEach((position, i) => {
        if (position.id === positionID && state.positions[i].pair) {
          const newAmm: IAmmDetail = { ...state.positions[i].pair?.ammDetail, ...ammDetail };
          state.positions[i].pair!.ammDetail = newAmm;
          state.positions[i].pair!.symbol = newAmm.symbol;
        }
      });
      if (state.currentPosition && state.currentPosition.id === positionID) {
        if (state.currentPosition.pair) {
          state.currentPosition.pair.ammDetail = { ...state.currentPosition.pair.ammDetail, ...ammDetail };
          state.currentPosition.pair.symbol = ammDetail.symbol;
          state.currentPosition.symbol = ammDetail.symbol;
        }
      }
    })
    .addCase(addPosition, (state, { payload: { position } }) => {
      const positionInList = state.positions.find((p) => p.id === position.id);
      if (!positionInList) {
        state.positions.push(position);
        state.newPositionID = position.id;
      }
    })
    .addCase(changeCurrentPosition, (state, { payload: { position } }) => {
      state.currentPosition = { ...initialPosition, ...state.currentPosition, ...position };
    })
    .addCase(resetCurrentPosition, (state, { payload: {} }) => {
      state.currentPosition = initialPosition;
    })
    .addCase(updateCurrentPositionFuturesDetail, (state, { payload: { position, futuresDetail } }) => {
      if (state.currentPosition.pair) {
        state.currentPosition.pair.futuresDetail = futuresDetail;
      }
    })
    .addCase(isLoadingPositionDetail, (state, { payload: { positionID, isLoading } }) => {
      state.positions.forEach((position, i) => {
        if (position.id === positionID) {
          state.positions[i].isLoading = isLoading;
        }
      });
      if (state.currentPosition && state.currentPosition.id === positionID) {
        state.currentPosition.isLoading = isLoading;
      }
    })
    .addCase(isLoadingPositionAmmDetail, (state, { payload: { positionID, isLoading } }) => {
      state.positions.forEach((position, i) => {
        if (position.id === positionID) {
          state.positions[i].isLoadingAmmDetail = isLoading;
        }
      });
      if (state.currentPosition && state.currentPosition.id === positionID) {
        state.currentPosition.isLoadingAmmDetail = isLoading;
      }
    }),
);
