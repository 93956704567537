import React, { ReactNode, useState, useEffect, useMemo, useCallback } from 'react';
import { notification, Collapse, Space, Spin, Tooltip, Button, Input, Slider } from 'antd';
import { LoadingOutlined, SwapRightOutlined, QuestionCircleFilled } from '@ant-design/icons';

const { Panel } = Collapse;

import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { useSelector } from 'react-redux';
import ContractTickerSelector from '@/components/ContractTickerSelector';
import LiquiditySelector from '@/pages/Selector';
import { txNotification } from '@/components/TxNotification';
import Modal from '@/components/Modal';

import { calculationDayDiff, formatUTCCurrentTime, getBlockHeightTime } from '@/utils/timeUtil';
import { IAmmDetail } from '@/interfaces/amm';
import { IPosition } from '@/interfaces/position';

import { useTradeToken, useTradeMarity, useTradeOrcle, useUIGlobalConfigs } from '@/state/swap/hooks';
import { useDiffHook } from '@/state/diff';
import { useCoinGetter, usePairGetter, usePairsActionHandlers } from '@/state/pairs/hooks';
import { usePositionHook } from '@/state/position/hooks';
import { ITransactionError } from '@/interfaces/error';
import {
  SYNFUTURES_DOCS_PARAMETERS_LINK,
  MIN_NUMBER_00000,
  PARSE_RECEIPT_ERROR,
  DEFAULT_DECIMAL_PLACES,
} from '@/constants';
import { dateFormat } from '@/constants/format';
import {
  negativeNumberColor,
  formatNumber,
  checkPositiveNumeric,
  inputNumChecker,
  formatNumberTransition,
} from '@/utils/numberUtil';
import { getDiffNumByPrice } from '@/utils/common';
import { useGlobalGetter } from '@/state/global/hooks';
import { AppState } from '@/state';
import { RedirectFromEnum } from '@/state/options/actions';

import {
  currentPositionDefault,
  anticipatePositionDefault,
  trialBalancePosition,
  IPositionDetail,
} from '@/utils/trialBalancePosition';
import { synWeb3, synDiffWeb3 } from '@/synWeb3';
import { AMM_STATUS, ORACLE_TYPE, DIFF_HASHRATE_SCALE, ETH_DECIMALS_PLACES } from '@/constants/config';
import { isDiffProductSymbol, getBaseQuoteBySymbol, getOracleTypeByCoinSymbol } from '@/utils';
import { PRODUCT_TYPE } from '@/constants/product';
import { chainConfig } from '@/constants/chain';
import DoneIcon from '@/assets/svg/icons/icon_done_16.svg';
import marks from './marks';
import CoinIcon from '@/components/CoinIcon';
import { IOraclePair } from '@/interfaces/pair';
import { useBalanceHook } from '@/state/balance';
import { ICoinBalance } from '@/interfaces/balance';
import formatNumberPrefixTooltip from '../Common/formatNumberPrefixTooltip';

import './style.scss';
import { GaCategory, gaEvent, gaException } from '@/utils/gaUtil';

const accountDetailDefault: IPosition = {
  id: '',
  symbol: '-',
  position: '0',
  entryPrice: '0',
  markPrice: '0',
  accountBalance: '0',
  unrealPnl: '0',
  liqPrice: '0',
  positionMargin: '0',
  mainMargin: '0',
  availableMargin: '0',
};

const ammDetailDefault: IAmmDetail = {
  id: '',
  ammProxy: '',
  symbol: '-',
  status: AMM_STATUS.TRADING,
  spotIndexPrice: '0',
  shareTotalSupply: '0',
  shareBalance: '0',
  oracle: '0',
  midPrice: '0',
  maturity: '0',
  ammPosition: '0',
  ammMarginBalance: '0',
};

// setProgressStep
enum IProgressStep {
  LOADING = 0,
  LOADED = 1,
  ADDING = 2,
  DONE = 3,
}

export interface IAddLiquidityProps {
  targetFrom: string;
  visible: boolean;
  setVisible: any;
  setTargetFrom?: any;
  dataInfo?: IPosition;
  setDataInfo?: any;
  poolListData?: any;
}

// 初始化难度
function useInitDifficulty(
  initPrice: string,
  spotIndexPrice: string,
  spotIndexDifficulty: string,
  setInitPrice: React.Dispatch<React.SetStateAction<string>>,
  isDiffProduct: boolean,
  isInitDiffProduct: boolean,
  setIsInitDiffProduct: React.Dispatch<React.SetStateAction<boolean>>,
  initDifficultyDefaultValue = '',
): [string, React.Dispatch<React.SetStateAction<string>>] {
  const [initDifficulty, setInitDifficulty] = useState(initDifficultyDefaultValue); // 初始化难度
  // 根据initDifficulty计算initPrice
  useEffect(() => {
    if (isDiffProduct) {
      if (initDifficulty && spotIndexDifficulty) {
        const initPrice = getDiffNumByPrice(initDifficulty);
        console.log(`xxx-isDiffProduct===${isDiffProduct} InitDiffProduct:${isInitDiffProduct} initPrice:`, initPrice);

        setIsInitDiffProduct(false);
        setInitPrice(initPrice);
      } else {
        setInitPrice('');
      }
    }
  }, [isDiffProduct, initDifficulty, spotIndexDifficulty, setInitPrice, isInitDiffProduct, setIsInitDiffProduct]);

  // spotIndexPrice初始化第一次initDifficulty
  useEffect(() => {
    if (!isDiffProduct) {
      return;
    }
    if (!isInitDiffProduct) {
      return;
    }
    if (spotIndexPrice && Number(spotIndexPrice) > 0 && !initDifficulty) {
      const initDifficulty = getDiffNumByPrice(spotIndexPrice);
      console.log(`🐟  --> diff-->spotIndexPrice:`, spotIndexPrice, `->initDifficulty:`, initDifficulty);

      setInitDifficulty(initDifficulty);
    }
  }, [spotIndexPrice, isDiffProduct, initDifficulty, isInitDiffProduct]);
  return [initDifficulty, setInitDifficulty];
}

const AddLiquidity = (props: IAddLiquidityProps) => {
  const { t } = useTranslation();
  const { active, account } = useWeb3React();
  const traderAddress = account || '';

  const selectQuote = useSelector<AppState, AppState['swap']['QUOTE']>((state) => state.swap.QUOTE);

  const { globalConfigs } = useGlobalGetter();
  const { uiGlobalConfig } = useUIGlobalConfigs();
  const { coinListConfig } = useCoinGetter();
  const { currentBlockHeight, currentBlockTime } = useDiffHook();
  const { onUpdatePair, onFetchAllOraclePairsAction } = usePairsActionHandlers();
  const { onAddPositionByAddress, onFetchPositionOnChainBySymbol } = usePositionHook();
  const { allPairs } = usePairGetter();
  const { onFetchTokenBalance, onUpdateTokenBalance, balanceList } = useBalanceHook();

  // simulation 是否展开状态
  const defaultCollapseDispay = false;
  const [collapseDispay, setCollapseDispay] = useState<boolean>(defaultCollapseDispay);

  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasPoolPairInit, setHasPoolPairInit] = useState(true);
  const [progressStep, setProgressStep] = useState<IProgressStep>(IProgressStep.LOADING);

  const [onprocess, setOnprocess] = useState(false);
  const [visible, setVisible] = useState(props.visible);

  const [ammProxy, setAmmProxy] = useState('');
  const [futuresProxy, setFuturesProxy] = useState('');
  const [accountDetail, setAccountDetail] = useState(accountDetailDefault);
  const [ammDetail, setAmmDetail] = useState(ammDetailDefault);

  const [spotIndexPrice, setSpotIndexPrice] = useState('0');
  const [showDatePicker, setShowDatePicker] = useState(true); // 日期 : 显示日历控件 or 显示 DropdownList 样式控件

  // setAnticipatePosition - anticipatePosition
  const [currentPosition, setCurrentPosition] = useState(currentPositionDefault);
  const [anticipatePosition, setAnticipatePosition] = useState(anticipatePositionDefault);

  const [symbol, setSymbol] = useState<string | undefined>('-');
  const [base, setBase] = useState<string | undefined>('');
  const [quote, setQuote] = useState<string | undefined>('');
  const [quantity, setQuantity] = useState('0');
  const [leverage, setLeverage] = useState(10);

  // const dataInfo = props.dataInfo as IAmmDetail;
  const defaultInitPrice = '0'; // Number(dataInfo?.midPrice) !== 0 ? dataInfo?.midPrice : dataInfo?.spotIndexPrice;
  const [initPrice, setInitPrice] = useState(defaultInitPrice);

  const [keepDecimals, setKeepDecimals] = useState(8); //保留几位小数

  const [deviationTips, setDeviationTips] = useState<boolean>(false);
  const [balanceTips, setBalanceTips] = useState<boolean>(false);
  const [isDiffProduct, setIsDiffProduct] = useState<boolean>(false);

  const [isInitDiffProduct, setIsInitDiffProduct] = useState(true);

  const [receivingLPToken, setReceivingLPToken] = useState(0);

  const { currencies } = useTradeToken();
  const { marity } = useTradeMarity();
  const { oracle } = useTradeOrcle();

  // 当前web3
  const currentWeb3 = useMemo(() => {
    return isDiffProduct ? synDiffWeb3 : synWeb3;
  }, [isDiffProduct]);

  // spotIndexPrice难度对应spotIndexDifficulty
  const spotIndexDifficulty = useMemo(() => {
    if (spotIndexPrice) {
      return getDiffNumByPrice(spotIndexPrice);
    }
    return '';
  }, [spotIndexPrice]);

  const [initDifficulty, setInitDifficulty] = useInitDifficulty(
    initPrice,
    spotIndexPrice,
    spotIndexDifficulty,
    setInitPrice,
    isDiffProduct,
    isInitDiffProduct,
    setIsInitDiffProduct,
  ); // 初始化难度

  useCallback(() => setVisible(props.visible), [visible]);

  const walletBalance = useMemo(() => {
    let res = '0';
    if (quote) {
      const balanceInfo = balanceList.find((coin) => coin.symbol === quote);
      if (balanceInfo) {
        res = balanceInfo.walletBalance;
      }
    }
    return res;
  }, [balanceList, quote]);

  // 计算 quoteToken-balance
  useEffect(() => {
    (async (): Promise<void> => {
      if (quote) {
        onFetchTokenBalance(traderAddress, coinListConfig[quote] as ICoinBalance);
      }
    })();
  }, [coinListConfig, onFetchTokenBalance, quote, traderAddress]);

  const initializedReset = (): void => {
    setHasPoolPairInit(true);
    setOnprocess(false);
    setLeverage(10);
    setQuantity('0');
    setDeviationTips(false);
    setProgressStep(IProgressStep.LOADING);
    props.setDataInfo(undefined);
    props.setTargetFrom(``);
    props.setVisible(false);
    console.log(` ---initializedReset() DONE `, initPrice, props.targetFrom);
  };

  useEffect(() => {
    const quoteDecimals = coinListConfig[selectQuote.token as string].decimals;

    // 因为在 ContractTickerSelector line：365 我们要做changeBase 所以 这里会有从state来的 base, 所以要加判定
    if (props.targetFrom != RedirectFromEnum.FROM_CREATE_POOL) {
      setHasPoolPairInit(true); ////每次切换日期的时候，重置为已经初始化的状态
    }

    if (
      props.targetFrom === RedirectFromEnum.POOL_LIST_RECORD ||
      props.targetFrom === RedirectFromEnum.FROM_CREATE_POOL
    ) {
      setProgressStep(IProgressStep.LOADED);
    }

    setKeepDecimals(quoteDecimals);

    if (isDiffProduct) {
      return;
    }

    const price = Number(ammDetail.midPrice) > 0 ? ammDetail.midPrice : ammDetail.spotIndexPrice;

    setInitPrice(price.toString());
    setSpotIndexPrice(price.toString());
    setHasPoolPairInit(Number(ammDetail.midPrice) > 0);
  }, [ammDetail, coinListConfig, isDiffProduct, props.targetFrom, selectQuote.token]);

  useMemo(async () => {
    console.log(`XXXXX+++++++1100: progressStep=${progressStep}`, props.visible, props.targetFrom);
    if (traderAddress && props.dataInfo && progressStep !== IProgressStep.DONE) {
      // console.log(`XXXXX+++++++2200 traderAddress`, traderAddress && props.visible, props.dataInfo );

      setHasLoaded(true); // has props.dataInfo , dataLoaded
      const AmmDetailDateInfo = props.dataInfo.pair?.ammDetail;
      if (!AmmDetailDateInfo) {
        return;
      }
      const recordSymbol = AmmDetailDateInfo.symbol;
      if (recordSymbol !== undefined && recordSymbol !== '') {
        setIsDiffProduct(isDiffProductSymbol(recordSymbol));
        const temp = recordSymbol.split('-');
        setSymbol(recordSymbol);
        setBase(temp[0]);
        setQuote(temp[1]);
        setProgressStep(IProgressStep.LOADED);
      }

      setVisible(props.visible);
      setAmmProxy(AmmDetailDateInfo.ammProxy as string);
      setFuturesProxy(props.dataInfo.pair?.futuresProxy as string);
      setAmmDetail(AmmDetailDateInfo);

      // INIT_CREATE_POOL don't need AccountInfo
      if (props.targetFrom !== RedirectFromEnum.FROM_CREATE_POOL) {
        try {
          const [accountInfo, err] = await currentWeb3.account.getAccountDetail(
            traderAddress,
            props.dataInfo.pair?.futuresProxy!,
          );
          if (err) {
            console.log(`🍉 err--currentWeb3.account.getAccountDetail:`, err);
          }
          console.log(` SUCCESS:: 🍉  await currentWeb3.account.getAccountDetail:`, accountInfo);
          accountInfo && setAccountDetail(accountInfo);
        } catch (error) {
          gaException(error.message);
          console.log(
            `await getAccountDetail :: ERROR:: `,
            error,
            ` account: ${traderAddress} , futuresProxy: ${props.dataInfo.pair?.futuresProxy}`,
          );
        }
      }

      const price =
        Number(AmmDetailDateInfo.midPrice) > 0 ? AmmDetailDateInfo.midPrice : AmmDetailDateInfo.spotIndexPrice;
      setInitPrice(price.toString());
      setSpotIndexPrice(price.toString());

      if (props.targetFrom === RedirectFromEnum.POOL_LIST_RECORD) {
        setHasPoolPairInit(true);
        setShowDatePicker(true);
      } else if (props.targetFrom === RedirectFromEnum.FROM_CREATE_POOL) {
        setLoading(false);
        setHasPoolPairInit(false);
        setShowDatePicker(true);
      }

      // console.log(`  ak5--->`, progressStep, props.targetFrom);
    } else if (traderAddress && props.targetFrom === RedirectFromEnum.POOL_PAGE_BUTTON) {
      setHasPoolPairInit(true);
      setShowDatePicker(false); //显示 DropdownList 组件
      setInitPrice('0');
      setSpotIndexPrice('0');
    }
  }, [traderAddress, currentWeb3.account, props.targetFrom, props.dataInfo]);

  useMemo(async () => {
    setLeverage(10);
    setQuantity('0');
    setInitPrice('0');

    // SFT-786//add liq窗口，打开loading状态下，默认展示已经init的状态而不是还没init的状态
    // 因为在 ContractTickerSelector line：365 我们要做changeBase 所以 这里会有从state来的 base, 所以要加判定
    if (props.targetFrom != RedirectFromEnum.FROM_CREATE_POOL) {
      setHasPoolPairInit(true);
    }

    setCurrentPosition(currentPositionDefault);
    setAnticipatePosition(anticipatePositionDefault);
    setProgressStep(IProgressStep.LOADING);

    // showDatePicker 显示日历控件 OR 日期下拉列表
    if (!showDatePicker) {
      if (currencies.BASE && currencies.QUOTE && marity.MARITY) {
        console.group(` useMemo--`);
        setLoading(true);

        let baseAddress = currencies.BASE;
        const quoteAddress = coinListConfig[currencies.QUOTE].address;
        const marityTimestamp = marity.MARITY;
        setBase(currencies.BASE);
        setQuote(currencies.QUOTE);
        setBalanceTips(false);

        let err: string | null = '';
        let ammAddress: string | null = '';
        let futuresAddress: string | null = '';
        let accountDetailInfo: IPosition | null = null;
        const currentWeb3 = oracle.ORACLE === ORACLE_TYPE.SYNFUTURES ? synDiffWeb3 : synWeb3;
        try {
          if (oracle.ORACLE === chainConfig.defaultOracleType) {
            baseAddress = coinListConfig[currencies.BASE].address;
            [ammAddress, futuresAddress, err] = await currentWeb3.reader.getUniswapContractAddresses(
              baseAddress,
              quoteAddress,
              marityTimestamp,
            );
          } else if (oracle.ORACLE === ORACLE_TYPE.CHAINLINK) {
            [ammAddress, futuresAddress, err] = await currentWeb3.reader.getChainlinkContractAddresses(
              baseAddress,
              quoteAddress,
              marityTimestamp,
            );
          } else if (oracle.ORACLE === ORACLE_TYPE.SYNFUTURES) {
            [ammAddress, futuresAddress, err] = await currentWeb3.reader.getBtcHashRateContractAddresses(
              quoteAddress,
              marityTimestamp,
            );
          }
          if (err) {
            const errorMessage = `get${oracle.ORACLE}ContractAddresses error @ baseAddress:${baseAddress}, quoteAddress:${quoteAddress}, marityTimestamp:${marityTimestamp} `;
            console.log(`err:`, err, errorMessage);
          }
        } catch (error) {
          gaException(error.message);
          console.log('🚀 ~ file: AddLiquidity.tsx ~ line 833 ~ useMemo ~ error', error);
        }
        if (!ammAddress) {
          console.log(`ammAddress:`, ammAddress);
          return;
        }

        setAmmProxy(ammAddress!);
        setFuturesProxy(futuresAddress!);

        // 读取试算需要的参数: BEGIN
        const [ammDetail] = await currentWeb3.amm.getAmmDetail(
          traderAddress as string,
          ammAddress as string,
          futuresAddress as string,
        );

        setAmmDetail(ammDetail as IAmmDetail);

        try {
          [accountDetailInfo, err] = await currentWeb3.account.getAccountDetail(
            traderAddress as string,
            futuresAddress as string,
          );

          setInitPrice(accountDetailInfo?.markPrice!);
          accountDetailInfo && setAccountDetail(accountDetailInfo);
          // 读取试算需要的参数: END
        } catch (error) {
          gaException(error.message);
          setAccountDetail(accountDetailDefault);
          console.log(`await currentWeb3.account.getAccountDetail:`, error);
        }
        setSymbol((ammDetail as IAmmDetail).symbol);

        // 尚未初始化的合约， ammPosition == 0， 设置initPrice = spotIndexPrice
        if (ammDetail && ammDetail.ammPosition === '0') {
          // 尚未init
          const spotPrice = (ammDetail as IAmmDetail).spotIndexPrice;

          console.log(`-尚未init--spotPrice---`, spotPrice);
          setSpotIndexPrice(spotPrice.toString());
          setInitPrice(spotPrice.toString());
          setHasPoolPairInit(false);
        } else {
          // 已经init
          console.log(` 已经init  hide initPrice ${ammDetail?.symbol}  midPrice:${ammDetail?.midPrice}`);
          setHasPoolPairInit(true);
        }

        setLoading(false);
        setHasLoaded(true);
        setProgressStep(IProgressStep.LOADED);
        console.groupEnd();
      } else {
        setProgressStep(IProgressStep.LOADING);
      }
    }
  }, [
    props.targetFrom,
    showDatePicker,
    currencies.BASE,
    currencies.QUOTE,
    marity.MARITY,
    coinListConfig,
    oracle.ORACLE,
    traderAddress,
  ]);

  useMemo(() => {
    // 当 accountDetail, ammDetail, leverage, quantity, initPrice 触发 试算
    // 可优化，节流
    // dX = dY * price (如果还未初始化，用initial price，否则用mid price)
    // 用户会收到的LP token数量为 dY / Y * Total Share, Y为AMM当前仓位

    const price = hasPoolPairInit
      ? Number(ammDetail.midPrice) > 0
        ? ammDetail.midPrice
        : ammDetail.spotIndexPrice
      : initPrice;
    const quantityValue = Number(quantity);
    const priceValue = Number(price);

    console.log(`\n\n 🍉 hasPoolPairInit:${hasPoolPairInit}, initPrice:${initPrice}, price:${price}`);

    try {
      let accountInfo = accountDetail;
      if (props.targetFrom === RedirectFromEnum.FROM_CREATE_POOL) {
        accountInfo = accountDetailDefault;
        setProgressStep(IProgressStep.LOADED); //FROM_CREATE_POOL ，LOADED
      }

      const globalConfig =
        oracle.ORACLE === ORACLE_TYPE.SYNFUTURES
          ? globalConfigs[PRODUCT_TYPE.DIFFICULTY]
          : globalConfigs[PRODUCT_TYPE.BASIC];

      if (priceValue > 0 && quantityValue > 0) {
        const [currentPosition, anticipatePosition, afterLPToken] = trialBalancePosition(
          globalConfig,
          accountInfo,
          ammDetail,
          quantity,
          priceValue,
          leverage,
        );
        console.log(
          `\n\n 试算结果::: currentPosition:`,
          ammDetail,
          `initPrice:${initPrice} afterLPToken: ${afterLPToken}`,
        );

        setReceivingLPToken(afterLPToken as number);
        setCurrentPosition(currentPosition as IPositionDetail);
        setAnticipatePosition(anticipatePosition as IPositionDetail);
      }
    } catch (error) {
      gaException(error.message);
      console.log(`error`);
    }
  }, [
    hasPoolPairInit,
    ammDetail,
    initPrice,
    quantity,
    accountDetail,
    props.targetFrom,
    oracle.ORACLE,
    globalConfigs,
    leverage,
  ]);

  function onClickCancelHandler(): void {
    gaEvent(GaCategory.POOL, 'Cancel ' + `${hasPoolPairInit ? 'initiating pool' : 'adding liquidity to pool'}`);
    if (!hasPoolPairInit) {
      onAfterDepositAndInitPool();
    }
    initializedReset();
  }

  const onClickAutoFillInitialPriceHandler = (): void => {
    setDeviationTips(false);
    setInitPrice(spotIndexPrice);
    if (isDiffProduct) {
      console.log('onClickAutoFillInitialPriceHandler->spotIndexDifficulty', spotIndexDifficulty);

      setInitDifficulty(spotIndexDifficulty);
    }
  };

  const onClickAutoFillFullQuantityHandler = (): void => {
    setQuantity(walletBalance);
  };

  const checkInitPrice = (initPrice: number): boolean => {
    // 未初始化的合约，需要用户设置 initPrice
    // 额外检查 initPrice 是否符合规范公式计算结果
    const globalConfig =
      oracle.ORACLE === ORACLE_TYPE.SYNFUTURES
        ? globalConfigs[PRODUCT_TYPE.DIFFICULTY]
        : globalConfigs[PRODUCT_TYPE.BASIC];
    const maxInitialDailyBasis = globalConfig.maxInitialDailyBasis / 10000;

    let spotPrice = Number(spotIndexPrice);
    let marityTime = marity.MARITY as string;

    // 难度产品-用户输入值，转为算力单位
    if (isDiffProduct) {
      initPrice = Number(initDifficulty);
      spotPrice = Number(spotIndexDifficulty);
      marityTime = getBlockHeightTime(Number(marityTime), currentBlockHeight, currentBlockTime, dateFormat);
    }

    console.log(` marityTime: ${marityTime}`, ammDetail);

    const days = calculationDayDiff(formatUTCCurrentTime('YYYY-MM-DD'), marityTime);
    console.log(`\n 🍉 Math.abs((initPrice - spotPrice) / spotPrice) <= maxInitialDailyBasis * days`);
    console.log(` Math.abs((${initPrice} - ${spotPrice}) / ${spotPrice}) <= ${maxInitialDailyBasis} * ${days}`);
    console.log(` checkInitPrice:`, Math.abs((initPrice - spotPrice) / spotPrice), `<=`, maxInitialDailyBasis * days);

    return Math.abs((initPrice - spotPrice) / spotPrice) <= maxInitialDailyBasis * days;
  };

  const onChangeInitPriceHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let value = e.target.value.trim();
    console.log(`onChangeInitPriceHandler::`, value);
    if (!checkPositiveNumeric(value)) {
      setDeviationTips(false);
      return;
    }
    // let decimals = DEFAULT_DECIMAL_PLACES;
    // if (base) {
    //   decimals = coinListConfig[base].decimals;
    // }
    // value = inputNumChecker(value, decimals);
    // 直接放开到18位
    value = inputNumChecker(value, ETH_DECIMALS_PLACES);

    // initPrice 是否合规检查
    if (checkInitPrice(Number(value))) {
      setDeviationTips(false);
    } else {
      setDeviationTips(true);
    }
    setInitPrice(value);
  };

  const onChangeInitDifficultyHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let value = e.target.value;
    console.log(`\n\n ---onChangeInitDifficultyHandler-[${isInitDiffProduct}]->value:`, value);
    value = inputNumChecker(value);

    // initPrice 是否合规检查
    if (checkInitPrice(Number(value))) {
      console.log(`1---onChangeInitDifficultyHandler-checkInitPrice-【success】:`, value);
      setDeviationTips(false);
    } else {
      console.log(`2---onChangeInitDifficultyHandler-checkInitPrice-【failed】:`, value);
      setDeviationTips(true);
    }
    setInitDifficulty(value);
  };

  const onChangeQuantityHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let value = e.target.value;
    if (!checkPositiveNumeric(value)) {
      return;
    }
    let decimals = DEFAULT_DECIMAL_PLACES;
    if (quote) {
      decimals = coinListConfig[quote].decimals;
    }
    value = inputNumChecker(value, decimals);
    setBalanceTips(Number(value) > Number(walletBalance));
    setQuantity(value);
  };

  const onChangeStepHandler = (value: any): void => {
    console.log(`onChangeStepHandler-Leverage:`, value, typeof value);
    setLeverage(parseInt(value));
  };

  // init 之后的操作
  function onAfterDepositAndInitPool(): void {
    onFetchAllOraclePairsAction();
  }

  async function comboAddLiquidity(): Promise<void> {
    const deadline = uiGlobalConfig.deadline; //globalConfig 读取,有默认值

    // const initialPrice = parseFloat(initPrice);
    const initialAmount = quantity;
    const quoteTokenAddress = coinListConfig[quote as string].address;

    console.log(
      `\n\n 9XX-->comboAddLiquidity: progressStep`,
      progressStep,
      `initPrice:`,
      initPrice,
      `initialAmount`,
      initialAmount,
      `quantity:`,
      quantity,
    );
    // 合并 approve 和 deposit 两步操作
    // 合约Pool Pair 是否已经初始化-
    if (hasPoolPairInit) {
      // 已初始化的add-liquidity
      setProgressStep(IProgressStep.ADDING);
      const contractSymbol = ammDetail.symbol;
      await showApproveNotification(
        contractSymbol as string,
        quoteTokenAddress,
        futuresProxy,
        'depositAndAddLiquidity',
        () => {
          depositAndAddLiquidityActionHandler(contractSymbol);
        },
      );
    } else {
      // 未初始化的add-liquidity 1-Create or PoolPageAddLiquidityInit
      // 【### hasPoolPairInit === false 】
      const oracleSelected = oracle.ORACLE ?? '';
      const baseSelected = currencies.BASE ?? '';
      const quoteSelected = currencies.QUOTE ?? '';

      // reader from hooks
      setBase(baseSelected);
      setQuote(quoteSelected);

      let baseAddress = baseSelected;
      const quoteAddress = quoteSelected && coinListConfig[quoteSelected].address;
      const marityTime = marity.MARITY as string;

      let ammProxy: string | null = ``;
      let futuresProxy: string | null = ``;

      const currentWeb3 = oracleSelected.toUpperCase() === ORACLE_TYPE.SYNFUTURES ? synDiffWeb3 : synWeb3;

      if (oracleSelected.toUpperCase() === chainConfig.defaultOracleType) {
        baseAddress = coinListConfig[baseSelected].address;
        [ammProxy, futuresProxy] = await currentWeb3.reader.getUniswapContractAddresses(
          baseAddress,
          quoteAddress,
          marityTime,
        );
      } else if (oracleSelected.toUpperCase() === ORACLE_TYPE.CHAINLINK) {
        [ammProxy, futuresProxy] = await currentWeb3.reader.getChainlinkContractAddresses(
          baseAddress,
          quoteAddress,
          marityTime,
        );
      } else if (oracleSelected.toUpperCase() === ORACLE_TYPE.SYNFUTURES) {
        [ammProxy, futuresProxy] = await currentWeb3.reader.getBtcHashRateContractAddresses(quoteAddress, marityTime);
      }

      setProgressStep(IProgressStep.ADDING);
      const [ammDetail, err] = await currentWeb3.amm.getAmmDetail(
        account as string,
        ammProxy as string,
        futuresProxy as string,
      );
      if (err) {
        setProgressStep(IProgressStep.LOADED);
        return;
      }

      const contractSymbol = ammDetail?.symbol;
      await showApproveNotification(
        contractSymbol as string,
        quoteTokenAddress,
        futuresProxy as string,
        'depositAndInitPool',
        () => {
          depositAndInitPoolActionHandler(contractSymbol as string, initialAmount, initPrice, deadline);
        },
      );
    }
  }

  const addPosition = useCallback(
    async (contractSymbol: string) => {
      console.log(`onAddPositionByAddress----->`, traderAddress, contractSymbol, ammProxy, futuresProxy);

      onAddPositionByAddress(traderAddress, contractSymbol, ammProxy, futuresProxy);
    },
    [ammProxy, futuresProxy, onAddPositionByAddress, traderAddress],
  );

  /**
   * 更新pair init属性（如果pair不存在则添加新pair）
   */
  const updatePairInitStatus = useCallback(
    async (contractSymbol: string) => {
      let pairInfo = allPairs.find((pair) => pair.oraclePair.symbol === contractSymbol);
      if (pairInfo) {
        pairInfo = JSON.parse(JSON.stringify(pairInfo));
        if (pairInfo) {
          pairInfo.isInit = true;
        }
      } else {
        // 本地添加pair，并设置init
        const [baseToken, quoteToken] = getBaseQuoteBySymbol(contractSymbol);
        const oracleType = getOracleTypeByCoinSymbol(contractSymbol);
        if (baseToken && quoteToken && oracleType) {
          const baseCoin = coinListConfig[baseToken];
          const quoteCoin = coinListConfig[quoteToken];
          if (baseCoin && quoteCoin) {
            const oraclePair: IOraclePair = {
              ammProxy: ammProxy as string,
              baseAddress: baseCoin.address,
              baseName: baseCoin.symbol,
              expiry: Math.floor(new Date(marity.MARITY as string).getTime() / 1000).toString(),
              futuresProxy: futuresProxy as string,
              id: futuresProxy,
              oracleType: oracleType,
              quoteTokenAddress: quoteCoin.address,
              quoteTokenSymbol: quoteCoin.symbol,
              symbol: (ammDetail as IAmmDetail).symbol,
            };
            pairInfo = {
              isInit: true,
              status: AMM_STATUS.TRADING,
              oracleType: oracleType,
              oraclePair: oraclePair,
              baseCoin: baseCoin,
              quoteCoin: quoteCoin,
            };
          }
        }
      }
      if (pairInfo) {
        onUpdatePair(pairInfo);
      }
    },
    [allPairs, ammDetail, ammProxy, coinListConfig, futuresProxy, marity.MARITY, onUpdatePair],
  );

  const depositAndAddLiquidityActionHandler = async (contractSymbol: string): Promise<void> => {
    try {
      const deadline = uiGlobalConfig.deadline;

      // ### ReactGAEvent ###
      const depositAndAddLiquidityParams = {
        method: 'currentWeb3.amm.depositAndAddLiquidity',
        contractSymbol,
        ammProxy,
        quantity,
        leverage: leverage / 10,
        quoteToken: quote,
        traderAddress,
        deadline,
      };
      console.log(`GoogleAnalytics:${JSON.stringify(depositAndAddLiquidityParams)}`);
      gaEvent(GaCategory.DEBUG, 'DepositAndAddLiquidity', JSON.stringify(depositAndAddLiquidityParams));
      // ### ReactGAEvent - END ###

      const depositAndAddLiquidityResult = await currentWeb3.amm.depositAndAddLiquidity(
        ammProxy,
        traderAddress,
        quantity,
        leverage / 10,
        quote as string,
        deadline,
        function(str) {
          txNotification.info({
            message: t('notification.depositAndAddLiquidity.title', { contractSymbol: contractSymbol }),
            description: t('notification.depositAndAddLiquidity.info'),
          });
          return str;
        },
      );

      if (depositAndAddLiquidityResult.status) {
        // onFetchPositionOnChainBySymbol(traderAddress, contractSymbol);
        addPosition(contractSymbol);

        setProgressStep(IProgressStep.DONE);

        try {
          // Transfer
          const depositAndAddLiquidityInfo = currentWeb3.amm.parseReceipt(depositAndAddLiquidityResult);
          const depositAndAddLiquidityFuturesInfo = currentWeb3.futures.parseReceipt(depositAndAddLiquidityResult);

          if (
            depositAndAddLiquidityInfo === PARSE_RECEIPT_ERROR ||
            depositAndAddLiquidityFuturesInfo === PARSE_RECEIPT_ERROR
          ) {
            console.log(`PARSE_RECEIPT_ERROR`);
            throw new Error(PARSE_RECEIPT_ERROR);
          }
          showDepositSuccessNotification(
            contractSymbol as string,
            depositAndAddLiquidityInfo,
            depositAndAddLiquidityFuturesInfo,
            'depositAndAddLiquidity',
            depositAndAddLiquidityResult.transactionHash,
          );

          return; //完成后保持MODAL界面冻结
        } catch (error) {
          gaException(error.message);
          txNotification.success({
            message: t('notification.depositAndAddLiquidity.title', { contractSymbol: contractSymbol }),
            description: t('notification.depositAndAddLiquidity.default'),
            tx: depositAndAddLiquidityResult.transactionHash,
          });
        }
      } else {
        setLoading(false);
        setOnprocess(false);
        setProgressStep(IProgressStep.LOADED);
      }
    } catch (error) {
      gaException(error.message);
      setLoading(false);
      setOnprocess(false);
      setProgressStep(IProgressStep.LOADED);

      if (error && error.code === 4001) {
        console.log(`USER REJECT:`, error);
        return;
      }

      const err = error as ITransactionError;
      let errDescription: ReactNode = t('notification.depositAndAddLiquidity.error');
      if (err.notifyWrap) {
        errDescription = err.notifyWrap(errDescription);
      }
      txNotification.error({
        duration: null,
        message: t('notification.depositAndAddLiquidity.title', { contractSymbol: contractSymbol }),
        description: errDescription,
        tx: err.receipt && err.receipt.transactionHash,
      });
    }
  };

  const depositAndInitPoolActionHandler = async (
    contractSymbol: string,
    initialAmount: string,
    initialPrice: string,
    deadline: number,
  ): Promise<void> => {
    try {
      // ### ReactGAEvent ###
      const depositAndInitPoolParams = {
        method: 'currentWeb3.amm.depositAndInitPool',
        contractSymbol,
        ammProxy,
        initialPrice,
        initialAmount,
        leverage: leverage / 10,
        quoteToken: quote,
        traderAddress,
        deadline: deadline,
      };
      console.log(`GoogleAnalytics:${JSON.stringify(depositAndInitPoolParams)}`);
      gaEvent(GaCategory.DEBUG, 'DepositAndInitPool', JSON.stringify(depositAndInitPoolParams));
      // ### ReactGAEvent - END ###

      const depositAndInitPool = await currentWeb3.amm.depositAndInitPool(
        ammProxy as string,
        traderAddress,
        initialAmount,
        initialPrice,
        leverage / 10,
        deadline,
        quote,
        function(str) {
          txNotification.info({
            message: t('notification.depositAndInitPool.title', { contractSymbol: contractSymbol }),
            description: t('notification.depositAndInitPool.info'),
          });
          return str;
        },
      );

      if (depositAndInitPool.status) {
        addPosition(contractSymbol);
        // // update redux pairs list data
        // console.log(`onUpdatePairStatusAction`, updateAmmDetail.symbol, allPairs);
        updatePairInitStatus(contractSymbol);
        console.log('----------ak6----DONE');
        setProgressStep(IProgressStep.DONE); //冻结弹窗数据

        try {
          const depositAndInitPoolInfo = currentWeb3.amm.parseReceipt(depositAndInitPool);
          const depositAndInitPoolFuturesInfo = currentWeb3.futures.parseReceipt(depositAndInitPool);

          if (depositAndInitPoolInfo === PARSE_RECEIPT_ERROR || depositAndInitPoolFuturesInfo === PARSE_RECEIPT_ERROR) {
            console.log(`PARSE_RECEIPT_ERROR`);
            throw new Error(PARSE_RECEIPT_ERROR);
          }

          // showDepositAndInitPoolSuccessNotification
          showDepositSuccessNotification(
            contractSymbol as string,
            depositAndInitPoolInfo,
            depositAndInitPoolFuturesInfo,
            'depositAndInitPool',
            depositAndInitPool.transactionHash,
          );

          return; //完成后保持MODAL界面冻结
        } catch (error) {
          gaException(error.message);
          txNotification.success({
            message: t('notification.depositAndInitPool.title', { contractSymbol: contractSymbol }),
            description: t('notification.depositAndInitPool.default'),
            tx: depositAndInitPool.transactionHash,
          });
        }
      }
    } catch (error) {
      gaException(error.message);
      setLoading(false);
      setOnprocess(false);
      setProgressStep(IProgressStep.LOADED);

      if (error && error.code === 4001) {
        console.log(`USER REJECT:`, error);
        return;
      }

      const err = error as ITransactionError;
      let errDescription: ReactNode = t('notification.depositAndInitPool.error');
      if (err.notifyWrap) {
        errDescription = err.notifyWrap(errDescription);
      }
      txNotification.error({
        duration: null,
        message: t('notification.depositAndInitPool.title', {
          contractSymbol: ammDetail?.symbol,
        }),
        description: errDescription,
        tx: err.receipt && err.receipt.transactionHash,
      });
    }
  };

  const showApproveNotification = async (
    contractSymbol: string,
    quoteTokenAddress: string,
    futuresProxy: string,
    template: string,
    approveNextActionHandler: () => void,
  ): Promise<void> => {
    try {
      const approveResult = await currentWeb3.futures.approve(
        traderAddress,
        quoteTokenAddress,
        futuresProxy,
        Number.MAX_SAFE_INTEGER - 1,
        true,
        function() {
          txNotification.info({
            message: t(`notification.${template}.title`, { contractSymbol: contractSymbol }),
            description: t(`notification.${template}.approveInfo`),
          });
          approveNextActionHandler();
        },
      );

      if (typeof approveResult === 'boolean' && approveResult === true) {
        approveNextActionHandler();
      } else {
        if (approveResult && approveResult.status) {
          const notificationStr = t(`notification.${template}.approveSuccess`, {
            quoteToken: quote,
          });
          const descriptionNode: ReactNode = (
            <div>
              <span dangerouslySetInnerHTML={{ __html: notificationStr }}></span>
            </div>
          );

          txNotification.success({
            message: t(`notification.${template}.title`, { contractSymbol: contractSymbol }),
            description: descriptionNode,
            tx: approveResult.transactionHash,
          });
        }
      }
    } catch (error) {
      gaException(error.message);
      setLoading(false);
      setOnprocess(false);
      setProgressStep(IProgressStep.LOADED);

      if (error && error.code === 4001) {
        console.log('USER REJECT setLoading:false');
        return;
      }

      const err = error as ITransactionError;
      let errDescription: ReactNode = t(`notification.${template}.approveError`, {
        quoteToken: quote,
      });
      if (err.notifyWrap) {
        errDescription = err.notifyWrap(errDescription);
      }

      txNotification.error({
        duration: null,
        message: t(`notification.${template}.title`, { contractSymbol: contractSymbol }),
        description: errDescription,
        tx: err.receipt && err.receipt.transactionHash,
      });
    }
  };

  const showDepositSuccessNotification = (
    contractSymbol: string,
    depositInfo: { Transfer: { value: string } },
    depositFuturesInfo: {
      Deposit: { wadAmount: string };
      TransferBalance: { wadAmount: string };
      Trade: { size: string; price: string };
    },
    template: string,
    tx?: string,
  ): void => {
    const receivedLPTokens = formatNumberTransition(currentWeb3.utils.fromWei(depositInfo.Transfer.value as string));
    const quoteQuantity = formatNumberTransition(currentWeb3.utils.fromWei(depositFuturesInfo.Deposit.wadAmount));
    const wadAmount = formatNumberTransition(currentWeb3.utils.fromWei(depositFuturesInfo.TransferBalance.wadAmount));
    const soldSize = formatNumberTransition(currentWeb3.utils.fromWei(depositFuturesInfo.Trade.size));
    const soldPrice = formatNumberTransition(currentWeb3.utils.fromWei(depositFuturesInfo.Trade.price)); //统一4位

    // ### ReactGAEvent ###
    const depositResult = {
      method: 'depositResult',
      contractSymbol,
      quantity: quoteQuantity,
      traderAddress,
      receivedLPTokens,
      quoteToken: quote,
      wadAmount,
      soldSize,
      soldPrice,
    };
    console.log(`GoogleAnalytics:${JSON.stringify(depositResult)}`);
    gaEvent(GaCategory.DEBUG, 'depositResult', JSON.stringify(depositResult));
    // ### ReactGAEvent - END ###

    // depositAndInitPool
    const notificationStr = t(`notification.${template}.success`, {
      quoteQuantity: quoteQuantity,
      receivedLPTokens: receivedLPTokens,
      quoteToken: quote,
      wadAmount: wadAmount,
      soldSize: soldSize,
      soldPrice: soldPrice,
    });
    const descriptionNode: ReactNode = (
      <div>
        <span dangerouslySetInnerHTML={{ __html: notificationStr }}></span>
      </div>
    );
    txNotification.success({
      message: t(`notification.${template}.title`, {
        contractSymbol: contractSymbol,
      }),
      description: descriptionNode,
      tx: tx,
    });
  };

  const onClickAddLiquidityHandler = (): void => {
    gaEvent(GaCategory.POOL, hasPoolPairInit ? 'Add Liquidity to pool' : 'Initiate Pool');
    if (props && props.dataInfo) {
      setAmmProxy(props.dataInfo.pair?.ammProxy as string);
      setFuturesProxy(props.dataInfo.pair?.futuresProxy as string);
    }

    if (active) {
      if (hasLoaded) {
        const initialPrice = parseFloat(initPrice) || 0;

        if (!hasPoolPairInit && initialPrice <= 0) {
          notification.warning({
            message: 'Warning',
            description: `Please Input ${isDiffProduct ? 'Initial Difficulty' : 'Initial Price'}`,
          });
          return;
        }

        if (parseFloat(quantity) > 0) {
          // 是否已经初始化的合约
          if (hasPoolPairInit) {
            // async function combo add liquidity
            setOnprocess(true);
            setProgressStep(IProgressStep.ADDING);
            comboAddLiquidity();
          } else {
            // 未初始化的合约，需要用户设置 initPrice
            // 额外检查 initPrice 是否符合规范公式计算结果
            // initPrice 是否合规检查 合规提交
            if (checkInitPrice(initialPrice)) {
              setOnprocess(true);
              setProgressStep(IProgressStep.ADDING);
              comboAddLiquidity();
            } else {
              //  'Liquidity', 'Deviation from the reference value is too large',
              txNotification.warning({
                message: t('notification.depositAndInitPool.title', {
                  contractSymbol: ammDetail?.symbol,
                }),
                description: t('notification.depositAndInitPool.initPriceExceeded'),
              });
            }
          }
        } else {
          txNotification.warning({
            message: t('notification.depositAndInitPool.title', {
              contractSymbol: ammDetail?.symbol,
            }),
            description: t('notification.depositAndInitPool.noQuantity'),
          });
        }
      } else {
        setProgressStep(IProgressStep.LOADING);
      }
    } else {
      setProgressStep(IProgressStep.LOADING);
    }
  };

  function onChangeProduct(productType: PRODUCT_TYPE): void {
    setIsDiffProduct(productType === PRODUCT_TYPE.DIFFICULTY);
  }

  // descFolderHandler This panel can only be collapsed by clicking text
  const descFolderHandler = (): ReactNode => {
    return collapseDispay ? <span>↑ LESS ↑</span> : <span>↓ MORE ↓</span>;
  };

  // 切换 simulation show more 状态
  const onChangeSimulationMoreHandler = (): void => {
    setCollapseDispay(!collapseDispay);
    console.log('pool-modal-fold-onChangeSimulationMoreHandler', collapseDispay);
  };

  return (
    <React.Fragment>
      <Modal
        className="pool-card-modal liquidity-modal"
        maskClosable={false}
        visible={props.visible}
        onOk={onClickCancelHandler}
        onCancel={onClickCancelHandler}
        footer={null}
        destroyOnClose
        style={{
          width: 456,
        }}>
        <div className="modal-title">{hasPoolPairInit ? 'Add Liquidity to Pool' : 'Initialize and Add Liquidity'}</div>

        <Spin
          className={progressStep >= 3 ? 'process-done' : ''}
          tip={progressStep >= 3 ? ' ' : ' '}
          spinning={onprocess || progressStep === 3}>
          {showDatePicker ? (
            <div className="ContractTickerSelector">
              <ContractTickerSelector targetFrom={props.targetFrom} dataInfo={props.dataInfo} />
            </div>
          ) : (
            <div className="liquidity-selector">
              <LiquiditySelector
                showCreatePool={true}
                selectorPairs="AllPairs"
                selectorType="addLiquidity"
                onChangeProduct={onChangeProduct}
              />
            </div>
          )}

          {hasPoolPairInit ? (
            <Spin spinning={loading}>
              <div className="modal-liquidity modal-add-liquidity ">
                <div className="modal-quantity-wrap">
                  Quantity
                  <div className="balance-wrap">
                    Balance:
                    <span className="balance-value" onClick={onClickAutoFillFullQuantityHandler}>
                      {formatNumberPrefixTooltip(walletBalance)} {quote}
                    </span>
                  </div>
                </div>
                <Input
                  placeholder={t('message.placeholder.liquidityQuantity')}
                  className="input-value"
                  defaultValue="0"
                  min={0}
                  suffix={<CoinIcon quoteValue={quote || ''} />}
                  value={quantity}
                  onChange={onChangeQuantityHandler}
                />
                {balanceTips ? <div className="error-hint">Insufficient balance</div> : null}
              </div>
            </Spin>
          ) : (
            <Spin spinning={loading}>
              <div className="modal-liquidity modal-init-liquidity">
                <div className="modal-init-price">
                  <div className="modal-quantity-wrap">
                    {isDiffProduct ? 'Initial Difficulty' : 'Initial Price'}
                    <div className="quantity-reference">
                      <span className="reference-text">reference:</span>
                      <span className="reference-value">
                        <span className="reference-value-num" onClick={onClickAutoFillInitialPriceHandler}>
                          {isDiffProduct ? (
                            <span>{`${spotIndexDifficulty} ${DIFF_HASHRATE_SCALE}`}</span>
                          ) : (
                            <span>
                              {formatNumberPrefixTooltip(spotIndexPrice)} {quote}
                            </span>
                          )}
                        </span>
                        <Tooltip
                          placement="top"
                          title={
                            isDiffProduct
                              ? `Reference difficulty: the latest spot index difficulty from oracle.`
                              : `Reference price: the latest spot index price from oracle.`
                          }>
                          <QuestionCircleFilled className="tooltip-info-icon" style={{ marginTop: -3 }} />
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                  <div className="init-price-wrap">
                    {isDiffProduct ? (
                      <Input
                        placeholder={t('message.placeholder.initialDifficulty')}
                        className="input-value"
                        defaultValue={spotIndexDifficulty}
                        suffix={DIFF_HASHRATE_SCALE}
                        step={0.0001}
                        min={0}
                        value={initDifficulty}
                        onChange={onChangeInitDifficultyHandler}
                      />
                    ) : (
                      <Input
                        placeholder={t('message.placeholder.initialPrice')}
                        className="input-value"
                        defaultValue={spotIndexPrice}
                        suffix={<CoinIcon quoteValue={quote || ''} />}
                        step={0.0001}
                        min={0}
                        value={initPrice}
                        onChange={onChangeInitPriceHandler}
                      />
                    )}
                    {deviationTips ? (
                      <div className="error-hint">
                        {t('message.initPriceTips')}{' '}
                        <a
                          href={SYNFUTURES_DOCS_PARAMETERS_LINK}
                          rel="noreferrer noopener"
                          className="syn-link"
                          target="_blank">
                          Learn more
                        </a>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="modal-init-quantity">
                  <div className="modal-quantity-wrap">
                    Quantity
                    <div className="balance-wrap">
                      Balance:
                      <span className="balance-value" onClick={onClickAutoFillFullQuantityHandler}>
                        {formatNumberPrefixTooltip(walletBalance)} {quote}
                      </span>
                    </div>
                  </div>
                  <Input
                    placeholder={t('message.placeholder.liquidityQuantity')}
                    className="input-value"
                    defaultValue="0"
                    min={0}
                    suffix={<CoinIcon quoteValue={quote || ''} />}
                    value={quantity}
                    onChange={onChangeQuantityHandler}
                  />
                  {balanceTips ? <div className="error-hint">{t('message.balanceOverrunTips')}</div> : null}
                </div>
              </div>
            </Spin>
          )}

          <div className="modal-tx-tips">
            Transaction Detail:
            <Tooltip
              placement="right"
              className="accountPositionTablePopover"
              title={
                ammDetail.symbol
                  ? `You will be adding ${anticipatePosition.shareDelta?.toFixed(4)} synthetic ${
                      getBaseQuoteBySymbol(ammDetail.symbol)[0]
                    } (1x Long) and ${anticipatePosition.valueDelta?.toFixed(4)} ${
                      getBaseQuoteBySymbol(ammDetail.symbol)[1]
                    } position from the pool. Meanwhile you will
              be opening an initial hedge (${leverage / 10}x Short) transaction for your synthetic ${
                      getBaseQuoteBySymbol(ammDetail.symbol)[0]
                    } position.`
                  : ''
              }>
              <QuestionCircleFilled className="tooltip-info-icon" style={{ height: 12, marginTop: 3 }} />
            </Tooltip>
            <a
              className="tx-link syn-link"
              target="_blank"
              rel="noreferrer noopener"
              href="https://synfutures.github.io/docs/docs/">
              Learn more on how it works &gt;
            </a>
          </div>

          <div className="modal-txt-tips">{t('message.liquiditySliderTips')}</div>

          <div className="modal-slider-wrap">
            <Slider
              style={{ marginTop: 18, marginLeft: 16, marginRight: 16 }}
              className="pool-slider"
              // tipFormatter={() => <LocationIcon style={{ height: 20, fontSize: '13px', color: '#00bfbf' }} />}
              tooltipVisible={false}
              tooltipPlacement="top"
              marks={marks(10, 10, 1, 'X')}
              min={10}
              step={10}
              defaultValue={10}
              value={leverage}
              onChange={onChangeStepHandler}
            />

            <div className="modal-tips-wrap">
              <div className="modal-tips">
                You will be receiving
                <span style={{ fontFamily: 'Helvetica', fontWeight: 700, color: '#000000' }}>
                  {' '}
                  {formatNumberPrefixTooltip(receivingLPToken)}{' '}
                </span>
                SynFutures
                <span style={{ fontFamily: 'Helvetica', fontWeight: 700, color: '#000000' }}> {symbol} </span>
                LP token
              </div>
            </div>
          </div>
        </Spin>

        <div className="modal-button-wrap">
          {progressStep == 0 ? (
            <Button className="modal-button" disabled>
              <div className="inner">
                <LoadingOutlined style={{ marginRight: 8 }} />
                Loading
              </div>
            </Button>
          ) : null}

          {progressStep == 1 ? (
            <Button className="modal-button" disabled={balanceTips} onClick={onClickAddLiquidityHandler}>
              <div className="inner">{hasPoolPairInit ? 'Add Liquidity' : 'Initialize and Add Liquidity'}</div>
            </Button>
          ) : null}

          {progressStep > 1 && progressStep < 3 ? (
            <Button className="modal-button" disabled>
              <div className="inner">
                <LoadingOutlined style={{ marginRight: 8 }} />
                {hasPoolPairInit ? 'Add Liquidity' : 'Initialize and Add Liquidity'}
              </div>
            </Button>
          ) : null}

          {progressStep >= 3 ? (
            <Button className="modal-button" onClick={onClickCancelHandler}>
              <div className="inner">
                <img style={{ marginRight: 8 }} src={DoneIcon} />
                Done
              </div>
            </Button>
          ) : null}
        </div>

        <div className={collapseDispay ? 'pool-modal-desc' : 'pool-modal-desc pool-modal-fold'}>
          <div className="pool-modal-desc-head">
            <div className="desc-head-empty">SynFutures</div>
            <div className="desc-head-content">
              <div className="desc-head-current"> Current </div>
              <div className="desc-head-after"> After </div>
            </div>
          </div>

          <div className="pool-modal-desc-list">
            <div className="desc-list-title">
              <div className="desc-item-title">{'Fair Price'}</div>
              {isDiffProduct && <div className="desc-item-title">{'Fair Difficulty'}</div>}
              <div className="desc-item-title">Position</div>
            </div>

            <div className="desc-list-content">
              <div className="desc-list-left">
                <div className="desc-item-current">{formatNumberPrefixTooltip(ammDetail.midPrice)}</div>
                {isDiffProduct && <div className="desc-item-current">{getDiffNumByPrice(ammDetail.midPrice)}</div>}
                <div style={{ color: negativeNumberColor(currentPosition.position) }} className="desc-item-current">
                  {formatNumberPrefixTooltip(currentPosition.position)}
                </div>
              </div>
              <div className="desc-list-middle">
                <div className="desc-list-arrows">
                  {collapseDispay ? null : <SwapRightOutlined className="desc-list-arrows-icon" />}
                </div>
              </div>
              <div className="desc-list-right">
                <div className="desc-item-after">
                  {formatNumberPrefixTooltip(Number(ammDetail.midPrice) === 0 ? initPrice : ammDetail.midPrice)}
                </div>
                {isDiffProduct && (
                  <div className="desc-item-after">
                    {formatNumber(ammDetail.midPrice) === MIN_NUMBER_00000
                      ? initDifficulty || MIN_NUMBER_00000
                      : getDiffNumByPrice(ammDetail.midPrice)}
                  </div>
                )}
                <div style={{ color: negativeNumberColor(anticipatePosition.position) }} className="desc-item-after">
                  {formatNumberPrefixTooltip(anticipatePosition.position)}
                </div>
              </div>
            </div>
          </div>

          {/* 折叠面板 */}
          <Space className="desc-folder" direction="vertical">
            <Collapse
              className="desc-folder-wrap"
              collapsible="header"
              onChange={(): void => {
                onChangeSimulationMoreHandler();
              }}
              defaultActiveKey={['']}
              ghost>
              <Panel showArrow={false} header={descFolderHandler()} key={'showMore'}>
                <div className="pool-modal-desc-list">
                  <div className="desc-list-title">
                    <div className="desc-item-title">Margin</div>
                    <div className="desc-item-title">Init. Margin</div>
                    <div className="desc-item-title">Main. Margin</div>
                    <div className="desc-item-title">Liq. Price</div>
                    {isDiffProduct && <div className="desc-item-title">Liq. Difficulty</div>}
                    <div className="desc-item-title">Pool Share</div>
                    <div className="desc-item-title">Share Ratio</div>
                  </div>
                  <div className="desc-list-content">
                    <div className="desc-list-left">
                      <div className="desc-item-current">{formatNumberPrefixTooltip(currentPosition.margin)}</div>
                      <div className="desc-item-current">{formatNumberPrefixTooltip(currentPosition.initMargin)}</div>
                      <div className="desc-item-current">{formatNumberPrefixTooltip(currentPosition.mainMargin)}</div>
                      <div className="desc-item-current">
                        {formatNumberPrefixTooltip(hasPoolPairInit ? currentPosition.liqPrice : MIN_NUMBER_00000)}
                      </div>
                      {isDiffProduct && (
                        <div className="desc-item-current">
                          {hasPoolPairInit
                            ? getDiffNumByPrice(currentPosition.liqPrice)
                            : formatNumberPrefixTooltip(MIN_NUMBER_00000)}
                        </div>
                      )}
                      <div className="desc-item-current">
                        {formatNumberPrefixTooltip(currentPosition.poolShareAmount)}
                      </div>
                      <div className="desc-item-current">{formatNumber(currentPosition.poolShare, 2)}%</div>
                    </div>
                    <div className="desc-list-middle">
                      <div className="desc-list-arrows">
                        {collapseDispay ? <SwapRightOutlined className="desc-list-arrows-icon" /> : null}
                      </div>
                    </div>
                    <div className="desc-list-right">
                      <div className="desc-item-after">{formatNumberPrefixTooltip(anticipatePosition.margin)}</div>
                      <div className="desc-item-after">{formatNumberPrefixTooltip(anticipatePosition.initMargin)}</div>
                      <div className="desc-item-after">{formatNumberPrefixTooltip(anticipatePosition.mainMargin)}</div>
                      <div className="desc-item-after">
                        {/* 负的liq price都改成 0 */}
                        {formatNumberPrefixTooltip(anticipatePosition.liqPrice)}
                      </div>
                      {isDiffProduct && (
                        <div className="desc-item-after">{getDiffNumByPrice(anticipatePosition.liqPrice)}</div>
                      )}
                      <div className="desc-item-after">
                        {formatNumberPrefixTooltip(
                          Number(anticipatePosition.poolShareAmount) === 0 ? '0' : anticipatePosition.poolShareAmount,
                        )}
                      </div>
                      <div className="desc-item-after">{formatNumber(anticipatePosition.poolShare, 2)}%</div>
                    </div>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </Space>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AddLiquidity;
