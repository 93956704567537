import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../index';
import {
  IContractGlobalConfig,
  IContractGlobalMargins,
  IMarginsParam,
  IProductContractGlobalConfigs,
} from '@/interfaces/global';
import {
  saveGlobalConfig,
  saveGlobalMarginParam,
  changeProduct,
  updateCurrentUTCTime,
  changeCurrentChainId,
} from './actions';
import { synDiffWeb3, synWeb3, SYNWeb3 } from '@/synWeb3';
import { appChainId, chainConfig } from '@/constants/chain';
import { AVAILABLE_PRODUCTS, PRODUCT_TYPE } from '@/constants/product';
import { DIFF_BASE_NAME } from '@/constants/config';
import { currentUTCTime } from '@/utils/timeUtil';
import { pollingFunc } from '@/utils';
import { gaException } from '@/utils/gaUtil';

// 全局配置等信息

//#region actions
export function useGlobalActionHandlers(): {
  onSaveGlobalConfigAction: (config: IContractGlobalConfig, productType: PRODUCT_TYPE) => void;
  onFetchGlobalConfigAction: () => Promise<void>;
  onChangeProductAction: (product: PRODUCT_TYPE) => void;
  onRefreshCurrentUTCTime: () => void;
  onListenChainNetworkChange: () => void;
} {
  const dispatch = useDispatch<AppDispatch>();
  const onSaveGlobalConfigAction = useCallback(
    (config: IContractGlobalConfig, productType: PRODUCT_TYPE) => {
      dispatch(
        saveGlobalConfig({
          config,
          productType,
        }),
      );
    },
    [dispatch],
  );
  const onFetchGlobalConfigAction = useCallback(async () => {
    // v1 basic产品配置
    if (AVAILABLE_PRODUCTS.includes(PRODUCT_TYPE.BASIC)) {
      const [globalConfig, err] = await synWeb3.global.getContractGlobalConfig();
      if (err) {
        console.log(`err:`, err);
        return;
      }
      onSaveGlobalConfigAction(globalConfig as IContractGlobalConfig, PRODUCT_TYPE.BASIC);
    }
    // 难度产品配置
    if (AVAILABLE_PRODUCTS.includes(PRODUCT_TYPE.DIFFICULTY)) {
      const [globalConfig, err] = await synDiffWeb3.global.getContractGlobalConfig();
      if (err) {
        console.log(`err:`, err);
        return;
      }

      onSaveGlobalConfigAction(globalConfig as IContractGlobalConfig, PRODUCT_TYPE.DIFFICULTY);
    }
  }, [dispatch]);

  const onChangeProductAction = useCallback(
    (product: PRODUCT_TYPE) => {
      dispatch(changeProduct({ params: product }));
    },
    [dispatch],
  );

  // 定时更新当前时间
  const onRefreshCurrentUTCTime = useCallback(() => {
    pollingFunc(() => {
      const time = currentUTCTime();
      dispatch(updateCurrentUTCTime({ time: time }));
    }, 1000); // 每一秒更新
  }, [dispatch]);

  const onNetworkListener = useCallback((onAfeterChainChange: (chainId: number) => void) => {
    if (typeof window.ethereum === 'undefined') {
      console.log('MetaMask is not installed!');
      return;
    }
    synWeb3.global.getChainId().then((chainId: number) => {
      onAfeterChainChange(chainId);
    });

    const { ethereum } = window;
    if (ethereum && ethereum.on) {
      const handleChainChanged = () => {
        synWeb3.global.getChainId().then((chainId: number) => {
          onAfeterChainChange(chainId);
        });
      };
      ethereum.on('chainChanged', handleChainChanged);
    }
  }, []);

  const onListenChainNetworkChange = useCallback(() => {
    onNetworkListener((chainId) => {
      dispatch(changeCurrentChainId({ chainId }));
    });
  }, [dispatch, onNetworkListener]);
  return {
    onSaveGlobalConfigAction,
    onFetchGlobalConfigAction,
    onChangeProductAction,
    onRefreshCurrentUTCTime,
    onListenChainNetworkChange,
  };
}

//#region actions
export function useGlobalMarginsActionHandlers(): {
  onGlobalMarginsAction: (params: IMarginsParam) => void;
  onFetchGlobalMarginsAction: () => Promise<void>;
} {
  const dispatch = useDispatch<AppDispatch>();
  const onGlobalMarginsAction = useCallback(
    (marginsParam: IMarginsParam) => {
      dispatch(
        saveGlobalMarginParam({
          params: marginsParam,
        }),
      );
    },
    [dispatch],
  );
  const onFetchGlobalMarginsAction = useCallback(async () => {
    const keys = Object.keys(chainConfig.quotesCoinConfig);

    const promiseList = Object.values(chainConfig.quotesCoinConfig).map((coin) => {
      let isDiffProduct = false;

      if (
        chainConfig.pairTreeConfig.SYNFUTURES &&
        chainConfig.pairTreeConfig.SYNFUTURES[DIFF_BASE_NAME] &&
        chainConfig.pairTreeConfig.SYNFUTURES[DIFF_BASE_NAME].pairs
      ) {
        const pair = chainConfig.pairTreeConfig.SYNFUTURES[DIFF_BASE_NAME].pairs.find(
          (pair) => pair.quoteCoin.symbol === coin.symbol,
        );
        isDiffProduct = !!pair;
      }
      if (isDiffProduct) {
        return synDiffWeb3.global.getGlobalMarginsParam(coin.address);
      } else {
        return synWeb3.global.getGlobalMarginsParam(coin.address);
      }
    });

    Promise.all(promiseList)
      .then((values) => {
        const globalMargins: IMarginsParam = {};
        for (let i = 0; i < values.length; i++) {
          const key = keys[i];
          const value = values[i];
          globalMargins[key] = value[0] as IContractGlobalMargins;
        }
        onGlobalMarginsAction(globalMargins);
      })
      .catch((error) => {
        gaException(error.message);
        console.log(`error`, error);
        return;
      });
  }, [dispatch]);

  return {
    onGlobalMarginsAction,
    onFetchGlobalMarginsAction,
  };
}

//#endregion

//#region getters
export function useGlobalState(): AppState['global'] {
  return useSelector<AppState, AppState['global']>((state) => state.global);
}

export function useGlobalGetter(): {
  globalConfigs: IProductContractGlobalConfigs;
  marginsParam: IMarginsParam;
  currentProductType: PRODUCT_TYPE;
  currentWeb3: SYNWeb3;
  currentUTCTime: number;
  currentGlobalConfig: IContractGlobalConfig;
  currentChainId: number;
  isCorrectChain: boolean;
} {
  const { globalConfigs, marginsParam, currentProductType, currentUTCTime, currentChainId } = useGlobalState();
  const currentWeb3 = currentProductType === PRODUCT_TYPE.DIFFICULTY ? synDiffWeb3 : synWeb3;
  const currentGlobalConfig =
    currentProductType === PRODUCT_TYPE.DIFFICULTY ? globalConfigs.DIFFICULTY : globalConfigs.BASIC;
  const isCorrectChain = useMemo(() => {
    return currentChainId === appChainId;
  }, [currentChainId]);
  return {
    globalConfigs,
    marginsParam,
    currentProductType,
    currentWeb3,
    currentUTCTime,
    currentGlobalConfig,
    currentChainId,
    isCorrectChain,
  };
}

//#endregion
