import React, { useEffect, useState, useMemo } from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { QuestionCircleFilled } from '@ant-design/icons';
import { usePositionHook } from '@/state/position';
import DetailCard from './DetailCard';
import { useGlobalGetter } from '@/state/global/hooks';
import { FutureSymbol } from '@/components/FutureSymbol';
import { useDiffHook } from '@/state/diff';
import { PRODUCT_TYPE } from '@/constants/product';
import { getBlockHeightTimestamps, getDeadLineTimeDuration, toLongTimeDisplayFormat } from '@/utils/timeUtil';
import { getDiffNumByPrice } from '@/utils/common';
import { AMM_STATUS } from '@/constants/config';
import { DIFF_CURRENT_BLOCK_UPDATE_LINK } from '@/constants';
import formatNumberPrefixTooltip from '@/components/Common/formatNumberPrefixTooltip';

import './ContractDetail.scss';
import { getEtherscanLink } from '@/utils';

export default function ContractDetail(): JSX.Element {
  const { t } = useTranslation();
  const [ammMaturityTime, setAmmMaturityTime] = useState<number>(0);
  const { currentAmmDetail, currentFuturesDetail } = usePositionHook();
  const { currentBlockHeight, currentBlockTime } = useDiffHook();
  const { currentProductType, currentUTCTime } = useGlobalGetter();

  const isSymbolDifficulty = useMemo(() => {
    return currentProductType === PRODUCT_TYPE.DIFFICULTY;
  }, [currentProductType]);

  useEffect(() => {
    if (currentAmmDetail && currentAmmDetail.symbol !== '-') {
      if (isSymbolDifficulty) {
        setAmmMaturityTime(Number(currentAmmDetail.maturity));
      } else {
        setAmmMaturityTime(parseInt(currentAmmDetail.maturity, 10) * 1000);
      }
    } else {
      setAmmMaturityTime(0);
    }
  }, [currentAmmDetail, isSymbolDifficulty]);

  // 获取显示的时间倒计时，格式为 xxD xx:xx:xx
  const maturityDurationCountDown = useMemo(() => {
    if (currentUTCTime > 0 && ammMaturityTime > 0) {
      let maturityTime = ammMaturityTime;
      // 如果是难度难度合约，根据块高获取时间
      if (isSymbolDifficulty && currentAmmDetail) {
        maturityTime = getBlockHeightTimestamps(
          Number(currentAmmDetail.maturity),
          currentBlockHeight,
          currentBlockTime,
        );
      }
      if (currentUTCTime > maturityTime) {
        return '00:00:00';
      }
      const duration = getDeadLineTimeDuration(currentUTCTime, maturityTime);
      const days = Math.floor(duration.asDays());
      let timeDiff = `${toLongTimeDisplayFormat(duration.hours())}:${toLongTimeDisplayFormat(
        duration.minutes(),
      )}:${toLongTimeDisplayFormat(duration.seconds())}`;
      // 如果＞1天，则显示
      if (days > 0) {
        timeDiff = `${days}D ${timeDiff}`;
      }
      return timeDiff;
    }
    return '';
  }, [currentUTCTime, ammMaturityTime, isSymbolDifficulty, currentAmmDetail, currentBlockHeight, currentBlockTime]);

  // 难度产品
  const blockToMaturity = useMemo(() => {
    let res = 0;
    if (isSymbolDifficulty) {
      const maturityBlock = ammMaturityTime;
      if (Number(maturityBlock) - Number(currentBlockHeight) > 0) {
        res = Number(maturityBlock) - Number(currentBlockHeight);
      }
    }
    return res;
  }, [ammMaturityTime, currentBlockHeight, isSymbolDifficulty]);

  const isEmptyPair = useMemo(() => {
    if (currentAmmDetail && currentFuturesDetail) {
      return false;
    }
    return true;
  }, [currentAmmDetail, currentFuturesDetail]);

  return (
    <DetailCard
      className={`trade-details-card_contract-detail ${isSymbolDifficulty && 'contract-detail_diff'}`}
      isHideIcon={!isEmptyPair}
      title={
        <span>
          {!isEmptyPair && currentAmmDetail ? (
            <FutureSymbol
              status={currentAmmDetail.status}
              symbol={currentAmmDetail.symbol}
              isShowTradeLink={false}
              link={getEtherscanLink(currentAmmDetail.ammProxy, 'address')}
              linkTarget="_blank"
            />
          ) : (
            'Contract Detail'
          )}
        </span>
      }>
      <div>
        <dl>
          <dt>
            <b>Fair Price</b>
          </dt>
          <dd>
            <b>{!isEmptyPair && currentAmmDetail ? formatNumberPrefixTooltip(currentAmmDetail.midPrice) : '-'}</b>
          </dd>
        </dl>
        {isSymbolDifficulty && (
          <dl>
            <dt>
              <b>Fair Difficulty</b>
            </dt>
            <dd>
              <b>{!isEmptyPair && currentAmmDetail ? getDiffNumByPrice(currentAmmDetail.midPrice) : '-'}</b>
            </dd>
          </dl>
        )}
        <dl>
          <dt>
            <b>Spot Price</b>
          </dt>
          <dd>
            <b>{!isEmptyPair && currentAmmDetail ? formatNumberPrefixTooltip(currentAmmDetail.spotIndexPrice) : '-'}</b>
          </dd>
        </dl>
        {isSymbolDifficulty && (
          <dl>
            <dt>
              <b>Spot Difficulty</b>
            </dt>
            <dd>
              <b>{!isEmptyPair && currentAmmDetail ? getDiffNumByPrice(currentAmmDetail.spotIndexPrice) : '-'}</b>
            </dd>
          </dl>
        )}
        <dl>
          <dt>Open Interests</dt>
          <dd>
            {!isEmptyPair && currentFuturesDetail ? formatNumberPrefixTooltip(currentFuturesDetail.openInterests) : '-'}
          </dd>
        </dl>
        <dl>
          <dt>AMM Position</dt>
          <dd>{!isEmptyPair && currentAmmDetail ? formatNumberPrefixTooltip(currentAmmDetail.ammPosition) : '-'}</dd>
        </dl>
        {!isSymbolDifficulty && (
          <dl>
            <dt>Time to Maturity</dt>
            <dd>{!isEmptyPair && currentAmmDetail && maturityDurationCountDown ? maturityDurationCountDown : '-'}</dd>
          </dl>
        )}
        {isSymbolDifficulty && !isEmptyPair && currentAmmDetail && currentAmmDetail.status === AMM_STATUS.SETTLING && (
          <dl>
            <dt>Block to Maturity</dt>
            <dd>{blockToMaturity}</dd>
          </dl>
        )}
        {isSymbolDifficulty && !isEmptyPair && currentAmmDetail && (
          <dl>
            <dt>
              Current Height
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('message.tooltip.trade.details.contractDetail.currentHeight', {
                        link: DIFF_CURRENT_BLOCK_UPDATE_LINK,
                      }),
                    }}></span>
                }>
                <QuestionCircleFilled className="tooltip-info-icon" />
              </Tooltip>
            </dt>
            <dd>{currentBlockHeight}</dd>
          </dl>
        )}
      </div>
    </DetailCard>
  );
}
