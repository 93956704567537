import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { AppDispatch, AppState } from '../index';
import { IPosition, IPositionDisplay } from '@/interfaces/position';
import {
  resetState,
  savePositions,
  addPosition,
  deletePosition,
  updatePosition,
  isFetchingData,
  updatePositionDetail,
  updatePositionAmmDetail,
  changeCurrentPosition,
  resetCurrentPosition,
  updateCurrentPositionFuturesDetail,
  isLoadingPositionDetail,
  isLoadingPositionAmmDetail,
} from './actions';
import { AVAILABLE_PRODUCTS, PRODUCT_TYPE } from '@/constants/product';
import { chainConfig } from '@/constants/chain';
import { fetchUserPositionList } from '@/utils/IGraphsQuery';
import { synDiffWeb3, SYNWeb3, synWeb3 } from '@/synWeb3';
import { isDiffProductSymbol } from '@/utils/index';
import { orderByDataList } from '@/utils/common';
import { useDiffState } from '@/state/diff/hooks';
import { AMM_STATUS } from '@/constants/config';
import { formatUTCTime, getBlockHeightTimestamps } from '@/utils/timeUtil';
import { IAmmDetail } from '@/interfaces/amm';
import { IFuturesDetail } from '@/interfaces/futures';
import { gaException } from '@/utils/gaUtil';
import { usePairsActionHandlers } from '@/state/pairs/hooks';
import useDebounce from '@/hooks/useDebounce';

export function usePositionState(): AppState['position'] {
  return useSelector<AppState, AppState['position']>((state) => state.position);
}

export const usePositionHook = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    positions: allPositions,
    isFetching,
    newPositionID,
    currentPosition: currentPositionInState,
  } = usePositionState();
  const { currentBlockHeight, currentBlockTime } = useDiffState();
  const { onUpdatePairStatusBySymbol } = usePairsActionHandlers();

  /**
   * 当前仓位，加上marginBalance
   */
  const currentPositionData: IPositionDisplay = useMemo(() => {
    return {
      ...currentPositionInState,
      marginBalance: Number(currentPositionInState.accountBalance) + Number(currentPositionInState.unrealPnl),
    };
  }, [currentPositionInState]);

  // useDebounce来修复多次渲染问题
  const currentPosition = useDebounce(currentPositionData, 200);

  const onResetState = useCallback(() => {
    dispatch(resetState());
  }, [dispatch]);

  /**
   * 获取链上账户position
   */
  const onFetchAccountDetail = useCallback(async (account: string, futuresProxy: string, currentWeb3: SYNWeb3) => {
    return new Promise<IPosition | null>((resolve, reject) => {
      currentWeb3.account
        .getAccountDetail(account, futuresProxy)
        .then(([accountDetailInfo, err]) => {
          resolve(accountDetailInfo);
        })
        .catch((error) => {
          gaException(error.message);
          reject(error);
        });
    });
  }, []);

  /**
   * 获取链上amm detail
   */
  const onFetchAmmDetail = useCallback(
    (account: string, futuresProxy: string, ammProxy: string, currentWeb3: SYNWeb3) => {
      return new Promise<IAmmDetail | null>((resolve, reject) => {
        currentWeb3.amm
          .getAmmDetail(account, ammProxy, futuresProxy)
          .then(([ammDetail, err]) => {
            resolve(ammDetail);
          })
          .catch((error) => {
            gaException(error.message);
            reject(error);
          });
      });
    },
    [],
  );
  /**
   * 获取链上Futures detail
   */
  const onFetchFuturesDetail = useCallback((futuresProxy: string, currentWeb3: SYNWeb3) => {
    return new Promise<IFuturesDetail | null>((resolve, reject) => {
      currentWeb3.futures
        .getFuturesDetail(futuresProxy)
        .then(([futureDetail]) => {
          resolve(futureDetail);
        })
        .catch((error) => {
          gaException(error.message);
          reject(error);
        });
    });
  }, []);

  // 获取链上数据来更新position
  const onFetchPositionOnChain = useCallback(
    (account: string, position: IPosition, currentWeb3?: SYNWeb3) => {
      if (!currentWeb3) {
        currentWeb3 = isDiffProductSymbol(position.symbol) ? synDiffWeb3 : synWeb3;
      }
      if (position.pair) {
        if (!position.pair.futuresProxy || !position.pair.ammProxy) {
          return;
        }
        dispatch(
          isLoadingPositionDetail({
            positionID: position.id,
            isLoading: true,
          }),
        );
        dispatch(
          isLoadingPositionAmmDetail({
            positionID: position.id,
            isLoading: true,
          }),
        );
        onFetchAccountDetail(account, position.pair.futuresProxy, currentWeb3)
          .then((accountDetailInfo) => {
            if (accountDetailInfo) {
              dispatch(
                updatePositionDetail({
                  positionID: position.id,
                  positionDetail: accountDetailInfo,
                }),
              );
            }
          })
          .catch((error) => {
            gaException(error.message);
            console.log('🚀 ~ file: hooks.ts ~ line 142 ~ onFetchAccountDetail ~ err', error);
          })
          .finally(() => {
            dispatch(
              isLoadingPositionDetail({
                positionID: position.id,
                isLoading: false,
              }),
            );
          });
        onFetchAmmDetail(account, position.pair.futuresProxy, position.pair.ammProxy, currentWeb3)
          .then((ammDetail) => {
            if (ammDetail) {
              dispatch(
                updatePositionAmmDetail({
                  positionID: position.id,
                  ammDetail: ammDetail,
                }),
              );
              onUpdatePairStatusBySymbol(ammDetail.symbol, ammDetail.status);
            }
          })
          .catch((error) => {
            gaException(error.message);
            console.log('🚀 ~ file: hooks.ts ~ line 164 ~ onFetchAmmDetail ~ err', error);
          })
          .finally(() => {
            dispatch(
              isLoadingPositionAmmDetail({
                positionID: position.id,
                isLoading: false,
              }),
            );
          });
      }
    },
    [dispatch, onFetchAccountDetail, onFetchAmmDetail, onUpdatePairStatusBySymbol],
  );

  const onFetchPositionOnChainBySymbol = useCallback(
    (account: string, symbol: string) => {
      const position = allPositions.find((p) => p.symbol === symbol);
      if (position) {
        onFetchPositionOnChain(account, position);
      }
    },
    [allPositions, onFetchPositionOnChain],
  );

  const onAddPosition = useCallback(
    (position: IPosition) => {
      dispatch(
        addPosition({
          position,
        }),
      );
    },
    [dispatch],
  );

  /**
   * 添加position
   */
  const onAddPositionByAddress = useCallback(
    (account: string, symbol: string, ammProxy: string, futuresProxy: string) => {
      const newPosition = {
        id: `${account.toLowerCase()}-${futuresProxy}`, // 构造唯一id
        symbol,
        pair: {
          symbol,
          ammProxy,
          futuresProxy,
        },
      } as IPosition;
      dispatch(
        addPosition({
          position: newPosition,
        }),
      );
      onFetchPositionOnChain(account, newPosition);
    },
    [dispatch, onFetchPositionOnChain],
  );

  /**
   * 修改并获取当前position信息
   */
  const onChangeCurrentPositionByAddress = useCallback(
    async (account: string, futuresProxy: string, ammProxy: string, currentWeb3: SYNWeb3) => {
      if (account && ammProxy && futuresProxy) {
        const currentPosition = {
          id: `${account.toLowerCase()}-${futuresProxy}`,
          pair: {
            id: futuresProxy,
            ammProxy,
            futuresProxy,
          },
        } as IPosition;
        dispatch(
          changeCurrentPosition({
            position: currentPosition,
          }),
        );
        onFetchPositionOnChain(account, currentPosition, currentWeb3);
        onFetchFuturesDetail(futuresProxy, currentWeb3).then((futuresDetail) => {
          if (futuresDetail) {
            dispatch(
              updateCurrentPositionFuturesDetail({
                position: currentPosition,
                futuresDetail: {
                  ...futuresDetail,
                  id: futuresProxy,
                  futuresProxy,
                } as IFuturesDetail,
              }),
            );
          }
        });
      }
    },
    [dispatch, onFetchFuturesDetail, onFetchPositionOnChain],
  );

  /**
   * 获取当前仓位信息（包括account,amm,futures信息）
   */
  const onFetchCurrentPosition = useCallback(
    async (account: string, currentWeb3: SYNWeb3) => {
      onFetchPositionOnChain(account, currentPosition, currentWeb3);
      if (currentPosition && currentPosition.pair) {
        const futuresProxy = currentPosition.pair.futuresProxy;
        if (!futuresProxy) {
          return;
        }
        onFetchFuturesDetail(futuresProxy, currentWeb3).then((futuresDetail) => {
          if (futuresDetail) {
            dispatch(
              updateCurrentPositionFuturesDetail({
                position: currentPosition,
                futuresDetail: {
                  ...futuresDetail,
                  id: futuresProxy,
                  futuresProxy,
                } as IFuturesDetail,
              }),
            );
          }
        });
      }
    },
    [currentPosition, dispatch, onFetchFuturesDetail, onFetchPositionOnChain],
  );

  /**
   * 重置当前position
   */
  const onResetCurrentPosition = useCallback(async () => {
    dispatch(resetCurrentPosition({}));
  }, [dispatch]);

  /**
   * 递归获取用户position
   */
  const fetchAllUserPositionList = useCallback(
    async (url: string, account: string, pageSize = 500, currentPageIndex = 0, totalCount?: number) => {
      const res = await fetchUserPositionList(url, account);
      if (res) {
        if (res.list && res.list.length > 0) {
          dispatch(
            savePositions({
              positions: res.list,
            }),
          );
          res.list.forEach((position) => {
            onFetchPositionOnChain(account, position);
          });
        }

        // 如果还有数据则递归获取
        if (res.totalCount >= pageSize + currentPageIndex) {
          await fetchAllUserPositionList(url, account, pageSize, currentPageIndex + pageSize, res.totalCount);
        }
      }
    },
    [dispatch, onFetchPositionOnChain],
  );

  const onFetchAllPositionList = useCallback(
    async (account: string) => {
      // let positionList: IPosition[] = [];
      try {
        dispatch(
          isFetchingData({
            isFetching: true,
          }),
        );
        for (let i = 0; i < AVAILABLE_PRODUCTS.length; i++) {
          const productType = AVAILABLE_PRODUCTS[i];
          await fetchAllUserPositionList(chainConfig.GRAPHQL_API_URL[productType], account);
        }
      } catch (error) {
        gaException(error.message);
        console.log('🚀 ~ file: position hooks.ts ~ line 262 ~ error', error);
      } finally {
        dispatch(
          isFetchingData({
            isFetching: false,
          }),
        );
      }
    },
    [dispatch, fetchAllUserPositionList],
  );

  // 所有仓位
  const positionList = useMemo(() => {
    // 过滤settled的仓位
    const positionList = allPositions.map((position) => {
      return {
        ...position,
        marginBalance: Number(position.accountBalance) + (Number(position.unrealPnl) || 0),
      } as IPositionDisplay;
    });
    // return orderByDataList(positionList, currentBlockHeight, currentBlockTime);
    // TODO: 暂时测试用，改回 orderByDataList
    return orderBy(
      positionList,
      [
        // 新创建的在最上
        function(item) {
          if (item.id === newPositionID) {
            return -1;
          }
          return 0;
        },
        function(item) {
          return Object.keys(AMM_STATUS).indexOf(item.pair?.ammDetail?.status || AMM_STATUS.TRADING);
        },
        function(item) {
          const isDiffProduct = isDiffProductSymbol(item.symbol);
          if (isDiffProduct) {
            return Math.floor(
              getBlockHeightTimestamps(Number(item.pair?.ammDetail?.maturity), currentBlockHeight, currentBlockTime) /
                1000,
            );
          }
          return item.pair?.ammDetail?.maturity;
        },
        function(item) {
          return item.symbol;
        },
      ],
      ['asc', 'desc', 'asc', 'asc'],
    );
  }, [allPositions, currentBlockHeight, currentBlockTime, newPositionID]);

  const positions = useMemo(() => {
    // 只过滤balance，因为有充值没position情况
    return positionList.filter((position: IPositionDisplay) => {
      return !(position.pair?.ammDetail?.status === AMM_STATUS.SETTLED && Number(position.marginBalance) <= 0);
    });
  }, [positionList]);

  // pool页面仓位
  const poolPositions = useMemo(() => {
    // 过滤shareBalance
    return positionList.filter((position: IPositionDisplay) => {
      return position.pair?.ammDetail && !(Number(position.pair?.ammDetail?.shareBalance) <= 0);
    });
  }, [positionList]);

  /**
   * 当前ammDetail
   */
  const currentAmmDetail = useMemo(() => {
    if (currentPosition && currentPosition.pair?.ammDetail) {
      return currentPosition.pair?.ammDetail;
    }
    return null;
  }, [currentPosition]);

  /**
   * 当前futuresDetail
   */
  const currentFuturesDetail = useMemo(() => {
    if (currentPosition && currentPosition.pair?.futuresDetail) {
      return currentPosition.pair?.futuresDetail;
    }
    return null;
  }, [currentPosition]);

  return {
    isFetching,
    positions,
    poolPositions,
    onFetchAllPositionList,
    onFetchPositionOnChain,
    onFetchPositionOnChainBySymbol,
    onAddPosition,
    onAddPositionByAddress,
    onChangeCurrentPositionByAddress,
    currentPosition,
    onResetCurrentPosition,
    currentAmmDetail,
    currentFuturesDetail,
    onFetchCurrentPosition,
    onResetState,
    onFetchAmmDetail,
    onFetchAccountDetail,
    onFetchFuturesDetail,
  } as const;
};
