import crypto from 'crypto';
import { decryptAlgorithm, localStorageKey } from '../constants/config';

const iv = crypto.randomBytes(16);

export function encrypt(
  text: any,
): {
  iv: string;
  content: string;
} {
  const cipher = crypto.createCipheriv(decryptAlgorithm, localStorageKey, iv);

  const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

  return {
    iv: iv.toString('hex'),
    content: encrypted.toString('hex'),
  };
}

export function decrypt(hash: any): string {
  hash = JSON.parse(hash);

  const decipher = crypto.createDecipheriv(decryptAlgorithm, localStorageKey, Buffer.from(hash.iv, 'hex'));

  const decrpyted = Buffer.concat([decipher.update(Buffer.from(hash.content, 'hex')), decipher.final()]);

  return decrpyted.toString();
}

export function saveLocalStorage(key: string, value: string): void {
  localStorage.setItem(key, JSON.stringify(encrypt(value)));
}

export function readLocalStorage(key: string): string {
  const storage = localStorage.getItem(key);
  if (storage) {
    return decrypt(storage);
  }
  return '';
}
