import React from 'react';
import NoRecordIcon from '../../assets/svg/icons/icon_no_record.svg';

export default function customizeRenderEmpty() {
  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          height: 200,
        }}>
        <img src={NoRecordIcon} style={{ width: 48, marginTop: 48 }} />
        <p
          style={{
            marginTop: 24,
            fontSize: 11,
            fontFamily: 'Helvetica',
            fontWeight: 400,
            color: '#184858',
          }}>
          No Record
        </p>
      </div>
    </div>
  );
}
