import Web3 from 'web3';
import BN from 'bn.js';
import { Unit } from 'web3-utils';
import { provider, TransactionReceipt } from 'web3-core';
import { sendTransactionWrap, parseReceipt, simulate } from '@/utils/contract';
import { AddressConfig } from '@/interfaces/config';
import { ABIConfig } from '@/interfaces/chain';
import { IContractGlobalConfig, IContractGlobalMargins } from '@/interfaces/global';
import { IToken } from '@/interfaces/token';
import { IAmmDetail } from '@/interfaces/amm';
import { IFuturesDetail } from '@/interfaces/futures';
import { Contract } from 'web3-eth-contract';
import { ChainId, chainConfig } from '@/constants/chain';
import { formatUTCTime, currentUTCTime, formatUTCCurrentTime, toUTCTimeByDateStr } from '@/utils/timeUtil';

import { formatNumber, safeNumberFormat } from '@/utils/numberUtil';
import { PRODUCT_TYPE } from '@/constants/product';
import isZero from '@/utils/isZero';
import { getAmmStatusByNum } from '@/utils';
import { AMM_STATUS } from '@/constants/config';
import { ISynWeb3Utils } from '@/interfaces/synWeb3';
import { ReactNode } from 'react';
import { IPosition } from '@/interfaces/position';
import { MAX_NUMBER_999999, TRADE_DIRECTION } from '@/constants';
import { gaException } from '@/utils/gaUtil';

export class SYNWeb3 {
  productType: PRODUCT_TYPE;
  gasPrice = '';
  web3: Web3;
  ABIConfig: ABIConfig;
  addressConfigs: AddressConfig = chainConfig.CONTRACT_ADDRESS.BASIC;
  private globalContract: Contract;
  private readerContract: Contract;
  private factoryContract: Contract;
  private oracleContract: Contract | undefined;

  constructor(type: PRODUCT_TYPE = PRODUCT_TYPE.BASIC) {
    this.productType = type;
    this.web3 = new Web3();
    [this.addressConfigs, this.ABIConfig] = this.global.changeAddressConfigs(type);
    this.globalContract = this.global.buildContract(this.ABIConfig.GLOBAL_CONFIG_ABI, this.addressConfigs.ConfigAddr);
    this.readerContract = this.global.buildContract(this.ABIConfig.READER_ABI, this.addressConfigs.ReaderAddr);
    this.factoryContract = this.global.buildContract(this.ABIConfig.FACTORY_ABI, this.addressConfigs.FactoryAddr);
    // 目前难度合约配置了oracle abi
    if (this.ABIConfig.ORACLE_ABI && this.addressConfigs.OracleAddr) {
      this.oracleContract = this.global.buildContract(this.ABIConfig.ORACLE_ABI, this.addressConfigs.OracleAddr);
    }
  }

  /**
   * 全局配置
   *
   * @memberof SYNWeb3
   */
  global = {
    /**
     * 根据产品类型切换地址及ABI配置
     * @param type 产品类型
     */
    changeAddressConfigs: (type: PRODUCT_TYPE): [AddressConfig, ABIConfig] => {
      let abiConfig = chainConfig.ABI_CONFIG.BASIC; // 默认基础产品ABI
      let addressConfig: AddressConfig = chainConfig.CONTRACT_ADDRESS.BASIC;
      if (type === PRODUCT_TYPE.DIFFICULTY) {
        abiConfig = chainConfig.ABI_CONFIG.DIFFICULTY;
        addressConfig = chainConfig.CONTRACT_ADDRESS.DIFFICULTY;
      }
      this.ABIConfig = abiConfig;
      return [addressConfig, abiConfig];
    },
    /**
     * 初始化web3
     * @param provider 默认浏览器默认provider，大部分是metamask
     */
    initWeb3: async (provider: provider = Web3.givenProvider): Promise<void> => {
      this.web3.setProvider(provider);
      // await this.global.getGasPrice();
    },
    /**
     * 获取当前网络Id
     */
    getChainId: async (): Promise<ChainId> => {
      const ChainIdNum = await this.web3.eth.getChainId();
      return ChainIdNum as ChainId;
    },

    getGasPrice: async () => {
      this.gasPrice = await this.web3.eth.getGasPrice();
    },

    setGasPrice: (gasPrice: string) => {
      this.gasPrice = gasPrice;
    },

    buildContract: (ABI: any, address: string): Contract => {
      const abi = JSON.parse(JSON.stringify(ABI));
      const contract = new this.web3.eth.Contract(abi, address);
      return contract;
    },
    getTransactionReceipt: async (tx: string) => {
      let txResult: any;
      await this.web3.eth
        .getTransactionReceipt(tx)
        .then((result: any) => {
          console.log(`[IReader.ts]  getTransactionReceipt: `, result, typeof result);
          txResult = result;
          return;
        })
        .catch((error) => {
          gaException(error.message);
          console.log(`[IReader.ts] error `, error);
          return;
        });
      return txResult;
    },
    /**
     *  获取全局合约配置
     */
    getContractGlobalConfig: async () => {
      const globalConfigContract = this.globalContract;

      let globalConfig: IContractGlobalConfig = {
        emaTimeConstant: 0,
        poolFeeRatio: 0,
        poolReserveFeeRatio: 0,
        maxPriceSlippageRatio: 0,
        maxInitialDailyBasis: 0,
        maxUserTradeOpenInterestRatio: 0,
        minAmmOpenInterestRatio: 0,
        maxSpotIndexChangePerSecondRatio: 0,
        initialMarginRatio: 0,
        maintenanceMarginRatio: 0,
        bankruptcyLiquidatorRewardRatio: 0,
        insurancePremiumRatio: 0,
      };

      console.log(`[IGlobalConfig.ts] `);

      await globalConfigContract.methods.parameter().call((err: any, result: string[]) => {
        if (err) {
          console.log(`[IGlobalConfig.ts]  err: ${err}`);
          return [null, `Cannot get Contract Global Config: ${err}`];
        }
        // globalConfig = result
        globalConfig = {
          emaTimeConstant: Number(result[0]),
          poolFeeRatio: Number(result[1]),
          poolReserveFeeRatio: Number(result[2]),
          maxPriceSlippageRatio: Number(result[3]),
          maxInitialDailyBasis: Number(result[4]),
          maxUserTradeOpenInterestRatio: Number(result[5]),
          minAmmOpenInterestRatio: Number(result[6]),
          maxSpotIndexChangePerSecondRatio: Number(result[7]),
          initialMarginRatio: Number(result[8]),
          maintenanceMarginRatio: Number(result[9]),
          bankruptcyLiquidatorRewardRatio: Number(result[10]),
          insurancePremiumRatio: Number(result[11]),
        };

        console.log(`[IGlobalConfig.ts]  globalConfig: `, globalConfig);
        return;
      });

      return [globalConfig, ''];
    },
    getGlobalMarginsParam: async (address: string) => {
      const globalConfigContract = this.globalContract;
      let marginsParam: IContractGlobalMargins = {
        allowed: false,
        alignToFriday: false,
        updateReward: 0,
      };
      await globalConfigContract.methods.marginsParam(address).call((err: any, result: string[]) => {
        if (err) {
          console.log(`[IGlobalConfig.ts]  err: ${err}`);
          return [null, `Cannot get Contract Global Config: ${err}`];
        }
        // marginsParam = result
        // console.log(`marginsParam -- result::`, result );
        let updateReward;
        if (Array.isArray(result)) {
          updateReward = Number(result[2]);
        } else {
          updateReward = Number(result['updateReward']);
        }
        marginsParam = {
          allowed: Boolean(result[0]),
          alignToFriday: Boolean(result[1]),
          updateReward: updateReward,
        };
        // console.log(`[IGlobalConfig.ts] ${address} marginsParam: `, marginsParam);
        return;
      });

      return [marginsParam, ''];
    },
  };

  /**
   * 工具类
   *
   * @memberof SYNWeb3
   */
  utils: ISynWeb3Utils = {
    fromWei: (value: string | BN, unit?: Unit): string => {
      return Web3.utils.fromWei(value || '0', unit);
    },

    toWei: <T extends BN | string | number>(value: T, unit?: Unit): BN | string => {
      // 解决number超过18位导致toWei报错问题
      // TODO: 把数字转换成bignumber再计算
      if (typeof value === 'string' || typeof value === 'number') {
        const val: string = safeNumberFormat(value);
        return Web3.utils.toWei(val, unit);
      } else {
        return Web3.utils.toWei(value as BN, unit);
      }
    },
    toBN: (value: number | string): BN => {
      return Web3.utils.toBN(value);
    },
    isAddress: (address: string, chainId?: number): boolean => {
      return Web3.utils.isAddress(address, chainId);
    },
    toChecksumAddress: (address: string, chainId?: number): string => {
      return Web3.utils.toChecksumAddress(address, chainId);
    },
  };

  /**
   * 合约相关操作
   * deposit，withdraw,
   * @memberof SYNWeb3
   */
  futures = {
    /**
     * approve token,当已经approve过则跳过
     * @param trader
     * @param tokenAddress
     * @param futuresAddr
     * @param skipApproveIfApproved 是否跳过approve过的
     */
    approveToken: async (
      trader: string,
      tokenAddress: string,
      futuresAddr: string,
      tokenInputAmount: number,
      skipApproveIfApproved = true,
    ) => {
      // ETH don't need approve
      if (tokenAddress === chainConfig.chainBasicCoin.address) {
        return [true, ''];
      }

      if (tokenAddress === '' || futuresAddr === '') {
        return [null, 'Token address and Future Proxy Cannot be empty'];
      }
      const tokenContract = this.global.buildContract(this.ABIConfig.ERC20_ABI, tokenAddress);
      const tokenAmountToApprove = this.utils.toBN(
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
      );

      let isApprove = false;

      // Approve token
      const unitsRes = await this.token.getTokenUnits(tokenAddress); // token units
      const scaleFactor = this.utils.toBN(10).pow(this.utils.toBN(unitsRes[0] as string));
      const allowanceRes = await this.token.getTokenAllowance(trader, tokenAddress, futuresAddr);
      const allowanceStr: string = allowanceRes[0] as string;
      console.log(`getTokenAllowance:  allowance = `, allowanceStr);

      const tokenAmount = scaleFactor.mul(this.utils.toBN(safeNumberFormat(Math.ceil(tokenInputAmount))));
      const allowance = this.utils.toBN(allowanceStr);
      console.log('allowance: ', allowanceStr, ' amount:', tokenInputAmount, allowance.lt(tokenAmount));
      // 判断是否需要approve
      let needApprove = true;
      if (skipApproveIfApproved && !allowance.lt(tokenAmount)) {
        needApprove = false;
      }
      if (needApprove) {
        await tokenContract.methods
          .approve(futuresAddr, tokenAmountToApprove)
          .send({ from: trader }, (err: any, result: number) => {
            if (err) {
              return [null, `Cannot approve token: ${tokenAddress}`];
            }
            isApprove = true;
            return;
          });
      } else {
        isApprove = true;
      }

      return [isApprove, ''];
    },

    /**
     * 改造 approve 函数，返回Tx 或  hasApprove
     * @param trader
     * @param tokenAddress
     * @param futuresAddr
     * @param tokenInputAmount
     * @param skipApproveIfApproved
     * @param onAfterSend
     * @returns
     */
    approve: async (
      trader: string,
      tokenAddress: string,
      futuresAddr: string,
      tokenInputAmount: number,
      skipApproveIfApproved = true,
      onAfterSend?: (result: string) => void,
    ): Promise<TransactionReceipt | boolean> => {
      // ETH don't need approve
      if (tokenAddress === chainConfig.chainBasicCoin.address) {
        return Promise.resolve(true);
      }

      const tokenContract = this.global.buildContract(this.ABIConfig.ERC20_ABI, tokenAddress);
      const tokenAmountToApprove = this.utils.toBN(
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
      );

      // Approve token
      const unitsRes = await this.token.getTokenUnits(tokenAddress); // token units
      const scaleFactor = this.utils.toBN(10).pow(this.utils.toBN(unitsRes[0] as string));
      const allowanceRes = await this.token.getTokenAllowance(trader, tokenAddress, futuresAddr);
      const allowanceStr: string = allowanceRes[0] as string;
      console.log(`getTokenAllowance:  allowance = `, allowanceStr);

      const tokenAmount = scaleFactor.mul(this.utils.toBN(safeNumberFormat(Math.ceil(tokenInputAmount))));
      const allowance = this.utils.toBN(allowanceStr);
      console.log('allowance: ', allowanceStr, ' amount:', tokenInputAmount, allowance.lt(tokenAmount));
      // 判断是否需要approve
      let needApprove = true;
      if (skipApproveIfApproved && !allowance.lt(tokenAmount)) {
        needApprove = false;
      }
      if (needApprove) {
        return sendTransactionWrap(
          this.web3,
          tokenContract.methods.approve,
          [futuresAddr, tokenAmountToApprove],
          { from: trader },
          'tokenContract.methods.approve',
          onAfterSend,
        );
      } else {
        return Promise.resolve(true);
      }
    },

    /**
     * 充值
     * @param quoteAddress deposit quote address
     * @param futuresAddr futures地址
     * @param account 交易addr
     * @param amount 数量
     * @param onAfterSend 发送tx后回调
     */
    deposit: async (
      quoteAddress: string,
      futuresAddr: string,
      account: string,
      amount: string,
      onAfterSend?: (result: string) => void,
    ): Promise<TransactionReceipt> => {
      let option = { from: account, gas: '200000' };
      if (quoteAddress === chainConfig.chainBasicCoin.address) {
        option = Object.assign({}, option, { value: this.utils.toWei(amount) });
      }
      const futureContract = this.global.buildContract(this.ABIConfig.FUTURES_ABI, futuresAddr);
      return sendTransactionWrap(
        this.web3,
        futureContract.methods.deposit,
        [this.utils.toWei(amount)],
        option,
        'futureContract.methods.deposit',
        onAfterSend,
      );
    },
    /**
     * 提现
     * @param futuresAddr futures地址
     * @param account 交易addr
     * @param amount 数量
     * @param onAfterSend 发送tx后回调
     */
    withdraw: async (
      futuresAddr: string,
      account: string,
      amount: string,
      onAfterSend?: (result: string) => void,
    ): Promise<TransactionReceipt> => {
      const futureContract = this.global.buildContract(this.ABIConfig.FUTURES_ABI, futuresAddr);

      return sendTransactionWrap(
        this.web3,
        futureContract.methods.withdraw,
        [this.utils.toWei(amount)],
        {
          from: account,
          gas: '240000',
        },
        'futureContract.methods.withdraw',
        onAfterSend,
      );
    },
    /**
     * settle交割
     * @param futuresAddr futures地址
     * @param trader 交易addr
     * @param onAfterSend 发送tx后回调
     */
    settle: async (
      futuresAddr: string,
      trader: string,
      onAfterSend?: (result: string) => void,
    ): Promise<TransactionReceipt> => {
      const futureContract = this.global.buildContract(this.ABIConfig.FUTURES_ABI, futuresAddr);
      return sendTransactionWrap(
        this.web3,
        futureContract.methods.settle,
        [],
        {
          from: trader,
          gas: '210000',
        },
        'futureContract.methods.settle',
        onAfterSend,
      );
    },
    /**
     *
     * @param futuresAddr
     * @param AmmProxy
     * @param account
     */
    // TODO: 这方法是干嘛的？,account并没有用，然后这个跟account.getAccountDetail有什么关系吗？
    getAccount: async (futuresAddr: string, AmmProxy: string, account: string) => {
      const futureContract = this.global.buildContract(this.ABIConfig.FUTURES_ABI, futuresAddr);
      let accountResult: any[] = [];
      await futureContract.methods.getAccount(AmmProxy).call((err: any, result: string) => {
        if (err) {
          console.log(`[IFutrue.ts]  deposit: `, err, typeof err);
        }
        console.log(`[IFutrue.ts] ---> getAccount deposit:  AmmProxy:${AmmProxy}`, result);
        accountResult = [result, err];
        return;
      });
      return accountResult;
    },

    getFuturesDetail: async (futuresAddr: string): Promise<[IFuturesDetail | null, string]> => {
      if (futuresAddr === '') {
        return [null, 'the future address can not be empty'];
      }
      const futuresDetail: IFuturesDetail = {
        id: '',
        futuresProxy: '',
        margin: '',
        marginSymbol: '',
        openInterests: '',
        insuranceFund: '',
      };

      const result = await this.readerContract.methods.getFuturesDetail(futuresAddr).call();
      futuresDetail.id = futuresAddr;
      futuresDetail.futuresProxy = futuresAddr;
      futuresDetail.margin = result.margin;
      futuresDetail.marginSymbol = result.marginSymbol;
      futuresDetail.openInterests = this.utils.fromWei(result.openInterests);
      futuresDetail.insuranceFund = this.utils.fromWei(result.insuranceFund);

      return [futuresDetail, ''];
    },

    /**
     * 解析 TransactionReceipt
     */
    parseReceipt: (receipt: TransactionReceipt) => {
      return parseReceipt(this.web3, receipt, this.ABIConfig.FUTURES_ABI);
    },
  };

  /**
   * amm相关操作
   * addLiquidity,depositAndAddLiquidity,removeLiquidity,trade
   * @memberof SYNWeb3
   */
  amm = {
    // ### 无引用，注释掉，下一版删除
    // addLiquidity: async (trader: string, quantity: number, ammProxy: string, deadline: number) => {
    //   if (trader === '') {
    //     return [null, 'Please check your provider'];
    //   }
    //   if (ammProxy === '') {
    //     return [null, 'The current base, quote and marity do not have pair. Please choose another one'];
    //   }
    //   if (quantity !== 0) {
    //     return [null, 'Please Enter Quantity'];
    //   }
    //   const ammContract = this.global.buildContract(this.ABIConfig.AMM_ABI, ammProxy);
    //   const amount = this.utils.toWei(quantity);
    //   // const deadline = Math.floor(currentUTCTime() / 1000) + 60;
    //   let afterAddLiquidity = false;
    //   await ammContract.methods.addLiquidity(amount, deadline).send({ from: trader }, (err: any) => {
    //     if (err) {
    //       return [null, `Cannot Add Liquidity: ${err}`];
    //     }
    //     afterAddLiquidity = true;
    //     return;
    //   });
    //   return [afterAddLiquidity, ''];
    // },

    /**
     *
     * @param ammProxy
     * @param trader
     * @param quantity
     * @param leverage
     */
    depositAndAddLiquidity: async (
      ammProxy: string,
      trader: string,
      quantity: string,
      leverage: number,
      quoteToken: string,
      deadline: number,
      onAfterSend?: (result: string) => void,
    ): Promise<TransactionReceipt> => {
      const ammContract = this.global.buildContract(this.ABIConfig.AMM_ABI, ammProxy);
      const amount = this.utils.toWei(quantity);
      const leverNumber = this.utils.toWei(leverage);
      const deadlineNumber = Math.floor(currentUTCTime() / 1000) + deadline;

      console.log(`[synWeb3] depositAndAddLiquidity quantity:${quantity}, leverage:${leverage} `);
      let option = { from: trader, gas: '480000' };
      if (quoteToken === chainConfig.chainBasicCoin.symbol) {
        option = Object.assign({}, option, { value: amount });
      }
      return sendTransactionWrap(
        this.web3,
        ammContract.methods.depositAndAddLiquidity,
        [amount, leverNumber, deadlineNumber],
        option,
        'ammContract.methods.depositAndAddLiquidity',
        onAfterSend,
      );
    },

    /**
     * 添加流动性并初始化PoolPair
     * @param ammProxy
     * @param account
     * @param wadAmount
     * @param initPrice
     * @param leverage
     * @param deadline
     * @param quoteToken ， 当 quoteToken=eth时， value: msg
     * @param onAfterSend
     */
    depositAndInitPool: async (
      ammProxy: string,
      account: string,
      wadAmount: string,
      initPrice: string,
      leverage: number,
      deadline = 300,
      quoteToken = '',
      onAfterSend?: (result: string) => void,
    ): Promise<TransactionReceipt> => {
      const ammContract = this.global.buildContract(this.ABIConfig.AMM_ABI, ammProxy);
      const expiry = Math.floor((currentUTCTime() + deadline * 1000) / 1000);

      console.log(`[synWeb3] depositAndInitPool wadAmount:${wadAmount}, initPrice:${initPrice}  deadline:${deadline}`);

      let option = { from: account, gas: '600000' };
      if (quoteToken === chainConfig.chainBasicCoin.symbol) {
        option = Object.assign({}, option, { value: this.utils.toWei(wadAmount) });
      }
      return sendTransactionWrap(
        this.web3,
        ammContract.methods.depositAndInitPool,
        [this.utils.toWei(wadAmount), this.utils.toWei(initPrice), this.utils.toWei(leverage), expiry],
        option,
        'ammContract.methods.depositAndInitPool',
        onAfterSend,
      );
    },

    /**
     *
     * @param ammProxy AMM合约地址
     * @param account trade用户地址
     * @param amount 数量
     */
    removeLiquidity: async (
      ammProxy: string,
      account: string,
      quantity: string,
      deadline = 300,
      onAfterSend?: (result: string) => void,
    ): Promise<TransactionReceipt> => {
      const ammContract = this.global.buildContract(this.ABIConfig.AMM_ABI, ammProxy);
      const amount = this.utils.toWei(quantity);
      const deadlineNumber = Math.floor(currentUTCTime() / 1000) + deadline;
      return sendTransactionWrap(
        this.web3,
        ammContract.methods.removeLiquidity,
        [amount, deadlineNumber],
        { from: account, gas: '280000' },
        'ammContract.methods.removeLiquidity',
        onAfterSend,
      );
    },

    /**
     *
     * @param ammProxy
     * @param account
     * @param onAfterSend
     */
    update: async (
      ammProxy: string,
      account: string,
      onAfterSend?: (result: string) => void,
    ): Promise<TransactionReceipt> => {
      const ammContract = this.global.buildContract(this.ABIConfig.AMM_ABI, ammProxy);
      return sendTransactionWrap(
        this.web3,
        ammContract.methods.update,
        [],
        {
          from: account,
          gas: '200000',
        },
        'ammContract.methods.update',
        onAfterSend,
      );
    },

    settleShare: async (
      ammProxy: string,
      trader: string,
      onAfterSend?: (hash: string) => void,
    ): Promise<TransactionReceipt> => {
      const ammContract = this.global.buildContract(this.ABIConfig.AMM_ABI, ammProxy);
      return sendTransactionWrap(
        this.web3,
        ammContract.methods.settleShare,
        [],
        {
          from: trader,
          gas: '120000',
        },
        'ammContract.methods.settleShare',
        onAfterSend,
      );
    },

    trade: async (
      isBuy: boolean,
      amount: string,
      limitPrice: string,
      deadline: number,
      ammProxy: string,
      trader: string,
      onAfterSend?: (hash: string) => void,
    ): Promise<TransactionReceipt> => {
      console.log(
        ` 🔥 web3 isBuy:${isBuy}  size:${amount} limitPrice:${limitPrice} deadline:${deadline} ammProxy:${ammProxy} limitPrice:${limitPrice} `,
      );
      const ammContract = this.global.buildContract(this.ABIConfig.AMM_ABI, ammProxy);
      const weiAmount = this.utils.toWei(amount);
      // 解决number超过18位导致toWei报错问题
      // TODO: 把数字转换成bignumber再计算
      const limitPriceBN = this.utils.toWei(limitPrice);
      const deadlineNumber = Math.floor(currentUTCTime() / 1000) + deadline;

      return sendTransactionWrap(
        this.web3,
        ammContract.methods.trade,
        [isBuy, weiAmount, limitPriceBN, deadlineNumber],
        {
          from: trader,
          gas: '350000',
        },
        'ammContract.methods.trade',
        onAfterSend,
      );
    },

    /**
     *
     */
    tradeSimulation: async (
      isBuy: boolean,
      amount: string,
      limitPrice: string,
      deadline: number,
      ammProxy: string,
      trader: string,
    ): Promise<{ reason: ReactNode }> => {
      console.log(
        `\n\n tradeSimulation: isBuy:${isBuy}  amount:${amount} limitPrice:${limitPrice} deadline:${deadline} ammProxy:${ammProxy} trader:${trader}`,
      );
      const ammContract = this.global.buildContract(this.ABIConfig.AMM_ABI, ammProxy);
      const weiAmount = this.utils.toWei(amount);
      const deadlineNumber = Math.floor(currentUTCTime() / 1000) + deadline;

      // 小数点后尾数超过 18位 Web3.utils.toBN 报错，安全处理
      const limitPriceBN = this.utils.toWei(limitPrice);
      const txHash = ammContract.methods.trade(isBuy, weiAmount, limitPriceBN, deadlineNumber).encodeABI();

      console.log(`tradeSimulation-----txHash::\n `, txHash);
      return await simulate(this.web3, trader, ammProxy, txHash);
    },

    getBuyPrice: async (amount: string, ammAddress: string): Promise<[number, number, string | null]> => {
      console.log(`[IReader] getBuyPrice  amount:`, amount, `ammAddress:`, ammAddress);
      if (Number(amount) <= 0) {
        return [0, 0, 'Please input a valid amount'];
      }
      let price = 0;
      let initialMargin = 0;
      await this.readerContract.methods
        .getBuyPrice(ammAddress, this.utils.toWei(amount))
        .call((err: any, result: { price: string; initialMargin: string }) => {
          if (err) {
            console.log(`[IReader] getBuyPrice  error:`);
            return [null, `Cannot get getBuyPrice from ammAddress: ${ammAddress}, amount: ${amount}`];
          }
          price = Number(this.utils.fromWei(result.price));
          initialMargin = Number(this.utils.fromWei(result.initialMargin));
          // 对于bsc上，price异常还是会返回一个超大的数
          if (price >= Number(MAX_NUMBER_999999)) {
            price = 0;
          }
          if (initialMargin >= Number(MAX_NUMBER_999999)) {
            initialMargin = 0;
          }
          return;
        });

      console.log(`[IReader] getBuyPrice  price:`, price, `initialMargin:`, initialMargin);
      return [price, initialMargin, null];
    },

    getSellPrice: async (amount: string, ammAddress: string): Promise<[number, number, string | null]> => {
      if (Number(amount) <= 0) {
        return [0, 0, 'Please input a valid amount'];
      }
      let price = 0;
      let initialMargin = 0;
      await this.readerContract.methods
        .getSellPrice(ammAddress, this.utils.toWei(amount))
        .call((err: any, result: { price: string; initialMargin: string }) => {
          if (err) {
            return [null, `Cannot get getSellPrice from ammAddress: ${ammAddress}, amount: ${amount}`];
          }

          price = Number(this.utils.fromWei(result.price));
          initialMargin = Number(this.utils.fromWei(result.initialMargin));
          return;
        });
      return [price, initialMargin, null];
    },
    getPrice: async (
      side: TRADE_DIRECTION,
      amount: string,
      ammAddress: string,
    ): Promise<[number, number, string | null]> => {
      const fn = side === TRADE_DIRECTION.LONG ? this.amm.getBuyPrice : this.amm.getSellPrice;
      return fn(amount, ammAddress);
    },
    getAmmDetail: async (
      trader: string,
      ammAddr: string,
      futuresAddr: string,
    ): Promise<[IAmmDetail | null, string]> => {
      if (!trader) {
        return [null, 'Please check your metamask'];
      }
      if (futuresAddr === '') {
        return [null, 'the future address can not be empty'];
      }
      if (ammAddr === '') {
        return [null, 'the amm address can not be empty'];
      }
      const ammDetail: IAmmDetail = {
        id: '',
        ammProxy: '',
        symbol: '',
        status: AMM_STATUS.TRADING,
        spotIndexPrice: '',
        shareTotalSupply: '',
        shareBalance: '',
        oracle: '',
        midPrice: '',
        maturity: '',
        ammPosition: '',
        ammMarginBalance: '',
      };
      await this.readerContract.methods
        .getAmmDetail(ammAddr, futuresAddr, trader)
        .call((err: any, result: IAmmDetail) => {
          if (err) {
            return [
              null,
              `Cannot get getAmmDetail from futuresAddr: ${futuresAddr}, ammAddr: ${ammAddr}, trader: ${trader}`,
            ];
          }
          ammDetail.id = ammAddr;
          ammDetail.ammProxy = ammAddr;
          ammDetail.symbol = result.symbol;
          ammDetail.status = getAmmStatusByNum(Number(result.status));
          ammDetail.spotIndexPrice = this.utils.fromWei(result.spotIndexPrice);
          ammDetail.shareTotalSupply = this.utils.fromWei(result.shareTotalSupply);
          ammDetail.shareBalance = this.utils.fromWei(result.shareBalance);
          ammDetail.oracle = result.oracle;
          ammDetail.midPrice = this.utils.fromWei(result.midPrice);
          ammDetail.maturity = result.maturity;
          ammDetail.ammPosition = this.utils.fromWei(result.ammPosition);
          ammDetail.ammMarginBalance = this.utils.fromWei(result.ammMarginBalance);
          console.log('🚀 ~ file: index.ts ~ line 828 ~ SYNWeb3 ~ .call ~ ammDetail', JSON.stringify(ammDetail));

          return;
        });
      return [ammDetail, ''];
    },
    /**
     * 解析 TransactionReceipt
     */
    parseReceipt: (receipt: TransactionReceipt) => {
      return parseReceipt(this.web3, receipt, this.ABIConfig.AMM_ABI);
    },

    liquidate: async (
      managerAddress: string,
      ammProxy: string,
      traderAddress: string,
      deadline: number,
      onAfterSend?: (hash: string) => void,
    ): Promise<TransactionReceipt> => {
      console.log(
        ` 🔥 web3 managerAddress:${managerAddress}  traderAddress:${traderAddress} deadline:${deadline} ammProxy:${ammProxy}`,
      );
      const ammContract = this.global.buildContract(this.ABIConfig.AMM_ABI, ammProxy);
      const deadlineNumber = Math.floor(currentUTCTime() / 1000) + deadline;

      // const res = await ammContract.methods.liquidate(traderAddress, deadlineNumber).call();
      // console.log('🚀 ~ file: index.ts ~ line 852 ~ SYNWeb3 ~ res', res);

      return sendTransactionWrap(
        this.web3,
        ammContract.methods.liquidate,
        [traderAddress, deadlineNumber],
        {
          from: managerAddress,
          gas: '350000',
        },
        'ammContract.methods.liquidate',
        onAfterSend,
      );
    },
    liquidateByAmm: (
      managerAddress: string,
      ammProxy: string,
      traderAddress: string,
      deadline: number,
      onAfterSend?: (hash: string) => void,
    ): Promise<TransactionReceipt> => {
      console.log(
        ` 🔥 web3 managerAddress:${managerAddress}  traderAddress:${traderAddress} deadline:${deadline} ammProxy:${ammProxy}`,
      );
      const ammContract = this.global.buildContract(this.ABIConfig.AMM_ABI, ammProxy);
      const deadlineNumber = Math.floor(currentUTCTime() / 1000) + deadline;

      return sendTransactionWrap(
        this.web3,
        ammContract.methods.liquidateByAmm,
        [traderAddress, deadlineNumber],
        {
          from: managerAddress,
          gas: '350000',
        },
        'ammContract.methods.liquidateByAmm',
        onAfterSend,
      );
    },
  };

  /**
   * token symbol 信息
   * getTokenFromAddress
   * @memberof SYNWeb3
   */
  token = {
    /**
     * 根据token地址获取token信息
     * @param rawAddress token address
     */
    getTokenFromAddress: async (rawAddress: string): Promise<[IToken | null, string]> => {
      const isAddress = this.utils.isAddress(rawAddress);
      if (!isAddress) {
        return [null, `${rawAddress} not an address`];
      }
      let symbol = '';
      let name = '';
      let decimals = 0;
      const address = this.utils.toChecksumAddress(rawAddress);
      const tokenContract = this.global.buildContract(this.ABIConfig.ERC20_ABI, address);
      await tokenContract.methods.symbol().call((err: any, result: any) => {
        if (err) {
          return [null, `Cannot get symbol from address: ${rawAddress}`];
        }
        symbol = result;
        return;
      });
      await tokenContract.methods.name().call((err: any, result: any) => {
        if (err) {
          return [null, `Cannot get name from address: ${rawAddress}`];
        }
        name = result;
        return;
      });
      await tokenContract.methods.decimals().call((err: any, result: number) => {
        if (err) {
          return [null, `Cannot get decimals from address: ${rawAddress}`];
        }
        decimals = result;
        return;
      });
      const token: IToken = {
        // TODO: remove ChainId
        chainId: ChainId.KOVAN,
        address: address,
        decimals: Number(decimals),
        symbol: symbol,
        name: name,
      };
      return [token, ''];
    },

    getTokenAllowance: async (trader: string, tokenAddress: string, futuresProxy: string) => {
      console.log(
        `[IReader] getTokenAllowance: trader:${trader}`,
        `tokenAddress:${tokenAddress}`,
        `futuresProxy:${futuresProxy}`,
      );
      let allowance;
      if (isZero(tokenAddress)) {
        // ETH
        allowance = await this.web3.eth.getBalance(trader);
      } else {
        const tokenContract = this.global.buildContract(this.ABIConfig.ERC20_ABI, tokenAddress);
        await tokenContract.methods.allowance(trader, futuresProxy).call((err: any, result: number) => {
          if (err) {
            return [null, `Cannot get allowance from tokenAddress: ${tokenAddress}`];
          }
          allowance = result;
          return;
        });
      }
      return [allowance, ''];
    },

    getTokenUnits: async (tokenAddress: string) => {
      if (tokenAddress === '') {
        return [null, 'Token address and Future proxy Cannot be empty'];
      }
      const tokenContract = this.global.buildContract(this.ABIConfig.ERC20_ABI, tokenAddress);

      const units = await tokenContract.methods.decimals().call();
      return [units, ''];
    },
  };

  /**
   * trader账户信息
   *
   * @memberof SYNWeb3
   */
  account = {
    getAccountDetail: async (trader: string, futuresAddr: string): Promise<[IPosition | null, string]> => {
      if (futuresAddr === '') {
        return [null, 'the future address can not be empty'];
      }
      const accountDetail: IPosition = {
        id: '',
        symbol: '',
        position: '0',
        entryPrice: '0',
        markPrice: '0',
        accountBalance: '0',
        unrealPnl: '0',
        liqPrice: '0',
        positionMargin: '0',
        mainMargin: '0',
        availableMargin: '0',
      };
      console.log(`[synWeb3@getAccountDetail]: trader::${trader}, futuresAddr::${futuresAddr}`);
      await this.readerContract.methods.getAccountDetail(futuresAddr, trader).call((err: any, result: IPosition) => {
        if (err) {
          console.log(`[synWeb3@getAccountDetail]: err::${err} `);
          return [null, `Cannot get getAccountDetail from futuresAddr: ${futuresAddr}, trader: ${trader}`];
        }

        accountDetail.id = `${trader.toLowerCase()}-${futuresAddr}`;
        accountDetail.symbol = result.symbol;
        accountDetail.position = this.utils.fromWei(result.position);
        accountDetail.entryPrice = this.utils.fromWei(result.entryPrice);
        accountDetail.markPrice = this.utils.fromWei(result.markPrice);
        accountDetail.accountBalance = this.utils.fromWei(result.accountBalance);
        accountDetail.unrealPnl = this.utils.fromWei(result.unrealPnl);
        accountDetail.liqPrice = this.utils.fromWei(result.liqPrice);
        accountDetail.positionMargin = this.utils.fromWei(result.positionMargin);
        accountDetail.mainMargin = this.utils.fromWei(result.mainMargin);
        accountDetail.availableMargin = this.utils.fromWei(result.availableMargin);

        console.log(`[synWeb3@getAccountDetail]::${JSON.stringify(accountDetail)}`);
        return;
      });
      return [accountDetail, ''];
    },
    getMarginTokenBalance: async (tokenAddress: string, account: string) => {
      if (tokenAddress === '' || account === '') {
        return [null, 'Token address and account Cannot be empty'];
      }
      const tokenContract = this.global.buildContract(this.ABIConfig.ERC20_ABI, tokenAddress);
      // console.log(` [IReaderUtil.ts] getMarginTokenBalance tokenAddress: ${tokenAddress}  account:${account}`);

      const balance = await tokenContract.methods.balanceOf(account).call();
      return [balance, ''];
    },
    /**
     *
     * @param account ETH账户地址
     */
    getBalance: async (account: string) => {
      if (account === '') {
        return [null, 'Account address Cannot be empty'];
      }
      const balance = await this.web3.eth.getBalance(account);
      console.log(`[IReaderUtil.ts] getBalance: ${account} `);
      return [balance, ''];
    },
  };

  /**
   * oracle reader
   *
   * @memberof SYNWeb3
   */
  reader = {
    getUniswapContractAddresses: async (
      base: string,
      quote: string,
      marity: string,
    ): Promise<[string | null, string | null, string]> => {
      if (base === '' || quote === '' || marity === '') {
        return [null, null, ''];
      }
      console.log(`[IReader]-getUniswapContractAddresses`, base, quote, marity);
      const selectMarity = toUTCTimeByDateStr(`${marity} 08:00:00`);
      let ammProxy = '';
      let futuresProxy = '';
      await this.readerContract.methods
        .getUniswapContractAddresses(this.addressConfigs.FactoryAddr, base, quote, selectMarity)
        .call((err: any, result: { [key: string]: string }) => {
          if (err) {
            return [
              null,
              null,
              `Cannot get getUniswapContractAddresses from base: ${base}, quote: ${quote}, marity: ${selectMarity}`,
            ];
          }

          ammProxy = result.ammProxy;
          futuresProxy = result.futuresProxy;
          return;
        });
      return [ammProxy, futuresProxy, ''];
    },

    getChainlinkContractAddresses: async (
      base: string,
      quote: string,
      marity: string,
    ): Promise<[string | null, string | null, string]> => {
      if (base === '' || quote === '' || marity === '') {
        return [null, null, ''];
      }
      const selectMarity = toUTCTimeByDateStr(`${marity} 08:00:00`);
      let ammProxy = '';
      let futuresProxy = '';
      await this.readerContract.methods
        .getChainlinkContractAddresses(this.addressConfigs.FactoryAddr, base, quote, selectMarity)
        .call((err: any, result: { [key: string]: string }) => {
          if (err) {
            return [
              null,
              null,
              `Cannot get getChainlinkContractAddresses from base: ${base}, quote: ${quote}, marity: ${selectMarity}`,
            ];
          }

          ammProxy = result.ammProxy;
          futuresProxy = result.futuresProxy;
          return;
        });
      return [ammProxy, futuresProxy, ''];
    },
    getBtcHashRateContractAddresses: async (
      quote: string,
      expiry: string,
    ): Promise<[string | null, string | null, string]> => {
      if (quote === '' || expiry === '') {
        return [null, null, ''];
      }
      // TODO expiry 应该是块高度对应的时间UNX搓
      let ammProxy = '';
      let futuresProxy = '';
      await this.readerContract.methods
        .getBtcHashRateContractAddresses(this.addressConfigs.FactoryAddr, quote, Number(expiry))
        .call((err: any, result: { [key: string]: string }) => {
          if (err) {
            return [null, null, `Cannot get getBtcHashRateContractAddresses from quote: ${quote}, expiry: ${expiry}`];
          }
          ammProxy = result.ammProxy;
          futuresProxy = result.futuresProxy;
          return;
        });
      return [ammProxy, futuresProxy, ''];
    },

    getAmmDetail: async (
      trader: string,
      ammProxy: string,
      futuresProxy: string,
      onAfterSend?: (result: string) => void,
    ): Promise<TransactionReceipt> => {
      return sendTransactionWrap(
        this.web3,
        this.readerContract.methods.getAmmDetail,
        [ammProxy, futuresProxy, trader],
        {
          from: trader,
          gas: '150000',
        },
        'readerContract.methods.getAmmDetail',
        onAfterSend,
      );
    },
    /**
     * 解析 TransactionReceipt
     */
    parseReceipt: (receipt: TransactionReceipt) => {
      return parseReceipt(this.web3, receipt, this.ABIConfig.READER_ABI);
    },
  };

  /**
   * factory交易工厂
   *
   * @memberof SYNWeb3
   */
  factory = {
    newUniswapPair: async (
      account: string,
      base: string,
      quote: string,
      maturity: string,
      onAfterSend?: (result: string) => void,
    ): Promise<TransactionReceipt> => {
      const expiry = toUTCTimeByDateStr(`${maturity} 08:00:00`);
      return sendTransactionWrap(
        this.web3,
        this.factoryContract.methods.newUniswapPair,
        [base, quote, expiry],
        {
          from: account,
          gas: '1600000',
        },
        'factoryContract.methods.newUniswapPair',
        onAfterSend,
      );
    },

    newChainlinkPair: async (
      account: string,
      base: string,
      quote: string,
      maturity: string,
      onAfterSend?: (result: string) => void,
    ): Promise<TransactionReceipt> => {
      const expiry = toUTCTimeByDateStr(`${maturity} 08:00:00`);
      return sendTransactionWrap(
        this.web3,
        this.factoryContract.methods.newChainlinkPair,
        [base, quote, expiry],
        {
          from: account,
          gas: '1200000',
        },
        'factoryContract.methods.newChainlinkPair',
        onAfterSend,
      );
    },

    newBitcoinMiningDifficultyPair: async (
      account: string,
      quote: string,
      expiryHeight: string,
      onAfterSend?: (result: string) => void,
    ): Promise<TransactionReceipt> => {
      return sendTransactionWrap(
        this.web3,
        this.factoryContract.methods.newBitcoinMiningDifficultyPair,
        [quote, expiryHeight],
        {
          from: account,
          gas: '3200000',
        },
        'factoryContract.methods.newBitcoinMiningDifficultyPair',
        onAfterSend,
      );
    },
  };

  /**
   * kovan 测试网用到的一些方法
   *
   * @memberof SYNWeb3
   */
  kovan = {
    /**
     * mint测试币
     * @param account
     * @param tokenAddress
     * @returns
     */
    mintToken: (
      account: string,
      tokenAddress: string,
      onAfterSend?: (result: string) => void,
    ): Promise<TransactionReceipt> => {
      const tokenContract = new this.web3.eth.Contract(this.ABIConfig.KOVAN_ERC20_ABI, tokenAddress);
      return sendTransactionWrap(
        this.web3,
        tokenContract.methods.mint,
        [],
        {
          from: account,
          gas: '100000',
        },
        'tokenContract.methods.mint',
        onAfterSend,
      );
    },
  };

  oracle = {
    /**
     * 获取oracle最新块高
     * @returns number
     */
    getLatestBlockHeight: async (): Promise<{ height: number; timestamp: number }> => {
      if (this.oracleContract) {
        const res = await this.oracleContract.methods.latestReward().call();
        if (res) {
          const latestHeight = Number(res.newestH) || 0;
          let latestTimestamp = 0;
          // 根据其他块高时间估算最新时间，median块高<=最新块高
          if (res.median) {
            const height = Number(res.median.h) || 0;
            const timestamp = Number(res.median.t) || 0;
            // 上一个块高时间 + 块高时间差 * 10min
            latestTimestamp = timestamp + (latestHeight - height) * (10 * 60);
          }

          return { height: latestHeight, timestamp: latestTimestamp };
        }
      }
      return { height: 0, timestamp: 0 };
    },
  };
}

export const synWeb3 = new SYNWeb3();
/**
 * 难度产品web3
 * TODO: 暂时这么用，以后需要重构
 */
export const synDiffWeb3 = new SYNWeb3(PRODUCT_TYPE.DIFFICULTY);
