import ReactGA from 'react-ga';

export function gaException(description: string, fatal = false): void {
  ReactGA.exception({
    description: description,
    fatal: fatal,
  });
}
export enum GaCategory {
  ACCOUNT = 'Account',
  POOL = 'Pool',
  POSITION = 'POSITION',
  DEPOSIT = 'Deposit',
  SELECTOR = 'Selector',
  CONTRACT_TICKER_SELECTOR = 'Contract Ticker Selector',
  TRADE = 'Trade',
  HEADER = 'Header',
  DEBUG = 'Debug',
}
export function gaEvent(
  category: string,
  action: string,
  label?: string,
  value?: number,
  nonInteraction?: boolean,
  transport?: string,
): void {
  ReactGA.event({
    category,
    action,
    value,
    label,
    nonInteraction,
    transport,
  });
}
