import React from 'react';
import IconDisable from '@/assets/svg/icons/icon_disable_32.svg';
import './index.scss';

interface IMaskProps {
  visible: boolean;
  title: React.ReactNode;
  desc?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  children: JSX.Element;
}

/**
 *  mask遮罩
 * @returns
 */
export default function Mask({ visible, title, desc, className, icon, children }: IMaskProps): JSX.Element {
  if (visible) {
    return (
      <div className={`mask-wrap ${className && className}`}>
        <div className="mask-container">
          {icon ? icon : <img className="mask-icon" src={IconDisable} alt="mask disable" />}
          <div className="mask-body">
            <div className="mask-title">{title}</div>
            {desc && <div className="mask-desc">{desc}</div>}
          </div>
        </div>
        <div className="mask-overlay"></div>
        {children}
      </div>
    );
  }
  return children;
}
