import { IPairConfig, IPairTree } from '@/interfaces/pair';
import { IOracle } from '@/interfaces/oracle';
import { ICoin } from '@/interfaces/coin';

/**
 * 根据oracle默认quote来构造对应交易对
 *
 * @param {ICoin} baseCoin
 * @param {IOracle} oracleConfig
 * @returns {IPairConfig[]}
 */
export function wrapOracleDefaultPairs(baseCoin: ICoin, oracleConfig: IOracle): IPairConfig[] {
  let res: IPairConfig[] = [];
  if (oracleConfig && oracleConfig.defaultQuoteCoins.length > 0) {
    res = oracleConfig.defaultQuoteCoins
      .filter((quoteCoin) => baseCoin.symbol !== quoteCoin.symbol) // 过滤base quote相同的交易对
      .map((coin) => {
        return {
          baseCoin,
          quoteCoin: coin,
          oracleType: oracleConfig.type,
        } as IPairConfig;
      });
  }
  return res;
}

/**
 *  获取默认配置的PairTree
 * @param oracleConfig oracle配置
 * @param baseCoins baseCoin数组
 * @returns
 */
export function getDefaultPairTree(oracleConfig: IOracle): { [key: string]: IPairTree } {
  const res: { [key: string]: IPairTree } = {};
  if (oracleConfig && oracleConfig.defaultBaseCoins) {
    oracleConfig.defaultBaseCoins.forEach((baseCoin) => {
      const pairs = wrapOracleDefaultPairs(baseCoin, oracleConfig);
      res[baseCoin.symbol] = {
        baseCoin,
        pairs,
      };
    });
  }

  return res;
}
