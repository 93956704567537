import { createReducer } from '@reduxjs/toolkit';

import { IContractGlobalConfig } from '@/interfaces/global';
import { IUIGlobalConfigs } from '@/interfaces/global/UIGlobalConfigs';
import { IOraclePair } from '@/interfaces/pair';

import {
  Field,
  replaceSwapState,
  saveGlobalConfig,
  saveUIGlobalConfig,
  selectCurrency,
  selectMarity,
  selectOracle,
  selectToken,
  setRecipient,
  switchCurrencies,
  typeInput,
} from './actions';

export interface ISwapState {
  readonly independentField: Field;
  readonly typedValue: string;
  readonly [Field.INPUT]: {
    readonly currencyId: string | undefined;
  };
  readonly [Field.OUTPUT]: {
    readonly currencyId: string | undefined;
  };
  readonly [Field.ORACLE]: {
    readonly value: string | undefined;
  };
  readonly [Field.BASE]: {
    readonly token: string | undefined;
  };
  readonly [Field.QUOTE]: {
    readonly token: string | undefined;
  };
  readonly [Field.MARITY]: {
    readonly marity: string;
  };
  readonly [Field.GLOBALCONFIG]: {
    readonly globalConfig: IContractGlobalConfig;
  };
  readonly [Field.UIGLOBALCONFIG]: {
    readonly uiGlobalConfig: IUIGlobalConfigs;
  };

  // the typed recipient address or ENS name, or null if swap should go to sender
  readonly recipient: string | null;

  readonly normalPairs: IOraclePair[];
  readonly allPairs: IOraclePair[];
}

const initialState: ISwapState = {
  independentField: Field.INPUT,
  typedValue: '',
  [Field.INPUT]: {
    currencyId: '',
  },
  [Field.OUTPUT]: {
    currencyId: '',
  },
  [Field.ORACLE]: {
    value: '',
  },
  [Field.BASE]: {
    token: '',
  },
  [Field.QUOTE]: {
    token: '',
  },
  [Field.MARITY]: {
    marity: '',
  },
  [Field.GLOBALCONFIG]: {
    globalConfig: {
      emaTimeConstant: 0,
      poolFeeRatio: 0,
      poolReserveFeeRatio: 0,
      maxPriceSlippageRatio: 0,
      maxInitialDailyBasis: 0,
      maxUserTradeOpenInterestRatio: 0,
      minAmmOpenInterestRatio: 0,
      maxSpotIndexChangePerSecondRatio: 0,
      initialMarginRatio: 0,
      maintenanceMarginRatio: 0,
      bankruptcyLiquidatorRewardRatio: 0,
      insurancePremiumRatio: 0,
    },
  },
  [Field.UIGLOBALCONFIG]: {
    uiGlobalConfig: {
      slippage: 0.005, //0.5%
      gasPrice: 0,
      deadline: 300, // 5min
    },
  },
  recipient: null,
  normalPairs: [],
  allPairs: [],
};

export default createReducer<ISwapState>(initialState, (builder) =>
  builder
    .addCase(
      replaceSwapState,
      (state, { payload: { typedValue, recipient, field, inputCurrencyId, outputCurrencyId } }) => {
        return {
          [Field.INPUT]: {
            currencyId: inputCurrencyId,
          },
          [Field.OUTPUT]: {
            currencyId: outputCurrencyId,
          },
          [Field.ORACLE]: {
            value: '',
          },
          [Field.BASE]: {
            token: '',
          },
          [Field.QUOTE]: {
            token: '',
          },
          [Field.MARITY]: {
            marity: '',
          },
          [Field.GLOBALCONFIG]: {
            globalConfig: {
              emaTimeConstant: 0,
              poolFeeRatio: 0,
              poolReserveFeeRatio: 0,
              maxPriceSlippageRatio: 0,
              maxInitialDailyBasis: 0,
              maxUserTradeOpenInterestRatio: 0,
              minAmmOpenInterestRatio: 0,
              maxSpotIndexChangePerSecondRatio: 0,
              initialMarginRatio: 0,
              maintenanceMarginRatio: 0,
              bankruptcyLiquidatorRewardRatio: 0,
              insurancePremiumRatio: 0,
            },
          },
          [Field.UIGLOBALCONFIG]: {
            uiGlobalConfig: {
              slippage: 0.005, //0.5%
              gasPrice: 0,
              deadline: 300, // 5min
            },
          },
          independentField: field,
          typedValue: typedValue,
          recipient,
          normalPairs: [],
          allPairs: [],
        };
      },
    )
    .addCase(selectCurrency, (state, { payload: { currencyId, field } }) => {
      const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT;
      if (currencyId === state[otherField].currencyId) {
        // the case where we have to swap the order
        return {
          ...state,
          independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
          [field]: { currencyId: currencyId },
          [otherField]: { currencyId: state[otherField].currencyId },
        };
      } else {
        // the normal case
        return {
          ...state,
          [field]: { currencyId: currencyId },
        };
      }
    })
    .addCase(selectOracle, (state, { payload: { value } }) => {
      return {
        ...state,
        ['ORACLE']: { value: value },
      };
    })
    .addCase(selectToken, (state, { payload: { token, field } }) => {
      const otherField = field === Field.BASE ? Field.BASE : Field.QUOTE;
      if (token === state[otherField].token) {
        // the case where we have to swap the order
        return {
          ...state,
          independentField: state.independentField === Field.BASE ? Field.BASE : Field.QUOTE,
          [field]: { token: token },
          [otherField]: { token: state[otherField].token },
        };
      } else {
        // the normal case
        return {
          ...state,
          [field]: { token: token },
        };
      }
    })
    .addCase(selectMarity, (state, { payload: { marity, field } }) => {
      return {
        ...state,
        [field]: { marity: marity },
      };
    })
    .addCase(saveGlobalConfig, (state, { payload: { globalConfig } }) => {
      state.GLOBALCONFIG.globalConfig = globalConfig;
    })
    .addCase(saveUIGlobalConfig, (state, { payload: { uiGlobalConfig } }) => {
      state.UIGLOBALCONFIG.uiGlobalConfig = uiGlobalConfig;
    })
    .addCase(switchCurrencies, (state) => {
      return {
        ...state,
        independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
        [Field.INPUT]: { currencyId: state[Field.OUTPUT].currencyId },
        [Field.OUTPUT]: { currencyId: state[Field.INPUT].currencyId },
      };
    })
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue,
      };
    })
    .addCase(setRecipient, (state, { payload: { recipient } }) => {
      state.recipient = recipient;
    }),
);
