import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { DatePicker, Tooltip, Select } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useTokenActionHandlers, useTradeMarity, useTradeOrcle, useTradeToken } from '@/state/swap/hooks';
import { Field } from '@/state/swap/actions';
import moment, { Moment } from 'moment';
import ContractSelectorModal from '@/components/ContractSelectorModal';
import { dateFormat } from '@/constants/format';
import { dateHyphen, getWeekExcludeFriday, getBlockHeightTime, currentUTCTime } from '@/utils/timeUtil';
import { usePairGetter } from '@/state/pairs/hooks';
import { ORACLE_TYPE, DIFF_BASE_NAME } from '@/constants/config';
import { chainConfig } from '@/constants/chain';
import { useGlobalGetter } from '@/state/global/hooks';
import { RedirectFromEnum } from '@/state/options/actions';
import { useDiffHook } from '@/state/diff';
import { isDiffProductSymbol, getOracleTypeByCoinSymbol, getSymbolByOracleType } from '@/utils';
import { IToken } from '@/interfaces/token';
import { IPosition } from '@/interfaces/position';
import TreeLinkIcon from '@/assets/svg/icons/icon_tree_link.svg';
import DropDownIcon from '@/assets/svg/icons/icon_arrow_drop_g.svg';
import CalendarDateIcon from '@/assets/svg/icons/icon_date.svg';
import CoinIcon from '@/components/CoinIcon';
import './style.scss';
import { IOraclePair } from '@/interfaces/pair';
import { GaCategory, gaEvent, gaException } from '@/utils/gaUtil';

const { Option, OptGroup } = Select;

interface IContractSectorProps {
  targetFrom: string;
  freeze?: boolean;
  query?: string;
  dataInfo?: IPosition;
  showTooltip?: false;
}

// const activeDateList: (string | undefined)[] = [];

export default function ContractTickerSector(props: IContractSectorProps): ReactElement {
  const { t } = useTranslation();
  const { oracle } = useTradeOrcle();
  const { currencies } = useTradeToken();
  const { marity } = useTradeMarity();
  const { marginsParam } = useGlobalGetter();

  const baseDefaultValue = currencies[Field.BASE];
  const quoteDefaultValue = currencies[Field.QUOTE];

  const chainLinkUnderlyingDefaultValue = currencies[Field.QUOTE];

  const datePickerDefaultValue = marity[Field.MARITY] ? moment(marity[Field.MARITY], dateFormat) : undefined;

  const [baseTokenSelect, handleBaseTokenSelect] = useState('');
  const [quoteTokenSelect, handleQuoteTokenSelect] = useState('');
  const [baseGroupSelect, handleBaseGroupSelect] = useState('' as ORACLE_TYPE);
  const { allPairs, pairTreeConfig } = usePairGetter();

  const [base, handleBaseChange] = useState(baseDefaultValue);
  const [datelist, setDatelist] = useState<string[]>();
  const [quoteSelect, handleQuoteSelect] = useState(chainLinkUnderlyingDefaultValue);
  const [selectBlockHeight, setSelectBlockHeight] = useState<any>(marity[Field.MARITY]);

  const [addCurrencyModalStatus, showAddCurrencyModal] = useState(false);

  const { onTokenSelection, onOracleChange, onMarityChange } = useTokenActionHandlers();

  const [readonly, setReadonly] = useState(false);
  const [readonlyBase, setReadonlyBase] = useState(false);
  const [readonlyQuote, setReadonlyQuote] = useState(false);
  // const [readonlyMaturity, setReadonlyMaturity] = useState(true);

  const [selectBlockHeightDate, setSelectBlockHeightDate] = useState<string>('');

  const { filteredBlockHeight, currentBlockHeight, currentBlockTime } = useDiffHook();
  const [datePickerValue, setDatePickerValue] = useState<any>(datePickerDefaultValue);
  const [isDiffProduct, setIsDiffProduct] = useState<boolean>(false);
  const [aliginToFriday, setAliginToFriday] = useState(false);

  // 已经被创建的Pair并且尚未过期的-特殊颜色显示-
  let currentFilterDateList: (string | undefined)[] = [];

  useEffect(() => {
    if (props.dataInfo) {
      console.log(`---useEffect--useMemo:`);
      const recordSymbol = props.dataInfo.symbol;
      if (recordSymbol) {
        setIsDiffProduct(isDiffProductSymbol(recordSymbol));
        const temp = recordSymbol.split('-');
        const tempBase = temp[0];
        const tempQuote = temp[1];
        const tempMaturity = new Date(dateHyphen(temp[2])).getTime();

        handleBaseChange(tempBase);
        handleQuoteSelect(tempQuote);
        if (isDiffProductSymbol(recordSymbol)) {
          const diffMaturity = temp[2];
          setSelectBlockHeight(diffMaturity);
          const diffMaturityDate = getBlockHeightTime(
            Number(diffMaturity),
            currentBlockHeight,
            currentBlockTime,
            dateFormat,
          );
          setSelectBlockHeightDate(diffMaturityDate);
        } else {
          setDatePickerValue(moment(tempMaturity));
        }
        setReadonly(true);
      } else {
        setReadonly(false);
      }
    }
  }, [currentBlockHeight, currentBlockTime, props.dataInfo]);

  const changeQuote = useCallback(
    async (quoteToken) => {
      let quote = quoteToken;
      // clear date select
      setSelectBlockHeight('');
      setDatePickerValue('');
      onMarityChange(Field.MARITY, '');
      // 如果选择base后，没有quote token，则默认选中第一个quote
      if (!quote && baseTokenSelect && baseGroupSelect) {
        const basePairs = pairTreeConfig[baseGroupSelect][baseTokenSelect];
        if (basePairs && basePairs.pairs.length > 0) {
          quote = basePairs.pairs[0].quoteCoin.symbol;
        }
      }

      handleQuoteSelect(quote);
      handleQuoteTokenSelect(quote);

      onMarityChange(Field.MARITY, '');
      setDatePickerValue('');
      setSelectBlockHeight('');

      onTokenSelection(Field.QUOTE, quote);
    },
    [onMarityChange, baseTokenSelect, baseGroupSelect, onTokenSelection, pairTreeConfig],
  );

  const changeBase = useCallback(
    async (e) => {
      handleBaseChange(e);
      const eSplit: string[] = e.split('-');
      if (eSplit.length === 2) {
        const baseSelected = eSplit[0];
        const oracleSelected = eSplit[1].toUpperCase() as ORACLE_TYPE;

        console.log(baseSelected, oracleSelected);

        handleBaseGroupSelect(oracleSelected as ORACLE_TYPE);
        handleBaseTokenSelect(baseSelected);
        onTokenSelection(Field.BASE, baseSelected as any);
        // setOracleSelected(oracleSelected);
        onOracleChange(Field.ORACLE, oracleSelected);
        setIsDiffProduct(oracleSelected === ORACLE_TYPE.SYNFUTURES && baseSelected === DIFF_BASE_NAME);
        const basePairs = pairTreeConfig[oracleSelected][eSplit[0]];
        if (basePairs && basePairs.pairs.length > 0) {
          const quote = basePairs.pairs[0].quoteCoin.symbol;
          changeQuote(quote);
          handleQuoteTokenSelect(quote);
        }
      }
    },
    [onTokenSelection, onOracleChange, pairTreeConfig, changeQuote],
  );

  const changeMaturity = useCallback(
    (date: Moment | null, dateString: string) => {
      onMarityChange(Field.MARITY, dateString);
      setDatePickerValue(moment(new Date(dateString + ' 08:00:00').getTime()));
    },
    [onMarityChange],
  );

  const changeHeight = useCallback(
    (dateString: string) => {
      onMarityChange(Field.MARITY, dateString);
      setSelectBlockHeight(dateString);
      const diffMaturityDate = getBlockHeightTime(Number(dateString), currentBlockHeight, currentBlockTime, dateFormat);
      setSelectBlockHeightDate(diffMaturityDate);
    },
    [currentBlockHeight, currentBlockTime, onMarityChange],
  );

  useMemo(async () => {
    const list = allPairs.map((n) => {
      return n.oraclePair.symbol;
    });
    setDatelist(list);
  }, [allPairs]);

  // TODO 可以进行优化重构
  // 日期限制
  // const datalist = ['20210205', '20210210','2021-12-12', '2022-02-20'];
  function filterDisabledDate(
    oracleSelected: string,
    baseSelected: string,
    quoteSelected: string,
  ): (string | undefined)[] | undefined {
    // abbr = Oracle Abbreviation
    const abbr = getSymbolByOracleType(oracleSelected as ORACLE_TYPE);

    const tempList = datelist?.map((n) => {
      const temp = n.split('-');
      const base = temp[0];
      const quote = temp[1];
      const date = temp[2];
      const oracle = temp[3];
      // console.log( `base:${base}, quote:${quote}, date:${date}, oracle:${oracle}, ` );
      if (abbr === oracle && baseSelected === base && quoteSelected === quote) {
        return date;
      } else {
        return;
      }
    });
    const list = tempList?.filter(Boolean);
    currentFilterDateList = list as string[];
    return list;
  }

  useEffect(() => {
    if (currencies.BASE && currencies.QUOTE) {
      filterDisabledDate(`${oracle.ORACLE}`, `${currencies.BASE}`, `${currencies.QUOTE}`);
    }
  }, [currencies.BASE, currencies.QUOTE, filterDisabledDate, oracle.ORACLE]);

  // 日期限制
  // const datalist = ['20210205', '20210210','2021-12-12', '2022-02-20'];
  const disabledDate = (current: Moment = moment()): boolean => {
    const list = currentFilterDateList;
    let hasPairsDateList;
    if (list) {
      hasPairsDateList = list.some((item) => {
        const date = moment(item);
        const currentDate = moment(current).format('YYYY-MM-DD');

        return date.isSame(currentDate);
      });
    }

    // 创建合约限制北京时间当天下午3带之前
    // UTC时间小时 大于 6， 下午15点之后 ，
    const isDayTimeExpired = new Date().getUTCHours() > 6 ? 0 : 1;
    let isAliginToFriday = false; //根据 alignToFriday判断是否对齐到周五
    try {
      if (currencies.BASE && currencies.QUOTE) {
        if (
          marginsParam &&
          marginsParam[quoteSelect as string] &&
          marginsParam[quoteSelect as string].hasOwnProperty('alignToFriday')
        ) {
          isAliginToFriday = marginsParam[quoteSelect as string].alignToFriday;
          setAliginToFriday(isAliginToFriday);
        }
      }
    } catch (error) {
      gaException(error.message);
      throw error;
    }
    const showFriday = isAliginToFriday ? getWeekExcludeFriday(current) : false;
    return hasPairsDateList || current < moment().subtract(isDayTimeExpired, 'days') || showFriday;
    // return hasPairsDateList || (current && current < moment()) || current > moment('2022-12-31');
    //  |限制未来1年| current > moment().add(366, 'days')
  };

  useMemo(() => {
    if (props.dataInfo) {
      const recordSymbol = props.dataInfo.symbol;
      if (recordSymbol) {
        const temp = recordSymbol.split('-');
        const tempBase = temp[0];
        const tempQuote = temp[1];
        const tempMaturity = new Date(dateHyphen(temp[2])).getTime();

        handleBaseChange(tempBase);
        handleQuoteSelect(tempQuote);

        if (isDiffProductSymbol(recordSymbol)) {
          const diffMaturity = temp[2];
          setSelectBlockHeight(diffMaturity);
          const diffMaturityDate = getBlockHeightTime(
            Number(diffMaturity),
            currentBlockHeight,
            currentBlockTime,
            dateFormat,
          );
          setSelectBlockHeightDate(diffMaturityDate);
        } else {
          setDatePickerValue(moment(tempMaturity));
        }

        setReadonly(true);
      } else {
        setReadonly(false);
      }
    }
  }, [currentBlockHeight, currentBlockTime, props.dataInfo]);

  useEffect(() => {
    switch (props.targetFrom) {
      case RedirectFromEnum.INIT_CREATE_POOL: {
        console.log(` 🌶  1---RedirectFromEnum.INIT_CREATE_POOL`, RedirectFromEnum.INIT_CREATE_POOL);
        // hasLoaded = true;//加载完成
        setReadonly(false);

        changeBase(``);
        changeQuote(``);
        setSelectBlockHeight('');
        setDatePickerValue('');

        onTokenSelection(Field.BASE, ``);
        onTokenSelection(Field.QUOTE, ``);
        onOracleChange(Field.ORACLE, ``);
        break;
      }
      case RedirectFromEnum.POOL_PAGE_BUTTON: {
        console.log(` 🌶   2---RedirectFromEnum.POOL_PAGE_BUTTON`, RedirectFromEnum.POOL_PAGE_BUTTON);

        // setDatePickerValue('');
        // setLoading(true);
        // setReadonly(false);
        // setReadonlyBase(true);
        // setReadonlyQuote(true);
        break;
      }
      case RedirectFromEnum.POOL_LIST_RECORD: {
        if (props.dataInfo) {
          console.log(` 🌶   3---RedirectFromEnum.POOL_LIST_RECORD`, RedirectFromEnum.POOL_LIST_RECORD);
          // hasLoaded = true;//加载完成
          setReadonly(true);

          const symbol = props.dataInfo.symbol;
          const temp = symbol.split('-');
          const tempBase = temp[0];
          const tempQuote = temp[1];
          const tempMarity = temp[2];
          const tempAbbr = temp[3];
          const tempOracle = getOracleTypeByCoinSymbol(tempAbbr);
          changeBase(`${tempBase}-${tempOracle}`);
          changeQuote(tempQuote);
          if (isDiffProductSymbol(symbol)) {
            changeHeight(tempMarity);
          } else {
            changeMaturity(null, dateHyphen(tempMarity));
          }

          onTokenSelection(Field.BASE, tempBase);
          onTokenSelection(Field.QUOTE, tempQuote);
          onOracleChange(Field.ORACLE, tempOracle);
        }
        break;
      }
      case RedirectFromEnum.ROUTE_CREATE_POOL: {
        if (props.query) {
          console.log(` 🌶   4---RedirectFromEnum.ROUTE_CREATE_POOL`, RedirectFromEnum.ROUTE_CREATE_POOL);
          const queryParams = JSON.parse(props.query);
          changeBase(`${queryParams.base}-${queryParams.oracle}`);
          changeQuote(queryParams.quote);
          setSelectBlockHeight('');
          setDatePickerValue('');
          setReadonly(false);
          setReadonlyBase(true);
          setReadonlyQuote(true);

          onTokenSelection(Field.BASE, queryParams.base);
          onTokenSelection(Field.QUOTE, queryParams.quote);
          onOracleChange(Field.ORACLE, queryParams.oracle);
        }
        break;
      }
      case RedirectFromEnum.FROM_CREATE_POOL: {
        console.log(` 🌶   5---RedirectFromEnum.FROM_CREATE_POOL`, RedirectFromEnum.FROM_CREATE_POOL);
        const { symbol, baseName, quoteTokenSymbol, oracleType } = props.dataInfo?.pair as IOraclePair;
        changeBase(`${baseName}-${oracleType}`);
        changeQuote(quoteTokenSymbol);
        const dateString = dateHyphen(symbol.split('-')[2]);
        changeMaturity(null, dateString);
        break;
      }
    }
  }, [changeHeight, changeMaturity, onOracleChange, onTokenSelection, props.dataInfo, props.query, props.targetFrom]);

  useMemo(() => {
    if (currencies.BASE && currencies.QUOTE) {
      filterDisabledDate(`${oracle.ORACLE}`, `${currencies.BASE}`, `${currencies.QUOTE}`);
    }
  }, [currencies.BASE, currencies.QUOTE, filterDisabledDate, oracle.ORACLE]);

  const handleInputSelect = useCallback(
    (inputCurrency: IToken, oracleType: ORACLE_TYPE) => {
      if (!inputCurrency) {
        return;
      }
      changeBase(`${inputCurrency.symbol}-${oracleType}`);
    },
    [changeBase],
  );

  return (
    <div className="contract-selector">
      {props.showTooltip ? (
        <div className="title">
          Contract
          <Tooltip title={t('message.contractSelectorTips')}>
            <QuestionCircleFilled className="tooltip-info-icon" />
          </Tooltip>
        </div>
      ) : null}

      <div className="select-row">
        <div className="col-wrap">
          <div className="col-txt">Base</div>

          <Select
            className="col-select"
            dropdownClassName="contract-select__dropdown"
            value={base}
            defaultValue={baseDefaultValue}
            // placeholder="Select base"
            onChange={(val: string): void => {
              gaEvent(GaCategory.CONTRACT_TICKER_SELECTOR, 'Change quote', val);
              changeBase(val);
            }}
            suffixIcon={<img src={DropDownIcon} className="contract-select__dropdown_arrow" />}
            optionLabelProp="label"
            disabled={readonly ?? readonlyBase}
            listHeight={300}>
            {Object.values(chainConfig.oracleListConfig).map((oracle) => (
              <OptGroup
                label={
                  <div className="contract-select__base-group">
                    <div className="contract-select__base-group-name">
                      <img className="icon-coin" src={oracle.icon} alt={oracle.type} />
                      <span>{oracle.fullName}</span>
                    </div>
                    {oracle.type === chainConfig.defaultOracleType && (
                      <a
                        style={{ flex: 'none', display: 'block', cursor: 'pointer' }}
                        onClick={(): void => showAddCurrencyModal(true)}>
                        More
                      </a>
                    )}
                  </div>
                }
                key={oracle.type}>
                {Object.values(pairTreeConfig[oracle.type]).map((pair) => (
                  <Option
                    key={`${oracle.type}-${pair.baseCoin.symbol}`}
                    value={`${pair.baseCoin.symbol}-${oracle.type}`}
                    label={
                      <>
                        <img className="icon-coin" src={pair.baseCoin.icon} alt={pair.baseCoin.symbol} />
                        {pair.baseCoin.symbol}
                      </>
                    }>
                    <div className="contract-select__dropdown__coin-item">
                      <img className="icon-coin icon-oracle" src={TreeLinkIcon} alt={oracle.type} />
                      <CoinIcon quoteValue={pair.baseCoin.symbol} />
                    </div>
                  </Option>
                ))}
              </OptGroup>
            ))}
          </Select>
        </div>

        <div className="col-wrap">
          <div className="col-txt">Quote</div>

          <Select
            className="col-select"
            value={quoteSelect}
            defaultValue={quoteDefaultValue}
            // placeholder="Select quote"
            dropdownClassName="contract-select__dropdown quote-list"
            onChange={(val: string): void => {
              gaEvent(GaCategory.CONTRACT_TICKER_SELECTOR, 'Change quote', val);
              changeQuote(val);
            }}
            suffixIcon={<img src={DropDownIcon} className="contract-select__dropdown_arrow" />}
            disabled={readonly ?? readonlyQuote}>
            {baseGroupSelect &&
              baseTokenSelect &&
              pairTreeConfig[baseGroupSelect][baseTokenSelect] &&
              pairTreeConfig[baseGroupSelect][baseTokenSelect].pairs &&
              pairTreeConfig[baseGroupSelect][baseTokenSelect].pairs.length > 0 &&
              pairTreeConfig[baseGroupSelect][baseTokenSelect].pairs.map((pair) => (
                <Option value={pair.quoteCoin.symbol} key={pair.quoteCoin.symbol}>
                  <div className="contract-select__dropdown__coin-item">
                    <CoinIcon quoteValue={pair.quoteCoin.symbol} />
                  </div>
                </Option>
              ))}
          </Select>
        </div>

        <div className="col-wrap">
          <div className="col-txt">
            {' '}
            Maturity
            {aliginToFriday ? (
              <Tooltip placement="top" title="Available maturity is restricted to concentrate liquidity.">
                <QuestionCircleFilled className="tooltip-info-icon" />
              </Tooltip>
            ) : (
              ''
            )}
          </div>
          {isDiffProduct ? (
            <Select
              dropdownClassName="contract-select__dropdown"
              suffixIcon={<img src={DropDownIcon} className="contract-select__dropdown_arrow" alt="" />}
              onChange={(val: string): void => {
                gaEvent(GaCategory.CONTRACT_TICKER_SELECTOR, 'Change Height', val.toString());
                changeHeight(val);
              }}
              value={selectBlockHeightDate}
              disabled={readonly}
              optionLabelProp="label"
              dropdownRender={(menu): ReactElement => (
                <>
                  {baseTokenSelect && quoteTokenSelect && filteredBlockHeight && filteredBlockHeight.length > 0 && (
                    <div className="bloc-height-list-title">
                      <span>Height</span>
                      <span>Date</span>
                    </div>
                  )}
                  {menu}
                </>
              )}>
              {filteredBlockHeight.map((p: any) => (
                <Option
                  key={p.height}
                  value={p.height}
                  label={p.utcTime}
                  style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="bloc-height-list-item">
                    <span>{p.height}</span>
                    <span>{p.utcTime}</span>
                  </div>
                </Option>
              ))}
            </Select>
          ) : (
            <DatePicker
              className="select-datepicker"
              mode="date"
              placeholder=""
              disabledDate={disabledDate}
              defaultValue={datePickerDefaultValue}
              value={datePickerValue}
              onChange={(date: Moment | null, dateString: string): void => {
                gaEvent(GaCategory.CONTRACT_TICKER_SELECTOR, 'Change Maturity', dateString);
                changeMaturity(date, dateString);
              }}
              disabled={readonly}
              dateRender={(current: moment.Moment): React.ReactNode => {
                // TODO 当前下午3点之后 不可选择已经创建的Pair样式置灰

                // 创建合约限制北京时间当天下午3带之前
                // UTC时间小时 大于 6， 下午15点之后 ，
                const dayTimeExpired = new Date().getUTCHours() > 6 ? true : false;
                const currentUTCTimestamp = currentUTCTime();
                const currentUTCDay = moment(currentUTCTimestamp).format('YYYYMMDD');
                const currentSameDay = moment(current).format('YYYYMMDD') === currentUTCDay;

                if (dayTimeExpired && currentSameDay) {
                  return <div className="ant-picker-cell-inner ant-picker-cell-inner__expired">{current.date()}</div>;
                } else if (moment(current).isBefore(currentUTCDay)) {
                  return <div className="ant-picker-cell-inner ant-picker-cell-inner__expired">{current.date()}</div>;
                } else if (aliginToFriday && getWeekExcludeFriday(current)) {
                  return <div className="ant-picker-cell-inner ant-picker-cell-inner__expired">{current.date()}</div>;
                } else if (currentFilterDateList.includes(current.format('YYYYMMDD'))) {
                  return (
                    <div className="ant-picker-cell-inner ant-picker-cell-inner__active">
                      {current.date()}
                      <i className="ant-picker-cell-inner__active-dot"></i>
                    </div>
                  );
                } else {
                  return <div className="ant-picker-cell-inner">{current.date()}</div>;
                }
              }}
              showToday={false}
              allowClear={false}
              inputReadOnly={true}
              suffixIcon={<img src={CalendarDateIcon} className="contract-select__dropdown_arrow" />}
            />
          )}
        </div>
      </div>
      {addCurrencyModalStatus ? (
        <ContractSelectorModal
          field={Field.BASE}
          isOpen={addCurrencyModalStatus}
          onTokenSelect={handleInputSelect}
          handleContractSelectorModalVisible={showAddCurrencyModal}
        />
      ) : null}
    </div>
  );
}
