import moment from 'moment';
import { dateFormat, dateFormatEn } from '@/constants/format';

export function formatUTCTime(timestamps: number, format = 'YYYY-MM-DD hh:mm:ss'): string {
  return moment.utc(timestamps).format(format);
}

export function formatUTCCurrentTime(format: string): string {
  const now = moment.utc();
  return now.format(format);
}

export function toUTCTimeByDateStr(dateStr: string): number {
  return moment.utc(dateStr).unix();
}

export function currentUTCTime(): number {
  return moment.utc().valueOf();
}

/**
 * 获取显示的时间差（xx Days xx Hrs xx Mins)
 * @param timeSpan 时间差
 * @returns 显示的天、小时、分钟
 */
export function timeDiffDisplay(timeSpan: number): string {
  //计算出相差天数
  const days = Math.floor(timeSpan / (24 * 3600 * 1000));

  //计算出小时数
  const leave1 = timeSpan % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
  const hours = Math.floor(leave1 / (3600 * 1000));

  //计算相差分钟数
  const leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
  const minutes = Math.floor(leave2 / (60 * 1000));

  return days + ' Days ' + hours + ' Hrs ' + minutes + ' Mins ';
}

/**
 * 获取时间差
 * @param startTime 开始时间
 * @param endTime 结束时间
 * @returns moment.Duration
 */
export function getDeadLineTimeDuration(startTime: number, endTime: number): moment.Duration {
  const deadLineTimeSpan = moment(endTime).diff(moment(startTime));
  const durationTime = moment.duration(deadLineTimeSpan);
  return durationTime;
}

export function calculationTimeDiff(startTime: string | number, endTime: string | number): string {
  const startDate = new Date(startTime); //开始时间
  const endDate = new Date(endTime); //结束时间
  const date3 = endDate.getTime() - startDate.getTime(); //时间差秒
  return timeDiffDisplay(date3);
}

export function calculationDayDiff(startTime: string, endTime: string): number {
  const startDate = new Date(startTime); //开始时间
  const endDate = new Date(endTime); //结束时间
  const date3 = endDate.getTime() - startDate.getTime(); //时间差秒
  //计算出相差天数
  const days = Math.ceil(date3 / (24 * 3600 * 1000));
  return days;
}

export function dateHyphen(dateTime: string): string {
  if (dateTime.length > 6) {
    const year = dateTime.substr(0, 4);
    const month = dateTime.substr(4, 2);
    const day = dateTime.substr(6);
    return `${year}-${month}-${day}`;
  }
  return '';
}

//ETH-USDC-20210404-UNI -> 2021-04-04
export function symbolToDate(symbol: string): string {
  return dateHyphen(symbol.split('-')[2]);
}

export function formatDate(timestamps: number, formater = 'YYYY-MM-DD hh:mm:ss'): string {
  return moment(timestamps).format(formater);
}

export function getWeekExcludeFriday(date: any): boolean {
  // 参数时间戳
  const week = moment(date).day();
  // let weekOfDay = moment(current).format("E") // 指定日期的周的第几天
  // let lastFriday = moment(current).subtract(weekOfDay-5, 'days')
  switch (week) {
    case 5:
      return false;
    default:
      return true;
  }
}

// 创建合约时限制当天北京时间下午3带你之前
export function isDayTimeExpired(): boolean {
  // if(Intl.DateTimeFormat().resolvedOptions().timeZone === 'Asia/Shanghai'){
  //   console.log('Asia/Shanghai');
  // }else{
  //   // 返回格林威治时间和本地时间之间的时差，以分钟为单位。
  //   const timeOffset = new Date().getTimezoneOffset();
  //   console.log('timeOffset', timeOffset );
  // }

  return new Date().getUTCHours() < 7;
}

/**
 *  获取当前块高时间（根据当前块时间）
 *
 * @export
 * @param {number} height 目标块
 * @param {number} currBlockHeight 当前块高
 *  * @param {number} currentBlockTime 当前块高获取时间
 * @param {*} [format=dateFormatEn] 格式
 * @returns {string} 时间 string
 */
export function getBlockHeightTime(
  height: number,
  currBlockHeight: number,
  currentBlockTime: number,
  format = dateFormat,
): string {
  // 当前时间+块差*10min
  const timestamps = getBlockHeightTimestamps(height, currBlockHeight, currentBlockTime);
  return formatUTCTime(timestamps, format);
}

/**
 * 获取当前块高时间戳（根据当前块时间）
 * @param {number} height 目标块
 * @param {number} currBlockHeight 当前块高
 * @param {number} currentBlockTime 当前块高获取时间
 */
export function getBlockHeightTimestamps(height: number, currBlockHeight: number, currentBlockTime: number): number {
  // 当前时间+块差*10min
  return currentBlockTime + getBlockHeightTimeSpan(height, currBlockHeight);
}

/**
 * 获取块高时间差
 * @param height 目标块
 * @param currBlockHeight 当前块
 * @returns 时间差
 */
export function getBlockHeightTimeSpan(height: number, currBlockHeight: number): number {
  // 当前时间+块差*10min
  return (height - currBlockHeight) * 10 * 60 * 1000;
}

/**
 * 转化成长时间数值用户显示，比如 2->02,12->12
 * @param n 数值
 * @returns
 */
export function toLongTimeDisplayFormat(n: number): string {
  let res = n.toString();
  if (res.length < 2) {
    res = `0${res}`;
  }
  return res;
}
