import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../index';
import { ICoin } from '@/interfaces/coin';
import { IOracleAmm, IPair } from '@/interfaces/pair';
import {
  addCoinConfig,
  addPairConfig,
  loadLocalCoinConfig,
  loadLocalPairConfig,
  savePairs,
  addPair,
  changeCurrentPair,
  isFetchingData,
  updatePair,
  updatePairStatusBySymbol,
} from './actions';
import { fetchAllPairs } from '@/utils/IGraphsQuery';
import {
  AMM_STATUS,
  DEFAULT_COIN_ICON,
  DIFF_BASE_NAME,
  ORACLE_TYPE,
  CONFIG_TYPE,
  ETH_DECIMALS_PLACES,
} from '@/constants/config';
import { chainConfig } from '@/constants/chain';
import { useDiffState } from '@/state/diff/hooks';
import { AVAILABLE_PRODUCTS, PRODUCT_TYPE } from '@/constants/product';
import { currentUTCTime } from '@/utils/timeUtil';
import { DEFAULT_DECIMAL_PLACES } from '@/constants';
import { gaException } from '@/utils/gaUtil';

export function usePairsState(): AppState['pairs'] {
  return useSelector<AppState, AppState['pairs']>((state) => state.pairs);
}

//#region actions
export function usePairsActionHandlers(): {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSaveAllPairsAction: (pairs: IPair[]) => void;
  onAddPairAction: (pair: IPair) => void;
  onUpdatePair: (pair: IPair) => void;
  onFetchAllOraclePairsAction: () => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addBaseAction: (baseCoin: ICoin, oracleType: ORACLE_TYPE, quoteCoins: ICoin[], configType: CONFIG_TYPE) => void;
  loadLocalPairsAndCoinsAction: () => void;
  onChangeCurrentPair: (pair: IPair) => void;
  onUpdatePairStatusBySymbol: (symbol: string, status: AMM_STATUS) => void;
} {
  const dispatch = useDispatch<AppDispatch>();
  const { pairTreeConfig, coinListConfig } = usePairsState();
  // 保存所有交易对
  const onSaveAllPairsAction = useCallback(
    (pairs: IPair[]) => {
      dispatch(
        savePairs({
          pairs,
        }),
      );
    },
    [dispatch],
  );

  // TODO: 改成只更新一条数据
  const onUpdatePair = useCallback(
    (pair: IPair) => {
      dispatch(
        updatePair({
          pair,
        }),
      );
    },
    [dispatch],
  );

  const onUpdatePairStatusBySymbol = useCallback(
    (symbol: string, status: AMM_STATUS) => {
      dispatch(
        updatePairStatusBySymbol({
          symbol,
          status,
        }),
      );
    },
    [dispatch],
  );

  const onAddPairAction = useCallback(
    (pair: IPair) => {
      dispatch(
        addPair({
          pair,
        }),
      );
    },
    [dispatch],
  );

  const getPairListByOraclePairInfo = useCallback(
    (allOracleAmmRes: IOracleAmm[]) => {
      const pairs = allOracleAmmRes
        .filter((r) => r.pair.baseName)
        .map((amm) => {
          const res = {
            oraclePair: amm.pair,
            status: amm.status,
            isInit: amm.init,
            oracleType: amm.pair.oracleType,
          } as IPair;

          const oracleBaseList = pairTreeConfig[amm.pair.oracleType];
          if (oracleBaseList && amm.pair.baseName) {
            if (oracleBaseList[amm.pair.baseName]) {
              const pairTree = oracleBaseList[amm.pair.baseName];
              // base coin
              res.baseCoin = pairTree.baseCoin;
              let quoteCoin = {
                symbol: amm.pair.quoteTokenSymbol,
                fullName: amm.pair.quoteTokenSymbol, // TODO: 获取quote的名称
                icon: DEFAULT_COIN_ICON,
                address: amm.pair.quoteTokenAddress,
              } as ICoin;
              if (pairTree.pairs.length > 0) {
                const pairFind = pairTree.pairs.find((p) => p.quoteCoin.symbol === amm.pair.quoteTokenSymbol);
                if (pairFind) {
                  quoteCoin = {
                    ...quoteCoin,
                    ...pairFind.quoteCoin,
                  };
                }
              }
              // quote coin
              res.quoteCoin = quoteCoin;
            } else {
              // 添加新 base pair
              res.baseCoin = {
                symbol: amm.pair.baseName,
                fullName: amm.pair.baseName,
                address: amm.pair.baseAddress,
                icon: DEFAULT_COIN_ICON,
              } as ICoin;
              res.quoteCoin = {
                symbol: amm.pair.quoteTokenSymbol,
                fullName: amm.pair.quoteTokenSymbol,
                address: amm.pair.quoteTokenAddress,
                icon: DEFAULT_COIN_ICON,
              } as ICoin;
              if (coinListConfig[amm.pair.quoteTokenSymbol]) {
                res.quoteCoin.icon = coinListConfig[amm.pair.quoteTokenSymbol].icon;
              }
            }
          }

          return res;
        });
      return pairs;
    },
    [pairTreeConfig, coinListConfig],
  );

  /**
   * 递归获取数据
   */
  const fetchAllPairList = useCallback(
    async (url: string, pageSize = 500, currentPageIndex = 0, totalCount?: number) => {
      const res = await fetchAllPairs(url, pageSize, currentPageIndex);
      if (res) {
        if (res.list.length > 0) {
          const pairs = getPairListByOraclePairInfo(res.list);

          if (pairs.length > 0) {
            onSaveAllPairsAction(pairs);
          }
        }
        // 如果还有数据则递归获取
        if (res.totalCount >= pageSize + currentPageIndex) {
          fetchAllPairList(url, pageSize, currentPageIndex + pageSize, res.totalCount);
        }
      }
    },
    [getPairListByOraclePairInfo, onSaveAllPairsAction],
  );

  // 获取链上所有交易对，如果有新pair保存到对应pair config中
  const onFetchAllOraclePairsAction = useCallback(async () => {
    try {
      dispatch(
        isFetchingData({
          isFetching: true,
        }),
      );
      for (let i = 0; i < AVAILABLE_PRODUCTS.length; i++) {
        const productType = AVAILABLE_PRODUCTS[i];
        await fetchAllPairList(chainConfig.GRAPHQL_API_URL[productType]);
      }
    } catch (error) {
      gaException(error.message);
      console.log('🚀 ~ file: hooks.ts ~ line 187 ~ onFetchAllOraclePairsAction ~ error', error);
    } finally {
      dispatch(
        isFetchingData({
          isFetching: false,
        }),
      );
    }
  }, [dispatch, fetchAllPairList]);
  // 添加base coin和 pairs
  const addBaseAction = useCallback(
    (
      baseCoin: ICoin,
      oracleType: ORACLE_TYPE = chainConfig.defaultOracleType,
      quoteCoins: ICoin[] = chainConfig.oracleListConfig[chainConfig.defaultOracleType].defaultQuoteCoins,
      configType: CONFIG_TYPE,
    ) => {
      // 1. add coin
      dispatch(
        addCoinConfig({
          coin: baseCoin,
        }),
      );
      // 2. add pairs
      if (quoteCoins.length > 0) {
        quoteCoins.forEach((quote) => {
          dispatch(
            addPairConfig({
              pair: {
                baseCoin,
                quoteCoin: quote,
                oracleType,
                configType: configType,
              },
            }),
          );
        });
      }
    },
    [dispatch],
  );
  // 载入localStorage pairs and coins
  const loadLocalPairsAndCoinsAction = useCallback(() => {
    dispatch(loadLocalCoinConfig({}));
    dispatch(loadLocalPairConfig({}));
  }, [dispatch]);

  const onChangeCurrentPair = useCallback(
    (pair: IPair) => {
      dispatch(changeCurrentPair({ pair }));
    },
    [dispatch],
  );

  return {
    addBaseAction,
    loadLocalPairsAndCoinsAction,
    onFetchAllOraclePairsAction,
    onSaveAllPairsAction,
    onAddPairAction,
    onUpdatePair,
    onChangeCurrentPair,
    onUpdatePairStatusBySymbol,
  };
}

//#endregion

//#region getters

/**
 * 获取币种配置等
 *
 * @export
 * @returns
 */
export function useCoinGetter() {
  const { coinListConfig } = usePairsState();
  // 币种Proxy
  const coinListConfigProxy = useMemo(() => {
    return new Proxy(coinListConfig, {
      get(target, coinName: string) {
        // 如果币种配置不存在，则返回默认配置
        if (!(coinName in target)) {
          return {
            symbol: coinName,
            fullName: coinName,
            address: '',
            icon: DEFAULT_COIN_ICON,
            keepDecimals: DEFAULT_DECIMAL_PLACES,
            decimals: ETH_DECIMALS_PLACES,
          } as ICoin;
        }
        return target[coinName];
      },
    });
  }, [coinListConfig]);
  return { coinListConfig: coinListConfigProxy };
}

/**
 * 返回pair交易等
 *
 * @export
 * @returns
 */
export function usePairGetter() {
  const { pairTreeConfig, currentPair, pairs, isFetching } = usePairsState();
  const { currentBlockHeight } = useDiffState();
  const allPairs = useMemo(() => {
    return [...pairs].sort((a, b) => Number(a.oraclePair.expiry) - Number(b.oraclePair.expiry));
  }, [pairs]);

  // trade页面可以交易的pairs
  // 1. 状态在TRADING、SETTLING
  // 2. pair已经初始化
  const tradingPairs = useMemo(() => {
    return allPairs.filter((pair) => [AMM_STATUS.TRADING, AMM_STATUS.SETTLING].includes(pair.status) && pair.isInit);
  }, [allPairs]);

  // 难度交易对
  const diffAllPairs = useMemo(() => {
    return allPairs.filter((pair) => pair.oracleType === ORACLE_TYPE.SYNFUTURES);
  }, [allPairs]);

  // 过滤过期的pairs
  // 1. 日期在当前时间之后的
  // 2. 状态在TRADING
  const freshPairs = useMemo(() => {
    let filteredPairs = allPairs;
    const nowTime = currentUTCTime();
    // 难度产品filter
    const diffFilter = (pair: IPair): boolean => {
      return Number(pair.oraclePair.expiry) > currentBlockHeight && Number(pair.oraclePair.expiry) % 2016 === 0;
    };
    // v1产品filter
    const basicFilter = (pair: IPair): boolean => {
      return Number(pair.oraclePair.expiry) * 1000 > nowTime;
    };
    filteredPairs = allPairs.filter((pair) => {
      if (pair.oracleType === ORACLE_TYPE.SYNFUTURES && pair.baseCoin.symbol === DIFF_BASE_NAME) {
        return diffFilter(pair);
      }
      return basicFilter(pair);
    });
    // 同时只显示TRADING交易对
    return filteredPairs.filter((pair) => [AMM_STATUS.TRADING].includes(pair.status));
  }, [allPairs, currentBlockHeight]);

  // console.log(`allPairsallPairs.length`, allPairs.length);

  return {
    pairTreeConfig,
    currentPair,
    allPairs,
    tradingPairs,
    diffAllPairs,
    freshPairs,
    isLoadingPairs: isFetching,
  };
}

//#endregion
//#endregion
