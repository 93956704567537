import { IChainConfig } from '@/interfaces/chain';
import { ChainId, appChainId } from './chain';
import * as mainnetChainConfig from './mainnet';
import * as kovanChainConfig from './kovan';
import * as bscChainConfig from './bsc';
import { ORACLE_TYPE, AMM_STATUS } from '@/constants/config';
import { IOraclePair, IPair } from '@/interfaces/pair';

let chainConfig: IChainConfig;

switch (appChainId) {
  case ChainId.MAINNET:
    chainConfig = JSON.parse(JSON.stringify(mainnetChainConfig));
    break;
  case ChainId.KOVAN:
    chainConfig = JSON.parse(JSON.stringify(kovanChainConfig));
    break;
  case ChainId.BSC:
    chainConfig = JSON.parse(JSON.stringify(bscChainConfig));
    break;
  default:
    chainConfig = JSON.parse(JSON.stringify(mainnetChainConfig));
    break;
}
//#region 读取环境变量，覆盖默认配置

// v1 basic graphql api
if (process.env.REACT_APP_BASIC_GRAPHQL_API_URL) {
  chainConfig.GRAPHQL_API_URL.BASIC = process.env.REACT_APP_BASIC_GRAPHQL_API_URL;
}
// 难度 graphql api
if (process.env.REACT_APP_DIFFICULTY_GRAPHQL_API_URL) {
  chainConfig.GRAPHQL_API_URL.DIFFICULTY = process.env.REACT_APP_DIFFICULTY_GRAPHQL_API_URL;
}

// v1 basic contract address
if (process.env.REACT_APP_BASIC_CONFIG_ADDRESS) {
  chainConfig.CONTRACT_ADDRESS.BASIC.ConfigAddr = process.env.REACT_APP_BASIC_CONFIG_ADDRESS || '';
  chainConfig.CONTRACT_ADDRESS.BASIC.FactoryAddr = process.env.REACT_APP_BASIC_FACTORY_ADDRESS || '';
  chainConfig.CONTRACT_ADDRESS.BASIC.ReaderAddr = process.env.REACT_APP_BASIC_READER_ADDRESS || '';
}
// 难度 contract address
if (process.env.REACT_APP_DIFFICULTY_CONFIG_ADDRESS) {
  chainConfig.CONTRACT_ADDRESS.DIFFICULTY.ConfigAddr = process.env.REACT_APP_DIFFICULTY_CONFIG_ADDRESS || '';
  chainConfig.CONTRACT_ADDRESS.DIFFICULTY.FactoryAddr = process.env.REACT_APP_DIFFICULTY_FACTORY_ADDRESS || '';
  chainConfig.CONTRACT_ADDRESS.DIFFICULTY.ReaderAddr = process.env.REACT_APP_DIFFICULTY_READER_ADDRESS || '';
  chainConfig.CONTRACT_ADDRESS.DIFFICULTY.OracleAddr = process.env.REACT_APP_DIFFICULTY_ORACLE_ADDRESS || '';
}

// 默认pair
if (process.env.REACT_APP_DEFAULT_PAIR) {
  const [oracleStr, baseStr, quoteStr] = process.env.REACT_APP_DEFAULT_PAIR.split('-');

  // 默认UNISWAP ETH/USDC交易对
  const defaultOracle: ORACLE_TYPE = oracleStr as ORACLE_TYPE;
  if (chainConfig.pairTreeConfig[defaultOracle] && chainConfig.pairTreeConfig[defaultOracle][baseStr]) {
    const DEFAULT_PAIR_CONFIG = chainConfig.pairTreeConfig[defaultOracle][baseStr].pairs.find(
      (pair) => pair.quoteCoin.symbol === quoteStr,
    );
    if (DEFAULT_PAIR_CONFIG) {
      const EMPTY_ORACLE_PAIR: IOraclePair = {
        ammProxy: '',
        baseAddress: '',
        baseName: DEFAULT_PAIR_CONFIG.baseCoin.symbol,
        futuresProxy: '',
        id: '',
        oracleType: defaultOracle,
        quoteTokenAddress: '',
        quoteTokenSymbol: DEFAULT_PAIR_CONFIG.quoteCoin.symbol,
        expiry: '',
        symbol: '',
      };
      const DEFAULT_PAIR: IPair = {
        ...DEFAULT_PAIR_CONFIG,
        isInit: true,
        status: AMM_STATUS.TRADING,
        oraclePair: EMPTY_ORACLE_PAIR,
      };

      chainConfig.DEFAULT_PAIR = DEFAULT_PAIR;
    }
  }
}

//#endregion

export { ChainId, appChainId, chainConfig };
