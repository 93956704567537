import { ORACLE_TYPE } from '@/constants/config';
import { IOracleAmm, IOraclePair } from '../interfaces/pair';
import { synDiffWeb3, synWeb3 } from '@/synWeb3';
import { IPosition } from '@/interfaces/position';
import { IAmmDetail as IAmm } from '@/interfaces/amm';
import { IFuturesDetail } from '@/interfaces/futures';
import { gaException } from '@/utils/gaUtil';

const referrerPolicy = 'strict-origin-when-cross-origin';
const headers = {
  accept: '*/*',
  'accept-language': 'en-US,en;q=0.9,zh-CN;q=0.8,zh;q=0.7,zh-TW;q=0.6',
  'content-type': 'application/json',
  'sec-fetch-dest': 'empty',
  'sec-fetch-mode': 'cors',
  'sec-fetch-site': 'cross-site',
};

// example consuming code
interface IIndexerPair extends IOraclePair {
  AmmDetail: IAmm;
  FuturesDetail: IFuturesDetail;
}

export interface IIndexerPosition extends IPosition {
  maintenanceMargin: string;
  shareTokenBalance: string;
  pair: IIndexerPair;
}

interface IHttpResponse<T> extends Response {
  parsedBody?: T;
}

export async function http<T>(request: RequestInfo): Promise<IHttpResponse<T>> {
  const response: IHttpResponse<T> = await fetch(request);

  try {
    // may error if there is no body
    response.parsedBody = await response.json();
  } catch (error) {
    gaException(error.message);
  }

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

export async function get<T>(path: string, args: RequestInit = { method: 'get' }): Promise<IHttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function post<T>(
  path: string,
  body: any,
  args: RequestInit = { method: 'post', body: JSON.stringify(body) },
): Promise<IHttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function put<T>(
  path: string,
  body: any,
  args: RequestInit = { method: 'put', body: JSON.stringify(body) },
): Promise<IHttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function fetchAllPairs(
  url: string,
  pageSize = 500,
  currentPageIndex = 0,
): Promise<{ list: IOracleAmm[]; totalCount: number }> {
  // console.log(`\n >>> fetchAllPairs--${GRAPH_BASE_URL}`);
  return fetch(url, {
    headers: headers,
    referrerPolicy: referrerPolicy,
    body: JSON.stringify({
      query: `{
            factories{
              pairsCount
            }
            ammDetails (first: ${pageSize}, skip: ${currentPageIndex}, where: {status_not: SETTLED}){
              symbol
              status
              init
              pair {
                id
                oracleType
                baseName
                baseAddress
                quoteTokenSymbol
                quoteTokenAddress
                ammProxy
                futuresProxy
                symbol
                expiry
              }
            }   
    }`,
    }),
    method: 'POST',
    mode: 'cors',
    credentials: 'omit',
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(`[IGraphsQuery]fetchAllPairs-->data`, data);
      let list: IOracleAmm[] = [];
      let totalCount = 0;
      if (data && data.data) {
        list = data.data.ammDetails;
        if (list && list.length > 0) {
          list.forEach((amm: { pair: { oracleType: string; baseName: string } }) => {
            if (amm.pair) {
              amm.pair.oracleType = amm.pair.oracleType.toUpperCase() as ORACLE_TYPE;
            }
          });
        }
        if (data.data.factories && data.data.factories.length > 0) {
          totalCount = data.data.factories[0].pairsCount || list.length;
        } else {
          totalCount = list.length;
        }
      }

      return { list, totalCount };
    })
    .catch((error: Error) => {
      gaException(error.message);
      throw error;
    });
}

/**
 * 返回用户交易过的用户交易信息
 * @param url indexer地址
 * @param account 用户地址
 * @param pageSize
 * @param currentPageIndex
 * @param obf
 * @returns
 */
export async function fetchUserPositionList(
  url: string,
  account: string,
  pageSize = 100,
  currentPageIndex = 0,
  obf: 'asc' | 'desc' = 'asc',
): Promise<{ list: IPosition[]; totalCount: number }> {
  const bodyQuery = JSON.stringify({
    query: `{
      user(id: "${account.toLowerCase()}") {
        id
        tradePairsCount
        tradePairs (first: ${pageSize} , skip: ${currentPageIndex}, orderDirection: ${obf}, orderBy: expiry, where: {settled: false}){
          id
          position
          entryPrice
          markPrice
          accountBalance
          unrealPnl
          liqPrice
          positionMargin
          maintenanceMargin
          availableMargin
          shareTokenBalance
          pair {
            id
            symbol
            status
            ammProxy
            futuresProxy
            AmmDetail {
              id
              symbol
              status
              spotIndexPrice
              shareTotalSupply
              oracle
              midPrice
              maturity
              ammPosition
              ammMarginBalance
            }
            FuturesDetail {
              id
              margin
              marginSymbol
              openInterests
              insuranceFund
            }
          }
        }
      }
    }`,
  });

  // const url = `${GRAPH_BASE_URL}`;
  return fetch(url, {
    headers: headers,
    referrerPolicy: referrerPolicy,
    body: bodyQuery,
    method: 'POST',
    mode: 'cors',
    credentials: 'omit',
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(`[IGraphsQuery]fetchTradePairsList-->data .tradePairs`, data);
      let list: IPosition[] = [];
      let totalCount = 0;
      if (data && data.data && data.data.user) {
        if (data.data.user.tradePairs) {
          const positions: IPosition[] = data.data.user.tradePairs.map((p: IIndexerPosition) => {
            const position: IPosition = {
              ...p,
              mainMargin: synWeb3.utils.fromWei(p.maintenanceMargin),
              symbol: p.pair.symbol,
              position: synWeb3.utils.fromWei(p.position),
              entryPrice: synWeb3.utils.fromWei(p.entryPrice),
              markPrice: synWeb3.utils.fromWei(p.markPrice),
              accountBalance: synWeb3.utils.fromWei(p.accountBalance),
              unrealPnl: synWeb3.utils.fromWei(p.unrealPnl),
              liqPrice: synWeb3.utils.fromWei(p.liqPrice),
              positionMargin: synWeb3.utils.fromWei(p.positionMargin),
              availableMargin: synWeb3.utils.fromWei(p.availableMargin),
            } as IPosition;
            if (p.pair) {
              position.pair = p.pair as IOraclePair;
              if (p.pair.AmmDetail) {
                position.pair.ammDetail = {
                  ...p.pair.AmmDetail,
                  ammProxy: p.pair.AmmDetail.id,
                  spotIndexPrice: synWeb3.utils.fromWei(p.pair.AmmDetail.spotIndexPrice),
                  shareTotalSupply: synWeb3.utils.fromWei(p.pair.AmmDetail.shareTotalSupply),
                  shareBalance: synWeb3.utils.fromWei(p.shareTokenBalance),
                  midPrice: synWeb3.utils.fromWei(p.pair.AmmDetail.midPrice),
                  ammPosition: synWeb3.utils.fromWei(p.pair.AmmDetail.ammPosition),
                  ammMarginBalance: synWeb3.utils.fromWei(p.pair.AmmDetail.ammMarginBalance),
                } as IAmm;
              }
              if (p.pair.FuturesDetail) {
                position.pair.futuresDetail = {
                  ...p.pair.FuturesDetail,
                  futuresProxy: p.pair.FuturesDetail.id,
                  openInterests: synWeb3.utils.fromWei(p.pair.FuturesDetail.openInterests),
                  insuranceFund: synWeb3.utils.fromWei(p.pair.FuturesDetail.insuranceFund),
                } as IFuturesDetail;
              }
            }
            return position;
          });
          list = positions || [];
        }
        if (data.data.user.tradePairsCount) {
          totalCount = data.data.user.tradePairsCount || list.length;
        } else {
          totalCount = list.length;
        }
      }

      return { list, totalCount };
    });
}
