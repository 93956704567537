import { Card, Divider, Select, Tooltip } from 'antd';
import { PlusOutlined, QuestionCircleFilled } from '@ant-design/icons';
import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ContractSelectorModal from '@/components/ContractSelectorModal';
import { Field } from '@/state/swap/actions';
import { useWeb3React } from '@web3-react/core';
import { useTranslation } from 'react-i18next';
import { useGlobalActionHandlers, useGlobalGetter } from '@/state/global/hooks';
import { useTokenActionHandlers, useTradeToken } from '@/state/swap/hooks';
import { usePositionHook } from '@/state/position';
import usePrevious from '@/hooks/usePrevious';
import { IPair, IPairTree } from '@/interfaces/pair';
import { getBlockHeightTime, symbolToDate } from '@/utils/timeUtil';
import { useCoinGetter, usePairGetter, usePairsActionHandlers } from '@/state/pairs/hooks';
import { AMM_STATUS, ORACLE_TYPE } from '@/constants/config';
import { chainConfig, appChainId } from '@/constants/chain';
import { useDiffHook } from '@/state/diff';
import { dateFormat } from '@/constants/format';
import { isBtcMiningDifficulty, isDiffProductSymbol, getOracleTypeByCoinSymbol } from '@/utils';
import { synDiffWeb3, synWeb3 } from '@/synWeb3';
import { PRODUCT_TYPE } from '@/constants/product';
import { IToken } from '@/interfaces/token';

import TreeLinkIcon from '@/assets/svg/icons/icon_tree_link.svg';
import DropDownIcon from '@/assets/svg/icons/icon_arrow_drop_g.svg';
import CalendarDateIcon from '@/assets/svg/icons/icon_date.svg';

import './index.scss';
import CoinIcon from '@/components/CoinIcon';
import { GaCategory, gaEvent } from '@/utils/gaUtil';

const { Option, OptGroup } = Select;

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

interface IProps {
  showCreatePool?: boolean;
  showTooltip?: boolean;
  selectorPairs?: string;
  selectorType?: 'tradeSelector' | 'addLiquidity'; // 当前类型，目前有tradeSelector、addLiquidity
  onChangeProduct?: (productType: PRODUCT_TYPE) => void;
}

// let isFirstTime = false;

export default function Selector(props: IProps): JSX.Element {
  const { t } = useTranslation();
  const { currencies } = useTradeToken();
  const history = useHistory();
  const query = useQuery();
  const querySymbol = query.get('symbol') || '';

  const { coinListConfig } = useCoinGetter();
  const { onResetCurrentPosition, onChangeCurrentPositionByAddress } = usePositionHook();
  const { isCorrectChain } = useGlobalGetter();

  // 当前类型
  const selectorType = useMemo(() => {
    return props.selectorType || 'tradeSelector';
  }, [props.selectorType]);

  // don't remove oracle
  const quoteDefaultValue = currencies[Field.ORACLE] || '';
  const baseDefaultValue = currencies[Field.BASE];

  const [dateSelect, handleDateSelect] = useState('');
  const [selectedPairSymbol, handleSelectedPairSymbol] = useState('');
  const [baseTokenSelect, handleBaseTokenSelect] = useState('');
  const [quoteTokenSelect, handleQuoteTokenSelect] = useState('');
  const [baseGroupSelect, handleBaseGroupSelect] = useState('' as ORACLE_TYPE);
  const [isFirstTime, handleIsFirstTime] = useState(false);
  const prevTokens = usePrevious({ baseTokenSelect, quoteTokenSelect, isCorrectChain });
  const [base, handleBaseChange] = useState(baseDefaultValue);

  const [quoteSelect, handleQuoteSelect] = useState(quoteDefaultValue);

  const [addCurrencyModalStatus, showAddCurrencyModal] = useState(false);
  const [isDiffProduct, setIsDiffProduct] = useState<boolean>(false);
  // const [defaultBlockHeightValue, setDefaultBlockHeightValue] = useState<string>('');

  const { onTokenSelection, onOracleChange, onMarityChange } = useTokenActionHandlers();

  const { onChangeProductAction } = useGlobalActionHandlers();
  // const { currentProductType } = useGlobalGetter();
  // const [isCreatePoolModalVisible, setIsCreatePoolModalVisible] = useState(false);

  const { account } = useWeb3React();

  const { currentBlockHeight, currentBlockTime } = useDiffHook();

  const { pairTreeConfig, tradingPairs, freshPairs, isLoadingPairs } = usePairGetter();
  const { onChangeCurrentPair } = usePairsActionHandlers();
  // 从 GraphsQL 读取相应的 pairs 数据，
  const pairsList = props.selectorPairs === 'AllPairs' ? freshPairs : tradingPairs;

  const traderAddress = account || '';

  const DropDownIconNode = (): ReactNode => {
    return <img src={DropDownIcon} />;
  };
  const CalendarDateIconNode = (): ReactNode => {
    return <img src={CalendarDateIcon} />;
  };

  // 返回是否是难度产品回调
  useEffect(() => {
    if (props.onChangeProduct) {
      props.onChangeProduct(isDiffProduct ? PRODUCT_TYPE.DIFFICULTY : PRODUCT_TYPE.BASIC);
    }
  }, [isDiffProduct, props]);

  // 选择base/quote 后的pairsnormalPairs
  const filteredPairs = useMemo(() => {
    let res: IPair[] = [];
    if (pairsList.length > 0) {
      if (!!baseGroupSelect && !!baseTokenSelect && !!quoteTokenSelect) {
        // 过滤过的交易对
        res = pairsList.filter((pair) => {
          return (
            pair.oracleType === baseGroupSelect &&
            pair.baseCoin.symbol === baseTokenSelect &&
            pair.quoteCoin.symbol === quoteTokenSelect &&
            [AMM_STATUS.TRADING, AMM_STATUS.SETTLING].includes(pair.status)
          );
        });
      }
    }
    return res;
  }, [pairsList, baseGroupSelect, baseTokenSelect, quoteTokenSelect]);

  const showModal = useCallback(
    (e) => {
      showAddCurrencyModal(!addCurrencyModalStatus);
    },
    [addCurrencyModalStatus, showAddCurrencyModal],
  );

  const changeQuote = useCallback(
    async (quoteToken: string, isFirstTime = false) => {
      const quote = quoteToken;

      if (!isFirstTime) {
        // clear date select
        handleDateSelect('');
        handleSelectedPairSymbol('');
      }
      onMarityChange(Field.MARITY, '');
      onResetCurrentPosition();
      handleQuoteSelect(quote);
      handleQuoteTokenSelect(quote);
      onTokenSelection(Field.QUOTE, quote);
      //   await save(baseTokenSelect, quoteTokenSelect, dateSelect)
    },
    [onMarityChange, onResetCurrentPosition, onTokenSelection],
  );

  const changeBase = useCallback(
    async (e: string) => {
      handleBaseChange(e);
      const eSplit: string[] = e.split('-');

      if (eSplit.length === 2) {
        const selectedOracle = eSplit[1].toUpperCase();
        const selectedBase = eSplit[0];
        handleBaseGroupSelect(selectedOracle as ORACLE_TYPE);
        handleBaseTokenSelect(selectedBase);
        onChangeProductAction(selectedOracle === ORACLE_TYPE.SYNFUTURES ? PRODUCT_TYPE.DIFFICULTY : PRODUCT_TYPE.BASIC);
        onTokenSelection(Field.BASE, selectedBase);
        onOracleChange(Field.ORACLE, selectedOracle);
        // fix Add Liquidity selector bug
        onMarityChange(Field.MARITY, '');
        // 判断是否是难度产品
        const isSymbolDifficulty = isBtcMiningDifficulty(selectedBase);
        setIsDiffProduct(isSymbolDifficulty);
      }
    },
    [onChangeProductAction, onMarityChange, onOracleChange, onTokenSelection],
  );

  const handleInputSelect = useCallback(
    (inputToken: IToken, oracleType: ORACLE_TYPE) => {
      if (!inputToken) {
        return;
      }
      changeBase(`${inputToken.symbol}-${oracleType}`);
    },
    [changeBase],
  );

  const save = useCallback(
    async (selectPair: IPair, productType: PRODUCT_TYPE) => {
      const ammProxy: string = selectPair.oraclePair.ammProxy;
      const futuresProxy: string = selectPair.oraclePair.futuresProxy;
      if (!!ammProxy && !!futuresProxy) {
        onChangeProductAction(productType);
        const currentWeb3 = productType === PRODUCT_TYPE.DIFFICULTY ? synDiffWeb3 : synWeb3;
        onChangeCurrentPair(selectPair);
        onResetCurrentPosition();
        onChangeCurrentPositionByAddress(traderAddress, futuresProxy, ammProxy, currentWeb3);
        // save localStorage
        localStorage.setItem('syn_default_pair', JSON.stringify(selectPair));
      }
    },
    [
      onChangeProductAction,
      onChangeCurrentPair,
      onResetCurrentPosition,
      onChangeCurrentPositionByAddress,
      traderAddress,
    ],
  );

  // 选择日期事件
  const onChangeMarity = useCallback(
    async (selectedPairSymbol: string) => {
      const dateStr = symbolToDate(selectedPairSymbol);
      handleDateSelect(dateStr);
      handleSelectedPairSymbol(selectedPairSymbol);
      onMarityChange(Field.MARITY, dateStr);
      const selectPair = pairsList.find((pair) => pair.oraclePair.symbol === selectedPairSymbol);
      if (selectPair) {
        await save(selectPair, PRODUCT_TYPE.BASIC);
      }
    },
    [onMarityChange, save, pairsList],
  );

  // 选择选择块高度
  const onChangeBlockHeight = useCallback(
    async (height: string) => {
      handleDateSelect(height);
      onMarityChange(Field.MARITY, height);
    },
    [onMarityChange],
  );

  // 选择maturity后。
  useEffect(() => {
    const selectPair = filteredPairs.find((pair) => pair.oraclePair.expiry === dateSelect);
    if (selectPair) {
      handleSelectedPairSymbol(selectPair.oraclePair.symbol);
      save(selectPair, PRODUCT_TYPE.DIFFICULTY);
    }
  }, [dateSelect, filteredPairs, save]);

  const handleCreatePoolOk = (): void => {
    // setIsCreatePoolModalVisible(false);

    history.push(`/pool?base=${baseTokenSelect}&quote=${quoteTokenSelect}&oracle=${baseGroupSelect}`);
  };

  // 设置默认交易对
  // 1. 如果没有数据设置成默认配置中base\quote
  // 2. 如果query、localStorage存在，设置成对应交易对base\quote及pair
  // 3. 难度产品设置块高，非难度设置日期
  useEffect(() => {
    // 载入数据中跳过
    if (isLoadingPairs) {
      return;
    }
    let isFirst = isFirstTime;
    if (!baseGroupSelect && !baseTokenSelect && !quoteTokenSelect) {
      isFirst = true;
      handleIsFirstTime(isFirst);
    }
    // 当没有数据时，设置默认交易对即可
    if (!pairsList || pairsList.length <= 0) {
      if (isFirst && chainConfig.DEFAULT_PAIR && chainConfig.DEFAULT_PAIR.baseCoin) {
        const oracleType = chainConfig.DEFAULT_PAIR.oracleType;
        const baseTokenSymbol = chainConfig.DEFAULT_PAIR.baseCoin.symbol;
        const base = `${baseTokenSymbol}-${oracleType.toString()}`;
        const quoteTokenSymbol = chainConfig.DEFAULT_PAIR.quoteCoin.symbol;
        changeBase(base);
        changeQuote(quoteTokenSymbol, true);
      }
      handleIsFirstTime(false);
      return;
    }

    let oracleType = baseGroupSelect;
    let baseTokenSymbol = baseTokenSelect;
    let quoteTokenSymbol = quoteTokenSelect;
    let pairSymbol = selectedPairSymbol;
    // 恢复的本地pair，默认按query->localStorage->DEFAULT_PAIR顺序读取
    let localPair = chainConfig.DEFAULT_PAIR;
    // 第一次载入时读取query及localStorage设置默认交易对
    if (isFirst) {
      const pairStr = localStorage.getItem('syn_default_pair');
      // 如果有query默认优先query载入先
      if (querySymbol) {
        const symbols = querySymbol.split('-');
        const oracleType = getOracleTypeByCoinSymbol(symbols[3]);
        if (symbols.length === 4) {
          localPair = {
            baseCoin: coinListConfig[symbols[0]],
            quoteCoin: coinListConfig[symbols[1]],
            oracleType: oracleType,
            oraclePair: {
              symbol: querySymbol,
              oracleType: oracleType,
              baseName: symbols[0],
              quoteTokenSymbol: symbols[1],
            },
          } as IPair;
        }
        // 将query还原
        history.replace('/trade');
      } else if (pairStr) {
        localPair = JSON.parse(pairStr);
      }
      oracleType = localPair.oracleType;
      if (localPair.baseCoin) {
        baseTokenSymbol = localPair.baseCoin.symbol;
        quoteTokenSymbol = localPair.quoteCoin.symbol;
      } else {
        baseTokenSymbol = localPair.oraclePair.symbol;
        quoteTokenSymbol = localPair.oraclePair.quoteTokenSymbol;
      }

      pairSymbol = localPair.oraclePair.symbol;
      // 1. 查找数据pair是否存在，存在则继续
      // 2. 否则先设置成交易对第一个pair
      // 3. 如果交易对也不存在，则设置成默认交易对
      const pair = pairsList.find((pair) => pair.oraclePair.symbol === pairSymbol);
      if (!pair) {
        // 否则选择默认第一个交易对
        const firstPair = pairsList.find(
          (pair) =>
            pair.oracleType === localPair.oracleType &&
            pair.oraclePair.baseName === localPair.baseCoin.symbol &&
            pair.oraclePair.quoteTokenSymbol === localPair.quoteCoin.symbol,
        );
        // 判断第一个pair是否存在，不存在设置默认交易对
        if (firstPair) {
          oracleType = firstPair.oracleType;
          baseTokenSymbol = firstPair.baseCoin.symbol;
          quoteTokenSymbol = firstPair.quoteCoin.symbol;
          pairSymbol = firstPair.oraclePair.symbol;
        } else {
          // 再判断默认第一个pair
          const defaultPair = pairsList.find(
            (pair) =>
              pair.oracleType === chainConfig.DEFAULT_PAIR.oracleType &&
              pair.oraclePair.baseName === chainConfig.DEFAULT_PAIR.baseCoin.symbol &&
              pair.oraclePair.quoteTokenSymbol === chainConfig.DEFAULT_PAIR.quoteCoin.symbol,
          );
          oracleType = chainConfig.DEFAULT_PAIR.oracleType;
          baseTokenSymbol = chainConfig.DEFAULT_PAIR.baseCoin.symbol;
          quoteTokenSymbol = chainConfig.DEFAULT_PAIR.quoteCoin.symbol;
          // 如果默认第一个pair存在，则跳转
          if (defaultPair) {
            pairSymbol = defaultPair.oraclePair.symbol;
          }
        }
      }
      // 第一次加载结束
      handleIsFirstTime(false);
    }
    if (!baseTokenSymbol) {
      return;
    }

    // 设置base
    const base = `${baseTokenSymbol}-${oracleType.toString()}`;

    if (
      !(baseGroupSelect === oracleType && baseTokenSymbol === baseTokenSelect && quoteTokenSelect === quoteTokenSymbol)
    ) {
      changeBase(base);
      changeQuote(quoteTokenSymbol, isFirst);
    }
    // 如果pairSymbol相同，则跳过
    if (selectedPairSymbol === pairSymbol) {
      // 如果pair已经settled，则重新判断取值
      // TODO:重构成更简单的方法处理
      const pair = pairsList.find((pair) => pair.oraclePair.symbol === pairSymbol);
      if (selectedPairSymbol && !pair) {
        handleIsFirstTime(true);
      }
      return;
    }
    if (pairSymbol) {
      let maturity = '';
      // 对于难度产品，判断的是块高
      const isSymbolDifficulty = isDiffProductSymbol(pairSymbol);
      setIsDiffProduct(isSymbolDifficulty);
      if (isSymbolDifficulty) {
        const splits = pairSymbol.split('-');
        maturity = splits[2];
      } else {
        maturity = symbolToDate(pairSymbol);
      }
      // 如果当前选择的maturity与现有相同则跳过
      if (maturity === dateSelect) {
        return;
      }
      // 如果pair不纯在，则不设置maturity
      const pair = pairsList.find((pair) => pair.oraclePair.symbol === pairSymbol);
      if (!pair) {
        return;
      }
      // 难度产品设置块高，正常设置pairSymbol
      if (isSymbolDifficulty) {
        onChangeBlockHeight(maturity);
      } else {
        onChangeMarity(pairSymbol);
      }
    }
    // handleIsFirstTime(false);
  }, [
    pairsList,
    baseGroupSelect,
    baseTokenSelect,
    quoteTokenSelect,
    isLoadingPairs,
    changeBase,
    changeQuote,
    onChangeMarity,
    isFirstTime,
    selectedPairSymbol,
    querySymbol,
    history,
    coinListConfig,
    dateSelect,
    onChangeBlockHeight,
  ]);

  // 改变base之后，如果跟之前不一样选择第一个quote
  useEffect(() => {
    if (baseTokenSelect && prevTokens && prevTokens.baseTokenSelect && baseTokenSelect !== prevTokens.baseTokenSelect) {
      // 如果选择base后，没有quote token，则默认选中第一个quote
      const basePairs = pairTreeConfig[baseGroupSelect][baseTokenSelect];
      if (basePairs && basePairs.pairs.length > 0) {
        const firstQuote = basePairs.pairs[0].quoteCoin.symbol;
        // 通过changeQuote('')触发onChangeMarity
        changeQuote('');
        changeQuote(firstQuote);
        onMarityChange(Field.MARITY, '');
      }
    }
  }, [baseTokenSelect, prevTokens, baseGroupSelect, changeQuote, pairTreeConfig, onMarityChange]);

  // 改变quote之后
  useEffect(() => {
    // 如果没有,选择第一个交易对
    if (quoteTokenSelect && baseTokenSelect && prevTokens && prevTokens.baseTokenSelect) {
      if (quoteTokenSelect !== prevTokens.quoteTokenSelect || baseTokenSelect !== prevTokens.baseTokenSelect) {
        if (filteredPairs.length > 0) {
          const firstPair = filteredPairs.find(
            (pair) => pair.baseCoin.symbol === baseTokenSelect && pair.quoteCoin.symbol === quoteTokenSelect,
          );
          if (firstPair && firstPair.oraclePair.symbol) {
            const isSymbolDifficulty = isDiffProductSymbol(firstPair.oraclePair.symbol);
            if (isSymbolDifficulty) {
              onChangeBlockHeight(firstPair.oraclePair.expiry);
            } else {
              onChangeMarity(firstPair.oraclePair.symbol);
            }
          }
        }
      }
    }
  }, [quoteTokenSelect, baseTokenSelect, filteredPairs, prevTokens, onChangeMarity, onChangeBlockHeight]);

  // 切换到正确网络后，重新设置pair
  useEffect(() => {
    if (isCorrectChain && prevTokens && !prevTokens.isCorrectChain) {
      handleIsFirstTime(true);
    }
  }, [isCorrectChain, handleIsFirstTime, prevTokens]);

  // 计算对应块块的时间
  const calculatedBlockHeightTime = useCallback(
    (expiry: string): string => {
      return getBlockHeightTime(Number(expiry), currentBlockHeight, currentBlockTime, dateFormat);
    },
    [currentBlockHeight, currentBlockTime],
  );

  // 获取显示的basename
  function getDisplayBaseSymbol(pair: IPairTree): ReactElement {
    // // 如果是难度产品，显示fullname
    // if (isBtcMiningDifficulty(pair.baseCoin.symbol)) {
    //   return <span>{selectorType === 'tradeSelector' ? pair.baseCoin.fullName : pair.baseCoin.symbol}</span>;
    // }
    // 默认显示symbol
    return <span>{pair.baseCoin.symbol}</span>;
  }

  // maturity 下拉列表内容
  const maturitySelectorOptionList = useMemo(() => {
    // 难度产品显示日期+块高
    if (isDiffProduct) {
      return filteredPairs.map((p) => {
        const heightTime = calculatedBlockHeightTime(p.oraclePair.expiry);
        return (
          <Option
            className={`ant-select-quote-item ${dateSelect === symbolToDate(p.oraclePair.symbol) &&
              'ant-select-item-option-selected'}`}
            key={p.oraclePair.symbol}
            value={p.oraclePair.expiry}
            label={heightTime}>
            <div className="bloc-height-list-item">
              <span>{p.oraclePair.expiry}</span>
              <span>{heightTime}</span>
            </div>
          </Option>
        );
      });
    }
    return filteredPairs.map((p) => {
      return (
        <Option
          className={`ant-select-maturity-item ${dateSelect === symbolToDate(p.oraclePair.symbol) &&
            'ant-select-item-option-selected'}`}
          key={p.oraclePair.symbol}
          value={p.oraclePair.symbol}
          label={p.oraclePair.symbol}>
          <div>{symbolToDate(p.oraclePair.symbol)}</div>
        </Option>
      );
    });
  }, [filteredPairs, isDiffProduct, dateSelect, calculatedBlockHeightTime]);

  return (
    <div className="syn-selector">
      <Card className="contract-select" bordered={false}>
        {props.showTooltip ? (
          <div className="contract-select-title">
            Contract
            <Tooltip title={t('message.contractSelectorTips')}>
              <QuestionCircleFilled className="tooltip-info-icon" />
            </Tooltip>
          </div>
        ) : null}
        <div className="contract-select-list">
          <div className="contract-select-item">
            <div className="contract-select-item-name">Base</div>
            <Select
              defaultValue={baseDefaultValue}
              dropdownClassName="contract-select__dropdown"
              onChange={(val: string): void => {
                gaEvent(GaCategory.SELECTOR, 'Change base', val);
                changeBase(val);
              }}
              value={base}
              suffixIcon={DropDownIconNode}
              listHeight={500}
              optionLabelProp="label">
              {Object.values(chainConfig.oracleListConfig).map((oracle) => (
                <OptGroup
                  label={
                    <div className="contract-select__base-group">
                      <div className="contract-select__base-group-name">
                        <img className="icon-coin" src={oracle.icon} alt={oracle.symbol} />
                        <span>{oracle.fullName}</span>
                      </div>

                      {oracle.type === chainConfig.defaultOracleType && (
                        <a
                          style={{ flex: 'none', display: 'block', cursor: 'pointer' }}
                          onClick={(): void => showModal(true)}>
                          More
                        </a>
                      )}
                    </div>
                  }
                  key={oracle.type}>
                  {Object.values(pairTreeConfig[oracle.type]).map((pair) => (
                    <Option
                      key={`${oracle.type}-${pair.baseCoin.symbol}`}
                      value={`${pair.baseCoin.symbol}-${oracle.type}`}
                      title={pair.baseCoin.fullName}
                      label={<CoinIcon quoteValue={pair.baseCoin.symbol} />}>
                      <div className="contract-select__dropdown__coin-item">
                        <img className="icon-coin icon-oracle" src={TreeLinkIcon} alt={oracle.type} />
                        <CoinIcon quoteValue={pair.baseCoin.symbol} />
                      </div>
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </div>
          <div className="contract-select-item ">
            <div className="contract-select-item-name">Quote</div>

            <Select
              defaultValue={quoteDefaultValue}
              dropdownClassName="contract-select__dropdown"
              onChange={(val: string): void => {
                gaEvent(GaCategory.SELECTOR, 'Change quote', val);
                // 通过changeQuote('')触发onChangeMarity
                changeQuote('');
                changeQuote(val);
              }}
              suffixIcon={DropDownIconNode}
              value={quoteSelect}>
              {baseGroupSelect &&
                baseTokenSelect &&
                pairTreeConfig[baseGroupSelect] &&
                pairTreeConfig[baseGroupSelect][baseTokenSelect] &&
                pairTreeConfig[baseGroupSelect][baseTokenSelect].pairs &&
                pairTreeConfig[baseGroupSelect][baseTokenSelect].pairs.length > 0 &&
                pairTreeConfig[baseGroupSelect][baseTokenSelect].pairs.map((pair) => (
                  <Option key={pair.quoteCoin.symbol} value={pair.quoteCoin.symbol}>
                    <div className="contract-select__dropdown__coin-item">
                      <CoinIcon quoteValue={pair.quoteCoin.symbol} />
                    </div>
                  </Option>
                ))}
            </Select>
          </div>
          <div className="contract-select-item contract-select-item_maturity">
            <div className="contract-select-item-name">Maturity</div>

            <Select
              dropdownClassName="contract-select__dropdown"
              onChange={(val: string): void => {
                if (isDiffProduct) {
                  gaEvent(GaCategory.SELECTOR, 'Change blockHeight', val);
                  onChangeBlockHeight(val);
                } else {
                  gaEvent(GaCategory.SELECTOR, 'Change  maturity', val);
                  onChangeMarity(val);
                }
              }}
              optionLabelProp="label"
              defaultValue={dateSelect}
              value={dateSelect}
              suffixIcon={CalendarDateIconNode}
              dropdownRender={(menu): ReactElement => (
                <div>
                  {isDiffProduct && baseTokenSelect && quoteTokenSelect && filteredPairs && filteredPairs.length > 0 && (
                    <div className="bloc-height-list-title">
                      <span>Height</span>
                      <span>Date</span>
                    </div>
                  )}
                  {menu}
                  {props.showCreatePool && baseTokenSelect && quoteTokenSelect && (
                    <>
                      <Divider />
                      <div className="contract-select__dropdown__create-pool">
                        <a className="syn-link" onClick={handleCreatePoolOk}>
                          <PlusOutlined /> Create Pool
                        </a>
                      </div>
                    </>
                  )}
                </div>
              )}>
              {maturitySelectorOptionList}
            </Select>
          </div>
        </div>
      </Card>

      {addCurrencyModalStatus ? (
        <ContractSelectorModal
          field={Field.BASE}
          isOpen={addCurrencyModalStatus}
          onTokenSelect={handleInputSelect}
          handleContractSelectorModalVisible={showAddCurrencyModal}
        />
      ) : null}
    </div>
  );
}
