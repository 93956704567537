import { createAction } from '@reduxjs/toolkit';

export enum Field {
  BLOCK_HEIGHT = 'BLOCK_HEIGHT',
}

// 设置当前块高
export const updateCurrentBlockHeight = createAction<{ height: number; timestamp: number }>(
  'diff/updateCurrentBlockHeight',
);
