import { TRADE_DIRECTION } from '@/constants';
import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import { resetState, changeInputAmount, changePrice, changeSide, changeDepositMargin } from './actions';

export type TradeState = {
  readonly tradeSide: TRADE_DIRECTION;
  readonly tradeAmount: number;
  readonly tradePrice: number;
  readonly initialMargin: number;
  /**
   * 充值输入的margin数值
   */
  readonly depositMargin: string;
};

const initialState: TradeState = {
  tradeSide: TRADE_DIRECTION.LONG,
  tradeAmount: 0,
  tradePrice: 0,
  initialMargin: 0,
  depositMargin: '0',
};

export default createReducer<TradeState>(initialState, (builder) =>
  builder
    .addCase(resetState, () => initialState)
    .addCase(changeSide, (state, { payload: { side } }) => {
      state.tradeSide = side;
    })
    .addCase(changeInputAmount, (state, { payload: { inputAmount } }) => {
      state.tradeAmount = inputAmount;
    })
    .addCase(changePrice, (state, { payload: { price, initialMargin } }) => {
      state.tradePrice = price;
      state.initialMargin = initialMargin;
    })
    .addCase(changeDepositMargin, (state, { payload: { margin } }) => {
      state.depositMargin = margin;
    }),
);
