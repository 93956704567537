import { ICoinList } from '@/interfaces/coin';
import {
  ZERO_ADDRESS,
  DIFF_BASE_NAME,
  USD_KEEP_DECIMALS_PLACES,
  BTC_KEEP_DECIMALS_PLACES,
  ETH_KEEP_DECIMALS_PLACES,
} from '@/constants/config';

import { PRODUCT_TYPE, AVAILABLE_PRODUCTS } from '@/constants/product';

const allCoins: ICoinList = {
  BTC: {
    symbol: 'BTC',
    fullName: 'Bitcoin',
    address: '',
    decimals: 8,
    keepDecimals: BTC_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_btc.svg'),
  },
  XAU: {
    symbol: 'XAU',
    fullName: 'Gold',
    address: '',
    decimals: 8, // TODO：目前没找到对应decimals，暂用8
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_xau.svg'),
  },
  ETH: {
    symbol: 'ETH',
    fullName: 'Ethereum',
    address: ZERO_ADDRESS,
    decimals: 18,
    keepDecimals: ETH_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_eth.svg'),
  },
  LINK: {
    symbol: 'LINK',
    fullName: 'Chainlink',
    address: '0x935D0754b1707ab589F0ef26c977ceD8fD729D92',
    decimals: 18,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_chainlink.svg'),
  },
  UNI: {
    symbol: 'UNI',
    fullName: 'Uniswap',
    address: '0x7c524EC8848e64D3DD884052FDff85C77Fd504AD',
    decimals: 18,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_uniswap.svg'),
  },
  AAVE: {
    symbol: 'AAVE',
    fullName: 'Aave',
    address: '0xd1ab3E5EA14e8C6ef45B4B7f2D5Eed1c5b77b112',
    decimals: 18,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_aave.svg'),
  },
  COMP: {
    symbol: 'COMP',
    fullName: 'Compound',
    address: '0xE921dFD5dE4F1B6055040c982af237B529fc0b5F',
    decimals: 18,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_comp.svg'),
  },
  USDC: {
    symbol: 'USDC',
    fullName: 'USD Coin',
    address: '0x51D102A0203999110BC13B1ddf1c78566C02040A',
    decimals: 6,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_usdc.svg'),
  },
  USDT: {
    symbol: 'USDT',
    fullName: 'Tether',
    address: '0x2568d37520C08Ecc772f5feb9705e7b8Ca248535',
    decimals: 6,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_usdt.svg'),
  },
  DAI: {
    symbol: 'DAI',
    fullName: 'DAI',
    address: '0x815343B0FcA4f9b49c89118E66b21fBFCA51b3Eb',
    decimals: 18,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_dai.svg'),
  },
  [DIFF_BASE_NAME]: {
    symbol: DIFF_BASE_NAME,
    fullName: 'Bitcoin Hash Rate',
    address: '',
    decimals: 8,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_mining.svg'),
  },
  SFT: {
    symbol: 'SFT',
    fullName: 'SynFutures',
    address: '',
    decimals: 18,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_sf.svg'),
  },
  WBTC: {
    symbol: 'WBTC',
    fullName: 'Wrapped Bitcoin',
    address: '0xd19eB5103b2238f5738411D367F528A5340ED943',
    decimals: 8,
    keepDecimals: BTC_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_wbtc.svg'),
  },
};

const chainBasicCoin = allCoins['ETH'];

/**
 * 基础产品Base配置
 */
const basicBasesConfig: ICoinList = {
  BTC: allCoins['BTC'],
  XAU: allCoins['XAU'],
  ETH: allCoins['ETH'],
  LINK: allCoins['LINK'],
  UNI: allCoins['UNI'],
  AAVE: allCoins['AAVE'],
  COMP: allCoins['COMP'],
};

/**
 * 基础产品Quote配置
 */
const basicQuotesConfig: ICoinList = {
  ETH: allCoins['ETH'],
  USDC: allCoins['USDC'],
  USDT: allCoins['USDT'],
  DAI: allCoins['DAI'],
};

/**
 * 难度产品Base配置
 */
const synFuturesBasesConfig: ICoinList = {
  [DIFF_BASE_NAME]: allCoins[DIFF_BASE_NAME],
  SFT: allCoins['SFT'],
};

/**
 * 难度产品Quote配置
 */
const synFuturesQuotesConfig: ICoinList = {
  WBTC: allCoins['WBTC'],
};

/**
 * 基础产品币种配置
 */
const basicCoinListConfig: ICoinList = {
  ...basicBasesConfig,
  ...basicQuotesConfig,
};

/**
 * 难度产品币种配置
 */
const synFuturesCoinListConfig: ICoinList = {
  ...synFuturesBasesConfig,
  ...synFuturesQuotesConfig,
};

/**
 * 币种配置
 */
const coinListConfig: ICoinList = {
  ...basicCoinListConfig,
  ...synFuturesCoinListConfig,
};

/**
 * bases币种配置
 */
const basesCoinConfig: ICoinList = AVAILABLE_PRODUCTS.reduce((config, productType) => {
  if (productType === PRODUCT_TYPE.DIFFICULTY) {
    config = { ...config, ...synFuturesBasesConfig };
  } else if (productType === PRODUCT_TYPE.BASIC) {
    config = { ...config, ...basicBasesConfig };
  }
  return config;
}, {} as ICoinList);

/**
 * quote币种配置（如 my balance币种列表）
 */
const quotesCoinConfig: ICoinList = AVAILABLE_PRODUCTS.reduce((config, productType) => {
  if (productType === PRODUCT_TYPE.DIFFICULTY) {
    config = { ...config, ...synFuturesQuotesConfig };
  } else if (productType === PRODUCT_TYPE.BASIC) {
    config = { ...config, ...basicQuotesConfig };
  }
  return config;
}, {} as ICoinList);

export { coinListConfig, basesCoinConfig, quotesCoinConfig, chainBasicCoin };
