import React, { ReactNode, useState, useEffect, useMemo, useCallback } from 'react';
import { Spin, Collapse, Space, Slider, Modal, Button, Tooltip } from 'antd';
import { LoadingOutlined, SwapRightOutlined, QuestionCircleFilled } from '@ant-design/icons';

const { Panel } = Collapse;

import { useWeb3React } from '@web3-react/core';
import { useTranslation } from 'react-i18next';
import ContractTickerSelector from '@/components/ContractTickerSelector';
import { txNotification } from '@/components/TxNotification';
import formatNumberPrefixTooltip from '@/components/Common/formatNumberPrefixTooltip';

import { RedirectFromEnum } from '@/state/options/actions';
import { useUIGlobalConfigs } from '@/state/swap/hooks';
import { IAmmDetail } from '@/interfaces/amm';
import { AMM_STATUS } from '@/constants/config';
import { IPosition } from '@/interfaces/position';
import { synWeb3, synDiffWeb3 } from '@/synWeb3';
import {
  currentPositionDefault,
  anticipatePositionDefault,
  trialBalanceReduceLiquidity,
  IPositionDetail,
} from '@/utils/trialBalancePosition';
import { useGlobalGetter } from '@/state/global/hooks';
import { ITransactionError } from '@/interfaces/error';
import { isDiffProductSymbol, getBaseQuoteBySymbol } from '@/utils';
import { getDiffNumByPrice } from '@/utils/common';
import { usePositionHook } from '@/state/position/hooks';
import { PRODUCT_TYPE } from '@/constants/product';
import { formatNumber, negativeNumberColor, safeNumberFormat, formatNumberTransition } from '@/utils/numberUtil';
import { PARSE_RECEIPT_ERROR, SYNFUTURES_DOCS_LINK } from '@/constants';

import { GaCategory, gaEvent, gaException } from '@/utils/gaUtil';
import BigNumber from 'bignumber.js';
import DoneIcon from '@/assets/svg/icons/icon_done_16.svg';

import marks from './marks';
import './style.scss';

const accountDetailDefault: IPosition = {
  id: '',
  symbol: '',
  position: '0',
  entryPrice: '0',
  markPrice: '0',
  accountBalance: '0',
  unrealPnl: '0',
  liqPrice: '0',
  positionMargin: '0',
  mainMargin: '0',
  availableMargin: '0',
};

const ammDetailDefault: IAmmDetail = {
  id: '',
  ammProxy: '',
  symbol: '',
  status: AMM_STATUS.TRADING,
  spotIndexPrice: '0',
  shareTotalSupply: '0',
  shareBalance: '0',
  oracle: '0',
  midPrice: '0',
  maturity: '0',
  ammPosition: '0',
  ammMarginBalance: '0',
};

let ammProxy: string | undefined | null = '';
let futuresProxy: string | undefined | null = '';

// setProgressStep
enum IProgressStep {
  LOADING = 0,
  LOADED = 1,
  REMOVING = 2,
  DONE = 3,
}

export interface IRemoveLiquidityProps {
  visible: boolean;
  setVisible: any;
  dataInfo: IPosition;
}

const RemoveLiquidity = (props: IRemoveLiquidityProps) => {
  const { t } = useTranslation();
  const { active, account } = useWeb3React();
  const traderAddress = account || '';
  const { onFetchPositionOnChainBySymbol } = usePositionHook();

  const { globalConfigs } = useGlobalGetter();
  const { uiGlobalConfig } = useUIGlobalConfigs();
  const [currentPosition, setCurrentPosition] = useState(currentPositionDefault);
  const [anticipatePosition, setAnticipatePosition] = useState(anticipatePositionDefault);

  // simulation 是否展开状态
  const defaultCollapseDispay = false;
  const [collapseDispay, setCollapseDispay] = useState<boolean>(defaultCollapseDispay);

  const [percent, setPercent] = useState(0); //移除仓位百分比
  const [quantity, setQuantity] = useState('0'); //解决parseFloat js精度不足的问题
  const [onprocess, setOnprocess] = useState(false);
  const [visible, setVisible] = useState(props.visible);
  const [accountDetail, setAccountDetail] = useState(accountDetailDefault);
  const [ammDetail, setAmmDetail] = useState(ammDetailDefault);
  const [progressStep, setProgressStep] = useState<IProgressStep>(IProgressStep.LOADING);
  // const [allowRemoveAll, setAllowRemoveAll] = useState(false); //是否允许100%移除仓位
  const [isDiffProduct, setIsDiffProduct] = useState<boolean>(false);
  // 当前web3
  const currentWeb3 = useMemo(() => {
    return isDiffProduct ? synDiffWeb3 : synWeb3;
  }, [isDiffProduct]);

  const marginDifference = useMemo(() => {
    const diff = Number(anticipatePosition.margin) - Number(currentPosition.margin);
    const symbol = props.dataInfo.symbol ? props.dataInfo.symbol.split('-')[1] : '';
    return {
      diff: diff > 0 ? diff : 0,
      symbol: symbol || '',
    };
  }, [anticipatePosition.margin, currentPosition.margin, props.dataInfo.symbol]);

  // const deadline =   || 300; //globalConfig 读取

  useCallback(() => setVisible(props.visible), [visible]);

  async function asyncAccountInfo(futuresProxy: string): Promise<void> {
    if (account) {
      isDiffProduct;
      const [accountInfo, err] = await currentWeb3.account.getAccountDetail(account, futuresProxy);
      if (err) {
        console.log(`err:`, err);
        return;
      }
      accountInfo && setAccountDetail(accountInfo);
    }
  }

  const onChangeStepHandler = (value: number): void => {
    console.log(`onChangeStep# value:${value}  amm:`, ammDetail);
    const shareBalance = ammDetail.shareBalance; //ammDetail
    //  ((value * parseFloat(shareBalance)) / 100);
    const removeAmount =
      value === 100
        ? shareBalance
        : new BigNumber(value)
            .times(shareBalance)
            .div(100)
            .toString(10);
    console.log(`onChangeStep# value:${value} shareBalance:${shareBalance} removeAmount:${removeAmount}  `);
    setPercent(value);
    setQuantity(removeAmount);
  };

  useEffect(() => {
    if (props && props.dataInfo) {
      if (progressStep !== IProgressStep.DONE) {
        const ammDetail = props.dataInfo.pair?.ammDetail;
        ammProxy = props.dataInfo.pair?.ammProxy;
        futuresProxy = props.dataInfo.pair?.futuresProxy;

        if (futuresProxy && ammDetail) {
          setIsDiffProduct(isDiffProductSymbol(props.dataInfo.symbol));
          setAmmDetail(ammDetail);
          asyncAccountInfo(futuresProxy);
          // 当用户是池子唯一LP时最大移除95%-2021/03/09 显示 0%-100%
          // props.dataInfo.shareBalance === props.dataInfo.shareTotalSupply ? setMaxPercent(95) : setMaxPercent(100);
        }
        setProgressStep(IProgressStep.LOADED);
      }
    }
  }, [props.dataInfo]);

  useMemo(() => {
    const globalConfig = isDiffProduct ? globalConfigs[PRODUCT_TYPE.DIFFICULTY] : globalConfigs[PRODUCT_TYPE.BASIC];
    // dX = dY * price (如果还未初始化，用initial price，否则用mid price)
    // 用户会收到的LP token数量为 dY / Y * Total Share, Y为AMM当前仓位
    const price = parseFloat(ammDetail.midPrice);
    // const ammDetail = props.dataInfo;
    if (progressStep !== IProgressStep.DONE) {
      const [currentPosition, anticipatePosition] = trialBalanceReduceLiquidity(
        globalConfig,
        accountDetail,
        ammDetail,
        quantity,
        price,
      );
      setCurrentPosition(currentPosition as IPositionDetail);
      setAnticipatePosition(anticipatePosition as IPositionDetail);
    }
  }, [accountDetail, ammDetail, globalConfigs, isDiffProduct, progressStep, quantity]);

  const initializedReset = (): void => {
    setPercent(0);
    setQuantity('0');
    setProgressStep(IProgressStep.LOADING);
    setOnprocess(false);
    console.log(` RemoveLiquidity---initializedReset() DONE `);
  };

  function onClickCancelHandler(): void {
    gaEvent(GaCategory.POOL, 'Cancel Remove liquidity');
    initializedReset();
    props.setVisible(false);
  }

  async function comboRemoveLiquidity(): Promise<void> {
    console.group(`comboRemoveLiquidity`);
    setOnprocess(true);
    setProgressStep(IProgressStep.REMOVING);

    const ammProxy = props.dataInfo.pair?.ammProxy || '';
    const futuresProxy = props.dataInfo.pair?.futuresProxy || '';
    const contractSymbol = ammDetail.symbol;
    const deadline = uiGlobalConfig.deadline || 300;

    try {
      console.log(`\n\n\n --> RemoveLiquidity---quantity:${quantity}() DONE `);

      // ### ReactGAEvent ###
      const removeLiquidityParams = {
        method: 'currentWeb3.amm.removeLiquidity',
        contractSymbol,
        ammProxy,
        futuresProxy,
        quantity,
        traderAddress,
        deadline,
      };
      console.log(`GoogleAnalytics:${JSON.stringify(removeLiquidityParams)}`);
      gaEvent(GaCategory.DEBUG, 'RemoveLiquidity', JSON.stringify(removeLiquidityParams));
      // ### ReactGAEvent - END ###

      const removeLiquidityResult = await currentWeb3.amm.removeLiquidity(
        ammProxy,
        traderAddress,
        safeNumberFormat(quantity),
        deadline,
        function() {
          txNotification.info({
            message: t('notification.removeLiquidity.title', { contractSymbol: contractSymbol }),
            description: t('notification.removeLiquidity.info'),
          });
        },
      );

      // const shareBalance = ammDetail?.shareBalance;
      if (removeLiquidityResult.status) {
        // update redux data
        updateMyPoolDetailsListActionHandler(contractSymbol, traderAddress);

        setProgressStep(IProgressStep.DONE);

        try {
          const removeLiquidityInfo = currentWeb3.reader.parseReceipt(removeLiquidityResult);
          const removeLiquidityFuturesInfo = currentWeb3.futures.parseReceipt(removeLiquidityResult);

          if (removeLiquidityInfo === PARSE_RECEIPT_ERROR || removeLiquidityFuturesInfo === PARSE_RECEIPT_ERROR) {
            console.log(`PARSE_RECEIPT_ERROR`);
            throw new Error(PARSE_RECEIPT_ERROR);
          }

          console.log(`\n\n\n 🍉 isDiffProduct:::`, isDiffProduct);
          console.log(`\n removeLiquidityInfo:::`, removeLiquidityInfo);
          console.log(`\n removeLiquidityFuturesInfo:::`, removeLiquidityFuturesInfo); // Trade:

          showRemoveLiquiditySuccessNotification(
            contractSymbol,
            ammDetail as IAmmDetail,
            removeLiquidityInfo,
            removeLiquidityFuturesInfo,
            removeLiquidityResult.transactionHash,
          );
        } catch (error) {
          gaException(error.message);
          txNotification.success({
            message: t('notification.removeLiquidity.title', { contractSymbol: contractSymbol }),
            description: t('notification.removeLiquidity.default'),
            tx: removeLiquidityResult.transactionHash,
          });
        }
      }
    } catch (error) {
      gaException(error.message);
      setOnprocess(false);
      setProgressStep(IProgressStep.LOADED);

      if (error && error.code === 4001) {
        console.log(`USER REJECT:`, error);
        return;
      }

      const err = error as ITransactionError;
      let errDescription: ReactNode = t('notification.removeLiquidity.error');
      if (err.notifyWrap) {
        errDescription = err.notifyWrap(errDescription);
      }
      txNotification.error({
        duration: null,
        message: t('notification.removeLiquidity.title', { contractSymbol: contractSymbol }),
        description: errDescription,
        tx: err.receipt && err.receipt.transactionHash,
      });
    }
    console.groupEnd();
  }

  // 更新redux数据列表

  /**
   * 更新 Redux数据 -> updateMyPoolDetailsList
   * @param ammProxy Amm( )合约地址
   * @param futuresProxy Futures( )合约地址
   */
  const updateMyPoolDetailsListActionHandler = async (contractSymbol: string, traderAddress: string) => {
    onFetchPositionOnChainBySymbol(traderAddress, contractSymbol);
  };

  const showRemoveLiquiditySuccessNotification = (
    contractSymbol: string,
    ammDetail: IAmmDetail,
    removeLiquidityInfo: {
      Transfer: { _value: string };
    },
    removeLiquidityFuturesInfo: {
      TransferBalance: { wadAmount: string };
      Trade: { size: string; price: string };
    },
    tx?: string,
  ): void => {
    const quoteToken = ammDetail?.symbol.split('-')[1];

    const burntValue = isDiffProduct
      ? formatNumberTransition(currentWeb3.utils.fromWei(removeLiquidityFuturesInfo.Trade.size))
      : formatNumberTransition(currentWeb3.utils.fromWei(removeLiquidityInfo.Transfer._value));
    const quoteQuantity = formatNumberTransition(
      currentWeb3.utils.fromWei(removeLiquidityFuturesInfo.TransferBalance.wadAmount),
    );
    const hedgeSize = formatNumberTransition(currentWeb3.utils.fromWei(removeLiquidityFuturesInfo.Trade.size));
    const hedgePrice = formatNumberTransition(currentWeb3.utils.fromWei(removeLiquidityFuturesInfo.Trade.price));

    console.log(`\n contractSymbol:::`, contractSymbol, `quoteToken:`, quoteToken, `burntValue:`, burntValue);

    const notificationStr = t('notification.removeLiquidity.success', {
      quoteQuantity: quoteQuantity,
      quoteToken: quoteToken,
      burntValue: burntValue,
      hedgeSize: hedgeSize,
      hedgePrice: hedgePrice,
    });
    const descriptionNode: ReactNode = (
      <div>
        <span dangerouslySetInnerHTML={{ __html: notificationStr }}></span>
      </div>
    );

    txNotification.success({
      message: t('notification.removeLiquidity.title', { contractSymbol: contractSymbol }),
      description: descriptionNode,
      tx: tx,
    });
  };

  const onClickRemoveLiquidityHandler = (): void => {
    gaEvent(GaCategory.POOL, 'Remove liquidity');
    if (active) {
      if (percent === 0) {
        setProgressStep(IProgressStep.LOADED);
        txNotification.warning({
          message: t('notification.removeLiquidity.title', { contractSymbol: ammDetail.symbol }),
          description: t('notification.removeLiquidity.noPercentag'),
        });
      } else {
        setProgressStep(IProgressStep.LOADED);
        comboRemoveLiquidity();
      }
    } else {
      setProgressStep(IProgressStep.LOADED);
      txNotification.warning({
        message: t('notification.wallet.title'), // 'Warning',
        description: t('notification.wallet.noConnection'), // 'Please connect your wallet',
      });
    }
  };

  // descFolderHandler This panel can only be collapsed by clicking text
  const descFolderHandler = (): ReactNode => {
    return collapseDispay ? <span>↑ LESS ↑</span> : <span>↓ MORE ↓</span>;
  };

  // 切换 simulation show more 状态
  const onChangeSimulationMoreHandler = (): void => {
    setCollapseDispay(!collapseDispay);
  };

  return (
    <React.Fragment>
      <Modal
        className="pool-card-modal remove-liquidity-modal liquidity-modal"
        maskClosable={false}
        visible={props.visible}
        onOk={(): void => onClickCancelHandler()}
        onCancel={(): void => onClickCancelHandler()}
        footer={null}
        destroyOnClose
        style={{ width: 456 }}>
        <div className="modal-title">Remove Liquidity from Pool</div>

        <div className="contract-selector">
          <ContractTickerSelector targetFrom={RedirectFromEnum.POOL_LIST_RECORD} dataInfo={props.dataInfo} />
        </div>

        <Spin
          className={progressStep >= 3 ? 'process-done' : ''}
          tip={progressStep >= 3 ? ' ' : ' '}
          spinning={onprocess || progressStep === 3}>
          <div className="modal-quantity-wrap">
            Shares:
            <div className="slider-remove-number">{formatNumberPrefixTooltip(quantity)}</div>
          </div>

          <div className="modal-slider-wrap">
            <div className="slider-box-wrap">
              <div className="slider-box">
                <Slider
                  style={{ marginTop: 18, marginLeft: 16, marginRight: 16, width: '100%' }}
                  className="pool-slider"
                  tooltipVisible={false}
                  tooltipPlacement="top"
                  min={0}
                  marks={marks(21, 5, 5, '%')}
                  max={100}
                  step={5}
                  onChange={onChangeStepHandler}
                />
              </div>
            </div>
          </div>
        </Spin>

        <div className="modal-tx-tips">
          Transaction Detail:
          <Tooltip
            placement="right"
            className="accountPositionTablePopover"
            title={`You will be removing ${formatNumberTransition(anticipatePosition.shareDelta || 0)} synthetic ${
              getBaseQuoteBySymbol(ammDetail.symbol)[0]
            } (1x Long) and ${formatNumberTransition(anticipatePosition.valueDelta || 0)} ${
              getBaseQuoteBySymbol(ammDetail.symbol)[1]
            } position from the pool. Meanwhile you will
              be closing your initial hedge (short) transaction for your synthetic ${
                getBaseQuoteBySymbol(ammDetail.symbol)[0]
              } position.`}>
            <QuestionCircleFilled className="tooltip-info-icon" style={{ height: 12, marginTop: 3 }} />
          </Tooltip>
          <a className="tx-link syn-link" target="_blank" rel="noreferrer noopener" href={SYNFUTURES_DOCS_LINK}>
            Learn more on how it works &gt;
          </a>
        </div>

        {progressStep <= 1 ? (
          <React.Fragment>
            <div className="modal-button-wrap">
              <Button className="button-wrap button-white" onClick={onClickRemoveLiquidityHandler}>
                <div className="button-inner">Remove Liquidity</div>
              </Button>
            </div>
            <div className="instruction-line">
              <b className="syn-bold-1">
                {formatNumberPrefixTooltip(marginDifference.diff)} {marginDifference.symbol}
              </b>{' '}
              will be moved to your account to maintain your position
            </div>
          </React.Fragment>
        ) : (
          <></>
        )}

        {progressStep === 2 ? (
          <div className="modal-button-wrap">
            <Button className="button-wrap button-white" disabled>
              <div className="button-inner">
                <LoadingOutlined style={{ marginRight: 8 }} />
                Remove Liquidity
              </div>
            </Button>
          </div>
        ) : (
          <></>
        )}

        {progressStep === 3 ? (
          <div className="modal-button-wrap">
            <Button className="button-wrap button-white" onClick={onClickCancelHandler}>
              <div className="button-inner">
                <img style={{ marginRight: 8 }} src={DoneIcon} />
                Done
              </div>
            </Button>
          </div>
        ) : (
          <></>
        )}
        <div className={collapseDispay ? 'pool-modal-desc' : 'pool-modal-desc pool-modal-fold'}>
          <div className="pool-modal-desc-head">
            <div className="desc-head-empty">SynFutures</div>
            <div className="desc-head-content">
              <div className="desc-head-current"> Current</div>
              <div className="desc-head-after"> After</div>
            </div>
          </div>

          <div className="pool-modal-desc-list">
            <div className={collapseDispay ? 'desc-list-title' : 'desc-list-title desc-list-fold'}>
              <div className="desc-item-title">Fair Price</div>
              {isDiffProduct && <div className="desc-item-title">Fair Difficulty</div>}
              <div className="desc-item-title">Position</div>
            </div>

            <div className="desc-list-content">
              <div className="desc-list-left">
                <div className="desc-item-current">{formatNumberPrefixTooltip(ammDetail.midPrice)}</div>
                {isDiffProduct && <div className="desc-item-current">{getDiffNumByPrice(ammDetail.midPrice)}</div>}
                <div style={{ color: negativeNumberColor(currentPosition.position) }} className="desc-item-current">
                  {formatNumberPrefixTooltip(currentPosition.position)}
                </div>
              </div>
              <div className="desc-list-middle">
                <div className="desc-list-arrows">
                  {collapseDispay ? null : <SwapRightOutlined className="desc-list-arrows-icon" />}
                </div>
              </div>
              <div className="desc-list-right">
                <div className="desc-item-after">{formatNumberPrefixTooltip(ammDetail.midPrice)}</div>
                {isDiffProduct && <div className="desc-item-after">{getDiffNumByPrice(ammDetail.midPrice)}</div>}
                <div style={{ color: negativeNumberColor(anticipatePosition.position) }} className="desc-item-after">
                  {formatNumberPrefixTooltip(anticipatePosition.position)}
                </div>
              </div>
            </div>
          </div>

          {/* 折叠面板 */}
          <Space className="desc-folder" direction="vertical">
            <Collapse
              className="desc-folder-wrap"
              collapsible="header"
              onChange={(): void => {
                onChangeSimulationMoreHandler();
              }}
              defaultActiveKey={['']}
              ghost>
              <Panel showArrow={false} header={descFolderHandler()} key={'showMore'}>
                <div className="pool-modal-desc-list">
                  <div className="desc-list-title">
                    <div className="desc-item-title">Margin</div>
                    <div className="desc-item-title">Init. Margin</div>
                    <div className="desc-item-title">Main. Margin</div>
                    <div className="desc-item-title">Liq. Price</div>
                    {isDiffProduct && <div className="desc-item-title">Liq. Difficulty</div>}
                    <div className="desc-item-title">Pool Share</div>
                    <div className="desc-item-title">Share Ratio</div>
                  </div>
                  <div className="desc-list-content">
                    <div className="desc-list-left">
                      <div className="desc-item-current">{formatNumberPrefixTooltip(currentPosition.margin)}</div>
                      <div className="desc-item-current">{formatNumberPrefixTooltip(currentPosition.initMargin)}</div>
                      <div className="desc-item-current">{formatNumberPrefixTooltip(currentPosition.mainMargin)}</div>
                      <div className="desc-item-current">{formatNumberPrefixTooltip(currentPosition.liqPrice)}</div>
                      {isDiffProduct && (
                        <div className="desc-item-current">{getDiffNumByPrice(currentPosition.liqPrice)}</div>
                      )}
                      <div className="desc-item-current">
                        {formatNumberPrefixTooltip(currentPosition.poolShareAmount)}
                      </div>
                      <div className="desc-item-current">{formatNumber(currentPosition.poolShare, 2)}%</div>
                    </div>
                    <div className="desc-list-middle">
                      <div className="desc-list-arrows">
                        {collapseDispay ? <SwapRightOutlined className="desc-list-arrows-icon" /> : null}
                      </div>
                    </div>
                    <div className="desc-list-right">
                      <div className="desc-item-after">{formatNumberPrefixTooltip(anticipatePosition.margin)}</div>
                      <div className="desc-item-after">{formatNumberPrefixTooltip(anticipatePosition.initMargin)}</div>
                      <div className="desc-item-after">{formatNumberPrefixTooltip(anticipatePosition.mainMargin)}</div>
                      <div className="desc-item-after">{formatNumberPrefixTooltip(anticipatePosition.liqPrice)}</div>
                      {isDiffProduct && (
                        <div className="desc-item-after">{getDiffNumByPrice(anticipatePosition.liqPrice)}</div>
                      )}
                      <div className="desc-item-after">
                        {formatNumberPrefixTooltip(anticipatePosition.poolShareAmount)}
                      </div>
                      <div className="desc-item-after">{formatNumber(anticipatePosition.poolShare, 2)}%</div>
                    </div>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </Space>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default RemoveLiquidity;
