/**
 * 以太坊（Ether）交易网络类型
 */
export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42,
  BSC = 56,
}

/**
 * 当前环境配置的ChainId
 */
export const appChainId: ChainId = parseInt(process.env.REACT_APP_CHAIN_ID || '1') as ChainId;
