import React, { ReactNode, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { Button, Input, Popover } from 'antd';
import { Link } from 'react-router-dom';
import CoinIcon from '@/components/CoinIcon';
import { QuestionCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { useTranslation } from 'react-i18next';
import { txNotification } from '@/components/TxNotification';
import { Field } from '@/state/swap/actions';
import { useTradeMarity, useTradeToken } from '@/state/swap/hooks';
import { useGlobalGetter } from '@/state/global/hooks';
import { inputNumChecker, formatInputNumber, formatNumberTransition } from '@/utils/numberUtil';
import { usePairGetter, useCoinGetter } from '@/state/pairs/hooks';
import usePrevious from '@/hooks/usePrevious';
import { usePositionHook } from '@/state/position';
import { ITransactionError } from '@/interfaces/error';
import { PARSE_RECEIPT_ERROR } from '@/constants';
import { isDiffProductSymbol } from '@/utils';
import { AMM_STATUS } from '@/constants/config';
import { chainConfig } from '@/constants/chain';
import { useTradeHook } from '@/state/trade';
import { useBalanceHook } from '@/state/balance';
import { ICoinBalance } from '@/interfaces/balance';
import { gaException } from '@/utils/gaUtil';

import CurrentBalanceTitle, { BalanceTitleType } from '@/components/CurrentBalanceTitle';
import formatNumberPrefixTooltip from '@/components/Common/formatNumberPrefixTooltip';

import DepositButtonIcon from '@/assets/svg/icons/icon_deposit_16.svg';

const BalanceDeposit = (): JSX.Element => {
  const { t } = useTranslation();
  const [tokenInputQuantity, handleTokenInputQuantity] = useState('');
  const [isDepositing, handleIsDepositing] = useState(false); // deposit 中
  const { currentPair } = usePairGetter();
  const { coinListConfig } = useCoinGetter();
  const { isCorrectChain, currentWeb3 } = useGlobalGetter();
  const { account } = useWeb3React();
  const traderAddress = useMemo(() => {
    return account || '';
  }, [account]);
  const prevProxy = usePrevious({ traderAddress, isCorrectChain });

  const { marity } = useTradeMarity();
  const { onFetchCurrentPosition, currentPosition, currentAmmDetail } = usePositionHook();
  const { calcAdditionalMargin, onChangeDepositMargin, depositMargin } = useTradeHook();
  const { onFetchTokenBalance, onUpdateTokenBalance, balanceList } = useBalanceHook();

  const { currencies } = useTradeToken();
  const quoteDefaultValue = currencies[Field.QUOTE] || '';
  const baseDefaultValue = currencies[Field.BASE] || '';
  const marityTime = marity.MARITY;

  const isDiffProduct = useMemo(() => {
    if (currentPosition.symbol) {
      return isDiffProductSymbol(currentPosition.symbol);
    }
    return false;
  }, [currentPosition.symbol]);

  const coinBalanceInfo = useMemo(() => {
    if (quoteDefaultValue) {
      return balanceList.find((coin) => coin.symbol === quoteDefaultValue);
    }
    return null;
  }, [balanceList, quoteDefaultValue]);

  // 重新初始化数据
  const reInitDatas = useCallback(() => {
    handleIsDepositing(false);
    handleTokenInputQuantity('');
  }, []);

  // 切换账户重新获取数据
  useEffect(() => {
    if (traderAddress) {
      reInitDatas();
    }
  }, [reInitDatas, traderAddress]);

  // 切换pair,先清空数据
  useEffect(() => {
    reInitDatas();
  }, [currentPair, quoteDefaultValue, baseDefaultValue, reInitDatas]);

  // 点击Additional Margin，填入数值
  useEffect(() => {
    handleTokenInputQuantity(depositMargin);
  }, [depositMargin]);

  // 计算 quoteToken-balance
  useEffect(() => {
    (async (): Promise<void> => {
      if (quoteDefaultValue) {
        onFetchTokenBalance(traderAddress, coinListConfig[quoteDefaultValue] as ICoinBalance);
      }
    })();
  }, [coinListConfig, onFetchTokenBalance, quoteDefaultValue, traderAddress]);

  // 点击position填入数值
  const onClickWalletBalance = useCallback((balance: number | string, unit: string) => {
    handleTokenInputQuantity(new BigNumber(balance).abs().toString(10));
  }, []);

  const showDepositNotification = useCallback(
    (
      contractSymbol: string,
      quoteToken: string,
      depositResult: {
        transactionHash: string;
      },
      depositResultInfo: {
        Deposit: {
          wadAmount: string;
        };
      },
    ): void => {
      const wadAmountNumber = currentWeb3.utils.fromWei(depositResultInfo.Deposit.wadAmount);
      const wadAmount = formatNumberTransition(wadAmountNumber);
      const notificationStr = t('notification.deposit.success', {
        wadAmount: wadAmount,
        quoteToken: quoteToken,
      });
      const descriptionNode: ReactNode = (
        <div>
          <span dangerouslySetInnerHTML={{ __html: notificationStr }}></span>
        </div>
      );
      txNotification.success({
        message: t('notification.deposit.title', { contractSymbol: contractSymbol }),
        description: descriptionNode,
        tx: depositResult.transactionHash,
      });
    },
    [currentWeb3.utils, t],
  );

  const depositActionHandler = useCallback(
    async (contractSymbol: string) => {
      try {
        if (!currentPosition.pair || Number(tokenInputQuantity) <= 0) {
          return;
        }

        const depositResult = await currentWeb3.futures.deposit(
          coinListConfig[quoteDefaultValue].address,
          currentPosition.pair.futuresProxy,
          traderAddress,
          tokenInputQuantity,
          () => {
            txNotification.info({
              message: t('notification.deposit.title', {
                contractSymbol: contractSymbol,
              }), // 'Margin',
              description: t('notification.deposit.info'), // 'Deposit tx sent, waiting for confirmation...',
            });
          },
        );
        const quoteToken = currentPosition.symbol.split('-')[1];

        if (depositResult.status) {
          onFetchCurrentPosition(traderAddress, currentWeb3);
          handleTokenInputQuantity(''); // Deposit成功后清空输入框 SFT-765
          handleIsDepositing(false);

          // deposite 完成后更新页面中 WalletBalance 余额显示
          // 仅更新UI, 暂无必要重新链上查询余额
          // deposite 成功之后， Update WalletBalance 显示。
          if (coinBalanceInfo) {
            onUpdateTokenBalance(
              coinBalanceInfo,
              new BigNumber(coinBalanceInfo.walletBalance).minus(tokenInputQuantity).toString(10),
            );
          }

          try {
            const depositResultInfo = currentWeb3.futures.parseReceipt(depositResult);

            if (depositResultInfo === PARSE_RECEIPT_ERROR) {
              console.log(`PARSE_RECEIPT_ERROR`);
              throw new Error(PARSE_RECEIPT_ERROR);
            }

            // show deposit success txNotification
            showDepositNotification(contractSymbol, quoteToken, depositResult, depositResultInfo);
          } catch (error) {
            gaException(error.message);
            txNotification.success({
              message: t('notification.deposit.title', { contractSymbol: contractSymbol }),
              description: t('notification.deposit.default'),
              tx: depositResult.transactionHash,
            });
          }
        } else {
          // Deposited ${1} ${quote token} into ${contract symbol}
          txNotification.error({
            message: t('notification.deposit.title', { contractSymbol: contractSymbol }),
            description: t('notification.deposit.error'),
            tx: depositResult.transactionHash,
          });
        }
      } catch (error) {
        gaException(error.message);
        handleIsDepositing(false);
        if (error && error.code === 4001) {
          console.log(`USER REJECT`);
          return;
        }
        // Deposit transfer amount exceeded balance
        const err = error as ITransactionError;
        let errDescription: ReactNode = t('notification.deposit.error', {
          contractSymbol: contractSymbol,
        });
        if (err.notifyWrap) {
          errDescription = err.notifyWrap(errDescription);
        }

        txNotification.error({
          duration: null,
          message: t('notification.deposit.title', { contractSymbol: contractSymbol }),
          description: errDescription,
          tx: err.receipt && err.receipt.transactionHash,
        });
      }
    },
    [
      coinBalanceInfo,
      coinListConfig,
      currentPosition.pair,
      currentPosition.symbol,
      currentWeb3,
      onFetchCurrentPosition,
      onUpdateTokenBalance,
      quoteDefaultValue,
      showDepositNotification,
      t,
      tokenInputQuantity,
      traderAddress,
    ],
  );

  const depositToken = useCallback(async () => {
    if (quoteDefaultValue === undefined || baseDefaultValue === undefined || marityTime === '') {
      return;
    }
    if (quoteDefaultValue !== undefined && coinListConfig[quoteDefaultValue] === null) {
      txNotification.warning({
        message: 'Warning',
        description: t('message.chooseQuote'),
      });
      return;
    }
    if (traderAddress === null || traderAddress === undefined || traderAddress === '') {
      txNotification.warning({
        message: 'Warning',
        description: t('message.connectMetamask'),
      });
      return;
    }
    if (tokenInputQuantity === null || tokenInputQuantity === undefined || tokenInputQuantity === '') {
      txNotification.warning({
        message: 'Warning',
        description: t('message.enterAmount'),
      });
      return;
    }
    const contractSymbol = currentPosition.symbol;
    if (traderAddress !== null && traderAddress !== undefined && currentPosition.pair) {
      try {
        // Approve token
        handleIsDepositing(true);

        const approveResult = await currentWeb3.futures.approve(
          account as string,
          coinListConfig[quoteDefaultValue].address,
          currentPosition.pair.futuresProxy,
          Number.MAX_SAFE_INTEGER - 1,
          true,
          function() {
            txNotification.info({
              message: t('notification.deposit.title', { contractSymbol: contractSymbol }),
              description: t('notification.deposit.approveInfo'),
            });

            depositActionHandler(contractSymbol);
          },
        );

        if (typeof approveResult === 'boolean' && approveResult === true) {
          console.log(`approveResult:`, approveResult);
          // //已经approve,无需再次approve
          depositActionHandler(contractSymbol);
        } else if (approveResult && approveResult.status) {
          console.log(`approveResult:`, approveResult, `approve Succeeded!`);

          const notificationStr = t('notification.deposit.approveSuccess', {
            quoteToken: quoteDefaultValue,
          });
          const descriptionNode: ReactNode = (
            <div>
              <span dangerouslySetInnerHTML={{ __html: notificationStr }}></span>
            </div>
          );

          txNotification.success({
            message: t('notification.deposit.title', { contractSymbol: currentPosition.symbol }),
            description: descriptionNode,
            tx: approveResult.transactionHash,
          });
        }
      } catch (error) {
        gaException(error.message);
        handleIsDepositing(false);

        if (error && error.code === 4001) {
          console.log(`USER REJECT`);
          return;
        }
        // Deposit transfer amount exceeded balance
        const err = error as ITransactionError;
        let errDescription: ReactNode = t('notification.deposit.approveError', {
          quoteToken: quoteDefaultValue,
        });
        if (err.notifyWrap) {
          errDescription = err.notifyWrap(errDescription);
        }

        txNotification.error({
          duration: null,
          message: t('notification.deposit.title', { contractSymbol: contractSymbol }),
          description: errDescription,
          tx: err.receipt && err.receipt.transactionHash,
        });
      }
    }
  }, [
    quoteDefaultValue,
    baseDefaultValue,
    marityTime,
    coinListConfig,
    traderAddress,
    tokenInputQuantity,
    currentPosition.symbol,
    currentPosition.pair,
    t,
    currentWeb3.futures,
    account,
    depositActionHandler,
  ]);

  // 可用的amm 状态
  const isNormalStatus = useMemo(() => {
    return currentAmmDetail && [AMM_STATUS.TRADING, AMM_STATUS.SETTLING].includes(currentAmmDetail.status);
  }, [currentAmmDetail]);

  const isSettleStatus = useMemo(() => {
    return currentAmmDetail && currentAmmDetail.status === AMM_STATUS.SETTLED;
  }, [currentAmmDetail]);

  const isEnableDeposit = useMemo(() => {
    return !isSettleStatus && isNormalStatus;
  }, [isNormalStatus, isSettleStatus]);

  const margin2x = useMemo(() => {
    return calcAdditionalMargin(2);
  }, [calcAdditionalMargin]);

  const BalanceMarginPopover = (
    <div className="popover-balance-margin">
      <dl>
        <dt>Available Margin</dt>
        <dd>
          {formatNumberPrefixTooltip(currentPosition.availableMargin)} {quoteDefaultValue}
        </dd>
      </dl>
      <dl>
        <dt>Margin Balance</dt>
        <dd>
          {formatNumberPrefixTooltip(currentPosition.marginBalance || 0)} {quoteDefaultValue}
        </dd>
      </dl>
    </div>
  );

  return (
    <div className={`trade-page-deposit ${isDiffProduct && 'trade-page-deposit_diff'}`}>
      <div className="trade-page-deposit-wrap ">
        <div className="trade-page-balance-header">
          <CurrentBalanceTitle
            className="trade-page-deposit-title"
            title="Wallet Balance"
            balance={coinBalanceInfo?.walletBalance || 0}
            unit={quoteDefaultValue}
            type={BalanceTitleType.BALANCE}
            onClickBalance={onClickWalletBalance}></CurrentBalanceTitle>
          <Link to="account" className="syn-link">
            Manage Margin
          </Link>
        </div>

        <div className="trade-page-deposit-container ">
          <Input
            className="deposit-input-wrap"
            defaultValue="0"
            placeholder={t('message.placeholder.liquidityQuantity')}
            disabled={!isNormalStatus}
            value={tokenInputQuantity}
            onChange={(e): void => {
              const inputNum = inputNumChecker(e.target.value, coinListConfig[quoteDefaultValue].decimals);
              // handleTokenInputQuantity(inputNum);
              onChangeDepositMargin(inputNum);
            }}
            suffix={<CoinIcon quoteValue={quoteDefaultValue} />}
          />
          <div className="deposit-button-wrap">
            <Button
              block
              loading={isDepositing}
              type="primary"
              className="deposit-button-detail"
              disabled={!isEnableDeposit}
              onClick={depositToken}>
              {!isDepositing ? <img src={DepositButtonIcon} alt="" /> : null}
              Deposit
            </Button>
          </div>
        </div>
      </div>

      {Number(margin2x) > 0 && (
        <div className="trade-page-margin-wrap">
          <div className="trade-page-margin-info">
            <dl>
              <dt>
                Additional Margin for 2x Leverage
                <Popover content={BalanceMarginPopover} placement="bottom">
                  <InfoCircleOutlined className="popover-info-icon" />
                </Popover>
              </dt>
              <dd
                style={{ cursor: 'pointer', fontWeight: 'bold' }}
                onClick={(): void => {
                  onChangeDepositMargin(
                    formatInputNumber(margin2x, coinListConfig[quoteDefaultValue].decimals).toString(),
                  );
                }}>
                {formatNumberPrefixTooltip(margin2x)} {quoteDefaultValue}
              </dd>
            </dl>
          </div>
        </div>
      )}
    </div>
  );
};

export default BalanceDeposit;
