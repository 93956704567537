import { ICoinList } from '@/interfaces/coin';
import {
  ZERO_ADDRESS,
  DIFF_BASE_NAME,
  USD_KEEP_DECIMALS_PLACES,
  BTC_KEEP_DECIMALS_PLACES,
  ETH_KEEP_DECIMALS_PLACES,
} from '@/constants/config';

import { PRODUCT_TYPE, AVAILABLE_PRODUCTS } from '@/constants/product';

const allCoins: ICoinList = {
  BTC: {
    symbol: 'BTC',
    fullName: 'Bitcoin',
    address: '',
    decimals: 8,
    keepDecimals: BTC_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_btc.svg'),
  },
  XAU: {
    symbol: 'XAU',
    fullName: 'Gold',
    address: '',
    decimals: 8, // TODO：目前没找到对应decimals，暂用8
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_xau.svg'),
  },
  LINK: {
    symbol: 'LINK',
    fullName: 'Chainlink',
    address: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
    decimals: 18,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_chainlink.svg'),
  },
  [DIFF_BASE_NAME]: {
    symbol: DIFF_BASE_NAME,
    fullName: 'Bitcoin Hash Rate',
    address: '',
    decimals: 8,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_mining.svg'),
  },
  SFT: {
    symbol: 'SFT',
    fullName: 'SynFutures',
    address: '',
    decimals: 18,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_sf.svg'),
  },
  BTCB: {
    symbol: 'BTCB',
    fullName: 'Binance-Peg BTCB Token',
    address: '0x64Af7f0F87646E7076Bf9b36910Fa7899E02d7d7',
    decimals: 18,
    keepDecimals: BTC_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_btc.svg'),
  },
  BNB: {
    symbol: 'BNB',
    fullName: 'Binance Coin',
    address: ZERO_ADDRESS,
    decimals: 18,
    keepDecimals: ETH_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_bnb.svg'),
  },
  BUSD: {
    symbol: 'BUSD',
    fullName: 'Binance-Peg BUSD Token',
    address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    decimals: 18,
    keepDecimals: ETH_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_busd.svg'),
  },
  Cake: {
    symbol: 'Cake',
    fullName: 'PancakeSwap',
    address: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    decimals: 18,
    keepDecimals: USD_KEEP_DECIMALS_PLACES,
    icon: require('@/assets/svg/tokens/icon_crypto_cake.svg'),
  },
};

const chainBasicCoin = allCoins['BNB'];

/**
 * 基础产品Base配置
 */
const basicBasesConfig: ICoinList = {
  BTC: allCoins['BTC'],
  XAU: allCoins['XAU'],
  BNB: allCoins['BNB'],
  Cake: allCoins['Cake'],
  LINK: allCoins['LINK'],
};

/**
 * 基础产品Quote配置
 */
const basicQuotesConfig: ICoinList = {
  BUSD: allCoins['BUSD'],
  BNB: allCoins['BNB'],
};

/**
 * 难度产品Base配置
 */
const synFuturesBasesConfig: ICoinList = {
  [DIFF_BASE_NAME]: allCoins[DIFF_BASE_NAME],
  SFT: allCoins['SFT'],
};

/**
 * 难度产品Quote配置
 */
const synFuturesQuotesConfig: ICoinList = {
  BTCB: allCoins['BTCB'],
};

/**
 * 基础产品币种配置
 */
const basicCoinListConfig: ICoinList = {
  ...basicBasesConfig,
  ...basicQuotesConfig,
};

/**
 * 难度产品币种配置
 */
const synFuturesCoinListConfig: ICoinList = {
  ...synFuturesBasesConfig,
  ...synFuturesQuotesConfig,
};

/**
 * 币种配置
 */
const coinListConfig: ICoinList = {
  ...basicCoinListConfig,
  ...synFuturesCoinListConfig,
};

/**
 * bases币种配置
 */
const basesCoinConfig: ICoinList = AVAILABLE_PRODUCTS.reduce((config, productType) => {
  if (productType === PRODUCT_TYPE.DIFFICULTY) {
    config = { ...config, ...synFuturesBasesConfig };
  } else if (productType === PRODUCT_TYPE.BASIC) {
    config = { ...config, ...basicBasesConfig };
  }
  return config;
}, {} as ICoinList);

/**
 * quote币种配置（如 my balance币种列表）
 */
const quotesCoinConfig: ICoinList = AVAILABLE_PRODUCTS.reduce((config, productType) => {
  if (productType === PRODUCT_TYPE.DIFFICULTY) {
    config = { ...config, ...synFuturesQuotesConfig };
  } else if (productType === PRODUCT_TYPE.BASIC) {
    config = { ...config, ...basicQuotesConfig };
  }
  return config;
}, {} as ICoinList);

export { coinListConfig, basesCoinConfig, quotesCoinConfig, chainBasicCoin };
