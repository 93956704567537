import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  addCoinConfig,
  addPairConfig,
  changeCurrentPair,
  deleteCoinConfig,
  deletePairConfig,
  loadLocalCoinConfig,
  loadLocalPairConfig,
  resetPairState,
  savePairs,
  addPair,
  isFetchingData,
  updatePair,
  updatePairStatusBySymbol,
} from './actions';
import { ICoin, ICoinList } from '../../interfaces/coin';
import { IPairTree, IPairConfig, IOracleAmm, IOraclePair, IPairTreeList, IPair } from '../../interfaces/pair';
import { chainConfig } from '@/constants/chain';
import { DEFAULT_COIN_ICON, ORACLE_TYPE, CONFIG_TYPE } from '@/constants/config';

export type PairState = {
  /**
   * 交易对配置树
   *
   * @type {IPairTreeList}
   */
  readonly pairTreeConfig: IPairTreeList;
  /**
   * 币种配置列表Object
   *
   * @type {ICoinList}
   */
  readonly coinListConfig: ICoinList;
  /**
   * 当前选择的交易对
   *
   * @type {IPair}
   */
  readonly currentPair: IPair;
  /**
   * 合约上所有交易对信息
   *
   * @type {IPair[]}
   */
  readonly pairs: IPair[];
  /**
   * 是否在加载链上数据
   *
   * @type {boolean}
   */
  readonly isFetching: boolean;
};

const initialState: PairState = {
  pairTreeConfig: chainConfig.pairTreeConfig,
  coinListConfig: chainConfig.coinListConfig,
  currentPair: chainConfig.DEFAULT_PAIR,
  pairs: [],
  isFetching: false,
};

export default createReducer<PairState>(initialState, (builder) =>
  builder
    .addCase(resetPairState, () => initialState)
    .addCase(isFetchingData, (state, { payload: { isFetching } }) => {
      state.isFetching = isFetching;
    })
    .addCase(addPairConfig, (state, { payload: { pair } }) => {
      const oracleBaseList = state.pairTreeConfig[pair.oracleType];
      if (oracleBaseList) {
        // 添加或者push
        if (oracleBaseList[pair.baseCoin.symbol]) {
          const pairs = oracleBaseList[pair.baseCoin.symbol].pairs;
          if (!pairs.find((p) => p.quoteCoin.symbol === pair.quoteCoin.symbol)) {
            pairs.push(pair);
          }
        } else {
          oracleBaseList[pair.baseCoin.symbol] = {
            baseCoin: pair.baseCoin,
            pairs: [pair],
          };
          if (pair.configType) {
            oracleBaseList[pair.baseCoin.symbol].configType = pair.configType;
          }
        }
      }
      localStorage.setItem('syn_pair_tree', JSON.stringify(state.pairTreeConfig));
    })
    .addCase(changeCurrentPair, (state, { payload: { pair } }) => {
      state.currentPair = pair;
    })
    .addCase(deletePairConfig, (state, { payload: { pair } }) => {
      const oracleBaseList = state.pairTreeConfig[pair.oracleType];
      if (oracleBaseList) {
        // 删除pair
        const baseConfig = oracleBaseList[pair.baseCoin.symbol];
        if (baseConfig && baseConfig.pairs.length > 0) {
          const pairInConfig = baseConfig.pairs.find((p) => p.quoteCoin.symbol === pair.quoteCoin.symbol);
          if (pairInConfig) {
            const pairs = _.dropWhile(baseConfig.pairs, (p) => p.quoteCoin.symbol === pairInConfig.quoteCoin.symbol);
            baseConfig.pairs = pairs;
          }
        }
      }
      return {
        ...state,
        pairTreeConfig: chainConfig.pairTreeConfig,
      };
    })
    .addCase(addCoinConfig, (state, { payload: { coin } }) => {
      state.coinListConfig[coin.symbol] = coin;
      localStorage.setItem('syn_coin_list', JSON.stringify(state.coinListConfig));
    })
    .addCase(deleteCoinConfig, (state, { payload: { coin } }) => {
      if (state.coinListConfig[coin.symbol]) {
        delete state.coinListConfig[coin.symbol];
      }
    })
    .addCase(loadLocalPairConfig, (state, { payload: {} }) => {
      const localPairsData = localStorage.getItem('syn_pair_tree');
      if (localPairsData) {
        const pairData: IPairTreeList = JSON.parse(localPairsData);
        // 只新增用户添加的pair（防止添加删除过的pair）
        Object.keys(state.pairTreeConfig).forEach((oracleTypeStr) => {
          const oracleType = oracleTypeStr as ORACLE_TYPE;
          if (pairData[oracleType]) {
            Object.entries(pairData[oracleType]).forEach(([coinName, pairTree]) => {
              if (pairTree.configType && pairTree.configType === CONFIG_TYPE.CUSTOM) {
                state.pairTreeConfig[oracleType][coinName] = pairTree;
                const baseCoin = state.pairTreeConfig[oracleType][coinName].baseCoin;
                // 强制把base coin 设置成默认icon
                // TODO: 以后如果有特殊icon，需要处理下
                if (baseCoin) {
                  const coinInConfig = chainConfig.coinListConfig[baseCoin.symbol];
                  // 不在默认币种配置中
                  if (!coinInConfig) {
                    baseCoin.icon = DEFAULT_COIN_ICON;
                  }
                }
              }
            });
          }
        });
      }
    })
    .addCase(loadLocalCoinConfig, (state, { payload: {} }) => {
      const localCoinData = localStorage.getItem('syn_coin_list');
      if (localCoinData) {
        const coinList: ICoinList = JSON.parse(localCoinData);
        // 只新增用户添加的币种（防止添加删除过的币种）
        Object.entries(coinList).forEach(([coinName, coinConfig]) => {
          if (coinConfig.configType && coinConfig.configType === CONFIG_TYPE.CUSTOM) {
            // 强制把base coin 设置成默认icon
            // TODO: 以后如果有特殊icon，需要处理下
            const coinInConfig = chainConfig.coinListConfig[coinConfig.symbol];
            // 不在默认币种配置中
            if (!coinInConfig) {
              state.coinListConfig[coinName] = coinConfig;
              state.coinListConfig[coinName].icon = DEFAULT_COIN_ICON;
            }
          }
        });
      }
    })

    .addCase(addPair, (state, { payload: { pair } }) => {
      state.pairs.push(pair);
    })
    .addCase(updatePair, (state, { payload: { pair } }) => {
      const pairInfo = state.pairs.find((p) => p.oraclePair.symbol === pair.oraclePair.symbol);
      if (pairInfo) {
        for (let i = 0; i < state.pairs.length; i++) {
          const p = state.pairs[i];
          if (p.oraclePair.symbol === pair.oraclePair.symbol) {
            state.pairs[i] = { ...state.pairs[i], ...pair };
          }
        }
      } else {
        state.pairs.push(pair);
      }
    })
    .addCase(updatePairStatusBySymbol, (state, { payload: { symbol, status } }) => {
      for (let i = 0; i < state.pairs.length; i++) {
        const p = state.pairs[i];
        if (p.oraclePair.symbol === symbol) {
          state.pairs[i].status = status;
        }
      }
      if (state.currentPair.oraclePair.symbol === symbol) {
        state.currentPair.status = status;
      }
    })
    .addCase(savePairs, (state, { payload: { pairs } }) => {
      if (pairs.length > 0) {
        state.pairs = _.values(_.merge(_.keyBy(state.pairs, 'oraclePair.symbol'), _.keyBy(pairs, 'oraclePair.symbol')));

        // pairs.forEach(pair => {
        //   // 更新 coins address
        //   const baseName = pair.baseCoin.symbol;
        //   state.coinListConfig[baseName] = {
        //     ...state.coinListConfig[baseName],
        //     ...pair.baseCoin,
        //     address: pair.oraclePair.baseAddress
        //   };
        //   const quoteName = pair.quoteCoin.symbol;
        //   state.coinListConfig[quoteName] = {
        //     ...state.coinListConfig[quoteName],
        //     ...pair.quoteCoin,
        //     address: pair.oraclePair.quoteTokenAddress
        //   };
        //   // 更新 pairTreeConfig
        //   const pairTreeConfig = state.pairTreeConfig;
        //   if (pairTreeConfig[pair.oracleType]) {
        //     const basePairs = pairTreeConfig[pair.oracleType][baseName];
        //     if (basePairs) {
        //       basePairs.baseCoin = state.coinListConfig[baseName];
        //       const pairInConfig = basePairs.pairs.find(p => p.quoteCoin.symbol === pair.quoteCoin.symbol);
        //       if (pairInConfig) {
        //         pairInConfig.quoteCoin = state.coinListConfig[quoteName];
        //       }
        //     } else {
        //       pairTreeConfig[pair.oracleType][baseName] = {
        //         baseCoin: state.coinListConfig[baseName],
        //         pairs: [
        //           {
        //             baseCoin: state.coinListConfig[baseName],
        //             quoteCoin: state.coinListConfig[quoteName],
        //             oracleType: pair.oracleType
        //           }
        //         ]
        //       };
        //     }
        //   }
        //   // 更新pair coin
        //   pair.baseCoin = state.coinListConfig[baseName];
        //   pair.quoteCoin = state.coinListConfig[quoteName];
        // });
      }
    }),
);
