import React, { ReactNode } from 'react';

/**
 * 创建 Slider marks
 *
 * @param length slider-dot->总长度
 * @param step 步长
 * @param num 显示数字
 * @param suffix 后缀
 * @returns
 */
const marks = function(length: number, step: number, num: number, suffix: string) {
  const arr = Array.from({ length: length }, (x, i) => i);
  const temp: { [key: number]: { label: ReactNode } } = { 0: { label: <React.Fragment></React.Fragment> } };

  if (length > 10) {
    arr.forEach((n, i) => {
      i % 2 === 0
        ? (temp[i * step] = {
            label: (
              <div>
                <div className="slider-marks-vertical-line">|</div>
                <strong className="slider-marks-vertical-text">
                  {i * num}
                  {suffix}
                </strong>
              </div>
            ),
          })
        : (temp[i * step] = {
            label: <div></div>,
          });
    });
  } else {
    arr.forEach((n, i) => {
      temp[(i + 1) * step] = {
        label: (
          <div>
            <div className="slider-marks-vertical-line">|</div>
            <strong className="slider-marks-vertical-text">
              {(i + 1) * num}
              {suffix}
            </strong>
          </div>
        ),
      };
    });
  }
  return temp;
};

export default marks;

// if(length > 10){
//   arr.map((n, i)=>{
//     (i%2===0)?
//     temp[(i)*step] = {
//       label: <div>
//           <div className="slider-marks-vertical-line">|</div>
//           <strong className="slider-marks-vertical-text">{(i)* num}{suffix}</strong>
//         </div>
//     }
//     :
//     temp[(i)*step]= {
//       label: <div>
//         </div>
//     }
//   });

// }else{
//   arr.map((n, i)=>{
//     temp[(i+1)*step]= {
//       label: <div>
//           <div className="slider-marks-vertical-line">|</div>
//           <strong className="slider-marks-vertical-text">{(i+1)* num}{suffix}</strong>
//         </div>
//     }
//   });
// }
