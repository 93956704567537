import React, { ReactElement, ReactNode } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Button, Card, ConfigProvider, Table, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { synWeb3 } from '@/synWeb3';
import customizeRenderEmpty from '../EmptyTable';
import { ICoinBalanceDisplay } from '@/interfaces/balance';
import { useBalanceHook } from '@/state/balance';
import { formatNumber } from '@/utils/numberUtil';
import { getEtherscanLink } from '@/utils';
import { DEFAULT_DECIMAL_PLACES } from '@/constants';
import MintIcon from '@/assets/svg/icons/icon_cast_w.svg';
import FaucetIcon from '@/assets/svg/icons/icon_faucet.svg';
import { chainConfig } from '@/constants/chain';
import LoadingWrap from '@/components/LoadingWrap';
import { tablePaginationSetting } from '@/constants/ui';
import { currentProductReleaseEnv, PRODUCT_RELEASE_ENV } from '@/constants/product';
import { ColumnsType } from 'antd/lib/table';
import formatNumberPrefixTooltip from '@/components/Common/formatNumberPrefixTooltip';
import { GaCategory, gaEvent, gaException } from '@/utils/gaUtil';

import './style.scss';

type TableRowData = {
  onRefreshBalance: (balanceInfo: ICoinBalanceDisplay) => void;
};

export default function BalanceTable({ onRefreshBalance }: TableRowData): JSX.Element {
  const { t } = useTranslation();
  const { active, account } = useWeb3React();
  const { balanceList, isFetching: isFetchingBalance } = useBalanceHook();

  const onClickMintHandler = async (record: ICoinBalanceDisplay) => {
    console.log(`onClickMintHandler: mint:`, record);
    gaEvent(GaCategory.ACCOUNT, 'Open mint coin');

    if (!active) {
      notification.warning({
        message: t('notification.wallet.title'),
        description: t('notification.wallet.noConnection'), //'Please connect your wallet',
      });
      return;
    }

    try {
      const mintTokenResult = await synWeb3.kovan.mintToken(account as string, record.address, (res) => {
        console.log(res);
        notification.info({
          message: t('notification.mint.title'), //'Balance',
          description: t('notification.mint.info'),
        });
      });

      console.log(`mintTokenResult:`, mintTokenResult);

      const notificationStr = t('notification.mint.success', {
        mintQuantity: record.symbol.includes('BTC') ? formatNumber(1) : formatNumber(10000),
        mintToken: record.symbol,
      });
      const descriptionNode: ReactNode = (
        <div>
          <span dangerouslySetInnerHTML={{ __html: notificationStr }}></span>
        </div>
      );

      notification.success({
        message: t('notification.mint.title'),
        description: descriptionNode,
      });

      onRefreshBalance(record);
    } catch (error) {
      gaException(error.message);
      notification.error({
        message: t('notification.mint.title'),
        description: t('notification.mint.error', {
          mintToken: record.symbol,
        }),
      });
    }
  };

  const columnsInfo: ColumnsType<ICoinBalanceDisplay> = [
    {
      title: 'Margin Token',
      dataIndex: 'symbol',
      key: 'symbol',
      render: (text: string, record: ICoinBalanceDisplay): JSX.Element => (
        <span style={{ display: 'block', textAlign: 'left' }}>
          <a
            target="_blank"
            rel="noreferrer noopener"
            className="syn-link"
            href={getEtherscanLink(
              record.symbol === chainConfig.chainBasicCoin.symbol ? `${account}` : `${record.address}`,
              'address',
            )}>
            <img className="my-balances-icon" src={record.icon} alt={text} />
            <em className="my-balances-text">{text}</em>
          </a>
        </span>
      ),
    },
    {
      title: 'Margin Balance',
      dataIndex: 'balance',
      key: 'balance',
      width: '20%',
      align: 'right' as const,
      render: (text: string, record: ICoinBalanceDisplay): JSX.Element => {
        return (
          <LoadingWrap loading={record.isLoadingMarginBalance} align="right">
            <span>{formatNumberPrefixTooltip(text, DEFAULT_DECIMAL_PLACES, true, true)}</span>
          </LoadingWrap>
        );
      },
    },
    {
      title: 'Wallet Balance',
      dataIndex: 'walletBalance',
      key: 'walletBalance',
      width: '20%',
      align: 'right' as const,
      render: (text: string, record: ICoinBalanceDisplay): ReactElement => {
        return (
          <LoadingWrap loading={record.isLoadingWalletBalance} align="right">
            <span>{formatNumberPrefixTooltip(text, DEFAULT_DECIMAL_PLACES, false, true)}</span>
          </LoadingWrap>
        );
      },
    },

    {
      title: 'Total Balance',
      dataIndex: 'totalBalance',
      key: 'totalBalance',
      width: '20%',
      align: 'right' as const,
      render: (text: string, record: ICoinBalanceDisplay): ReactElement => {
        return (
          <LoadingWrap loading={record.isLoadingWalletBalance || record.isLoadingMarginBalance} align="right">
            <span>{formatNumberPrefixTooltip(text, DEFAULT_DECIMAL_PLACES, false, true)}</span>
          </LoadingWrap>
        );
      },
    },
    {
      title: 'Estimated Value',
      dataIndex: 'estimatedValue',
      key: 'estimatedValue',
      width: '25%',
      align: 'right' as const,
      render: (text: string, record: ICoinBalanceDisplay): JSX.Element => {
        return (
          <LoadingWrap loading={record.isLoadingWalletBalance || record.isLoadingMarginBalance} align="right">
            <span>{formatNumberPrefixTooltip(text, DEFAULT_DECIMAL_PLACES, true, true)}</span>
          </LoadingWrap>
        );
      },
    },
  ];

  if (currentProductReleaseEnv !== PRODUCT_RELEASE_ENV.PROD) {
    columnsInfo.push({
      title: 'Mint',
      dataIndex: 'mint',
      key: 'mint',
      align: 'center' as const,
      className: 'balances-table__mint-column',
      render: (_: any, record: ICoinBalanceDisplay) => (
        <span style={{ display: 'block', textAlign: 'center' }}>
          {record.symbol === chainConfig.chainBasicCoin.symbol ? (
            <Button
              className="btn-mint-wrap"
              size="small"
              type="default"
              target="_blank"
              href="https://gitter.im/kovan-testnet/faucet">
              <div className="btn-mint-inner">
                Faucet
                <img src={FaucetIcon} alt="Faucet" />
              </div>
            </Button>
          ) : (
            <Button className="btn-mint-wrap" size="small" type="default" onClick={() => onClickMintHandler(record)}>
              <div className="btn-mint-inner">
                Mint
                <img src={MintIcon} alt="Mint" />
              </div>
            </Button>
          )}
        </span>
      ),
    });
  }

  return (
    <React.Fragment>
      <Card
        headStyle={{ padding: '0', borderBottom: 'none' }}
        bodyStyle={{ padding: '0' }}
        title={
          <div
            style={{
              fontSize: 16,
              fontFamily: 'Helvetica',
              fontWeight: 'bold',
              color: '#184858',
            }}>
            My Balances
          </div>
        }
        bordered={false}
        style={{ background: 'transparent', width: 960, margin: 'auto' }}>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Table
            loading={isFetchingBalance}
            className={`balances-table-card-table ${currentProductReleaseEnv !== PRODUCT_RELEASE_ENV.PROD &&
              'balances-table_with-mint'}`}
            columns={columnsInfo}
            dataSource={balanceList}
            rowKey={(balance: ICoinBalanceDisplay): string => balance.symbol}
            size="middle"
            style={{
              border: '1px solid #F1F1F1',
              borderRadius: '10px',
              boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.03)',
            }}
            pagination={tablePaginationSetting}
          />
        </ConfigProvider>
      </Card>
    </React.Fragment>
  );
}
