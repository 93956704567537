import React, { ReactElement, useState, useCallback } from 'react';
import { Space, Table, Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';

import { useWeb3React } from '@web3-react/core';
import { useTranslation } from 'react-i18next';
import { IAmmDetail } from '@/interfaces/amm';
import { IPosition, IPositionDisplay } from '@/interfaces/position';
import { lessThanByNum, negativeNumberColor, formatNumber } from '@/utils/numberUtil';
import { LESS_NUMBER_00001 } from '@/constants';
import { tablePaginationSetting } from '@/constants/ui';
import RemoveLiquidityModal from '@/components/LiquidityModal/RemoveLiquidity';
import { getDiffNumByPrice, getDiffSymbolTips } from '@/utils/common';
import { isDiffProductSymbol } from '@/utils';
import { useDiffHook } from '@/state/diff';
import { getBlockHeightTimestamps } from '@/utils/timeUtil';
import { AMM_STATUS } from '@/constants/config';
import { FutureSymbol } from '@/components/FutureSymbol';
import LoadingWrap from '@/components/LoadingWrap';
import formatNumberPrefixTooltip from '@/components/Common/formatNumberPrefixTooltip';
import OperationButtons from './operationButtons';

import './style.scss';

// formatNumber  formatNumberPrefixTooltip
// formatNumber
export interface IPoolListPrpos {
  loading: boolean;
  poolListData: IPositionDisplay[];
  setDataInfo?: (info: IPosition) => void;
  setAddLiquidityTargetFrom?: any;
  setAddLiquidityModalVisible?: any;
}

const PoolList = (props: IPoolListPrpos) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const traderAddress = account || '';

  const { currentBlockHeight, currentBlockTime } = useDiffHook();

  const removeLiquidityVisibleDefault = false;
  const [removeLiqiudityModalVisible, setRemoveLiqiudityModalVisible] = useState(removeLiquidityVisibleDefault);
  const [removeLiqiudityDataInfo, setRemoveLiqiudityDataInfo] = useState<IPosition>();

  const onRemoveLiqiudityHandler = (record: IPosition): void => {
    console.log(`\n onRemoveLiqiudityHandler ---xxx---x`, record);
    setRemoveLiqiudityModalVisible(true);
    setRemoveLiqiudityDataInfo(record);
  };

  const rowClassName = (record: IPosition): string => {
    return record.pair?.ammDetail?.status === AMM_STATUS.EMERGENCY ? 'row-gray-bg' : '';
  };

  const diffSymbolTipsDisplay = useCallback(
    (record: IAmmDetail): JSX.Element | string => {
      const title = getDiffSymbolTips(record, currentBlockHeight, currentBlockTime, t);
      if (title) {
        return (
          <Tooltip title={title}>
            <QuestionCircleFilled className="tooltip-info-icon" />
          </Tooltip>
        );
      } else {
        return '';
      }
    },
    [currentBlockHeight, currentBlockTime, t],
  );

  const tableColumns = [
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      width: 240,
      render: (text: string, record: IPosition): ReactElement | '' => {
        if (record.pair?.ammDetail) {
          const ammDetail = record.pair?.ammDetail;
          return (
            ammDetail && (
              <>
                <FutureSymbol
                  status={ammDetail.status}
                  symbol={ammDetail.symbol}
                  isShowTradeLink={true}
                  record={ammDetail}
                />
                {ammDetail.status !== AMM_STATUS.SETTLED && diffSymbolTipsDisplay(ammDetail)}
              </>
            )
          );
        }
        return '';
      },
    },
    {
      title: 'Fair Price',
      dataIndex: 'midPrice',
      key: 'midPrice',
      render: (text: string, record: IPosition): ReactElement | '' => {
        if (record.pair?.ammDetail) {
          const midPrice = record.pair?.ammDetail?.midPrice;
          return (
            midPrice && (
              <span className="my-pools-row-cell">
                {isDiffProductSymbol(record.symbol) ? (
                  <Tooltip
                    title={
                      <div>
                        <div>Fair Difficulty: {getDiffNumByPrice(midPrice)}</div>
                      </div>
                    }>
                    {formatNumber(midPrice)}
                  </Tooltip>
                ) : (
                  formatNumberPrefixTooltip(midPrice)
                )}
              </span>
            )
          );
        }
        return '';
      },
    },
    {
      title: 'AMM Position',
      dataIndex: 'ammPosition',
      key: 'ammPosition',
      render: (text: string, record: IPosition): ReactElement | '' => {
        if (record.pair?.ammDetail) {
          const ammPosition = record.pair?.ammDetail?.ammPosition;
          return (
            ammPosition &&
            (lessThanByNum(ammPosition) ? (
              <Tooltip title={ammPosition}>
                <span className="my-pools-row-cell" style={{ color: negativeNumberColor(ammPosition) }}>
                  {LESS_NUMBER_00001}
                </span>
              </Tooltip>
            ) : (
              <span className="my-pools-row-cell" style={{ color: negativeNumberColor(ammPosition) }}>
                {formatNumberPrefixTooltip(ammPosition)}
              </span>
            ))
          );
        }
        return '';
      },
    },
    {
      title: (
        <Tooltip title="Margin Balance of the AMM">
          Margin Balance
          <QuestionCircleFilled className="tooltip-info-icon tooltip-head-icon" style={{ color: 'white' }} />
        </Tooltip>
      ),
      dataIndex: 'ammMarginBalance',
      key: 'ammMarginBalance',
      render: (text: string, record: IPosition): ReactElement | undefined | '' => {
        const ammMarginBalance = record.pair?.ammDetail?.ammMarginBalance;
        return (
          ammMarginBalance &&
          (lessThanByNum(ammMarginBalance) ? (
            <Tooltip title={ammMarginBalance}>
              <span className="my-pools-row-cell" style={{ color: negativeNumberColor(ammMarginBalance) }}>
                {LESS_NUMBER_00001}
              </span>
            </Tooltip>
          ) : (
            <span className="my-pools-row-cell" style={{ color: negativeNumberColor(ammMarginBalance) }}>
              {formatNumberPrefixTooltip(ammMarginBalance)}
            </span>
          ))
        );
      },
    },
    {
      title: 'My Share',
      dataIndex: 'shareTotalSupply',
      key: 'shareTotalSupply',
      render: (text: string, record: IPositionDisplay): ReactElement | '' => {
        if (record.pair?.ammDetail) {
          const shareTotalSupply = record.pair?.ammDetail?.shareTotalSupply;
          return (
            <LoadingWrap loading={record.isLoadingAmmDetail || false} align="right">
              {lessThanByNum(shareTotalSupply) ? (
                <span className="my-pools-row-cell">
                  <Tooltip title={shareTotalSupply}>{LESS_NUMBER_00001}</Tooltip>
                  <em className="my-share-percent">
                    (
                    {formatNumber(
                      (parseFloat(record.pair?.ammDetail?.shareBalance) / parseFloat(shareTotalSupply)) * 100,
                      2,
                    )}
                    %)
                  </em>
                </span>
              ) : (
                <span className="my-pools-row-cell">
                  {formatNumberPrefixTooltip(record.pair?.ammDetail?.shareBalance)}
                  <em className="my-share-percent">
                    (
                    {formatNumber(
                      (parseFloat(record.pair?.ammDetail?.shareBalance) / parseFloat(shareTotalSupply)) * 100,
                      2,
                    )}
                    %)
                  </em>
                </span>
              )}
            </LoadingWrap>
          );
        }
        return '';
      },
    },
    {
      title: (
        <Tooltip title="(Fair Price * AMM Position + Margin Balance) * My Share %">
          Share Value
          <QuestionCircleFilled className="tooltip-info-icon tooltip-head-icon" style={{ color: 'white' }} />
        </Tooltip>
      ),
      dataIndex: 'shareBalance',
      key: 'shareBalance',
      render: (text: string, record: IPosition): ReactElement | '' => {
        if (record.pair?.ammDetail) {
          return (
            <span className="my-pools-row-cell">
              {record.pair?.ammDetail?.status === AMM_STATUS.SETTLED
                ? formatNumberPrefixTooltip(
                    (parseFloat(record.pair?.ammDetail?.shareBalance) /
                      parseFloat(record.pair?.ammDetail?.shareTotalSupply)) *
                      parseFloat(record.pair?.ammDetail?.ammMarginBalance),
                  )
                : formatNumberPrefixTooltip(
                    parseFloat(record.pair?.ammDetail?.shareBalance) * parseFloat(record.pair?.ammDetail?.midPrice) * 2,
                  )}
            </span>
          );
        }
        return '';
      },
    },

    {
      title: 'Operations',
      dataIndex: 'Operations',
      align: 'center' as any,
      key: 'action',
      render: (_: any, record: IPosition): ReactElement | '' => {
        if (record.pair?.ammDetail) {
          let maturityTime = parseInt(record.pair?.ammDetail?.maturity, 10);
          if (isDiffProductSymbol(record.pair?.ammDetail?.symbol)) {
            maturityTime = getBlockHeightTimestamps(
              Number(record.pair?.ammDetail?.maturity),
              currentBlockHeight,
              currentBlockTime,
            );
          }
          return (
            <Space>
              {record.pair?.ammDetail?.status !== AMM_STATUS.EMERGENCY && (
                <OperationButtons
                  positionInfo={record}
                  traderAddress={traderAddress}
                  maturityTime={maturityTime}
                  props={props}
                  onRemoveLiqiudityHandler={onRemoveLiqiudityHandler}
                />
              )}
            </Space>
          );
        }
        return '';
      },
    },
  ];

  return (
    <React.Fragment>
      {removeLiqiudityModalVisible && removeLiqiudityDataInfo && (
        <RemoveLiquidityModal
          visible={removeLiqiudityModalVisible}
          setVisible={setRemoveLiqiudityModalVisible}
          dataInfo={removeLiqiudityDataInfo}
        />
      )}
      <Table
        className="my-pools MyPoolDetails"
        loading={props.loading}
        dataSource={props.poolListData}
        columns={tableColumns}
        rowClassName={(record: IPosition): string => rowClassName(record)}
        size="small"
        pagination={tablePaginationSetting}
        rowKey={(record): number | string => {
          return record.id;
        }}
      />
    </React.Fragment>
  );
};

export default PoolList;
