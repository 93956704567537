import { AMM_STATUS } from '@/constants/config';
import { createAction } from '@reduxjs/toolkit';

import { ICoin, ICoinList } from '../../interfaces/coin';
import { IPairTree, IPairConfig, IOracleAmm, IOraclePair, IPairTreeList, IPair } from '../../interfaces/pair';

export const resetPairState = createAction<void>('pairs/resetPairState');

//#region pair action
/**
 *  添加pair
 */
export const addPairConfig = createAction<{ pair: IPairConfig }>('pairs/addPairConfig');
/**
 * 删除pair
 */
export const deletePairConfig = createAction<{ pair: IPairConfig }>('pairs/deletePairConfig');
/**
 * 切换pair
 */
export const changeCurrentPair = createAction<{ pair: IPair }>('pairs/changeCurrentPair');

export const loadLocalPairConfig = createAction<{}>('pairs/loadLocalPairConfig');
export const isFetchingData = createAction<{ isFetching: boolean }>('pairs/isFetchingData');
export const savePairs = createAction<{ pairs: IPair[] }>('pairs/savePairs');
export const addPair = createAction<{ pair: IPair }>('pairs/addPair');
export const updatePair = createAction<{ pair: IPair }>('pairs/updatePair');
export const updatePairStatusBySymbol = createAction<{ symbol: string; status: AMM_STATUS }>(
  'pairs/updatePairStatusBySymbol',
);

//#endregion

//#region coin action
/**
 * 添加币种
 */
export const addCoinConfig = createAction<{ coin: ICoin }>('pairs/addCoinConfig');
/**
 * 删除币种
 */
export const deleteCoinConfig = createAction<{ coin: ICoin }>('pairs/deleteCoinConfig');

export const loadLocalCoinConfig = createAction<{}>('pairs/loadLocalCoinConfig');
//#endregion
