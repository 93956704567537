import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'inter-ui';
import { isMobile } from 'react-device-detect';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter, Route } from 'react-router-dom';
import { NetworkContextName } from './constants';
import { QueryParamProvider } from 'use-query-params';
import { notification } from 'antd';
import BigNumber from 'bignumber.js';
import ReactGA from 'react-ga';
import App from './pages/App';
import store from './state';
import './i18n';
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from './theme';
import getLibrary from './utils/getLibrary';
import SwitchNetworksModel from '@/components/SwitchNetworkModel';
import { getChainName } from './utils';
import { appChainId } from './constants/chain';

import './theme/index.scss';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://b5f78b97af764003af07ed21bfe8ca22@o513856.ingest.sentry.io/5616383',
    integrations: [new Integrations.BrowserTracing()],
    release: '1.0',
    environment: getChainName(appChainId),

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

const GOOGLE_ANALYTICS_ID: string | undefined = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
if (typeof GOOGLE_ANALYTICS_ID === 'string') {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
  ReactGA.set({
    customBrowserType: !isMobile
      ? 'desktop'
      : 'web3' in window || 'ethereum' in window
      ? 'mobileWeb3'
      : 'mobileRegular',
  });
} else {
  ReactGA.initialize('test', { testMode: true, debug: true });
}

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

if ('ethereum' in window) {
  (window.ethereum as any).autoRefreshOnNetworkChange = false;
}

notification.config({
  duration: 9,
  placement: 'bottomRight',
});

// bignumber 配置
BigNumber.config({ DECIMAL_PLACES: 18 });

ReactDOM.render(
  <div>
    <FixedGlobalStyle />
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Provider store={store}>
          <SwitchNetworksModel />
          <ThemeProvider>
            <ThemedGlobalStyle />
            <HashRouter>
              <QueryParamProvider ReactRouterRoute={Route}>
                <App />
              </QueryParamProvider>
            </HashRouter>
          </ThemeProvider>
        </Provider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </div>,
  document.getElementById('root'),
);

// <StrictMode>
// </StrictMode>
