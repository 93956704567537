import { Button, Form, Input, Popover, Tooltip } from 'antd';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { QuestionCircleFilled, InfoCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { useWeb3React } from '@web3-react/core';
import { useTranslation } from 'react-i18next';
import { useCoinGetter, usePairGetter } from '@/state/pairs/hooks';
import { txNotification } from '@/components/TxNotification';
import { Field } from '@/state/swap/actions';
import { useTradeToken, useUIGlobalConfigs } from '@/state/swap/hooks';
import { useGlobalGetter } from '@/state/global/hooks';
import { usePositionHook } from '@/state/position';
import { useTradeHook } from '@/state/trade';
import usePrevious from '@/hooks/usePrevious';
import { formatNumber, formatNumberTransition, inputNumChecker, negativeNumberColor } from '@/utils/numberUtil';
import BigNumber from 'bignumber.js';
import { ITransactionError } from '@/interfaces/error';
import { PRODUCT_TYPE } from '@/constants/product';
import { DIFF_CONTRACT_SPECS_LINK, PARSE_RECEIPT_ERROR, TRADE_DIRECTION, USD_COINS } from '@/constants';
import formatNumberPrefixTooltip from '@/components/Common/formatNumberPrefixTooltip';
import CurrentBalanceTitle, { BalanceTitleType } from '@/components/CurrentBalanceTitle';
import IconBtnTrade from '@/assets/svg/trade/icon_btn_trade.svg';
import { getDiffNumByPrice } from '@/utils/common';
import { DIFF_HASHRATE_SCALE } from '@/constants/config';
import { useBalanceHook } from '@/state/balance';
import { GaCategory, gaEvent, gaException } from '@/utils/gaUtil';

import './TradeForm.scss';

interface ITradeFormProps {
  tradeSide: TRADE_DIRECTION;
}

export default function TradeForm({ tradeSide }: ITradeFormProps): JSX.Element {
  const { t } = useTranslation();
  const { onFetchCurrentPosition, currentPosition, currentAmmDetail } = usePositionHook();
  const { currentWeb3, currentProductType } = useGlobalGetter();
  const {
    tradePrice,
    onChangeTradeAmount,
    onChangePrice,
    priceImpact,
    limitPriceStr,
    limitPriceBN,
    onFetchPrice,
    tradeFee,
  } = useTradeHook();
  const { coinListConfig } = useCoinGetter();
  const { currentPair } = usePairGetter();
  const { uiGlobalConfig } = useUIGlobalConfigs();
  const { balanceList } = useBalanceHook();

  // 输入数量
  const [inputAmountStr, setInputAmountStr] = useState<string>('');

  const [simulationStatus, setSimulationStatus] = useState<ValidateStatus>('');
  const [simulationReason, setSimulationReason] = useState<ReactNode>('');
  // const [dynamicLeverage, setDynamicLeverage] = useState<number>(0);

  const [isTradeLoading, setIsTradeLoading] = useState(false); // 下单中

  const { account } = useWeb3React();
  const { currencies } = useTradeToken();

  const traderAddress = account || '';
  const baseCurrency = currencies[Field.BASE] || '';
  const quoteCurrency = currencies[Field.QUOTE] || '';
  const prevProxy = usePrevious({ traderAddress });

  const isDiffProduct = useMemo(() => {
    return currentProductType === PRODUCT_TYPE.DIFFICULTY;
  }, [currentProductType]);

  const dynamicLeverage = useMemo(() => {
    const position = Math.abs(Number(currentPosition.position));
    if (position === 0) {
      return 0;
    } else {
      const markPrice = Number(currentPosition.markPrice);
      const marginBalance = Math.abs(Number(currentPosition.marginBalance));
      return (position * markPrice) / marginBalance;
    }
  }, [currentPosition.marginBalance, currentPosition.markPrice, currentPosition.position]);

  const simulationLoadingNode: ReactNode = useMemo(() => {
    return (
      <span>
        <SyncOutlined spin /> {t('message.simulating')}
      </span>
    );
  }, [t]);

  useEffect(() => {
    onChangeTradeAmount(inputAmountStr);
  }, [inputAmountStr, onChangeTradeAmount]);

  // 清空buy info
  const resetInfo = useCallback(() => {
    setInputAmountStr('');
    onChangeTradeAmount('');
    onChangePrice({ price: 0, initialMargin: 0 });
    setIsTradeLoading(false);

    setSimulationStatus('validating');

    setSimulationReason('');
  }, [onChangePrice, onChangeTradeAmount]);

  // 重新初始化数据
  const reInitDatas = useCallback(() => {
    console.log(` 🚚 reInitDatas`);
    resetInfo();
  }, [resetInfo]);

  // 合约不同重置数据
  useEffect(() => {
    reInitDatas();
  }, [currentPair, baseCurrency, quoteCurrency, reInitDatas, tradeSide]);

  // 切换账户重新获取数据
  useEffect(() => {
    if (traderAddress && prevProxy && prevProxy.traderAddress && traderAddress !== prevProxy.traderAddress) {
      reInitDatas();
    }
  }, [traderAddress, prevProxy, reInitDatas]);

  // 输入框 buyAmount 发生改变
  const onChangeAmountHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let buyAmount = e.target.value;

    setSimulationStatus('validating');

    setSimulationReason('');

    if (buyAmount.length === 0) {
      setInputAmountStr('');
      onChangeTradeAmount('');
      onChangePrice({ price: 0, initialMargin: 0 });
      return;
    }
    buyAmount = inputNumChecker(buyAmount, coinListConfig[baseCurrency].decimals);
    setInputAmountStr(buyAmount);
  };

  /**
   * 交易模拟
   */
  const fetchTradeSimulation = useCallback(
    async (tradeSide: TRADE_DIRECTION, inputAmountStr: string) => {
      const isBuy = tradeSide === TRADE_DIRECTION.LONG;

      if (!currentAmmDetail) {
        return;
      }
      // 获取价格
      if (Number(inputAmountStr) <= 0) {
        return;
      }
      // 显示 simulating loading
      setSimulationStatus(''); //validating
      setSimulationReason(simulationLoadingNode); //导致清空一侧

      const limitPriceBN = await onFetchPrice(currentWeb3, inputAmountStr);
      if (!limitPriceBN) {
        return;
      }

      // ### ReactGaEvent Simulation
      const tradeParams = {
        method: 'currentWeb3.amm.tradeSimulation',
        tradeAmount: inputAmountStr,
        isBuy,
        limitPrice: limitPriceBN.toString(10),
        ammProxy: currentAmmDetail.ammProxy,
        slippage: uiGlobalConfig.slippage,
        deadline: uiGlobalConfig.deadline,
        traderAddress,
      };
      console.log(`GoogleAnalytics:${JSON.stringify(tradeParams)}`);
      gaEvent(GaCategory.DEBUG, 'Trade', JSON.stringify(tradeParams));
      // ### ReactGaEvent END

      console.log(` 🚀--->BBB LIMIT-PRICE:${limitPriceBN.toString(10)} `, Date.now());

      // 模拟计算
      const result = await currentWeb3.amm.tradeSimulation(
        isBuy,
        inputAmountStr,
        limitPriceBN.toString(10),
        uiGlobalConfig.deadline,
        currentAmmDetail.ammProxy,
        traderAddress,
      );

      console.log(`-->fetchTradeSimulation:useDebouncedCallback::`, result);

      if (result && result.reason) {
        setSimulationStatus('warning');
        setSimulationReason(result.reason);
      } else {
        setSimulationStatus('');
        setSimulationReason(''); //解析成功之后
      }

      return result;
    },
    [
      currentAmmDetail,
      simulationLoadingNode,
      onFetchPrice,
      currentWeb3,
      uiGlobalConfig.slippage,
      uiGlobalConfig.deadline,
      traderAddress,
    ],
  );

  /**
   * 防抖 520 毫秒-获取交易 simulation 信息
   * useDebouncedCallback
   */
  const fetchTradeSimulationDebounced = useDebouncedCallback(
    // function
    (tradeSide: TRADE_DIRECTION, inputAmountStr: string) => {
      currentAmmDetail && fetchTradeSimulation(tradeSide, inputAmountStr);
    },
    // delay in ms
    520,
  );

  // 输入数量、切换side，充值触发更新
  useEffect(() => {
    fetchTradeSimulationDebounced(tradeSide, inputAmountStr);
  }, [inputAmountStr, tradeSide, currentPosition.accountBalance, fetchTradeSimulationDebounced]);

  const onClickTradeButton = useCallback(async () => {
    const isBuy = tradeSide === TRADE_DIRECTION.LONG;
    const contractSymbol = currentAmmDetail && currentAmmDetail.symbol;
    const deadline = uiGlobalConfig.deadline;
    if (Number(inputAmountStr) <= 0 || limitPriceBN.isLessThanOrEqualTo(0)) {
      return;
    }
    try {
      if (currentAmmDetail && currentAmmDetail.ammProxy !== '' && traderAddress) {
        const size = inputAmountStr;

        setIsTradeLoading(true);
        console.log(
          `Trade isBuy:${isBuy} size:${size} price:${tradePrice} limitPrice:${limitPriceStr} `,
          `deadline:${deadline}`,
          `${contractSymbol} ammProxy:${currentAmmDetail.ammProxy}`,
          limitPriceBN.isLessThanOrEqualTo(0),
        );

        const tradeParams = {
          method: 'currentWeb3.amm.trade',
          isBuy: isBuy,
          size,
          limitPrice: limitPriceStr,
          traderAddress,
          deadline,
        };

        console.log(`GoogleAnalytics:${JSON.stringify(tradeParams)}`);
        // log selected wallet
        gaEvent(GaCategory.DEBUG, 'Trade', JSON.stringify(tradeParams));

        const tradeResult = await currentWeb3.amm.trade(
          isBuy,
          size,
          limitPriceStr,
          deadline,
          currentAmmDetail.ammProxy,
          traderAddress,
          (hash: string) => {
            console.log(
              `Trade isBuy:${isBuy} size:${size} limitPrice:${limitPriceStr} `,
              `deadline:${deadline} hash: ${hash}`,
              `${contractSymbol} ammProxy:${currentAmmDetail.ammProxy}`,
            );

            txNotification.info({
              message: t('notification.trade.title', {
                contractSymbol: contractSymbol,
              }),
              description: t('notification.trade.info'),
            });
          },
        );

        if (tradeResult.status) {
          onFetchCurrentPosition(traderAddress, currentWeb3);
          try {
            const tradeResultInfo = currentWeb3.futures.parseReceipt(tradeResult);
            console.log(`\n tradeResultInfo \n: `, tradeResultInfo);

            if (tradeResultInfo === PARSE_RECEIPT_ERROR) {
              console.log(`PARSE_RECEIPT_ERROR`);
              throw new Error(PARSE_RECEIPT_ERROR);
            }

            const tradeSide = Number(tradeResultInfo.Trade.side);
            const tradeDirection = tradeSide === 1 ? 'Sold' : tradeSide === 2 ? 'Bought' : '';
            const tradeSize = currentWeb3.utils.fromWei(tradeResultInfo.Trade.size);
            const tradePrice = currentWeb3.utils.fromWei(tradeResultInfo.Trade.price);
            const dealAmount = formatNumberTransition(tradeSize);
            const dealPrice = formatNumberTransition(tradePrice);

            const notificationStr = t('notification.trade.success', {
              tradeDirection: tradeDirection,
              dealAmount: dealAmount,
              dealPrice: dealPrice,
              limitPrice: formatNumberTransition(limitPriceStr),
            });
            const descriptionNode: ReactNode = (
              <div>
                <span dangerouslySetInnerHTML={{ __html: notificationStr }}></span>
              </div>
            );
            // Bought/Sold 3.14 UNI at 1.1314 ETH
            txNotification.success({
              message: t('notification.trade.title', {
                contractSymbol: contractSymbol,
              }),
              description: descriptionNode,
              tx: tradeResult.transactionHash,
            });
          } catch (error) {
            gaException(error.message);
            txNotification.success({
              message: t('notification.trade.title', { contractSymbol: contractSymbol }),
              description: t('notification.trade.default'),
              tx: tradeResult.transactionHash,
            });
          }

          setIsTradeLoading(false);
        }
      }
    } catch (error) {
      gaException(error.message);
      console.log('🚀 ~ file: index.tsx ~ line 298 ~ TradeEntry ~ error', error);
      setIsTradeLoading(false);
      if (error && error.code === 4001) {
        return;
      }
      const err = error as ITransactionError;
      let errDescription: ReactNode = 'Trade failed';
      if (err.notifyWrap) {
        errDescription = err.notifyWrap(errDescription);
      }

      txNotification.error({
        duration: null,
        message: t('notification.trade.title', {
          contractSymbol: contractSymbol,
        }),
        description: errDescription,
        tx: err.receipt && err.receipt.transactionHash,
      });
    } finally {
      console.log(` \n\n\n ak555--------->finally->isBuy:${isBuy} `);
      resetInfo();
    }
  }, [
    tradeSide,
    currentAmmDetail,
    uiGlobalConfig.deadline,
    inputAmountStr,
    limitPriceBN,
    traderAddress,
    tradePrice,
    limitPriceStr,
    currentWeb3,
    t,
    onFetchCurrentPosition,
    resetInfo,
  ]);

  const getDiffMiningTip = useCallback(
    (name) => {
      return isDiffProduct ? (
        <Tooltip
          title={
            <span
              dangerouslySetInnerHTML={{
                __html: t('message.tooltip.trade.tradeEntry.diffTradePrice', { link: DIFF_CONTRACT_SPECS_LINK }),
              }}></span>
          }>
          {name}
        </Tooltip>
      ) : (
        name
      );
    },
    [isDiffProduct, t],
  );

  // 点击position填入数值
  const onClickCurrentPosition = useCallback((balance: number | string, unit: string) => {
    setInputAmountStr(new BigNumber(balance).abs().toString(10));
  }, []);

  // 1-没有输入， 2-availableMargin >0
  const isDisableTrade = useMemo(() => {
    // const isEnTrade = Number(currentPosition.availableMargin) === 0 || Number(inputAmountStr) === 0;
    // console.log(
    //   `isEnTrade:`,
    //   isEnTrade,
    //   `currentPosition.availableMargin`,
    //   currentPosition.availableMargin,
    //   `inputAmountStr:`,
    //   inputAmountStr,
    // );
    return Number(currentPosition.availableMargin) === 0 || Number(inputAmountStr) === 0;
  }, [currentPosition.availableMargin, inputAmountStr]);

  // trade fee 美元价格
  const tradeFeeInUSD = useMemo(() => {
    let res = 0;
    if (balanceList && balanceList.length > 0) {
      const coinBalance = balanceList.find((coin) => coin.symbol === quoteCurrency);
      if (coinBalance) {
        res = coinBalance.coinPrice * tradeFee;
      }
    }
    return res;
  }, [balanceList, quoteCurrency, tradeFee]);

  // text: side === buy ? Max. Trade Value : Min. Trade Value,
  // value: QuantityInput * LimitPrice
  const pricePopoverContent = useMemo(() => {
    return (
      <div className="popover-price-content">
        <dl>
          <dt>Limit Price</dt>
          <dd>
            {' '}
            {formatNumberPrefixTooltip(limitPriceStr)} {quoteCurrency}{' '}
          </dd>
        </dl>
        <dl>
          <dt> {tradeSide === TRADE_DIRECTION.LONG ? 'Max.' : 'Min.'} Trade Value </dt>
          <dd>
            {formatNumberPrefixTooltip(Number(inputAmountStr) * Number(limitPriceStr))} {quoteCurrency}
          </dd>
        </dl>
        <dl>
          <dt>Price Impact</dt>
          <dd>
            {Number(priceImpact) >= 0 ? '+' : ''}
            {formatNumber(priceImpact * 100, 2)}%
          </dd>
        </dl>
        <dl>
          <dt>Trade Fee</dt>
          <dd>
            {formatNumberPrefixTooltip(tradeFee)} {quoteCurrency}
          </dd>
        </dl>
        <dl>
          <dt> </dt>
          <dd>
            {!USD_COINS.includes(quoteCurrency) && tradeFee > 0 && (
              <span>
                {`≈ $`}
                {formatNumberTransition(tradeFeeInUSD)}
              </span>
            )}
          </dd>
        </dl>
      </div>
    );
  }, [inputAmountStr, limitPriceStr, priceImpact, quoteCurrency, tradeFee, tradeFeeInUSD, tradeSide]);

  return (
    <Form layout="vertical" className="trade-entry-form">
      <CurrentBalanceTitle
        className="trade-form__current-position"
        title="Current Position"
        type={BalanceTitleType.POSITION}
        tooltip={
          dynamicLeverage > 0
            ? `Dynamic leverage: ${formatNumberTransition(Math.ceil(dynamicLeverage * 100) / 100, 2) + 'x'}   `
            : ''
        }
        balance={currentPosition.position}
        balanceStyle={{
          color: negativeNumberColor(currentPosition.position),
        }}
        unit={baseCurrency}
        onClickBalance={onClickCurrentPosition}></CurrentBalanceTitle>

      <div className="trade-form-wrap">
        <div className="trade-input-wrap">
          <Form.Item
            // label="Quantity"
            className="trade-form-wrap-input"
            // validateStatus={simulationStatus}
            // help={simulationReason}
          >
            <Input
              // addonBefore="Quantity"
              disabled={isTradeLoading}
              placeholder={t('message.placeholder.tradeBuy')}
              defaultValue="0"
              min={0}
              max={100000000}
              value={inputAmountStr}
              onChange={onChangeAmountHandler}
              suffix={getDiffMiningTip(
                <>
                  <div className="trade-form__icon">
                    <img className="icon-coin" src={coinListConfig[baseCurrency].icon} alt={baseCurrency} />
                    <span>{baseCurrency}</span>
                  </div>
                </>,
              )}
            />
          </Form.Item>
        </div>

        <div className="trade-form-wrap-btn">
          <Button
            block
            className="trade-form__btn"
            disabled={isDisableTrade}
            loading={isTradeLoading}
            onClick={onClickTradeButton}>
            {!isTradeLoading && <img src={IconBtnTrade} alt="btn trade" />}
            Trade
          </Button>
        </div>
      </div>

      <div className="trade-simulation-wrap">
        <div className={`trade-simulation-content trade-simulation-content__${simulationStatus}`}>
          {simulationReason}
        </div>
      </div>

      {tradePrice > 0 && (
        <div className="trade-form__price-btn-wrap">
          <div className="trade-form__price-wrap">
            <div className="trade-form__price-title">Price </div>
            <div className="trade-form__price-number">{formatNumberPrefixTooltip(tradePrice)}</div>
            <div className="trade-form__price-tooltip">
              <Popover content={pricePopoverContent} placement="bottom">
                <InfoCircleOutlined className="popover-info-icon" style={{ verticalAlign: '1px' }} />
              </Popover>
            </div>
          </div>

          {isDiffProduct && (
            <div className="trade-form__difficulty-wrap">
              <div className="trade-form__price-difficulty">
                <dl>
                  <dt>Difficulty </dt>
                  <dd>
                    {getDiffNumByPrice(tradePrice)} {DIFF_HASHRATE_SCALE}
                    {getDiffMiningTip(
                      <QuestionCircleFilled className="tooltip-info-icon" style={{ verticalAlign: '1px' }} />,
                    )}
                  </dd>
                </dl>
              </div>
            </div>
          )}
        </div>
      )}
    </Form>
  );
}
