import React from 'react';
import IconDetail from '@/assets/svg/icons/icon_detail.svg';
import './DetailCard.scss';

interface IDetailCardProps {
  title: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  isHideIcon?: boolean;
  children: JSX.Element;
}

export default function DetailCard({ title, className, icon, isHideIcon, children }: IDetailCardProps): JSX.Element {
  return (
    <div className={`trade-details-card ${className}`}>
      <div className="trade-details-card-header">
        {!isHideIcon &&
          (icon ? icon : <img className="trade-details-card-header__icon" src={IconDetail} alt="icon detail" />)}
        {title}
      </div>
      <div className="trade-details-card-body">{children}</div>
    </div>
  );
}
