import React, { ReactNode, ReactElement, useCallback, useMemo, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { MinusOutlined, PlusOutlined, ArrowRightOutlined } from '@ant-design/icons';

import { synDiffWeb3, synWeb3 } from '@/synWeb3';
import { useTranslation } from 'react-i18next';
import { RedirectFromEnum } from '@/state/options/actions';
import { usePositionHook } from '@/state/position';
import { formatNumber, formatNumberTransition } from '@/utils/numberUtil';
import { ITransactionError } from '@/interfaces/error';
import { PARSE_RECEIPT_ERROR } from '@/constants';
import { txNotification } from '@/components/TxNotification';
import { isDiffProductSymbol } from '@/utils';
import { AMM_STATUS } from '@/constants/config';
import { IPoolListPrpos } from './index';
import { ReactGaEvent } from '@/utils/debugUtil';
import { currentUTCTime } from '@/utils/timeUtil';
import { IPosition } from '@/interfaces/position';

import './operationButtons.scss';
import { GaCategory, gaEvent, gaException } from '@/utils/gaUtil';

export interface IOperationModal {
  props: IPoolListPrpos;
  positionInfo: IPosition;
  traderAddress: string;
  maturityTime: number;
  onRemoveLiqiudityHandler: (ammDetail: IPosition) => void;
}

export enum SettleStateEnum {
  NORMAL = 'NORMAL',
  LOADING = 'LOADING',
  DONE = 'DONE',
}

export default function OperationButtons({
  props,
  positionInfo,
  traderAddress,
  maturityTime,
  onRemoveLiqiudityHandler,
}: IOperationModal): ReactElement {
  const { t } = useTranslation();
  const { onFetchPositionOnChain } = usePositionHook();

  const [settleState, setSettleState] = useState<SettleStateEnum>(SettleStateEnum.NORMAL); // settle进行中

  const onClickAddLiqiudity = (record: IPosition): void => {
    gaEvent(GaCategory.POOL, 'Open Add liquidity modal from list');
    props.setDataInfo && props.setDataInfo(record);
    props.setAddLiquidityTargetFrom(RedirectFromEnum.POOL_LIST_RECORD);
    props.setAddLiquidityModalVisible(true);
  };

  async function comboSettleActionHandler(record: IPosition): Promise<void> {
    const contractSymbol = record.symbol;
    try {
      setSettleState(SettleStateEnum.LOADING);
      const isDiffProduct = isDiffProductSymbol(record.symbol);
      const currentWeb3 = isDiffProduct ? synDiffWeb3 : synWeb3;

      // ### ReactGAEvent ###
      const settleParams = {
        method: 'currentWeb3.futures.settle',
        contractSymbol,
        ammProxy: record.pair?.ammProxy,
        futuresProxy: record.pair?.futuresProxy,
        traderAddress,
      };
      console.log(`GoogleAnalytics:${JSON.stringify(settleParams)}`);
      gaEvent(GaCategory.DEBUG, 'Settle', JSON.stringify(settleParams));
      // ### ReactGAEvent - END ###

      const settleResult = await currentWeb3.futures.settle(record.pair?.futuresProxy || '', traderAddress, function() {
        txNotification.info({
          message: t('notification.settle.title', { contractSymbol: contractSymbol }),
          description: t('notification.settle.info'),
        });
      });
      console.log(`\n\n onClickSettleHandler---> res:`, settleResult);

      if (settleResult.status) {
        setSettleState(SettleStateEnum.DONE); //settle完成,按钮禁用

        onFetchPositionOnChain(traderAddress, record);

        try {
          const settleResultInfo = currentWeb3.futures.parseReceipt(settleResult);
          console.log(`\n 1- settleResultInfo---> res:`, settleResultInfo);
          console.log(
            `getAmmDetail(ammProxy:${record.pair?.ammProxy}, futuresProxy:${record.pair?.futuresProxy}) success`,
          );
          if (settleResultInfo === PARSE_RECEIPT_ERROR) {
            console.log(`PARSE_RECEIPT_ERROR`);
            throw new Error(PARSE_RECEIPT_ERROR);
          }

          showNotification(contractSymbol, settleResultInfo, settleResult.transactionHash);
        } catch (error) {
          gaException(error.message);
          txNotification.success({
            message: t('notification.settle.title', { contractSymbol: contractSymbol }),
            description: t('notification.settle.default'),
            tx: settleResult.transactionHash,
          });
        }
      }
    } catch (error) {
      console.log(error);
      gaException(error.message);

      setSettleState(SettleStateEnum.NORMAL);
      if (error && error.code === 4001) {
        console.log('USER REJECT');
        return;
      }

      const err = error as ITransactionError;
      let errDescription: ReactNode = t('notification.settle.error');
      if (err.notifyWrap) {
        errDescription = err.notifyWrap(errDescription);
      }

      txNotification.error({
        message: t('notification.settle.title', { contractSymbol: contractSymbol }),
        description: errDescription,
        tx: err.receipt && err.receipt.transactionHash,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const showNotification = (
    contractSymbol: string,
    settleResultInfo: {
      Settle: { pnl: string; price: string; size: string; trader: string };
      Transfer: { value: string };
      Withdraw: { wadAmount: string };
    },
    tx?: string,
  ) => {
    let settlePrice = '';
    let wadAmount = '';

    if (settleResultInfo && settleResultInfo.Settle && settleResultInfo.Settle.price) {
      settlePrice = formatNumberTransition(synWeb3.utils.fromWei(settleResultInfo.Settle.price));
    }
    if (settleResultInfo && settleResultInfo.Withdraw && settleResultInfo.Withdraw.wadAmount) {
      wadAmount = formatNumberTransition(synWeb3.utils.fromWei(settleResultInfo.Withdraw.wadAmount));
    }

    const quoteToken = contractSymbol.split('-')[1];

    console.log(` ⛵  showNotification#settlePrice:${settlePrice}, wadAmount:${wadAmount}, quoteToken:${quoteToken}`);

    // depositAndInitPool
    const notificationStr = t('notification.settle.success', {
      quoteToken: quoteToken,
      wadAmount: wadAmount,
      settlePrice: settlePrice,
    });
    const descriptionNode: ReactNode = (
      <div>
        <span dangerouslySetInnerHTML={{ __html: notificationStr }}></span>
      </div>
    );

    // ### ReactGAEvent ###
    const settleResult = {
      method: 'settleResult',
      contractSymbol,
      settlePrice,
      wadAmount,
      quoteToken,
      traderAddress,
      tx,
    };
    console.log(`GoogleAnalytics:${JSON.stringify(settleResult)}`);
    gaEvent(GaCategory.DEBUG, 'Settle', JSON.stringify(settleResult));
    // ### ReactGAEvent - END ###

    txNotification.success({
      duration: null,
      message: t('notification.settle.title', { contractSymbol: contractSymbol }),
      description: descriptionNode,
      tx: tx,
    });
  };

  const onClickSettleHandler = (record: IPosition): void => {
    console.log(`onClickSettleHandler:`, record, settleState);

    comboSettleActionHandler(record);
  };

  let resDom = null;

  if (!positionInfo.pair?.ammDetail) {
    return <></>;
  }

  if (
    positionInfo.pair?.ammDetail.status === AMM_STATUS.TRADING &&
    maturityTime > Math.floor(currentUTCTime() / 1000)
  ) {
    resDom = (
      <React.Fragment>
        <Tooltip title="Add Liquidity">
          <Button className="button-wrap padded" size="small" onClick={() => onClickAddLiqiudity(positionInfo)}>
            <div className="button-normal">
              <PlusOutlined className="button-suffix-icon" title="Add" alt="Add" />
            </div>
          </Button>
        </Tooltip>

        <Tooltip title="Remove Liquidity">
          <Button
            style={{ marginLeft: 4 }}
            className="button-wrap padded button-white"
            size="small"
            onClick={(): void => onRemoveLiqiudityHandler(positionInfo)}>
            <div className="button-inner">
              <MinusOutlined className="button-suffix-icon" title="Remove" alt="Remove" />
            </div>
          </Button>
        </Tooltip>
      </React.Fragment>
    );
  } else if (positionInfo.pair?.ammDetail.status === AMM_STATUS.SETTLING) {
    resDom = (
      <React.Fragment>
        <Tooltip title="Remove Liquidity">
          <Button
            className="button-wrap padded button-white"
            size="small"
            onClick={(): void => onRemoveLiqiudityHandler(positionInfo)}>
            <div className="button-inner">
              <MinusOutlined className="button-suffix-icon" title="Remove" alt="Remove" />
            </div>
          </Button>
        </Tooltip>
      </React.Fragment>
    );
  } else if (positionInfo.pair?.ammDetail.status === AMM_STATUS.SETTLED) {
    resDom = (
      <React.Fragment>
        <Tooltip title="Settle">
          <Button
            className="button-wrap padded button-settle"
            size="small"
            loading={settleState === SettleStateEnum.LOADING}
            disabled={Number(positionInfo.pair?.ammDetail.shareBalance) === 0}
            onClick={(): void => onClickSettleHandler(positionInfo)}>
            <div className="button-normal">
              <ArrowRightOutlined />
            </div>
          </Button>
        </Tooltip>
      </React.Fragment>
    );
  }

  return <div>{resDom}</div>;
}
