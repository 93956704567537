import React, { useMemo } from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Field } from '@/state/swap/actions';
import { useTradeToken } from '@/state/swap/hooks';
import { useGlobalGetter } from '@/state/global/hooks';
import { negativeNumberColor } from '@/utils/numberUtil';
import { usePositionHook } from '@/state/position';
import { MIN_NUMBER_00000 } from '@/constants';
import { getDiffNumByPrice } from '@/utils/common';
import { isDiffProductSymbol } from '@/utils';
import { DIFF_HASHRATE_SCALE } from '@/constants/config';
import { PRODUCT_TYPE } from '@/constants/product';
import DetailCard from './DetailCard';
import formatNumberPrefixTooltip from '@/components/Common/formatNumberPrefixTooltip';

import './PositionDetail.scss';

export default function PositionDetail(): JSX.Element {
  const { t } = useTranslation();
  const { currencies } = useTradeToken();
  const quoteDefaultValue = currencies[Field.QUOTE] || '';

  const { currentPosition } = usePositionHook();

  const { currentProductType } = useGlobalGetter();

  const isSymbolDifficulty = useMemo(() => {
    return currentProductType === PRODUCT_TYPE.DIFFICULTY;
  }, [currentProductType]);

  const isDiffProduct = useMemo(() => {
    if (currentPosition.symbol) {
      return isDiffProductSymbol(currentPosition.symbol);
    }
    return false;
  }, [currentPosition.symbol]);

  const liqPrice = useMemo(() => {
    if (Number(currentPosition.liqPrice) > 0) {
      const price = Number(currentPosition.liqPrice);
      if (price > 1e20) {
        return Number(MIN_NUMBER_00000);
      } else {
        return price;
      }
    }
    return 0;
  }, [currentPosition.liqPrice]);

  return (
    <DetailCard
      className={`trade-detail-card_position-detail ${isSymbolDifficulty && 'trade-position-card-detail_diff'}`}
      title={<span>Position Detail</span>}>
      <div className={`position-detail__table ${isDiffProduct && 'position-detail__table_diff'}`}>
        <dl>
          <dt>Mark Price</dt>
          <dd>
            {formatNumberPrefixTooltip(currentPosition.markPrice)} {quoteDefaultValue}
          </dd>
        </dl>
        <dl>
          <dt>
            Liq. Price
            <Tooltip title="Liquidation Price. Your position will be liquidated if mark price reaches this level">
              <QuestionCircleFilled className="tooltip-info-icon" />
            </Tooltip>
          </dt>
          <dd>
            {formatNumberPrefixTooltip(liqPrice.toString())} {quoteDefaultValue}
          </dd>
        </dl>
        {isDiffProduct && (
          <dl>
            <dt>
              Liq. Difficulty
              <Tooltip title="Liquidation Difficulty. Your position will be liquidated if mark difficulty reaches this level">
                <QuestionCircleFilled className="tooltip-info-icon" />
              </Tooltip>
            </dt>
            <dd>
              {getDiffNumByPrice(liqPrice.toString())} {DIFF_HASHRATE_SCALE}
            </dd>
          </dl>
        )}
        <dl>
          <dt>
            Margin Bal.
            <Tooltip title="Margin Balance. Total balance of your margin in this AMM, including unrealized Pnl">
              <QuestionCircleFilled className="tooltip-info-icon" />
            </Tooltip>
          </dt>
          <dd
            style={{
              color: negativeNumberColor(currentPosition.marginBalance || 0),
            }}>
            {formatNumberPrefixTooltip(currentPosition.marginBalance || 0)} {quoteDefaultValue}
          </dd>
        </dl>
        <dl>
          <dt>
            Avail. Margin
            <Tooltip title="Available Margin. Margin that can be withdrawn or used to increase you current positions">
              <QuestionCircleFilled className="tooltip-info-icon" />
            </Tooltip>
          </dt>
          <dd>
            {formatNumberPrefixTooltip(currentPosition.availableMargin)} {quoteDefaultValue}
          </dd>
        </dl>
        <dl>
          <dt>
            Main. Margin
            <Tooltip title="Maintenance Margin. Minimum margin required to hold your current position. If your margin balance falls below this level, your position will be liquidated">
              <QuestionCircleFilled className="tooltip-info-icon" />
            </Tooltip>
          </dt>
          <dd>
            {formatNumberPrefixTooltip(currentPosition.mainMargin)} {quoteDefaultValue}
          </dd>
        </dl>
      </div>
    </DetailCard>
  );
}
