import { ORACLE_TYPE, AMM_STATUS, DIFF_BASE_NAME } from '@/constants/config';
import { chainBasicCoin } from './coin';
import { IPairConfig, IPairTreeList, IOraclePair, IPair, IPairTree } from '@/interfaces/pair';
import { oracleListConfig } from './oracle';
import { PRODUCT_TYPE, AVAILABLE_PRODUCTS } from '@/constants/product';
import { getDefaultPairTree } from '@/utils/pair';

export type IBasicPairTreeList = {
  [ORACLE_TYPE.CHAINLINK]: { [key: string]: IPairTree };
  [ORACLE_TYPE.UNISWAPV2]: { [key: string]: IPairTree };
};
export type ISynfuturesPairTreeList = {
  [ORACLE_TYPE.SYNFUTURES]: { [key: string]: IPairTree };
};

/**
 * 基础产品 pair配置
 */
const basicPairTreeConfig: IBasicPairTreeList = {
  [ORACLE_TYPE.CHAINLINK]: getDefaultPairTree(oracleListConfig[ORACLE_TYPE.CHAINLINK]),
  [ORACLE_TYPE.UNISWAPV2]: getDefaultPairTree(oracleListConfig[ORACLE_TYPE.UNISWAPV2]),
};

/**
 * 难度产品pair配置
 */
const diffPairTreeConfig: ISynfuturesPairTreeList = {
  [ORACLE_TYPE.SYNFUTURES]: getDefaultPairTree(oracleListConfig[ORACLE_TYPE.SYNFUTURES]),
};

/**
 *  交易对配置
 */
const pairTreeConfig: IPairTreeList = AVAILABLE_PRODUCTS.reduce((config, productType) => {
  if (productType === PRODUCT_TYPE.DIFFICULTY) {
    config = { ...config, ...diffPairTreeConfig };
  } else if (productType === PRODUCT_TYPE.BASIC) {
    config = { ...config, ...basicPairTreeConfig };
  }
  return config;
}, {} as IPairTreeList);

// 默认UNISWAP ETH/USDC交易对
let defaultOracle: ORACLE_TYPE = ORACLE_TYPE.UNISWAPV2;
let DEFAULT_PAIR_CONFIG: IPairConfig;
if (AVAILABLE_PRODUCTS.includes(PRODUCT_TYPE.BASIC)) {
  defaultOracle = ORACLE_TYPE.UNISWAPV2;
  DEFAULT_PAIR_CONFIG = pairTreeConfig[defaultOracle][chainBasicCoin.symbol].pairs[0];
} else if (AVAILABLE_PRODUCTS.includes(PRODUCT_TYPE.DIFFICULTY)) {
  defaultOracle = ORACLE_TYPE.SYNFUTURES;
  DEFAULT_PAIR_CONFIG = pairTreeConfig[defaultOracle][DIFF_BASE_NAME].pairs[0];
} else {
  defaultOracle = ORACLE_TYPE.UNISWAPV2;
  DEFAULT_PAIR_CONFIG = pairTreeConfig[defaultOracle][chainBasicCoin.symbol].pairs[0];
}

const EMPTY_ORACLE_PAIR: IOraclePair = {
  ammProxy: '',
  baseAddress: '',
  baseName: DEFAULT_PAIR_CONFIG.baseCoin.symbol,
  futuresProxy: '',
  id: '',
  oracleType: defaultOracle,
  quoteTokenAddress: '',
  quoteTokenSymbol: DEFAULT_PAIR_CONFIG.quoteCoin.symbol,
  expiry: '',
  symbol: '',
};
const DEFAULT_PAIR: IPair = {
  ...DEFAULT_PAIR_CONFIG,
  isInit: true,
  status: AMM_STATUS.TRADING,
  oraclePair: EMPTY_ORACLE_PAIR,
};

export { pairTreeConfig, DEFAULT_PAIR };
