import { ORACLE_TYPE } from '@/constants/config';
import { IOracleList, IOracle } from '@/interfaces/oracle';
import { coinListConfig } from './coin';
import { PRODUCT_TYPE, AVAILABLE_PRODUCTS } from '@/constants/product';
import { DIFF_BASE_NAME } from '@/constants/config';

export type IBasicOracleList = {
  [ORACLE_TYPE.CHAINLINK]: IOracle;
  [ORACLE_TYPE.PANCAKEV2]: IOracle;
};
export type ISynfuturesOracleList = {
  [ORACLE_TYPE.SYNFUTURES]: IOracle;
};

const basicOracleListConfig: IBasicOracleList = {
  [ORACLE_TYPE.CHAINLINK]: {
    ...coinListConfig['LINK'],
    type: ORACLE_TYPE.CHAINLINK,
    defaultBaseCoins: [coinListConfig['BTC'], coinListConfig['XAU']],
    defaultQuoteCoins: [coinListConfig['BUSD']],
  },
  [ORACLE_TYPE.PANCAKEV2]: {
    ...coinListConfig['Cake'],
    symbol: 'CAKEV2',
    fullName: 'Pancake V2',
    type: ORACLE_TYPE.PANCAKEV2,
    defaultBaseCoins: [coinListConfig['BNB'], coinListConfig['Cake']],
    defaultQuoteCoins: [coinListConfig['BUSD'], coinListConfig['BNB']],
  },
};

const diffOracleListConfig: ISynfuturesOracleList = {
  [ORACLE_TYPE.SYNFUTURES]: {
    ...coinListConfig['SFT'],
    type: ORACLE_TYPE.SYNFUTURES,
    defaultBaseCoins: [coinListConfig[DIFF_BASE_NAME]],
    defaultQuoteCoins: [coinListConfig['BTCB']],
  },
};

/**
 *  交易对配置
 */
const oracleListConfig: IOracleList = AVAILABLE_PRODUCTS.reduce((config, productType) => {
  if (productType === PRODUCT_TYPE.DIFFICULTY) {
    config = { ...config, ...diffOracleListConfig };
  } else if (productType === PRODUCT_TYPE.BASIC) {
    config = { ...config, ...basicOracleListConfig };
  }
  return config;
}, {} as IOracleList);

const defaultOracleType = ORACLE_TYPE.PANCAKEV2;

export { oracleListConfig, defaultOracleType };
