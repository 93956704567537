import React, { FC, ReactElement, useState, ReactNode, useCallback } from 'react';
import { TransactionReceipt } from 'web3-core';
import { useWeb3React } from '@web3-react/core';
import { Button, Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ContractTickerSelector from '@/components/ContractTickerSelector';
import { useTradeToken, useTradeMarity, useTradeOrcle } from '@/state/swap/hooks';
import { TransactionReceiptDefault } from '@/constants';
import { ORACLE_TYPE, AMM_STATUS } from '@/constants/config';
import { IPair, IOraclePair } from '@/interfaces/pair';
import { useCoinGetter, usePairsActionHandlers } from '@/state/pairs/hooks';
import { RedirectFromEnum } from '@/state/options/actions';
import { ITransactionError } from '@/interfaces/error';
import isZero from '@/utils/isZero';
import { synWeb3, synDiffWeb3, SYNWeb3 } from '@/synWeb3';
import { getSymbolByOracleType } from '@/utils';
import { IAmmDetail } from '@/interfaces/amm';
import { txNotification } from '@/components/TxNotification';
// import { usePositionHook } from '@/state/position';
import { IPosition } from '@/interfaces/position';
import { chainConfig } from '@/constants/chain';

import './style.scss';
import { GaCategory, gaEvent, gaException } from '@/utils/gaUtil';

// setProgressStep
enum IProgressStep {
  LOADING = 0,
  CREATING = 1,
  DONE = 2,
}

export enum CreateStatusEnum {
  Init,
  Done,
}

type nullType = string | null;

interface ICreatePoolProps {
  targetFrom: string;
  visible: boolean;
  setVisible?: any;
  freeze?: boolean;
  setFreeze?: any;
  setAddLiquidityTargetFrom?: any;
  setAddLiquidityModalVisible?: any;
  dataInfo?: any;
  setDataInfo?: (info: IPosition) => void;
  query?: string;
}

const CreatePoolModal: FC<ICreatePoolProps> = (props: ICreatePoolProps): ReactElement => {
  const { t } = useTranslation();
  const { active, account } = useWeb3React();
  const traderAddress = account || '';
  const [createStatus, setCreateStatus] = useState<CreateStatusEnum>(CreateStatusEnum.Init);
  const { currencies } = useTradeToken();
  const { marity } = useTradeMarity();
  const { oracle } = useTradeOrcle();

  const { onAddPairAction } = usePairsActionHandlers();
  // const { onAddPositionByAddress } = usePositionHook();
  const { coinListConfig } = useCoinGetter();
  const [progressStep, setProgressStep] = useState<IProgressStep>(IProgressStep.LOADING);
  const [onprocess, setOnprocess] = useState(false);
  const [recordData, setRecordData] = useState<IPosition>();

  const onClickCancelHandler = (): void => {
    setOnprocess(false);
    setProgressStep(IProgressStep.LOADING);
    setCreateStatus(CreateStatusEnum.Init);
    props.setFreeze(false);
    props.setVisible(false);
  };

  const onClickInitializeHandler = (): void => {
    gaEvent(GaCategory.POOL, 'Open Initialize Pool Modal');
    setProgressStep(IProgressStep.LOADING);
    setCreateStatus(CreateStatusEnum.Init);
    props.setFreeze(false);
    props.setVisible(false);
    props.setAddLiquidityTargetFrom(RedirectFromEnum.FROM_CREATE_POOL);
    props.setAddLiquidityModalVisible(true);
    recordData && props.setDataInfo && props.setDataInfo(recordData);
  };

  // 获取合约地址
  const getContractAddresses = async (
    oracleType: string,
    baseAddress: string,
    quoteAddress: string,
    maturity: string,
    currentWeb3: SYNWeb3 = synWeb3,
  ): Promise<[string, string, string]> => {
    let ammProxy: null | string = ``;
    let futuresProxy: null | string = ``;
    let err = '';
    if (oracleType === chainConfig.defaultOracleType) {
      [ammProxy, futuresProxy, err] = await currentWeb3.reader.getUniswapContractAddresses(
        baseAddress,
        quoteAddress,
        maturity,
      );
    } else if (oracleType === ORACLE_TYPE.CHAINLINK) {
      [ammProxy, futuresProxy, err] = await currentWeb3.reader.getChainlinkContractAddresses(
        baseAddress,
        quoteAddress,
        maturity,
      );
    } else if (oracleType === ORACLE_TYPE.SYNFUTURES) {
      [ammProxy, futuresProxy, err] = await currentWeb3.reader.getBtcHashRateContractAddresses(quoteAddress, maturity);
    }
    return [ammProxy || '', futuresProxy || '', err];
  };

  // 获取合约地址
  const newContractAddress = async (
    oracleType: ORACLE_TYPE,
    ammProxy: nullType,
    futuresProxy: nullType,
    baseAddress: string,
    quoteAddress: string,
    maturity: string,
    contractSegment: string,
    currentWeb3: SYNWeb3 = synWeb3,
  ): Promise<TransactionReceipt | null> => {
    console.log(`will new${oracleType}Pair:`, baseAddress, quoteAddress, maturity);
    let createPairResult: TransactionReceipt = TransactionReceiptDefault;

    const contractSymbol = `${contractSegment}-${getSymbolByOracleType(oracleType)}`;

    const txSendingNotifyFn = (str: string): void => {
      txNotification.info({
        message: t('notification.createPair.title', {
          contractSymbol,
        }),
        description: t('notification.createPair.info'),
      });
    };

    if (oracleType === chainConfig.defaultOracleType) {
      createPairResult = await currentWeb3.factory.newUniswapPair(
        traderAddress,
        baseAddress,
        quoteAddress,
        maturity,
        txSendingNotifyFn,
      );
    } else if (oracleType === ORACLE_TYPE.CHAINLINK) {
      createPairResult = await currentWeb3.factory.newChainlinkPair(
        traderAddress,
        baseAddress,
        quoteAddress,
        maturity,
        txSendingNotifyFn,
      );
    } else if (oracleType === ORACLE_TYPE.SYNFUTURES) {
      createPairResult = await currentWeb3.factory.newBitcoinMiningDifficultyPair(
        traderAddress,
        quoteAddress,
        maturity,
        txSendingNotifyFn,
      );
    }

    // ### ReactGAEvent ###
    const createPoolPairParams = {
      method: 'currentWeb3.factory.createPoolPair',
      contractSymbol,
      oracleType,
      traderAddress,
      quoteAddress,
      maturity,
    };
    console.log(`GoogleAnalytics:${JSON.stringify(createPoolPairParams)}`);
    gaEvent(GaCategory.DEBUG, 'CreatePoolPair', JSON.stringify(createPoolPairParams));
    // ### ReactGAEvent - END ###

    console.log(`Create Pool Pair Success.:【 ${oracleType} 】:`, createPairResult);
    return createPairResult;
  };

  /**
   *  添加pair到redux
   * @param oracleSelected
   * @param baseTokenSelect
   * @param quoteTokenSelect
   * @param maturity
   * @param baseAddress
   * @param quoteAddress
   * @param ammProxy
   * @param futuresProxy
   * @param ammDetail
   */
  async function addPair(
    oracleSelected: ORACLE_TYPE,
    baseTokenSelect: string,
    quoteTokenSelect: string,
    ammDetail: IAmmDetail,
    oraclePair: IOraclePair,
  ): Promise<void> {
    // Pair Created Success，Query ammProxy&futuresProxy
    const baseCoin = coinListConfig[baseTokenSelect];
    const quoteCoin = coinListConfig[quoteTokenSelect];

    const pairInfo: IPair = {
      isInit: false,
      status: AMM_STATUS.TRADING,
      oracleType: oracleSelected,
      oraclePair: oraclePair,
      baseCoin: baseCoin,
      quoteCoin: quoteCoin,
    };

    console.log(`\n\n  1-onAddPairAction>>>ammDetail:`, ammDetail);
    console.log(`\n\n  2-onAddPairAction>>>pairInfo:`, pairInfo);
    onAddPairAction(pairInfo);
  }

  /**
   * 构造新的position给initLp
   */
  const generateNewPosition = useCallback(
    (traderAddress: string, ammDetail: IAmmDetail, futuresProxy: string, oraclePair: IOraclePair) => {
      const newPosition: IPosition = {
        id: `${traderAddress.toLowerCase()}-${futuresProxy}`,
        symbol: ammDetail.symbol,
        position: '0',
        entryPrice: '0',
        markPrice: '0',
        accountBalance: '0',
        unrealPnl: '0',
        liqPrice: '0',
        positionMargin: '0',
        mainMargin: '0',
        availableMargin: '0',
        pair: {
          ...oraclePair,
          ammDetail: ammDetail,
          symbol: ammDetail.symbol,
          futuresProxy: futuresProxy,
          ammProxy: ammDetail.ammProxy,
        },
      };
      return newPosition;
    },
    [],
  );

  const asyncContractCreate = async (
    oracleSelected: ORACLE_TYPE,
    baseTokenSelect: string,
    quoteTokenSelect: string,
    maturity: string,
    currentWeb3: SYNWeb3 = synWeb3,
  ): Promise<void> => {
    setOnprocess(true);
    props.setFreeze(true); //冻结合约日期币种Selector
    setProgressStep(IProgressStep.CREATING);

    let baseAddress = baseTokenSelect;
    const quoteAddress = coinListConfig[quoteTokenSelect].address;

    if (oracleSelected === chainConfig.defaultOracleType) {
      baseAddress = coinListConfig[baseTokenSelect].address;
    }

    const [ammProxy, futuresProxy, err] = await getContractAddresses(
      oracleSelected,
      baseAddress,
      quoteAddress,
      maturity,
      currentWeb3,
    );
    if (err) {
      return;
    }

    console.log(` ammProxy, futuresProxy : ${ammProxy}, ${futuresProxy} `);

    const contractSegment = `${baseTokenSelect}-${quoteTokenSelect}-${maturity.replace(/-/gi, '')}`;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (!(isZero(ammProxy!) && isZero(futuresProxy!))) {
      console.log(` 📮  The contract has been created`, `\n`, `ammProxy:${ammProxy}, futuresProxy:${futuresProxy}`);
      setOnprocess(false);
      setProgressStep(IProgressStep.LOADING);

      const contractSymbol = `${contractSegment}-${getSymbolByOracleType(oracleSelected)}`;
      // has been created
      txNotification.warning({
        message: t('notification.createPair.title', {
          contractSymbol: contractSymbol,
        }),
        description: t('notification.createPair.existPair'),
      });
      return;
    }

    try {
      setProgressStep(IProgressStep.CREATING);
      console.log(`will new${oracleSelected}Pair:`, baseAddress, quoteAddress, maturity);
      const createPairResult = await newContractAddress(
        oracleSelected,
        ammProxy,
        futuresProxy,
        baseAddress,
        quoteAddress,
        maturity,
        contractSegment,
        currentWeb3,
      );

      if (createPairResult && createPairResult.status) {
        // Pair Created Success，Query ammProxy&futuresProxy
        const [ammProxy, futuresProxy] = await getContractAddresses(
          oracleSelected,
          baseAddress,
          quoteAddress,
          maturity,
          currentWeb3,
        );

        const [ammDetail, err] = await currentWeb3.amm.getAmmDetail(
          traderAddress,
          ammProxy as string,
          futuresProxy as string,
        );

        if (ammDetail) {
          const oraclePair: IOraclePair = {
            ammProxy: ammProxy as string,
            baseAddress: baseAddress,
            baseName: baseTokenSelect,
            expiry: Math.floor(new Date(maturity).getTime() / 1000).toString(),
            futuresProxy: futuresProxy as string,
            id: futuresProxy,
            oracleType: oracleSelected,
            quoteTokenAddress: quoteAddress,
            quoteTokenSymbol: quoteTokenSelect,
            symbol: (ammDetail as IAmmDetail).symbol,
          };
          // 构造pair
          addPair(oracleSelected, baseTokenSelect, quoteTokenSelect, ammDetail, oraclePair);
          // 构造position
          const position = generateNewPosition(traderAddress, ammDetail, futuresProxy, oraclePair);
          setRecordData(position);
        }

        // nextTickProcess(`Ready for Initialization`, 2);
        setProgressStep(IProgressStep.DONE);
        setOnprocess(false);
        setCreateStatus(CreateStatusEnum.Done);

        txNotification.success({
          message: t('notification.createPair.title', {
            contractSymbol: ammDetail?.symbol,
          }),
          description: t('notification.createPair.success'),
          tx: createPairResult.transactionHash,
        });
      }
    } catch (error) {
      gaException(error.message);
      setOnprocess(false);
      setProgressStep(IProgressStep.LOADING);

      if (error && error.code === 4001) {
        console.log('USER REJECT');
        return;
      }

      const err = error as ITransactionError;
      let errDescription: ReactNode = 'Failed';
      if (err.notifyWrap) {
        errDescription = err.notifyWrap(errDescription);
      }

      txNotification.error({
        duration: null,
        message: t('notification.createPair.title'),
        description: errDescription,
        tx: err.receipt && err.receipt.transactionHash,
      });
    }
  };

  // 创建合约
  const onClickCreateHandler = async (): Promise<void> => {
    gaEvent(GaCategory.POOL, 'Create Pool');
    if (active) {
      console.group(`comboCreatePool`);
      if (oracle.ORACLE && currencies.BASE && currencies.QUOTE && marity.MARITY) {
        const currentWeb3 = oracle.ORACLE === ORACLE_TYPE.SYNFUTURES ? synDiffWeb3 : synWeb3;
        console.log(`comboCreatePool-->:`);
        console.log(`comboCreatePool-->:${oracle.ORACLE}  ${currencies.BASE}  ${currencies.QUOTE}  ${marity.MARITY}`);
        asyncContractCreate(
          oracle.ORACLE as ORACLE_TYPE,
          currencies.BASE as string,
          currencies.QUOTE as string,
          (marity.MARITY || '').toString(),
          currentWeb3,
        );
      } else {
        console.log(`Please select Contract info`);
      }
      console.groupEnd();
    } else {
      setOnprocess(false);
      setProgressStep(IProgressStep.LOADING);

      txNotification.warning({
        message: t('notification.wallet.title'), //'Warning',
        description: t('notification.wallet.noConnection'), // 'Please connect your wallet',
      });
    }
  };

  return (
    <React.Fragment>
      <Modal
        className="pool-card-modal create-pool"
        visible={props.visible}
        maskClosable={false}
        onOk={onClickCancelHandler}
        onCancel={onClickCancelHandler}
        footer={null}
        destroyOnClose
        style={{
          width: 456,
        }}>
        <div className="create-pool-title">Create Pool</div>
        <Spin
          className={progressStep >= 1 ? 'process-done' : ''}
          tip={progressStep >= 1 ? ' ' : ' '}
          spinning={onprocess || progressStep !== 0}>
          <ContractTickerSelector
            targetFrom={props.targetFrom}
            dataInfo={props.dataInfo}
            freeze={props.freeze}
            query={props.query}
          />
        </Spin>

        {onprocess ? (
          <div className="create-pool-btnwrap">
            <Button className="create-pool-button">
              <div className="create-pool-button-text">
                <LoadingOutlined style={{ marginRight: 8 }} />
                Creating
              </div>
            </Button>
          </div>
        ) : (
          <React.Fragment>
            {createStatus === CreateStatusEnum.Done ? (
              <div className="create-pool-btnwrap">
                <Button className="create-pool-button" onClick={onClickInitializeHandler}>
                  <div className="create-pool-button-text">Initialize Pool</div>
                </Button>
              </div>
            ) : (
              <div className="create-pool-btnwrap">
                <Button className="create-pool-button" onClick={onClickCreateHandler}>
                  <div className="create-pool-button-text">Create</div>
                </Button>
              </div>
            )}
          </React.Fragment>
        )}
      </Modal>
    </React.Fragment>
  );
};
export default CreatePoolModal;
